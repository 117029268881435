import React from "react";
import Company from "../component/Company";

function CompanyContainer() {
  return (
    <>
      <Company></Company>
    </>
  );
}
export default CompanyContainer;
