import React from "react";

function Intro() {
  return (
    <section className="page-banner">
      <div className="container">
        <div className="page-content-wrapper">
          <div className="page-badge">REPORTING</div>
          <h1 className="page-title">
            {/* Create Maximum Transparency with ESG Reporting */}
            Best ESG Reporting Software
          </h1>
          <p className="page-description">
            Generate ESG reports aligned with internationally recognized
            sustainability frameworks <br />
            such as GRI, CSRD, ESRS, TCFD, CDP, BRSR, IFRS and others using the
            automated data fetch feature.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Intro;
