import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFour() {
  return (
    <section className="grant-feature-section">
      <div className="grant-feature-container">
        <div className="grant-feature-wrapper">
          <div className="grant-feature-image-wrapper">
            <div className="grant-feature-image">
              <img src={assets.images.sampleImage} alt="updapt" />
            </div>
            <div className="dot-shape4">  
             <img src={assets.images.homeBannerImageDot} alt="updapt" />
             </div>
          </div>
          <div className="grant-feature-content-wrapper">
            <div className="grant-feature-content">
              <h1 className="grant-feature-title">
                Align with UN SDGs Programs{" "}
              </h1>
              <p className="grant-feature-description">
                Label your CSR projects with he UN SDGs and get a marketplace
                for local and international NGOs and their related programs
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFour;
