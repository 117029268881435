import React from "react";

function FormSection() {
  return (
    <div className="free-trial-feature">
      <h1 className="page-title">Start your Free Trial</h1>
      <p className="free-trial-feature-description">
        Updapt helps businesses improve their sustainability performance with a
        SaaS solution that allows seamless ESG data management and reporting.
      </p>

      <ul className="solution-feature-menu">
        With Updapt's ESG platform, you will be able to :
        <li className="solution-feature-menu-list">
          Collect and track ESG data with ease.
        </li>
        <li className="solution-feature-menu-list">
          Calculate GHG Scope 1, 2 and 3 emissions and other ESG related data.
        </li>
        <li className="solution-feature-menu-list">
          Visualize ESG performance using analytical dashboards.
        </li>
        <li className="solution-feature-menu-list">
          Generate ESG reports aligned with global sustainability frameworks.
        </li>
        <li className="solution-feature-menu-list">
          Automate and comply with SEBI's Business Responsibility and
          Sustainability Reporting requirement.
        </li>
        <li className="solution-feature-menu-list">
          Audit, trace and validate data throughout the entire ESG process.
        </li>
      </ul>

      <p className="free-trial-feature-description">
        Accelerate your sustainability journey with Updapt's easy-to-use ESG
        platform. Fill out the form, and we'll reach out to you to schedule a
        demo.
      </p>
    </div>
  );
}

export default FormSection;
