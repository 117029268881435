import React, { useState } from "react";
import CaseStudyOne from "../components/CaseStudyOne";

function CaseStudyOneContainer() {
  const [caseStudyData] = useState({
    title: "Cement Industry Case Study",
    img: "cementIndustry.jpg",
    content:
      "The company is one of the India's pioneering homegrown cement companies. The company operates a manufacturing capacity of 35.9 MnT per annum (MTPA), across 14 cement plants and grinding units which are spread across 10 states. With over 33,000 dealers and sub-dealers, the company presently services more than 22 states and is among the leading players in every region where it is present in, this company is India's largest manufacturer of slag cement and is a category leader in super-speciality cement used for oil wells, railway sleepers, and airstrips. As a prominent Indian cement manufacturer and thought leader, the company continues to aspire to enhance value in a sustainable way for all its stakeholders. The company is 13.3 times water positive and will be 20 times water positive by 2025. The company's commitment towards environmental protection reflects in its achievements. It has the lowest carbon footprint in the cement world globally and has also been ranked No. 1 in the global cement manufacturing sector by the Carbon Disclosure Project (CDP) for business readiness of lowest carbon transition. ",
  });
  const [downloadModalBool, setDownloadModalBool] = useState(false);
  return (
    <CaseStudyOne
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
    />
  );
}

export default CaseStudyOneContainer;
