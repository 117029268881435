import React from "react";
import "../SCSS/styles.scss";

function BlogEleven() {
  return (
    <>
      <div className="BlogEleven-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <div className="blog-title">Sustainability-Towards a Better Future</div>
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Dec 23, 2019</div>
              <div className="blog-read-time">4 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-2.jpg").default}
            alt="Blog-2"
          />
        </div>
        <p>
          <b>
            “Climate Change”, “Sustainable Development Goals”, “The Paris
            Agreement”, “ESG”, “Sustainable Finance”, “Sustainability Reporting”
          </b>
          . You must be wondering why we are listing all these topics
          consecutively, and anxiously expecting that this isn't another humdrum
          blog. The fact is, these are the topics which have gained tremendous
          mileage in 2019 and have pushed companies to reassess their business
          footprint on internal and external elements which are non-financial in
          nature.
        </p>

        <p>
          Yet, it has been observed that many organizations (public or private)
          are still in the nascent stage of acknowledging that ESG risks pose
          greater economic impact to the future of the business.
        </p>
        <p>
          In the current world, for businesses to become more viable and
          sustainable in the long run, they have to push for a shift in
          objectives from maximizing shareholder wealth to acknowledging the
          fact that environmental, social and governance factors are essential
          for long term value creation. This shift in considerations will
          require greater accountability by being transparent to the various
          parties involved in businesses, such as customers, employees,
          shareholders, society and the government.
        </p>
        <p>
          <i>
            Headlines from the New York Times and the Associated Press, saying
            <b>
              “Shareholder Value Is No Longer Everything, Top C.E.O.s Say”
            </b>{" "}
            and <b>“Top US CEOs Rethink the Meaning of Shareholder Value”</b>
          </i>
        </p>
        <p>
          With the need for greater transparency of a company’s performance in
          environmental and societal contribution, comes the need to produce
          high quality ESG data which is structured, related and meaningful to
          the relevant parties.
        </p>
        <p>
          So the immediate question now is: Where do I start? What goes into
          ESG? Am I doing it right? Let us assure you that the later part of
          this blog isn't going to be a rah-rah, ambitious speech on ESG.
          Instead, we’re going to break down the science behind how to start a
          sustainability journey with your company using ESG analysis and create
          awareness on different aspects constituting it. Whether you are new to
          sustainability management or already practicing it, this page should
          cover everything you need to know.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            So the immediate question now is: Where do I start? What goes into
            ESG? Am I doing it right?
          </div>
        </div>
        <p>
          <b>Deciding what applies to your area of business</b>
        </p>

        <p>
          Whether you're a conglomerate, pharma industry or an SME, not all ESG
          factors apply to all industries. Hence, understanding which key ESG
          measures applies to your business is crucial. Classifying ESG measures
          on an industry basis provides an opportunity to bridge the gap. So the
          question is how can we assess the gap?
        </p>

        <ul>
          <li>
            Awareness about your current sustainability strategies, targets and
            initiatives
          </li>
          <li>Understand what ESG factors are relevant for your industry</li>
          <li>
            Evaluate the gap between current status and the industry relevant
            requirements.
          </li>
          <li>
            Initiate efforts to identify specific gap elements and draft a plan
            to set strategy and action
          </li>
        </ul>

        <div className="sub-header">
          <div className="sub-header-content">
            Understanding which key ESG measures applies to your business is
            crucial. Classifying ESG measures on an industry basis provides an
            opportunity to bridge the gap. So the question is how can we assess
            the gap?
          </div>
        </div>
        <p>
          <b>Discovering and prioritize the relevant ESG factors</b>
        </p>
        <p>
          Post identifying the ESG factors via industry Gap analysis, it does
          become a difficult task for businesses to assess and implement
          solutions for all the ESG factors. Hence, it would be quintessential
          for a firm to evaluate these factors on the 5 important parameters
          mentioned below. By prioritizing factors which are an immediate risk
          to the company’s key functions and capitalize on opportunities arising
          from it.
        </p>

        <ul>
          <li>Operational Risk & Opportunity</li>
          <li>Financial Risk & Opportunity</li>
          <li>Reputational Risk & Opportunity</li>
          <li>Significance level of each ESG factors for businesses</li>
          <li>Cost impact on Execution</li>
        </ul>
        <p>
          <b>Aligning the ESG performance with Key Standards</b>
        </p>
        <p>
          When the company decides to set a sustainability strategy and starts
          setting targets and initiatives around it, it is inevitable that it
          has to be reported in a more structured manner to ensure the message
          is broadcasted to all the stakeholders as intended.
        </p>
        <p>
          Unlike reporting financial statements in GAAP or IFRS, ESG does not
          have a standard template which applies universally. To fill this void,
          there are few prominent organizations (such as GRI, CDP, SASB, TCFD)
          who specialize in categorizing key sustainability issues and risks
          under the broad headings of E,S and G.
        </p>
        <p>
          Businesses should ensure they evaluate these standards with the help
          of consultants and experts and select a method which is suitable to
          report their performance data. Hence, mapping the key ESG factors
          which were identified during the gap analysis and prioritization
          phase, becomes imperative.
        </p>

        <p>
          <b>Associating with world class initiatives</b>
        </p>
        <p>
          With increasing visibility and call for decarbonization, environment
          conservation, water conservation etc. an organization’s contribution
          to communities and making it a better place to live is rapidly gaining
          focus. The candid fact is businesses in the future will have no choice
          but to adopt these radical changes into their core business values in
          order to thrive.
        </p>
        <p>
          We believe that swiftly adopting and aligning with world initiatives
          such as the SDGs, the Paris agreement etc. will boost the
          organization's reputation and showcase the core values of the business
          in a brighter light.
        </p>
        <p>
          How can a business align with these larger goals? The answer is deeply
          rooted in the actions the company takes on how it uses these ESG
          factors. One can directly or indirectly link the prioritized ESG
          factors to the 17 SDG goals. The initiatives taken to conserve
          forests, provide solutions to water scarcity, contributing to
          underdeveloped communities, eradicating unemployment, hunger and
          poverty etc. directly contributes to the sustainable development goals
          developed by the UN.
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            The candid fact is businesses in the future will have no choice but
            to adopt these radical changes into their core business values in
            order to thrive.
          </div>
        </div>
        <p>
          These above mentioned brief steps on how an organization needs to
          explore sustainability gives you a glimpse of the features available
          on our upcoming cloud-based ESG Advisory product "u Advisory". We aim
          to solve these problems by structuring an innovative sustainability
          approach for new organizations and for firms who are already
          practicing it.
        </p>
        <p>
          We have carefully curated the product with insightful analysis’
          critical to your organization, user-friendly dashboards and an
          incredible list of ESG factors and measures that are custom-made to
          apply to your type of industry.
        </p>
        <p>
          We will be sharing more exciting details of the product on our social
          media feeds soon. Don’t forget to follow us.
        </p>
        <p>
          Let's get in touch if you need any immediate help on how to be a
          leader in sustainability.
        </p>
      </div>
    </>
  );
}
export default BlogEleven;
