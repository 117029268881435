import React from "react";

function Intro() {
  return (
    <section className="page-banner">
      <div className="container">
        <div className="page-content-wrapper">
          <h1 className="page-title">Newsroom</h1>
          <p className="page-description">News for Publication or Broadcast</p>
        </div>
      </div>
    </section>
  );
}

export default Intro;
