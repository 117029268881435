import React from "react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";

function FreeTrialForm({
  mobile,
  setMobile,
  handleTextOnChange,
  handleSendRequest,
  formObj,
  setSelectedOption,
  selectedOption,
  options,
  customStyles,
}) {
  return (
    <div className="free-trial-form">
      <form className="free-trial-form-wrapper">
        <div className="row">
          <div className="free-trial-form-field-name">
            <label className="form-label" htmlFor="free-trial-fname">
              First Name <span className="required">*</span>
            </label>
            <input
              id="firstName"
              name="firstName"
              className="form-input"
              type="text"
              placeholder="John"
              value={formObj.firstName}
              onChange={handleTextOnChange}
            ></input>
          </div>

          <div className="free-trial-form-field-name">
            <label className="form-label" htmlFor="free-trial-lname">
              Last Name <span className="required">*</span>
            </label>
            <input
              id="lastName"
              name="lastName"
              className="form-input"
              type="text"
              placeholder="Smith"
              value={formObj.lastName}
              onChange={handleTextOnChange}
            ></input>
          </div>
          <div className="free-trial-form-field-email">
            <label className="form-label" htmlFor="free-trial-email">
              Work Email <span className="required">*</span>
            </label>
            <input
              id="email"
              name="email"
              className="form-input"
              type="email"
              placeholder="johnsmith@company.com"
              value={formObj.email}
              onChange={handleTextOnChange}
            ></input>
          </div>
          <div className="free-trial-form-field-name">
            <label className="form-label" htmlFor="free-trial-job-title">
              Job Title <span className="required">*</span>
            </label>
            <input
              id="jobTitle"
              name="jobTitle"
              className="form-input"
              placeholder="Sustainability Manager"
              type="text"
              value={formObj.jobTitle}
              onChange={handleTextOnChange}
            ></input>
          </div>
          <div className="free-trial-form-field-name">
            <label className="form-label" htmlFor="free-trial-comapny-name">
              Company Name <span className="required">*</span>
            </label>
            <input
              id="companyName"
              name="companyName"
              className="form-input"
              placeholder="Your company name"
              type="text"
              value={formObj.companyName}
              onChange={handleTextOnChange}
            ></input>
          </div>
          <div className="free-trial-form-field-email">
            <label className="form-label" htmlFor="demo-comapny-name">
              Products/Solutions <span className="required">*</span>
            </label>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              styles={customStyles}
              isMulti
              placeholder="Select one or more products that you are interested in..."
            />
          </div>
          <div className="free-trial-form-field-mobile">
            <label className="form-label" htmlFor="free-trial-mobile-code">
              Mobile <span className="required">*</span>
            </label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              placeholder="Enter phone number"
              defaultCountry="IN"
              value={mobile}
              onChange={setMobile}
            />
          </div>
          <div className="free-trial-form-field-email line-gap">
            <span className="agreement-text">
              Updapt is committed to protecting and respecting your privacy. If
              you would like to receive communication from our team, select the
              checkbox below:
            </span>

            <div className="agreement-wrapper">
              <div className="chekbox-container">
                <input
                  type="checkbox"
                  className="agreement-checkbox"
                  onChange={(e) => handleTextOnChange(e, "agree")}
                />{" "}
              </div>

              <span className="agreement-text-with-checkbox">
                I agree to receive other communications from Updapt - an ESG
                Tech Co.
              </span>
            </div>
            <span className="agreement-text">
              You can unsubscribe from these communications at any time.
            </span>
            <br />
            <span className="agreement-text">
              Your privacy is important to us. By clicking submit, you consent
              to communication regarding this inquiry and to the storing and
              processing of your personal data in accordance with our{" "}
              <a
                href="/privacy"
                target="_blank"
                style={{ borderBottom: "1px dotted white", color: "#80dfff" }}
              >
                Privacy Policy.
              </a>
            </span>
          </div>
          <div className="free-trial-form-field-submit">
            <input
              className="send-request-btn"
              type="submit"
              value="Submit"
              onClick={handleSendRequest}
            ></input>
          </div>
        </div>
      </form>
      <NotificationContainer />
    </div>
  );
}

export default FreeTrialForm;
