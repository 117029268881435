import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFive() {
  return (
    <section className="scm-feature-section scm-feature-5">
      <div className="scm-feature-container">
        <div className="scm-feature-wrapper">
          <div className="scm-feature-content-wrapper">
            <div className="scm-feature-content">
              <h2 className="scm-feature-title">
                Set up instant notifications
              </h2>
              <p className="scm-feature-description">
                Receive automated assessment alerts through emails to help
                ensure you don't miss deadlines and respond quickly to delayed
                tasks to maintain continuity.
              </p>
            </div>
          </div>
          <div className="scm-feature-image-wrapper">
            <div className="scm-feature-image">
              <img src={assets.images.scmImage5} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFive;
