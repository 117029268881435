import React from "react";
import "../SCSS/styles.scss";

function BlogSeven() {
  return (
    <>
      <div className="BlogSeven-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <div className="blog-title">How the SDGs reinforce CSR in India?</div>
        {/* <div className="update-date">Updated: Jan 3, 2020</div> */}
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Jan 3, 2020</div>
              <div className="blog-read-time">4 min read</div>
            </div>
          </div>
        </div>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-6.jpg").default}
            alt="Blog-6"
          />
        </div>

        <p style={{ marginTop: "15px" }}>
          CSR funding can help us tap into resources that are required for
          environmental and socio-economic development in India. We can see an
          overlap in the SDGs and the CSR activities directed by The Companies
          Act, 2013. The act helps set a framework for promoting sustainable
          development in the country, while the SDGs and their 169 targets can
          help measure the outcome of the CSR activities.
        </p>

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.png")}
          />
        </div> */}
        {/* <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-6.svg")}
            alt="Blog-6"
          />
        </div> */}
        <div className="sub-heading">
          The Sustainable Development Goals (SDGs)
        </div>
        <p>
          The 17 Sustainable Development Goals (SDGs) were adopted and ratified
          in September 2015, by 193 member nations of the UN as a follow-up to
          the Millennium Development Goals (MDGs). The goals are all
          interconnected and focus on various thematic areas of sustainable
          development such as resource efficiency and conservation, democratic
          governance, peacebuilding, climate change, and disaster resilience.
          The 17 goals and 169 targets are set to be achieved by 2030. This
          calls for the collective effort of governments, civil society as well
          as the corporates across all countries.
        </p>
        <p>
          India is one of the 193 countries that have adopted the Sustainable
          Development Goals. Localization of SDGs is an important part of
          India’s SDG implementation strategy. Mobilization of funds from
          corporates through Corporate Social Responsibility (CSR) spending can
          contribute immensely to achieving the Sustainable Development Goals.
          Owing to this, the role of corporates is now considered significant in
          facilitating the implementation of SDGs in India. Therefore, CSR and
          SDGs together have a strong potential in attaining sustainable
          development.
        </p>
        <p>
          SDGs provide significant opportunities for participation in the
          corporate sector. The goals help explore the synergies between various
          stakeholders involved in the organization by bringing together the
          various sectors to achieve the common goal of sustainable development.
          For example, when a corporation directs its CSR on empowering women,
          it contributes to the SDGs- end poverty, gender equality, zero hunger,
          decent work, and economic growth. Similarly, when the corporation
          includes environmental sustainability in its CSR policy, then it
          contributes to the SDGs that focus on clean water and sanitation,
          affordable and clean energy, sustainable cities and communities,
          climate action, life below water and life on land.
        </p>
        <p>
          Various companies in India are now gradually integrating their
          business practices in line with the SDGs. They are promoting
          responsible consumption and production, and resource efficiency in
          their practices. Corporates can apply their innovation in helping to
          solve the challenges to sustainable development and hence play a
          strong role as facilitators to catalyze implementation of the SDGs.
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            Various government initiatives such as the Swachh Bharat Mission,
            Beti Bachao; Beti Padhao Yojana, etc. are linked to achieving the
            SDGs.
          </div>
        </div>

        <p>
          India is strongly committed to agenda 2030 and the Sustainable
          Development Goals (SDGs). This is because much of India’s
          developmental agenda is mirrored in them. Various government
          initiatives such as the Swachh Bharat Mission, Beti Bachao; Beti
          Padhao Yojana, etc. are linked to achieving the SDGs. According to the
          NITI Aayog Report from 2018, India now ranks 116 of 157 on the SDG
          index; this is due to the challenges faced during local implementation
          and data validation processes. A collaboration between the corporate
          sector, civil society organizations and the government can help tackle
          this issue.
        </p>
        <p>
          In conclusion, SDGs can help organizations invest in sustainable
          development while responsibly pursuing their business interests. SDG
          targets can help in obtaining measurable CSR outcomes for corporates.
          The SDGs can provide a means to study various stakeholder groups on
          social, environmental, and economic issues along with strategic
          benefits for corporations.
        </p>
      </div>
    </>
  );
}
export default BlogSeven;
