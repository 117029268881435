import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterSixteen() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter January 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-sixteen-container">
        <MetaTags>
          <title>
            ACCC Releases Eight Principles for Clear and Accurate Environmental
            Marketing Claims.
          </title>
          <meta
            name="description"
            content="The Australian Competition and Consumer Commission (ACCC) has unveiled a set of eight principles designed to assist businesses in ensuring the transparency and accuracy of environmental marketing and advertising claims."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          ACCC Releases Eight Principles for Clear and Accurate Environmental
          Marketing Claims.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/jan2024-1.jpg").default}
            alt="jan2024-1"
          />
        </figure>
        <p>
          The Australian Competition and Consumer Commission (ACCC) has unveiled
          a set of eight principles designed to assist businesses in ensuring
          the transparency and accuracy of environmental marketing and
          advertising claims. These guidelines, presented as the ACCC's final
          guidance on environmental claims, elucidate what the Commission deems
          as good practice in making such claims while enlightening businesses
          about their obligations under the Australian Consumer Law.
        </p>
        <p>
          They aim to facilitate clearer, evidence-backed environmental
          assertions, enabling consumers to make informed choices. Addressing
          the complexity of such claims, the ACCC urges businesses to
          communicate technical information effectively.
        </p>
        <p>The eight key principles are:</p>
        <p>
          <ol>
            <li>Make accurate and truthful claims.</li>
            <li>Have evidence to back up your claims.</li>
            <li>Don't hide or omit important information.</li>
            <li>Explain any conditions or qualifications on your claims.</li>
            <li>Avoid broad and unqualified claims.</li>
            <li>Use clear and easy-to-understand language.</li>
            <li>Visual elements should not give the wrong impression.</li>
            <li>Be direct and open about your sustainability transition.</li>
          </ol>
        </p>
        <p>
          By fostering trust and understanding among consumers, these principles
          not only assist in purchasing decisions but also incentivize fair
          competition based on genuine environmental efforts. Acknowledging the
          importance of businesses driving sustainability, the ACCC encourages
          legitimate marketing of eco-friendly practices.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The ACCC's introduction of eight principles for clear and accurate
            environmental marketing claims is critical in the business
            landscape. It will mitigates the issue of misleading environmental
            assertions, aligning with the ACCC's enforcement and compliance
            priorities. By setting guidelines for transparency and accuracy,
            businesses will be equipped to build and maintain consumer trust,
            addressing the prevalent challenge of skepticism surrounding green
            claims.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU's Mandates Corporate Accountability for Human Rights and
          Environment.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/jan2024-2.jpg").default
              }
              alt="jan2024-2"
            />
          </figure>
        </div>
        <p>
          European Parliament and Council lawmakers have officially reached a
          provisional agreement on the EU's Corporate Sustainability Due
          Diligence Directive (CSDDD). The directive mandates that major
          businesses assess and rectify adverse human rights and environmental
          impacts within their value chains. The accord, stemming from the
          European Commission's proposed CSDDD, imposes obligations on companies
          to identify, assess, prevent, and remedy impacts spanning child labor,
          pollution, and deforestation in both upstream and downstream
          activities.
        </p>
        <p>
          It will be Applicable to EU firms with over 500 employees and €150
          million in global revenues, the directive extends to non-EU companies
          with €300 million net revenue generated in the EU after a three-year
          grace period.The directive necessitates integrated due diligence in
          policies and risk management systems, compelling alignment with the
          Paris Agreement's 1.5°C warming limit.
        </p>
        <p>
          The directive also mandates companies to engage with affected parties,
          introducing a 5-year complaint mechanism. To ensure compliance, member
          states will establish supervisory authorities with powers to impose
          penalties, including fines up to 5% of annual global revenue. Pending
          formal approval by the EU Council and Parliament, the CSDDD is poised
          to enact positive change.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The Corporate Sustainability Due Diligence Directive heralds a new
            era of corporate accountability, fostering ethical practices and a
            fairer economy. Its broad reach across major EU and non-EU companies
            promotes responsible conduct, engaging stakeholders and aligning
            business strategies with climate goals. This mandate ensures
            transparency in value chains and introduces penalties, signifying a
            stringent enforcement mechanism.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Netherlands Central Bank Aligns Investment Portfolios with Paris
          Agreement Goals.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/jan2024-3.jpg").default}
            alt="jan2024-3"
          />
        </figure>
        <p>
          The Dutch Central Bank (DNB) unveiled a bold initiative to synchronize
          its reserves, encompassing equities and corporate bonds, with the
          pivotal goals of the Paris Agreement. In a resounding commitment, the
          DNB aims to halve the carbon footprint of its portfolio investments by
          2030, benchmarked against 2019 levels. This endeavor underscores a
          fervent dedication to effecting tangible emissions reductions across
          its portfolio companies.
        </p>
        <p>
          Aligned with the European Union's resolute stance on curbing global
          warming, DNB delineated a three-pronged strategy: 'Invest' in
          companies with lower carbon emissions or explicit emission reduction
          plans, "Engage" through dialogues with companies and fund managers to
          influence positive climate-related actions, and 'Avoid' investments in
          entities significantly reliant on fossil fuel activities, disregarding
          the Paris Climate Agreement or involved in controversial sectors.
        </p>
        <p>
          Emphasizing an annual evaluation, DNB remains committed to ensuring
          that reductions in its portfolio's carbon footprint are a result of
          authentic emissions curtailment at investee companies, aligning with
          their pursuit of genuine, real-world carbon reductions.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            By mitigating climate risks, enhancing resilience, and fostering a
            reputation for sustainability, companies position themselves for
            long-term success. The three-pronged strategy ensures leadership in
            sustainable finance, attracting socially responsible investors and
            maintaining a competitive edge. Annual evaluations reaffirm
            authenticity in emissions reductions, aligning with global climate
            goals and regulatory standards.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          MAS Redefines Green and Transition Standards with Singapore-Asia
          Taxonomy Introduction.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/jan2024-4.jpg").default}
            alt="jan2024-4"
          />
        </figure>
        <p>
          The Monetary Authority of Singapore (MAS) introduced the
          groundbreaking Singapore-Asia Taxonomy for Sustainable Finance during
          the COP28 climate conference. Notably, this taxonomy is the world's
          first to comprehensively define green and transition activities across
          eight key sectors: energy, industrial, carbon capture and
          sequestration, agriculture and forestry, construction and real estate,
          waste and circular economy, information and communications technology,
          and transportation. Employing a "traffic light" system, it classifies
          activities as green (sustainable), amber (transition), or ineligible
          based on their contribution to environmental objectives.
        </p>
        <p>
          One distinctive feature is the introduction of the "amber" category
          for transition activities, aligning with Asia's shift to a net-zero
          economy amid economic development. The taxonomy includes time-bound,
          sunset-date transition thresholds, discouraging prolonged reliance on
          non-sustainable practices and reducing the risk of greenwashing.
        </p>
        <p>
          The taxonomy facilitates a measured transition process, crucial for
          hard-to-abate sectors like the maritime industry. Additionally, it
          addresses the transition from coal-fired power plants, filling an
          international gap and providing criteria for responsible and fair coal
          phase-out. The MAS is actively engaging in sustainable finance
          initiatives, including partnerships to address climate finance gaps
          and a blended finance platform focusing on energy transition.
        </p>
        <p>
          The Singapore-Asia Taxonomy's industry-led, science-based approach and
          interoperability with major taxonomies demonstrate its potential to
          promote the green economy in Singapore and Southeast Asia. Future
          versions are planned to cover additional environmental objectives. The
          MAS is mapping the taxonomy to the Common Ground Taxonomy for global
          interoperability, aligning it with the EU Taxonomy and China's Green
          Bond Endorsed Project Catalogue. This mapping will enable consistent
          definitions, facilitating cross-border financing flows and
          taxonomy-aligned financing solutions.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The Singapore-Asia Taxonomy empowers businesses by offering
            transparency, mitigating risks, and aligning with global ESG
            standards. It enables access to sustainable finance, reducing
            capital costs, and enhancing financial performance. Particularly
            beneficial for hard-to-abate sectors, its clear classification
            facilitates smoother transitions towards sustainable practices,
            fostering accountability and attracting responsible investments.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          COP28 in the UAE underscores a commitment to a sustainable future with
          impactful global initiatives and breakthroughs.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/jan2024-5.jpg").default}
            alt="jan2024-5"
          />
        </figure>

        <p>
          The COP 28 marked a pivotal moment in the global fight against climate
          change, with far-reaching agreements and pledges. Central to the
          discussions was the Global Stocktake (GST), outlining eight crucial
          steps to limit the temperature increase to 1.5 degrees Celsius. Among
          these steps are the call to triple global renewable energy capacity
          and double the rate of energy efficiency improvements by 2030.
        </p>
        <p>
          COP 28 emphasized a just transition away from fossil fuels, aiming for
          a net-zero scenario by 2050. The Global Goal on Adaptation (GGA) took
          center stage, seeking to enhance adaptive capabilities and double
          adaptation finance. Notably, explicit 2030 targets were integrated for
          water security, ecosystem restoration, and health.
        </p>
        <p>
          Climate finance discussions unveiled a commitment to address the debt
          disparity between wealthy and developing nations, aiming to set a new
          collective quantified goal by 2025. The Loss and Damage Fund,
          operationalized at COP 28, will compensate countries grappling with
          climate impacts, with special consideration for the most vulnerable.
        </p>
        <p>
          The Global Renewables and Energy Efficiency Pledge urges signatories
          to work collaboratively towards tripling the world's installed
          renewable energy generation capacity to a staggering 11,000 GW by
          2030. Simultaneously, the pledge targets a collective doubling of the
          global average annual rate of energy efficiency improvements from
          around 2% to over 4% every year until 2030.
        </p>
        <p>
          The Global Cooling Pledge, with 66 national government signatories,
          lays out a data-backed plan to reduce cooling-related emissions by at
          least 68% globally by 2050 relative to 2022 levels. Another
          declaration launched at COP 28 seeks to triple global nuclear energy
          capacity by 2050, emphasizing the role of nuclear energy in the
          broader climate action agenda.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            COP28 outcomes offer businesses unprecedented prospects.
            Opportunities abound in renewable energy expansion, energy-efficient
            technologies, and transitioning from fossil fuels. Increased demand
            for adaptation solutions creates openings for resilient
            infrastructure and disaster management industries. Embracing these
            shifts positions businesses at the forefront of sustainable
            innovation and growth.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterSixteen;
