import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFive() {
  return (
    <section className="erm-feature-section feature-5">
      <div className="erm-feature-container">
        <div className="datatrack-grey-circle"></div>
        <div className="yellow-circle"></div>
        <div className="erm-feature-wrapper">
          <div className="erm-feature-content-wrapper">
            <div className="erm-feature-content">
              <h2 className="erm-feature-title">Comprehensive risk register</h2>
              <p className="erm-feature-description">
                Builds and maintains a centralized risk register with a complete
                overview of all the risks across your enterprise that helps in
                sound decision-making.
              </p>
            </div>
          </div>
          <div className="erm-feature-image-wrapper">
            <div className="erm-feature-image">
              <object
                data={assets.images.ermImage5}
                aria-label="ermImage5"
              ></object>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFive;
