import React, { useState } from "react";
import CaseStudyTwo from "../components/CaseStudyTwo";

function CaseStudyTwoContainer() {
  const [caseStudyData] = useState({
    title: "Healthcare case study",
    img: "healthCare.jpg",
    content:
      "Client is multinational healthcare group. Apart from the eponymous hospital chain, the company also operates pharmacies, primary care, diagnostic centres, telehealth clinics, and digital healthcare services through its subsidiaries. With an annual revenue of US$1.8 billion and an employee strength of over 60,000, Client Hospitals desired to pioneer sustainability and blend better ESG practices as part of its operations. With a core commitment to ensuring an outcome-based approach and creating value for its stakeholders through the lens of the best ESG practices Client partnered with Updapt to transform its sustainability journey and chart out its short to long-term sustainability goals. The challenges in hand were plenty, however, a clear resolute to scale up and move towards a real-time data-driven platform with a collective vision and clearly articulated roadmap ensured a smooth transition & easier adoption of the system. One of the major hurdles was the sheer volume and the absence of a process when it comes to the collection of data. With over 70 hospitals across geographies and 200+ users, ensuring a standardized and consistent data collection mechanism alongside the relevant knowledge transfer required detailed planning and close coordination with both teams.  ",
  });
  const [downloadModalBool, setDownloadModalBool] = useState(false);
  return (
    <CaseStudyTwo
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
    />
  );
}

export default CaseStudyTwoContainer;
