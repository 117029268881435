import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureThree() {
  return (
    <section className="net-zero-feature-section">
      <div className="net-zero-feature-container">
        <div className="net-zero-feature-wrapper">
          <div className="net-zero-feature-content-wrapper">
            <div className="net-zero-feature-content">
              <h2 className="net-zero-feature-title">
                Offset emission through various decarbonisation projects
              </h2>
              <p className="net-zero-feature-description">
                Choose various carbon offset programs powered by our verified
                carbon offset partners digitally through the platform and decide
                to offset the emissions of the organisation based on your
                budget, impact, and program preferences.
              </p>
            </div>
          </div>
          <div className="net-zero-feature-image-wrapper">
            <div className="net-zero-feature-image">
              <img src={assets.images.nzImage3} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureThree;
