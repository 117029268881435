import React from "react";
import "../SCSS/styles.scss";

function BlogOne() {
  return (
    <>
      <div className="BlogOne-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>3 min read</span>
        </div> */}
        <div className="blog-title">
          Understanding Net Zero Emission Targets
        </div>
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Jan 8, 2021</div>
              <div className="blog-read-time">3 min read</div>
            </div>
          </div>
        </div>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-12.jpg").default}
            alt="Blog-12"
          />
        </div>
        <div className="blog-intro">
          <p>
            Lately we have been reading stories about a number of organizations
            like Ikea, Microsoft, EY, Shell, etc. setting their targets to net
            zero emissions in the upcoming years. A growing number of countries
            like Canada, European Union, Japan, New Zealand, United Kingdom,
            etc. are setting targets towards achieving net zero emissions over
            the next few decades, most of them by 2050 in line with the
            guidelines and goals recommended by the 2015 Paris Climate Agreement
            and the 2018 IPCC (Intergovernmental Panel on Climate Change)
            report. It states that in order for us to avoid the worst climate
            impacts, the global greenhouse gas emissions will need to be dropped
            by half by 2030 and then reach net zero around mid-century.
          </p>
        </div>
        {/* <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-12.svg")}
            alt="Blog-12"
          />
        </div> */}
        {/* <div className="highlight">
          <div className="left">
            <div className="sub-header">What does net zero emissions mean?</div>
            <p>
              Net zero emissions simply means that all man-made greenhouse gas
              (GHG) emissions must be removed from the earth’s atmosphere by
              various measures that help in restoring the Earth’s net climate
              balance. Our atmosphere reacts even to small changes in the levels
              of GHG like carbon dioxide, methane, etc. Reducing emissions of
              these greenhouse gases directly and indirectly is what will lead
              us to a low carbon economy and help regulate global temperature
              rise.{" "}
            </p>
          </div>
          <div className="right">
            {" "}
            <img
              className="picture"
              src={require("../../../../../assets/Blogs/Blog1.png")}
            />
          </div>
        </div> */}{" "}
        <div className="sub-header">What does net zero emissions mean?</div>
        <p>
          Net zero emissions simply means that all man-made greenhouse gas (GHG)
          emissions must be removed from the earth’s atmosphere by various
          measures that help in restoring the Earth’s net climate balance. Our
          atmosphere reacts even to small changes in the levels of GHG like
          carbon dioxide, methane, etc. Reducing emissions of these greenhouse
          gases directly and indirectly is what will lead us to a low carbon
          economy and help regulate global temperature rise.
        </p>
        <div className="sub-header">How is this done?</div>
        <p>
          In simple terms, this is carried out by reducing carbon in the
          atmosphere by capture and storage in natural and artificial sinks. The
          2018 IPCC report explains that it is necessary for us to limit global
          warming to 1.5℃ in order to reduce challenging impacts on ecosystems,
          health and wellbeing; any further rise in average global temperature
          over the next decade could result in extreme weather events, rise in
          sea levels, depletion of polar ice caps, coral bleaching and other
          harmful impacts on the ecosystems.{" "}
        </p>
        <p>
          Before setting this target, it is important for a business or a
          government to develop a clear plan of actions to be carried out over
          the time period, in order to reduce emissions as much as possible.{" "}
        </p>
        <p>
          This is primarily done by switching to renewables from fossil fuel in
          various sectors. For example, electricity generation can be carried
          out using solar, wind, geothermal and even nuclear power. Developing
          and designing a transport system that runs on electricity, biofuels
          and hydrogen instead of fossil fuels goes a long way in reducing
          emissions. This approach has its limitations in some sectors like
          manufacturing and even aviation, it is not possible to bring down
          emissions to zero. Offsetting carbon is carried out in such cases
          where an equivalent amount of carbon (carbon dioxide) is removed from
          the atmosphere, this is called negative emissions. This is done by
          restoring forests because plants absorb CO2 during photosynthesis.
          Carbon sequestration is carried out through afforestation and
          reforestation programs to restore forest lands that have been lost or
          depleted. Another way this is carried out is by using technology
          through options like bioenergy that is combined with carbon capture
          and storage and direct air capture and storage. (Although, most of
          these do not yet exist on a large scale that is ready to be deployed
          on the field.)
        </p>
        <p>
          Policy changes, technological advancements and behavioral changes are
          paramount to reducing emissions. Changing dietary habits of people,
          halting deforestation, land restoration and reducing waste generation
          will have significant impacts on emission reduction. Stricter
          environmental regulations, increased investments towards removal of
          carbon in the atmosphere, changes in building design and efficiency,
          investments in clean energy and phasing out fossil fuels are some of
          the key solutions to this.
        </p>
        <p>
          Strong commitments like this are essential to create targets that
          align with a low carbon economy in the future. We know that the extent
          of global warming is proportional to the total amount of GHG emissions
          and its accumulation in the atmosphere. GHG emissions need to be
          curtailed to keep global warming well below 2℃. We must work together
          to achieve a future where our planet is healthy and safe rather than
          be ravaged by the negative impacts of the changing climate.{" "}
        </p>
      </div>
    </>
  );
}
export default BlogOne;
