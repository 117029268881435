import React from "react";
import { withRouter } from "react-router-dom";

function PartnersProgram({ programs }) {
  return (
    <section className="partner-plan-section">
      <div className="home-pricing-circle">
        <div className="home-pricing-circle-1"></div>
        <div className="home-pricing-circle-2"></div>
        <div className="home-pricing-circle-3"></div>
        <div className="home-pricing-circle-4"></div>
        <div className="home-pricing-circle-5"></div>
        <div className="home-pricing-circle-6"></div>
        <div className="home-pricing-circle-7"></div>
      </div>
      <div className="home-pricing-container">
        <div className="home-pricing-content">
          <h1 className="our-program-title">Our Partner Programs</h1>
        </div>

        <div className="home-pricing-wrapper">
          {programs.map((ele, index) => (
            <div
              className="partner-plan-card-wrapper"
              key={ele.name.toString()}
            >
              <div className="partner-plan-card">
                <h1 className="partner-plan-card-title">{ele.name}</h1>
                <div className="partner-plans-icon">
                  <img src={ele.icon} alt="icon" />
                </div>
                <p className="home-pricing-card-description">
                  {ele.description}
                </p>
                {/* <div className="home-pricing-button">
                  <Link to="/esg-plans" className="home-pricing-view-btn">
                    view Features
                  </Link>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="home-pricing-elements">
        <div className="home-pricing-element-1"></div>
        <div className="home-pricing-element-2"></div>
        <div className="home-pricing-element-3"></div>
        {/*  <div className="home-pricing-element-4"></div>
     <div className="home-pricing-element-5"></div>*/}
      </div>
    </section>
  );
}

export default withRouter(PartnersProgram);
