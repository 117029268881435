import React, { useState, useReducer, useEffect } from "react";
import Demo from "../components/Demo";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";

const initialFormObj = {
  firstName: "",
  lastName: "",
  email: "",
  jobTitle: "",
  companyName: "",
  mobile: "",
  optin: false,
};
function DemoContainer(props) {
  const [formObj, setFormObj] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialFormObj
  );
  const [mobile, setMobile] = useState("");
  const [errors, setErrors] = useState({});
  const [issubbmitted, setIsSubbmitted] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const options = [
    { value: "Updapt ESG", label: "Updapt ESG" },
    {
      value: "Updapt SCM [Supply chain management]",
      label: "Updapt SCM [Supply chain management]",
    },
    { value: "Updapt NetZero", label: "Updapt NetZero" },
    {
      value: "Updapt ERM [Risk management]",
      label: "Updapt ERM [Risk management]",
    },
    {
      value: "Updapt PE [Private equity]",
      label: "Updapt PE [Private equity]",
    },

    {
      value: "Consulting",
      label: "Consulting",
    },
  ];

  const handleTextOnChange = (event, type) => {
    if (type === "agree") {
      setFormObj({ ...formObj, optin: event.target.checked });
    } else {
      const { name, value } = event.target;
      setFormObj({ [name]: value });
    }
  };

  const validate = () => {
    let errors = {};

    const isText = RegExp(/^[A-Z ]+$/i);
    const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    if (!formObj.firstName) {
      errors.firstName = "Please enter first name";
      NotificationManager.warning(errors.firstName, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isText.test(formObj.firstName)) {
        errors.firstName = "First name accepts text only";
        NotificationManager.warning(errors.firstName, "", 2000);
        setErrors(errors);
        return;
      }
    }
    if (!formObj.lastName) {
      errors.lastName = "Please enter last name";
      NotificationManager.warning(errors.lastName, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isText.test(formObj.lastName)) {
        errors.lastName = "Last name accepts text only";
        NotificationManager.warning(errors.lastName, "", 2000);
        setErrors(errors);
        return;
      }
    }
    if (!formObj.email) {
      errors.email = "Please enter email";
      NotificationManager.warning(errors.email, "", 2000);
      setErrors(errors);
      return;
    } else if (!isEmail.test(formObj.email)) {
      errors.email = "Please enter a valid email address";
      NotificationManager.warning(errors.email, "", 2000);
      setErrors(errors);
      return;
    } else {
      const domain = formObj.email.split("@");
      if (
        domain[1].toLowerCase().includes("gmail") ||
        domain[1].toLowerCase().includes("yahoo") ||
        domain[1].toLowerCase().includes("rediffmail")
      ) {
        errors.email =
          "Please enter your business email address. This form does not accept addresses from gmail.com";
        NotificationManager.warning(errors.email, "", 3000);
        setErrors(errors);
        return;
      }
    }

    if (!formObj.jobTitle) {
      errors.jobTitle = "Please enter job title";
      NotificationManager.warning(errors.jobTitle, "", 2000);
      setErrors(errors);
      return;
    }
    if (!formObj.companyName) {
      errors.companyName = "Please enter company name";
      NotificationManager.warning(errors.companyName, "", 2000);
      setErrors(errors);
      return;
    }
    if (!selectedOption.length) {
      errors.products = "Please select interested products";
      NotificationManager.warning(errors.products, "", 2000);
      setErrors(errors);
      return;
    }
    if (!mobile) {
      errors.mobile = "Please enter mobile number";
      NotificationManager.warning(errors.mobile, "", 2000);
      setErrors(errors);
      return;
    } else {
      setFormObj({ ["mobile"]: mobile });
    }
    if (formObj.optin === false) {
      errors.products = "Please accept Terms and Conditions to continue";
      NotificationManager.warning(errors.products, "", 2000);
      setErrors(errors);
      return;
    }
    setErrors(errors);
  };

  const handleSendRequest = async (e) => {
    e.preventDefault();
    validate();
    setIsSubbmitted(true);
  };

  useEffect(() => {
    const postInfo = async () => {
      if (!Object.keys(errors).length && issubbmitted) {
        const prodList = selectedOption?.map((ele) => {
          return ele.value;
        });
        try {
          await axios.post("/contact/sales/api/demo", {
            firstName: formObj.firstName,
            lastName: formObj.lastName,
            email: formObj.email,
            jobTitle: formObj.jobTitle,
            companyName: formObj.companyName,
            mobile: formObj.mobile,
            message: formObj.message,
            products: prodList.join(", "),
          });

          //isTrue condition added to avoid execution of api until necessary for future use
          let isTrue = "false";
          if (isTrue === "true") {
            const freshworks = await axios.post("/crm/sales/api/contacts", {
              firstName: formObj.firstName,
              lastName: formObj.lastName,
              email: formObj.email,
              jobTitle: formObj.jobTitle,
              companyName: formObj.companyName,
              mobile: formObj.mobile,
              products: prodList.join(";"),
            });
            if (!freshworks.data.contact) {
              NotificationManager.error(freshworks.data);
            }
          }
          props.history.push("/demo-success", {
            from: "demo",
            name: formObj.firstName,
          });

          setFormObj(initialFormObj);
          setMobile("");
          setSelectedOption([]);
        } catch (error) {
          NotificationManager.error(
            "Please write to us at info@updaptcsr.com",
            "Error"
          );
          throw error;
        }
      }
    };
    postInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : "white",
      color: state.isSelected ? "white" : "black",
      fontSize: "14px",
      zIndex: "1",
    }),
  };

  return (
    <Demo
      formObj={formObj}
      handleTextOnChange={handleTextOnChange}
      handleSendRequest={handleSendRequest}
      mobile={mobile}
      setMobile={setMobile}
      options={options}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      customStyles={customStyles}
    ></Demo>
  );
}

export default withRouter(DemoContainer);
