import React, { useState } from "react";
import Analytics from "../components/Analytics";

function AnalyticsContainer() {
  const [whyFeatures] = useState([
    {
      header: "Climate change and transition risks are real",
      desc:
        "In today's business, analysing and predicting risks associated with climate change and their impact on your bottomline is critical. A dedicated and personalized risk or scenario analysis assists organisations in setting proactive business strategies.",
    },
    {
      header: "Identify your materiality issues and strategize",
      desc:
        "An organization's performace is upgraded by precisely establishing, tracing and communicating key ESG materiality issues. Tangible results can be noticed by setting key strategies and efficiency targets. Our analysis provides you with the methodology and platform to execute these activities in a procedural way.",
    },
    {
      header: "It's time to rethink and redesign business approach",
      desc:
        "Stakeholders, regulators and climate activists are demanding enterprises to be more responsible towards the  climate and communities. You need to take actions to reduce your environmental footprint by analysing your business impact. Use our personalized analyses to set climate based targets and gauge the cost and resources needed to acheive it.",
    },
  ]);
  return <Analytics whyFeatures={whyFeatures}></Analytics>;
}

export default AnalyticsContainer;
