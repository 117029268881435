import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";

function FooterContainer() {
  let now = new Date();
  let year = now.getFullYear();
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <Footer
      isVisible={isVisible}
      scrollToTop={scrollToTop}
      year={year}
    ></Footer>
  );
}

export default FooterContainer;
