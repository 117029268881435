import React, { useEffect, useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import CaseStudyDownloadModal from "../components/CaseStudyDownloadModal";

function CaseStudyDownloadModalContainer({
  caseStudyData,
  downloadModalBool,
  setDownloadModalBool,
}) {
  const [customerData, setCustomerData] = useState({ name: "", email: "" });
  const [errors, setErrors] = useState({});
  const [issubbmitted, setIsSubbmitted] = useState(false);
  const handleChange = (type, value) => {
    if (type === "name") {
      setCustomerData({ ...customerData, name: value });
    }
    if (type === "email") {
      setCustomerData({ ...customerData, email: value });
    }
  };

  const handleDownload = async (e) => {
    e.preventDefault();
    validate();
    setIsSubbmitted(true);
  };

  const validate = () => {
    let errors = {};

    const isText = RegExp(/^[A-Z ]+$/i);
    const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    if (!customerData.name) {
      errors.name = "Please enter first name";
      NotificationManager.warning(errors.name, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isText.test(customerData.name)) {
        errors.name = "First name accepts text only";
        NotificationManager.warning(errors.name, "", 2000);
        setErrors(errors);
        return;
      }
    }

    if (!customerData.email) {
      errors.email = "Please enter email";
      NotificationManager.warning(errors.email, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isEmail.test(customerData.email)) {
        errors.email = "Please enter a valid email address";
        NotificationManager.warning(errors.email, "", 2000);
        setErrors(errors);
        return;
      }
    }

    setErrors(errors);
  };

  useEffect(() => {
    const postInfo = async () => {
      if (!Object.keys(errors).length && issubbmitted) {
        try {
          const response = await axios.post(
            "/resource/case-study/file-download",
            { fileKey: caseStudyData.title },
            {
              responseType: "blob",
            }
          );
          const notificationMail = await axios.post(
            "/resource/case-study/file-download-notification-mail",
            {
              title: caseStudyData.title,
              name: customerData.name,
              email: customerData.email,
            }
          );
          const [fileResult, notificationResult] = await Promise.all([
            response,
            notificationMail,
          ]);

          if (fileResult && notificationResult) {
            let blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });

            let anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download = `${caseStudyData.title}.pdf`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

            NotificationManager.success(
              "File Downloaded Successfully",
              "",
              2000
            );
            setCustomerData({ name: "", email: "" });
            setDownloadModalBool(false);
          }
        } catch (error) {
          NotificationManager.error("Failed to download the file", "Error");
          throw error;
        }
      }
    };
    postInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <CaseStudyDownloadModal
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
      handleChange={handleChange}
      handleDownload={handleDownload}
      setCustomerData={setCustomerData}
    />
  );
}

export default CaseStudyDownloadModalContainer;
