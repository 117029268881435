import React from "react";
import Blogs from "../components/blogs";

import { withRouter } from "react-router-dom";




function BlogsContainer(props) {
  const handleClick = (link) => {
    props.history.push(link);
  };
  return (
    <>
      <Blogs handleClick={handleClick} blogsData={props.blogsData}/>
    </>
  );
}
export default withRouter(BlogsContainer);
