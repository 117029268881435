import React from "react";
import Intro from "./Intro";
import Policy from "./Policy";
import MetaTags from "react-meta-tags";

function Privacy() {
  return (
    <>
      <MetaTags>
        <title>Privacy Policy - Updapt</title>
        <meta
          name="description"
          content="Protecting your data is our top priority. Check more details about our privacy policy and we keep protecting all information safe."
        />
        <meta property="og:title" content="Privacy Policy - Updapt" />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <Intro></Intro>
      <Policy></Policy>
    </>
  );
}

export default Privacy;
