import React from "react";
import "../SCSS/styles.scss";

function BlogTwelve() {
  return (
    <>
      <div className="BlogTwelve-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>6 min read</span>
        </div> */}
        <div className="blog-title">SDGs 101</div>

        {/* <div className="update-date">Updated: December 22, 2019</div> */}
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Dec 22, 2019</div>
              <div className="blog-read-time">4 min read</div>
            </div>
          </div>
        </div>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-1.jpg").default}
            alt="Blog-1"
          />
        </div>

        <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            A Guide to understanding the UN assigned SDG’s
          </b>
        </p>
        {/* <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-1.svg")}
            alt="Blog-1"
          />
        </div> */}
        <p>
          <b>1. No Poverty</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.1.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>This goal intends to eradicate poverty in all its forms.</li>
          <li>
            Rapid growth witnessed in India has been helping millions out of
            poverty, but this has been uneven.
          </li>
          <li>Women are more likely to be affected due to the gender gap.</li>
          <li>Multidimensional poverty is now at 27.5% in India.</li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            <i>Action Aid India </i>- An organization that works at various
            levels to tackle poverty in India..
          </li>
          <li>
            <i>Kudumbashree </i>- A women's empowerment project in Kerala that
            provides them with microfinance, skill development, and community
            support.
          </li>
        </ul>

        <p>
          <b>2. Zero Hunger</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.2.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            Extreme hunger and malnutrition remains a huge barrier to
            development in many countries. About 15% of the population in South
            Asian countries are undernourished.
          </li>
          <li>
            In India, even though the agricultural sector accounts for 40% of
            the total employment, it only contributes to 15.5% of GDP.
          </li>
          <li>
            The government has also taken steps to enhance food security,
            including through a country-wide targeted public distribution
            system, a National Nutrition Mission and the National Food Security
            Act.{" "}
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            Akshaya Patra - A non-profit organization that runs school lunch
            programs across India
          </li>
          <li>
            National Mission on Sustainable Agriculture - A GoI initiative that
            promotes sustainable agriculture practices.
          </li>
        </ul>

        <p>
          <b>3. Good Health and Well-Being</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.3.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            Poor health hampers the well-being of an individual; it burdens
            family and public resources and weakens societies.
          </li>
          <li>
            Over the years, India has made significant efforts in reducing the
            under-five mortality rates as well as the prevalence of infectious
            diseases across the country.
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            Smile on wheels - A national-level mobile hospital program, catering
            to the underprivileged of our society.
          </li>
          <li>
            National Health Mission - A govt initiative trying to provide
            universal access to equitable, affordable & quality healthcare
            services that are accountable and responsive to people's needs.
          </li>
        </ul>

        <p>
          <b>4. Quality Education</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.4.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>Quality education is vital for sustainable development.</li>
          <li>
            In India, there has been significant progress in the
            universalization of primary education.
          </li>
          <li>
            The net enrolment rate for primary education is now at 100% for both
            males and females
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            Sarva Shiksha Abhiyan is a govt of India flagship program that
            intends to achieve the universalization of Elementary/Primary
            Education in a time-bound manner.
          </li>
          <li>
            Sampark Smart Class initiative - The initiative intends to remove
            learning barriers, especially in rural India, through innovative
            low-tech teaching tools and methodologies
          </li>
        </ul>

        <p>
          <b>5. Gender Equality</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.5.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            India has achieved gender equality in enrolment for primary
            education, but a lot of inequality is witnessed in higher education
            as well as in employment.
          </li>
          <li>
            Various steps are being taken by the government and corporations to
            close this gender gap. The net enrolment rate for primary education
            is now at 100% for both males and females
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            Beti Bachao, Beti Padhao - Program that aims to improve welfare
            services to girl children in India.
          </li>
          <li>
            Skills to succeed initiative - An online training program that helps
            disadvantaged youth in developing employability skills
          </li>
        </ul>

        <p>
          <b>6. Clean Water and Sanitation</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.6.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>Clean water is important for survival.</li>
          <li>
            Poor infrastructure and economy hinder the supply of clean water to
            various strata of society.
          </li>
          <li>
            Natural calamities and the spread of waterborne diseases account for
            most of the water woes in India.
          </li>
          <li>
            Improving sanitation has become a key initiative of the government.
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            Namami Gange initiative - A collaborative initiative to clean the
            Ganges and prevent the inflow of sewage and effluents and also
            biodiversity conservation.
          </li>
          <li>
            Independent lake rejuvenation initiatives are taken up by
            corporations.
          </li>
        </ul>

        <p>
          <b>7. Affordable and Clean Energy</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.7.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            Energy access is important to deal with our day to day challenges.
          </li>
          <li>Energy can be made more accessible</li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            National Solar Mission - A collaborative initiative to promote solar
            power in India.
          </li>
          <li>
            Deen Dayal Upadhyaya Gram Jyoti Yojana - A govt scheme designed to
            provide continuous power supply to the rural regions in India
          </li>
        </ul>

        <p>
          <b>8. Decent Work and Economic Growth</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.8.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            Sustained economic growth is extremely important to tackle
            developmental issues.
          </li>
          <li>
            India intends to create well-paying and productive jobs in non-
            agricultural sectors that can absorb more unskilled workers,
            including women and those in rural areas.{" "}
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            Start-up India - An initiative that promotes and encourages startup
            ecosystem in India.
          </li>
          <li>
            Skill India - A government initiative that promotes skill
            development to reduce unemployment rate in the country
          </li>
        </ul>

        <p>
          <b>9. Industry, Innovation, and Infrastructure</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.9.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            This goal intends to improve existing industrial infrastructure
            through technological innovation.
          </li>
          <li>
            Governments and businesses need to work on innovation, encourage
            scientific research, and improve access to information technology,
            universally.
          </li>
          <li>
            Various govt initiatives are fuelling innovation and sustainable
            industrial and economic development in the country.
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            Make in India initiative - An initiative launched by the govt to
            encourage companies to manufacture their products in India
          </li>
          <li>Provision of internet access to remote and rural areas</li>
        </ul>

        <p>
          <b>10. Reduced Inequalities</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.10.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            Large disparities are seen in income, access to health and education
            services across various countries in the world.
          </li>
          <li>
            The Government of India, through the Jan Dhan-Aadhaar-Mobile
            programs, aims to develop a comprehensive strategy of inclusion,
            financial empowerment, and social security.
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            Jan Dhan Yojan - A program that aims to provide affordable access to
            various financial services,
          </li>
          <li>
            Stand up India scheme - Promotes entrepreneurship among women and
            poorer communities
          </li>
        </ul>

        <p>
          <b>11. Sustainable Cities and Communities</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.11.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            More than half of the world's population lives in urban areas.
          </li>
          <li>
            Challenges to urban spaces can be overcome by improving resource use
            and focusing on reducing pollution and poverty
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            Smart cities mission - Urban renewal initiative that intends to make
            cities more sustainable and citizen friendly
          </li>
          <li>
            AMRUT (Atal Mission for Rejuvenation and Urban Transformation) - An
            initiative that aims to provide robust urban infrastructure.
          </li>
        </ul>

        <p>
          <b>12. Responsible Consumption and Production</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.12.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            This goal is about promoting efficient use of resources, energy
            efficiency, sustainable infrastructure and access to basic
            facilities.
          </li>
          <li>
            This intends to target different stakeholders in the business to do
            more and better with limited resources.
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>
            National Clean Energy Fund- An initiative that funds research and
            innovative projects in clean energy
          </li>
          <li>
            Use of recycled materials in various organizations (Nike, Ikea),
            Waste segregation and a ban on single-use plastic
          </li>
        </ul>

        <p>
          <b>13. Climate Action</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.13.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>Climate change is disrupting the national economy.</li>
          <li>
            Due to it, we are experiencing changing weather patterns, rising sea
            levels, and extreme weather events.
          </li>
          <li>
            India is making tremendous efforts to reduce GHG emissions and has
            also formulated the national action plan to tackle climate change.
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>National Action Plan on Climate Change.</li>
        </ul>

        <p>
          <b>14. Life below Water</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.14.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            Coastal and marine resources contribute greatly to our economy and
            are negatively impacted by climate change
          </li>
          <li>
            The Indian government’s Sagarmala Project (Blue Revolution), is
            working to improve the state of India’s ports and coastlines.
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>Establishment of Marine Protected Areas</li>
        </ul>

        <p>
          <b>15. Life on Land</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.15.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            Resources on land are being affected by the degradation of natural
            habitats, poaching, and trafficking of animals.
          </li>
          <li>
            Integrating ecosystem and biodiversity values into local planning
            and development processes are important for biodiversity
            conservation on the land.
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>Air quality assurance </li>
          <li>Project Tiger </li>
        </ul>

        <p>
          <b>16. Peace, Justice and Strong Institutions</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.16.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            Energy access is important to deal with our day to day challenges.
          </li>
          <li>
            Violence hinders development, growth, and well-being of nations.
          </li>
          <li>
            This goal is meant to promote peaceful and inclusive societies for
            sustainable development, the provision of access to justice for all,
            and building accountable institutions at all levels.
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>Pragati Platform</li>
        </ul>

        <p>
          <b>17. Partnerships for the Goals</b>
        </p>
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog12.17.png")}
          />
        </div> */}
        <p>Relevance of this SDG in India is as follows:</p>
        <ul>
          <li>
            Through this, India seeks to build stronger networks within the
            country and other regions of the world.
          </li>
          <li>
            This is being done by establishing collaborative partnerships within
            the country as well as with other countries to achieve the SDGs
          </li>
        </ul>
        <p>Some examples of CSR activities:</p>

        <ul>
          <li>Center for Responsible Business</li>
        </ul>
      </div>
    </>
  );
}
export default BlogTwelve;
