import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Platform({ blogsData }) {
  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="thankyou-blogs-section">
      <div className="thankyou-blogs-container">
        <div className="thankyou-blogs-content">
          <h1 className="thankyou-blogs-title">
            Learn more about ESG and Climate Change.
          </h1>
        </div>
        <div className="thankyou-blogs-slider-wrapper">
          <Slider {...settings} className="mobile-blog">
            {blogsData
              .map((data, index) => {
                let dataUrl = data.url;
                if (data.type === "blogs")
                  dataUrl = `/resource/blogs/${data.url}`;
                else if (data.type === "podcast")
                  dataUrl = `/resource/podcast/${data.url}`;
                else if (data.type === "website-media")
                  dataUrl = `/resource/media/${data.url}`;
                else if (data.type === "newsletter") dataUrl = `${data.url}`;
                return (
                  <div className="thankyou-blogs-slider" key={data.url}>
                    <a href={dataUrl}>
                      <div className="thankyou-blogs-grid">
                        <div className="thankyou-blogs-grid-img">
                          <img
                            alt="img"
                            src={
                              data.image.split(".")[1] !== "svg"
                                ? require(`../../../assets/${data.image}`)
                                    .default
                                : require(`../../../assets/${data.image}`)
                            }
                            height="100%"
                            width="100%"
                          />
                        </div>
                        <div className="thankyou-blogs-grid-content">
                          <h3 className="thankyou-blogs-grid-title">
                            {data.title}
                          </h3>
                          <p
                            className="thankyou-blogs-grid-description"
                            dangerouslySetInnerHTML={{
                              __html:
                                data.type === "podcast"
                                  ? data.synopsis
                                  : data.content,
                            }}
                          ></p>

                          <div className="thankyou-blogs-grid-badge">
                            {data.type === "blogs" ? (
                              <span className="blogs">{data.type}</span>
                            ) : (
                              <span className="newsletters">{data.type}</span>
                            )}
                          </div>
                          <div className="thankyou-blogs-grid-meta">
                            <span className="date">{data.date}</span>
                            <span className="status">{data.status}</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })
              .reverse()}
          </Slider>
          <div className="desktop-blog">
            {blogsData
              .map((data, index) => {
                let dataUrl = data.url;
                if (data.type === "blogs")
                  dataUrl = `/resource/blogs/${data.url}`;
                else if (data.type === "podcast")
                  dataUrl = `/resource/podcast/${data.url}`;
                else if (data.type === "website-media")
                  dataUrl = `/resource/media/${data.url}`;
                else if (data.type === "newsletter") dataUrl = `${data.url}`;
                return (
                  <div className="thankyou-blogs-slider" key={data.url}>
                    <a href={dataUrl} rel="noopener noreferrer">
                      <div className="thankyou-blogs-grid">
                        <div className="thankyou-blogs-grid-img">
                          <img
                            alt="img"
                            src={
                              data.image.split(".")[1] !== "svg"
                                ? require(`../../../assets/${data.image}`)
                                    .default
                                : require(`../../../assets/${data.image}`)
                            }
                            height="100%"
                            width="100%"
                          />
                        </div>
                        <div className="thankyou-blogs-grid-content">
                          <h3 className="thankyou-blogs-grid-title">
                            {data.title}
                          </h3>
                          <p
                            className="thankyou-blogs-grid-description"
                            dangerouslySetInnerHTML={{
                              __html:
                                data.type === "podcast"
                                  ? data.synopsis
                                  : data.content,
                            }}
                          ></p>

                          <div className="thankyou-blogs-grid-badge">
                            {data.type === "blogs" ? (
                              <span className="blogs">{data.type}</span>
                            ) : (
                              <span className="newsletters">{data.type}</span>
                            )}
                          </div>
                          <div className="thankyou-blogs-grid-meta">
                            <span className="date">{data.date}</span>
                            <span className="status">{data.status}</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })
              .reverse()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Platform;
