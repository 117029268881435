import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";

function NewsroomMediaTwo() {
  return (
    <div className="newsroom-media-two-container">
      <MetaTags>
        <title>
          Updapt - An ESG Tech Co. partners with Grant Thornton Advisory East
          Africa to empower East African Businesses in their Sustainability
          Journey
        </title>
        <meta
          name="description"
          content="Updapt - An ESG Tech Co, announced an alliance with Grant Thornton Advisory East Africa Limited, part of Grant Thornton International Ltd, one of the leading Audit, Assurance, and Advisory companies, headquartered in London, UK, and operating across more than 149 countries."
        />
        <meta
          property="og:title"
          content="UpdaptCSR | Sustainability Platform"
        />
      </MetaTags>
      <div className="blog-title">
        Updapt - An ESG Tech Co. partners with Grant Thornton Advisory East
        Africa to empower East African Businesses in their Sustainability
        Journey.
      </div>
      <div className="blog-picture">
        <figure>
          <img
            className="picture"
            src={
              require("../../../../../assets/Media/mediaTwoImageOne.jpeg")
                .default
            }
            alt="NewsroomMediaOne"
          />
        </figure>
      </div>
      <p>
        <a
          href="/"
          target="_blank"
          rel="noopener noreferrer"
          className="blog-content-href"
        >
          Updapt - An ESG Tech Co
        </a>
        , announced an alliance with{" "}
        <a
          href="https://www.grantthornton.co.ke/"
          target="_blank"
          rel="noopener noreferrer"
          className="blog-content-href"
        >
          Grant Thornton Advisory East Africa Limited
        </a>
        , part of Grant Thornton International Ltd, one of the leading Audit,
        Assurance, and Advisory companies, headquartered in London, UK, and
        operating across more than 149 countries. This collaboration aims to
        equip African businesses with the tools and expertise needed to
        accelerate their decarbonization journey and contribute to Africa's
        ambitious net-zero goals.
      </p>{" "}
      <p>
        Through this partnership, Updapt's innovative{" "}
        <a
          href="/solutions/esg-analytics"
          target="_blank"
          rel="noopener noreferrer"
          className="blog-content-href"
        >
          AI & ML driven ESG tools
        </a>{" "}
        and deep expertise of Grant Thornton would be readily available to East
        African businesses empowering them to strategize and execute their
        net-zero goals smartly towards a more sustainable future.
      </p>
      <p>
        Africa is a continent brimming with potential for sustainable growth.
        And with rapid urbanization and economic development, energy consumption
        is projected to double by 2040. This underscores the critical need for
        businesses to embrace sustainable practices and actively contribute to
        decarbonization efforts.
      </p>
      <p>
        “Our collaboration with Updapt marks a pivotal moment in our ESG and
        sustainability journey. It aligns seamlessly with our commitment to
        aiding clients in shaping a more sustainable and resilient business
        landscape. With Updapt's state-of-the-art technology at our fingertips,
        we anticipate driving significant and lasting transformation.", said{" "}
        <a
          href="https://www.linkedin.com/in/jasmine-shah-28575aa0/"
          target="_blank"
          rel="noopener noreferrer"
          className="blog-content-href"
        >
          Jasmine Shah
        </a>
        , Managing Partner, Grant Thornton, Uganda.
      </p>
      <p>
        <a
          href="https://www.linkedin.com/in/erik-solheim-a9464b225/"
          target="_blank"
          rel="noopener noreferrer"
          className="blog-content-href"
        >
          {" "}
          Erik Solheim
        </a>
        , Advisory Board Member at Updapt and a Global Green Leader said,
        "Climate action in Africa is gaining momentum, with a focus on climate
        finance, carbon markets and policy changes. African countries have the
        potential to be the frontrunners in renewable energy, sustainable land
        use, and innovative technologies, attracting investment, facilitating
        technology transfer, and positioning African nations as leaders in the
        global transition to green development. I'm certain that collaboration
        between Grant Thornton and Updapt ESG Tech would support businesses in
        managing climate risks and also capitalizing on green opportunities in a
        smarter way."
      </p>
      <p>
        Grant Thornton brings to the partnership a wealth of experience in
        audit, tax, and advisory services, complemented by Updapt's cutting-edge
        technology. This combination will empower businesses in East Africa to
        fulfill regulatory requirements more efficiently and exceed global ESG
        standards.
      </p>
      <p>
        <a
          href="https://www.linkedin.com/in/satish-ramchandani-936a042/"
          target="_blank"
          rel="noopener noreferrer"
          className="blog-content-href"
        >
          {" "}
          Satish Ramchandani
        </a>
        , Co-Founder & CBO at Updapt - An ESG Tech Co, said,"Africa accounts for
        just 4 percent of global emissions. Yet it suffers some of the worst
        effects of rising global temperatures. At the same time, the African
        continent is rich in resources like renewables, minerals, standing ready
        to drive its own green growth. Additionally, ESG disclosures are gaining
        fresh momentum in Africa, as investor interest and activism rises
        globally. Our partnership with Grant Thornton is going to be valuable
        for businesses in these markets both from a risk management perspective
        and also related opportunities, through our comprehensive and proven
        climate tech tools being used by companies across various industries and
        multiple geographies”.
      </p>
      <p>
        This partnership signifies a shared commitment to empowering East
        African businesses to achieve their sustainability goals and become
        responsible stewards of the environment. By leveraging Updapt's
        innovative technology and Grant Thornton's deep industry expertise, the
        firm plan to unlock Africa's green potential and support the goals
        outlined in Africa's Agenda 2063, which envisions a continent that is
        integrated, peaceful, and prosperous, driven by its citizens and
        representing a dynamic force on the international stage.
      </p>
    </div>
  );
}

export default NewsroomMediaTwo;
