/** @format */

import React from "react";
import CaseStudy from "../components/CaseStudy";
import { withRouter } from "react-router-dom";

function CaseStudyContainer(props) {
  const caseStudyTitle = props.location.search.split("?title=").pop();
  const handleClick = (link) => {
    props.history.push(link);
  };
  return (
    <CaseStudy
      newslettersData={props.newslettersData}
      caseStudyTitle={caseStudyTitle}
      handleClick={handleClick}
    ></CaseStudy>
  );
}
export default withRouter(CaseStudyContainer);
