import React from "react";
import "../SCSS/styles.css";
import assets from "../../../../../shared/constants/assetVariables";
import ApplicationForm from "../../ApplicationForm";

function Job({ jobData }) {
  function getTypeClass(type) {
    if (type === "remote") {
      return "remote";
    } else if (type === "onsite") {
      return "onsite";
    } else {
      return "hybrid";
    }
  }
  return (
    <>
      {(jobData ?? false) && (
        <div className="job-container">
          <div className="job-cover">
            <div className="header-container">
              <div className="circle-1">
                <div className="circle-2"></div>
                <div className="circle-rotate-1"></div>
                <div className="circle-rotate-2"></div>
              </div>

              <div className="header-title">{jobData?.title}</div>
              <div className="header-content">
                <div className="full-time">{jobData?.hours} |</div>
                <div className="location">
                  <img src={assets.icons.location} alt="location" />{" "}
                  {jobData?.location}
                </div>
              </div>
              <div className="type-cover">
                <div className={`type ${getTypeClass(jobData?.type)}`}>
                  {jobData?.type}
                </div>{" "}
              </div>
            </div>
            <div className="content-cover">
              <div className="section-container">
                {/* <div className="title">Role</div> */}
                <div className="description">{jobData?.description}</div>
              </div>
              <div className="section-container">
                <div className="title">What we expect</div>
                <div className="description">
                  <ul>
                    {jobData?.responsibilities?.map((res, i) => {
                      return <li key={res.toString()}>{res}</li>;
                    })}
                  </ul>
                </div>
              </div>
              <div className="section-container">
                <div className="title">What we need</div>
                <div className="description">
                  <ul>
                    {jobData?.qualifications?.map((ele, i) => {
                      return <li key={ele.toString()}>{ele}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <ApplicationForm jobData={jobData} />
        </div>
      )}
    </>
  );
}

export default Job;
