import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureTwo() {
  return (
    <section className="pa-feature-section feature-2">
      <div className="pa-feature-container">
        <div className="pa-feature-wrapper">
          <div className="pa-feature-image-wrapper">
            <div className="pa-feature-image">
              <object
                data={assets.images.paImage2}
                aria-label="paImage2"
              ></object>
            </div>
          </div>
          <div className="pa-feature-content-wrapper">
            <div className="pa-feature-content">
              <h2 className="pa-feature-title">
                Compare Portfolio's ESG Performance
              </h2>
              <p className="pa-feature-description">
                Compare your portfolio's ESG performance with others using our
                interactive dashboards and reports. Gain a comprehensive
                understanding of how your investments rank among other
                portfolios
              </p>
              <div className="datatrack-grey-circle"></div>
            </div>
          </div>
        </div>
        <div className="dots-grid"></div>
      </div>
    </section>
  );
}

export default FeatureTwo;
