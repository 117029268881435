// Header.js
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import DesktopNavbar from "./Navbar";
import SmallScreensNavbar from "./SmallScreensNavbar";
import assets from "../../../shared/constants/assetVariables";
import greenLogo from "../../../assets/Header/updapt-logo-green.png";
import whitelogo from "../../../assets/Header/updapt-logo-white.png";

function Header({
  width,
  isMobileView,
  dropdown,
  handledropDown,
  setMobileView,
}) {
  const [header, setHeader] = useState("header_trans-fixed");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 50) {
      return setHeader("header_trans-fixed");
    } else if (window.scrollY > 50) {
      return setHeader("pix-header-fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <>
      <header className={`site-header header-two ${header}`}>
        <div className="logo">
          <img
            src={assets.images.updaptNewLogo}
            alt="circle"
            height="100%"
            width="100%"
          />
        </div>
        <div className="header-container">
          <div className="header-inner">
            <div className="site-mobile-logo">
              <a href="/" className="logo-wrapper">
                <img
                  // src={assets.images.updaptLogoWhite}
                  src={whitelogo}
                  alt="site logo"
                  className="main-logo"
                  width="auto"
                  height="100%"
                />
                <img
                  //  src={assets.images.updaptLogoGreen}
                  src={greenLogo}
                  alt="site logo"
                  className="sticky-logo"
                  // height="100%"
                  // width="100%"
                  width="auto"
                  height="100%"
                />
              </a>
            </div>

            {width > 991 ? (
              <DesktopNavbar />
            ) : (
              <SmallScreensNavbar
                isMobileView={isMobileView}
                dropdown={dropdown}
                handledropDown={handledropDown}
                setMobileView={setMobileView}
              />
            )}
          </div>
        </div>
      </header>

      {/* <div className="logo">
    <img src={assets.images.updaptNewLogo} alt="circle" />
    </div> */}
      {/*  <header className="header-section">
     <div className="header-wrapper">
       <div className="updapt-logo">
      <Link to="/" className="logo-image">
       
        <img src={assets.images.updaptLogoWhite} alt="updapt" />
      
      </Link>
      </div>

      {/*if the width of the window is bigger than 1000px use <Navebar/>,
                   else user <SmallScreensNavbar/>*/}
      {/*}
      {width > 1000 ? (
        <DesktopNavbar />
      ) : (
        <SmallScreensNavbar isMobileView={isMobileView} dropdown={dropdown} handledropDown={handledropDown}

        setMobileView={setMobileView}
        />
      )}
      </div>
    </header> */}
    </>
  );
}

export default withRouter(Header);
