//Navbar.js
import React from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import assets from "../../../shared/constants/assetVariables";
import greenLogo from "../../../assets/Header/updapt-logo-green.png";
import whitelogo from "../../../assets/Header/updapt-logo-white.png";

function NavBarComponent({
  isMobileView,
  dropdown,
  setMobileView,
  handledropDown,
  location,
  onClick,
}) {
  let [solutionDropdown, setSoultionDropdown] = React.useState(false);
  let [resourseDropdown, setResourseDropdown] = React.useState(false);
  let [companyDropdown, setCompanyDropdown] = React.useState(false);
  let [dataManagementDropdown, setDataManagementDropdown] = React.useState(false);
  return (
    <nav className={`site-nav ${isMobileView ? "addTransiton" : "removeTransition"}`}>
      <div className="close-menu" onClick={() => setMobileView(!isMobileView)}>
        <span>Close</span>
        <span className="close-icon">
          <img src={assets.icons.closeIcon} alt="closeIcon" />
        </span>
      </div>

      <div className="site-logo">
        <Link to="/" className="logo-wrapper">
          <img
            // src={assets.images.updaptLogoWhite}
            // src={require('../../../assets/Header/updapt-logo-white.png')}
            src={whitelogo}
            alt="site logo"
            className="main-logo"
            height="100%"
            width="100%"
          />
          <img
            // src={assets.images.updaptLogoGreen}
            src={greenLogo}
            alt="site logo"
            className="sticky-logo"
            height="100%"
            width="100%"
          />
        </Link>
      </div>

      <div className="menu-wrapper">
        <ul className="site-main-menu">
          <li className={`menu-item-has-children ${solutionDropdown ? "is-open" : "is-closed"}`}>
            <div
              className={
                location.pathname.match(/^\/solutions/) ? "menu-title  active" : "menu-title"
              }
              onClick={() => setSoultionDropdown(!solutionDropdown)}>
              Solutions
            </div>
            <ul className="sub-menu solution">
              <li
                className="menu-list-item data-management"
                onClick={() => {
                  setDataManagementDropdown(!dataManagementDropdown);
                }}>
                <div
                  // to="/solutions/esg-data-management"
                  to=""
                  className="list-item border-first-child"
                  // onClick={onClick}
                >
                  <span className="icon-solution">
                    <img
                      src={assets.icons.dataManagementCover}
                      alt="data-manager"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title data-title">Data Management & Reporting</div>
                    <div className="sub-title data-sub-title">Collect, Monitor and Report</div>
                  </span>
                  {!isMobileView ? (
                    <span className="right-arrow">{">"}</span>
                  ) : (
                    <img
                      className="accordionArrow"
                      src={assets.icons.accordionArrow}
                      alt="accordionArrow"
                    />
                  )}
                </div>

                <div
                  className={
                    !isMobileView || !dataManagementDropdown
                      ? "nested-menu"
                      : "nested-menu nav-mobile-view"
                  }>
                  <NavLink
                    to="/solutions/esg-data-management"
                    className="list-item"
                    onClick={onClick}>
                    {!isMobileView && (
                      <span className="icon-solution">
                        <img
                          src={assets.icons.uDataManagementIcon}
                          alt="data-manager"
                          className="image-icon"
                          height="100%"
                          width="100%"
                        />
                      </span>
                    )}
                    <span className="description" onClick={onClick}>
                      <div className="title nested-data-title">ESG Data Management</div>
                      <div className="sub-title nested-data-sub-title">
                        Collect, Consolidate, and Monitor
                      </div>
                    </span>
                  </NavLink>
                  <NavLink to="/solutions/esg-reporting" className="list-item" onClick={onClick}>
                    {!isMobileView && (
                      <span className="icon-solution">
                        <img
                          src={assets.icons.uReportingIcon}
                          alt="reporting"
                          className="image-icon"
                          height="100%"
                          width="100%"
                        />
                      </span>
                    )}
                    <span className="description" onClick={onClick}>
                      <div className="title nested-data-title">ESG Reporting</div>
                      <div className="sub-title nested-data-sub-title">
                        Automated ESG Reporting at One-Click
                      </div>
                    </span>
                  </NavLink>
                  <NavLink to="/solutions/esg-analytics" className="list-item" onClick={onClick}>
                    {!isMobileView && (
                      <span className="icon-solution">
                        <img
                          src={assets.icons.uAnalyticsIcon}
                          alt="analytics"
                          className="image-icon"
                          height="100%"
                          width="100%"
                        />
                      </span>
                    )}
                    <span className="description" onClick={onClick}>
                      <div className="title nested-data-title">ESG Analytics</div>
                      <div className="sub-title nested-data-sub-title">
                        Deep Insights for Quick Analysis
                      </div>
                    </span>
                  </NavLink>
                </div>
              </li>
              <li className="menu-list-item">
                <NavLink to="/solutions/netzero" className="list-item" onClick={onClick}>
                  <span className="icon-solution">
                    <img src={assets.icons.nzIcon} alt="grant-icon" className="image-icon" />
                  </span>
                  <span className="description">
                    <div className="title">NetZero</div>
                    <div className="sub-title">
                      Measure, reduce, offset and report your carbon emission
                    </div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink
                  to="/solutions/enterprise-risk-management"
                  className="list-item"
                  onClick={onClick}>
                  <span className="icon-solution">
                    <img src={assets.icons.ermIcon} alt="grant-icon" className="image-icon" />
                  </span>
                  <span className="description">
                    <div className="title">Enterprise Risk Management</div>
                    <div className="sub-title">Proactively identify, manage and monitor risks</div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink
                  to="/solutions/supplier-assessment"
                  className="list-item"
                  onClick={onClick}>
                  <span className="icon-solution">
                    <img src={assets.icons.scmIcon} alt="scm-icon" className="image-icon" />
                  </span>
                  <span className="description">
                    <div className="title">Supplier Assessment</div>
                    <div className="sub-title">
                      Discover how easily you can manage your suppliers
                    </div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink
                  to="/solutions/portfolio-assessment"
                  className="list-item border-last-child"
                  onClick={onClick}>
                  <span className="icon-solution">
                    <img src={assets.icons.paIcon} alt="pa-icon" className="image-icon" />
                  </span>
                  <span className="description">
                    <div className="title">Portfolio Assessment</div>
                    <div className="sub-title">
                      Discover how easily you can manage your portfolio
                    </div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink
                  to="/solutions/double-materiality-assessment"
                  className="list-item border-last-child"
                  onClick={onClick}>
                  <span className="icon-solution">
                    <img src={assets.icons.maIcon} alt="pa-icon" className="image-icon" />
                  </span>
                  <span className="description">
                    <div className="title">Double materiality Assessment</div>
                    <div className="sub-title">
                      Evaluating both the financial impact of sustainability factors and the
                      company's ESG
                    </div>
                  </span>
                </NavLink>
              </li>
            </ul>
            <div className="mask-overlay"></div>
          </li>

          {/* --------------------------------------------------------------------------------------- */}
          <li className={`menu-item-has-children ${companyDropdown ? "is-open" : "is-closed"}`}>
            <div
              className={
                location.pathname.match(/^\/company/) ? "menu-title  active" : "menu-title"
              }
              onClick={() => setCompanyDropdown(!companyDropdown)}>
              Company
            </div>
            <ul className="sub-menu solution">
              <li className="menu-list-item">
                <NavLink to="/about" className="list-item border-first-child" onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.companyAboutus}
                      alt="data-manager"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">About Us</div>
                    <div className="sub-title">Company Glance and Team</div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink to="/company/esg-partners" className="list-item" onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.companyPartner}
                      alt="reporting"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">Partners</div>
                    <div className="sub-title">Connect, Collaborate, and Co-create</div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink to="/company/esg-clients" className="list-item" onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.client_nav_icon}
                      alt="clients"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">Clients</div>
                    <div className="sub-title">Our esteemed patrons</div>
                  </span>
                </NavLink>
              </li>{" "}
              <li className="menu-list-item">
                <NavLink
                  to="/company/careers"
                  className="list-item  border-last-child"
                  onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.careersNavIcon}
                      alt="careers"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">Careers</div>
                    <div className="sub-title">Learn, Adapt, and Inspire.</div>
                  </span>
                </NavLink>
              </li>
            </ul>
            <div className="mask-overlay"></div>
          </li>
          {/* --------------------------------------------------------------------------------------- */}

          {/* <li className="menu-item">
            <NavLink to="/esg-clients" className="menu-title" onClick={onClick}>
              Clients
            </NavLink>
          </li> */}
          <li className="menu-item">
            <NavLink to="/contact" className="menu-title">
              Contact us
            </NavLink>
          </li>

          <li className={`menu-item-has-children ${resourseDropdown ? "is-open" : "is-closed"}`}>
            <div
              className={
                location.pathname.match(/^\/resource/) ? "menu-title  active" : "menu-title"
              }
              onClick={() => setResourseDropdown(!resourseDropdown)}>
              Resources
            </div>

            <ul className="sub-menu resourse">
              <li className="menu-list-item">
                <NavLink
                  to="/resource/media"
                  className="list-item border-first-child"
                  onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.mediaIcon}
                      alt="media"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">Media</div>
                    <div className="sub-title">Company News and Events</div>
                  </span>
                </NavLink>
              </li>

              <li className="menu-list-item">
                <NavLink to="/resource/newsletter" className="list-item" onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.newsletterIcon}
                      alt="newsletter"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">Newsletters</div>
                    <div className="sub-title">Latest ESG Updates and Trends</div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink to="/resource/newsroom" className="list-item" onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.newsroomIcon}
                      alt="newsroom"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">Newsroom</div>
                    <div className="sub-title">News for Publication or Broadcast</div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink to="/resource/blogs" className="list-item" onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.blogsIcon}
                      alt="blogs"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">Blogs</div>
                    <div className="sub-title">ESG and Climate Change Insights</div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink to="/resource/podcast" className="list-item" onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.podcastNav}
                      alt="podcast"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">Podcast</div>
                    <div className="sub-title">Lets talk about ESG and more</div>
                  </span>
                </NavLink>
              </li>
              <li className="menu-list-item">
                <NavLink
                  to="/resource/case-study"
                  className="list-item  border-last-child"
                  onClick={onClick}>
                  <span className="icon-solution">
                    <img
                      src={assets.icons.caseStudyIcon}
                      alt="podcast"
                      className="image-icon"
                      height="100%"
                      width="100%"
                    />
                  </span>
                  <span className="description">
                    <div className="title">Case Study</div>
                    <div className="sub-title">Sustainability Transformation Tales</div>
                  </span>
                </NavLink>
              </li>
            </ul>
            <div className="mask-overlay"></div>
          </li>
        </ul>

        <div className="nav-right">
          <NavLink to="/demo" className="nav-btn" onClick={onClick}>
            Get a Demo
          </NavLink>
        </div>
      </div>
    </nav>
  );
}

export default withRouter(NavBarComponent);
