import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";

function BlogTwentySix() {
  return (
    <>
      <div className="BlogTwentySix-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Different terms, but the goal is "Let's go Net Zero!"</title>
          <meta
            name="description"
            content="Net Zero is a global objective that must be driven at the national level, where its policies are followed locally by all countries, cities and businesses developing an action plan."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">
          Different terms, but the goal is "Let's go Net Zero!"
        </div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Meenakshi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Mar 28, 2023</div>
              <div className="blog-read-time">2 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog26.jpg").default}
            alt="blog-26"
          />
        </div>
        {/* <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>Did you know?</b>
            </p>
          </div>
        </div> */}
        <p>
          <a
            href="/solutions/netzero"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            Net Zero
          </a>{" "}
          is a global objective that must be driven at the national level, where
          its policies are followed locally by all countries, cities and
          businesses developing an action plan. At the same time, it is crucial
          to mitigate global warming in the second half of the century and
          equally challenging to reduce all emissions to zero or to scale up to
          effective removals.
        </p>
        <p>
          More than 70 countries, including the most significant polluters
          (covering about 76% of global emissions) - China, the United States,
          and the European Union - have set a net-zero target. Science-Based
          Targets Initiative has identified that more than 3,000 businesses and
          financial institutions are actively reducing their emissions in line
          with climate science. And over a thousand cities, academic
          establishments, and over four hundred economic establishments have
          joined the Race to Zero, pledging to take a rigorous, instantaneous
          movement to halve international emissions by 2030.
        </p>
        <p>
          There are several approaches to achieving{" "}
          <a
            href="/solutions/netzero"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            net zero
          </a>
          , but following common principles is essential, and a study at the
          University of Oxford identifies one set of such. As per the study,
          there are seven attributes for a successful{" "}
          <a
            href="/solutions/netzero"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            net zero
          </a>{" "}
          strategy.
        </p>
        <p>
          <ul>
            <li>
              To get to{" "}
              <a
                href="/solutions/netzero"
                target="_blank"
                rel="noopener noreferrer"
                className="blog-content-href"
              >
                net zero
              </a>{" "}
              while setting measurable short-term goals.
            </li>
            <li>
              Taking a comprehensive approach to reducing emissions is crucial.
            </li>
            <li>
              Trade-offs, safety, and permanence must be considered to reduce
              emissions and remove carbon dioxide.
            </li>
            <li>
              A quality control system that ensures carbon offsets' social and
              environmental integrity.
            </li>
            <li>Regions, industries, and other groups must share efforts.</li>
            <li>
              Ensuring biodiversity conservation and indigenous people's rights
              must align with socio-ecological objectives.
            </li>
            <li>
              Investing in education and skills and collaborating across sectors
              is essential.
            </li>
          </ul>
        </p>
        <p>
          With all the pledges and strategies, the question that needs to be
          answered is: Have we taken that first step into action? Because the
          most important thing is to act and to go{" "}
          <a
            href="/solutions/netzero"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            net zero
          </a>{" "}
          in the right way.
        </p>
      </div>
    </>
  );
}
export default BlogTwentySix;
