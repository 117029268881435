import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterThree() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter December 2022
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterThree-container">
        <MetaTags>
          <title>
            IFRS announces CDP's incorporation of the ISSB Climate-related
            Disclosures.
          </title>
          <meta
            name="description"
            content="The Association of Banks in Singapore (ABS) has launched new sustainable private banking and wealth management guidelines that comprise principles for private banks..."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          IFRS announces CDP's incorporation of the ISSB Climate-related
          Disclosures
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/images/last.jpg").default}
              alt="media-01"
            />
          </figure>
        </div>
        <p>
          An announcement was made by the IFRS to the global market ahead of
          finance day at COP27 about CDP's incorporation of ISSB climate-related
          disclosures. With this announcement, CDP is positioning to scale the
          early adoption of the ISSB's climate standard across the global
          economy.
        </p>
        <p>
          CDP, the not-for-profit which runs the global disclosure system for
          investors, companies, cities, states, and regions to manage their
          environmental impacts, constantly creates a strategy to bring in
          unparalleled engagement on environmental issues across the globe. CDP
          issues a set of questionnaires annually on behalf of 680 financial
          institutions with over $130 trillion in assets and will be
          incorporating the International Sustainability Standards Board (ISSB)
          into its global environmental disclosure platform.
        </p>
        <p>
          The standards are being finalised and incorporated into CDP's existing
          questionnaires. With this integration, 18,700 companies (half of the
          global market capitalisation) will disclose environmental information
          through CDP in 2022. The integration is also expected to improve the
          consistency of climate-related information for investors and reduce
          the disclosure burden on entities through an alignment of
          requirements.
        </p>
        <p>
          In turn, CDP will provide the IFRS foundation with access to data on
          disclosures made against the climate standard for monitoring uptake
          and implementation of the measures and pursuing their continuous
          improvement.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>

          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The CDP and the ISSB integration is a significant step towards
            delivering a comprehensive global baseline for capital markets.
            Adopting ISSB standards will boost corporate action and
            accountability among governments and other regulators.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          BMW announced a new supply agreement for CO2-reduced steel
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/news4.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          Automotive manufacturer BMW constantly drives sustainability motives
          by conserving resources, including initiatives to source sustainable
          materials and focus on economic considerations.
        </p>
        <p>
          {/* <br /> */}
          The company’s strategy is to avoid more than 200 million tonnes of CO2
          emissions by 2030, including a 50% reduction in global CO2 use-phase
          emissions by 2030 and a more than 40% reduction in life cycle
          emissions. The activities include:
          <ul>
            <li>working on using sustainable materials,</li>
            <li>promoting the use of secondary materials, and</li>
            <li>ramping up recycled and reusable materials to 50% by 2030.</li>
          </ul>
        </p>
        <p>
          According to the group, around 20% of supply chain CO2 emissions for a
          mid-sized fully electric vehicle are attributable to steel. As part of
          the company’s decarbonization efforts, the BMW Group has announced a
          series of new supply agreements to reduce CO2 emissions in its
          supplier network.
        </p>
        <p>
          The BMW Group already signed an agreement with Swedish start-up H2
          Green Steel in October last year, and the group has now signed a
          corresponding agreement with Salzgitter AG to deliver low-carbon
          steel.{" "}
        </p>
        <p>
          H2 Green Steel will supply the BMW Group’s European Plants with steel
          produced exclusively using hydrogen and green power from renewable
          energies from 2025 onwards, which will reduce CO2 emissions by around
          95%. And the agreement with Salzgitter AG will be used in the standard
          production of cars at the Group’s European plants from 2026 onwards to
          reduce emissions by up to 400,000 tonnes per year from agreements with
          two suppliers.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Steel is one of the essential materials for car production and the
            biggest emitter of CO2 emissions globally. BMW’s agreement is a
            critical step for substantially reducing CO2 emissions at the source
            in the supplier network. Companies in a similar line must also
            consider using secondary steel from the circular economy to reduce
            CO2 emissions and gradually transition to low-carbon steel
            production.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Europe's three primary financial regulatory agencies, the European
          Supervisory Authorities (ESAs), call for evidence on Greenwashing
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/news5.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          European Supervisory Authorities (ESAs) include The European Banking
          Authority (EBA), The European Insurance and Occupational Pensions
          Authority (EIOPA), and The European Securities and Markets Authority
          (ESMA).
        </p>
        <p>
          Europe's three primary financial regulatory agencies aim to launch a
          significant greenwashing study to gather information on greenwashing
          risks and practices across the banking, insurance, and financial
          markets sectors.
        </p>
        <p>
          Greenwashing broadly includes sustainability-related claims relating
          to all aspects of the ESG spectrum (Environmental, social and
          governance dimensions). And in the past several months, as the
          commitments towards climate change grew, regulators and authorities
          have seen several high-profile greenwashing-related actions.
        </p>
        <p>
          According to a survey, greenwashing could be analysed through four
          main dimensions.
          <ul>
            <li>
              The actual topics on which the sustainability-related claims are
              made.
            </li>
            <li>
              The role market participants can play in greenwashing could
              include three possible categories: trigger, spreader, and receiver
              of a sustainability-related claim.
            </li>
            <li>
              The misleading qualities of a sustainability-related claim specify
              how a claim can be constructed as dishonest.
            </li>
            <li>
              The channels through which the sustainability-related claims are
              communicated to other actors across the sustainable value chain or
              the various stages of the product lifecycle in which they occur.
            </li>
          </ul>
        </p>
        <p>
          However, the ESAs aim to collect information on how to understand
          greenwashing and what the main drivers of greenwashing might be,
          including examples of potential greenwashing practices across the EU
          financial sector, the scale of greenwashing, and the areas of high
          greenwashing risks.
        </p>
        <p>
          The European regulator's call for evidence is open until 10 January
          2023, with progress reports due from each of the regulators in May of
          the same year. The final piece of the study can be expected in May
          2024.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>

          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Misleading ESG and sustainability disclosures have become a greater
            focus for regulators worldwide. Understanding the claims around
            greenwashing is crucial to help form policies and make the
            reliability of sustainability-related claims.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Moody's investor services propose a framework to assess net zero
          readiness
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/news6.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          Moody’s Investors Service (MIS), an American credit rating agency, is
          looking to provide an independent and comparative assessment of a
          company’s carbon transition plan.
        </p>
        <p>
          The proposed Net Zero Assessment (NZA) by the MIA aims to get around
          the variations in regional standards and inconsistencies in data
          reporting. MIA wants to provide a point-in- time assessment of the
          quality of a company’s implementation plan and the size of the
          emission cuts implied by decarbonisation ambitions for which the
          companies will be scored.
        </p>
        <p>
          The MIA is scoring on a five-point scale based on Moody’s opinion of
          the strength of a company’s carbon transition plan that will be
          updated on an annual or ad-hoc basis at the request of the scored
          entity. The highest score, the NZ-1, would imply that an entity’s plan
          is aligned with the goals of the Paris agreement, limiting temperature
          increases to 1.5&deg;C. The lowest score of NZ-5 would indicate a
          temperature rise of 2.6&deg;C.
        </p>
        <p>
          MIS initially intended to prepare the framework for the non-financial
          public sector and nonprofit entities, which may consider expanding the
          framework to include financial institutions and governments later. MIS
          will consult market participants to gauge interest in the framework
          and request feedback by 31st January 2023.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Net zero assessments are now essential for all businesses,
            especially the top companies with external pressures. Companies must
            set minimum procedural standards for target setting, and Moody’s
            must ensure that it meets standards of integrity, so the
            <a
              href="/solutions/esg-reporting"
              target="_blank"
              className="newsletter-content-href"
            >
              {" "}
              ESG assessment
            </a>{" "}
            provides investors with an independent and comparative assessment of
            a company's transition plans.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          The European Financial Reporting Advisory Group (EFRAG) approves final
          version of the European Sustainability Reporting Standards (ESRS)
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/news7.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          The European Financial Reporting Advisory Group (EFRAG) has approved
          the final version of the European Sustainability Reporting Standards
          (ESRS). EFRAG, a private association financed mainly by the EU, was
          mandated by the European Commission in June 2020 to prepare for new EU
          sustainability reporting standards, and in May 2021, EFRAG was
          requested to develop reporting standards for the CSRD.
        </p>
        <p>
          The ESRS is an amendment to the existing EU's Non-Financial Reporting
          Directive (NFRD) designed to ensure that sustainability information is
          reported in a carefully articulated manner and aims to serve as the
          architecture for one annual ESG report that meets the needs of EU
          regulators, investors, and other stakeholders.
        </p>
        <p>
          The standard sets out rules and requirements for companies to report
          on their:
          <ul>
            <li>Strategies and business model with sustainability.</li>
            <li>Governance and organization concerning sustainability.</li>
            <li>
              The materiality assessment process to select material ESG themes,
              topics, risks and opportunities.
            </li>
            <li>
              Sustainability and ESG performance implementation measures,
              covering policies, targets, actions, allocation of resources and
              performance metrics.
            </li>
          </ul>
        </p>

        <p>
          The new rules will significantly expand the number of companies
          required to provide sustainability disclosures to over 50,000 from
          around 12,000 and introduce more detailed reporting requirements on
          company impacts on the environment, human rights and social standards
          and sustainability-related risk.
        </p>
        <p>
          A company's first ESRS report will be due in early 2024 based on the
          company's 2023 fiscal year environmental performance. ESRS foresees
          the preparation of sector-specific standards.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3> Updapt Views: </h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            The approval of the new sustainability reporting system marks
            another significant step in helping companies become more circular
            and produce quality, comparable and effective performance data. The
            reporting must also increase a company’s accountability, prevent
            divergent sustainability standards, and ease the transition to a
            sustainable economy.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterThree;
