import React from "react";
import Intro from "./Intro";
import FeatureOne from "./FeatureOne";
import FeatureTwo from "./FeatureTwo";
import FeatureThree from "./FeatureThree";
import FeatureFour from "./FeatureFour";
import FeatureFive from "./FeatureFive";
import MetaTags from "react-meta-tags";

function EnterpriseRiskManagement(props) {
  return (
    <>
      <MetaTags>
        <title>Enterprise Risk Management (ERM) software | Updapt</title>
        <meta
          name="description"
          content="Updapt Enterprise Risk Management (ERM) Software Efficiently Identifies and Mitigates the Risk. It Bestows Comprehensive Risk Insights and Simplifies Your Risk Process."
        />
        <meta
          property="og:title"
          content="Enterprise Risk Management (ERM) software | Updapt"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <Intro></Intro>
      <FeatureOne></FeatureOne>
      <FeatureTwo />
      <FeatureThree />
      <FeatureFour />
      <FeatureFive />
    </>
  );
}

export default EnterpriseRiskManagement;
