import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureTwo() {
  return (
    <section className="grant-feature-section">
      <div className="grant-feature-container">
        <div className="grant-feature-wrapper">
          <div className="grant-feature-image-wrapper">
            <div className="grant-feature-image">
              <img src={assets.images.sampleImage} alt="updapt" />
            </div>
            <div className="dot-shape2">  
             <img src={assets.images.homeBannerImageDot} alt="updapt" />
             </div>
          </div>
          <div className="grant-feature-content-wrapper">
            <div className="grant-feature-content">
              <h1 className="grant-feature-title">
                Simplified CSR Fund Management{" "}
              </h1>
              <p className="grant-feature-description">
                Track multiple fund allocations, non-profit RFPs, and more
                <br />
                using a single dedicated interface that allows you to manage all
                program-specific finances and budgets, as well as track the
                frequency of funding.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureTwo;
