import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";

function NewsroomMediaOne() {
  return (
    <>
      <div className="newsroom-media-one-container">
        <MetaTags>
          <title>
            Updapt - new partnership announcement with DNV Supply Chain and
            Product Assurance (SCPA)
          </title>
          <meta
            name="description"
            content="Updapt is glad to announce a partnership with DNV Supply Chain and Product Assurance (SCPA), one of the leading assessment and assurance company headquartered in Oslo, Norway, with operations in over 100 countries."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">
          Updapt - new partnership announcement with DNV Supply Chain and
          Product Assurance (SCPA)
        </div>
        <div className="blog-author-details"></div>

        <div className="blog-picture">
          <figure>
            <img
              className="picture"
              src={
                require("../../../../../assets/Media/dnv-updapt-1.png").default
              }
              alt="media-01"
            />
            <figcaption style={{ color: "grey", fontSize: "12px" }}>
              <i>
                From LtoR: 1) Joher Jerwalla, Business head, Product
                Assurance-IME at DNV SCPA, 2) Prakash Tikare, Area Manager,
                India subcontinent and Middle East-DNV SCPA, 3) Anirban
                Chatterjee, Head of Business Development, Sales & Marketing-IME
                DNV SCPA, 4) Qarina Mistry Senior Manager - Partnership and
                Alliances, Updapt(an ESG Tech Co.) 5)Mr. Satish Ramchandani, Co-
                Founder & CBO, Updapt(an ESG Tech Co.)
              </i>
            </figcaption>
          </figure>
        </div>
        <div className="blog-picture">
          <figure>
            <img
              className="picture"
              src={
                require("../../../../../assets/Media/dnv-updapt-2.jpg").default
              }
              alt="media-01"
            />
          </figure>
        </div>

        <p>
          Updapt is glad to announce a partnership with DNV Supply Chain and
          Product Assurance (SCPA), one of the leading assessment and assurance
          company headquartered in Oslo, Norway, with operations in over 100
          countries.
        </p>
        <p>
          Through assessment and digital assurance solutions, DNV helps
          companies build trust and transparency around products, assets, supply
          chains and ecosystems. Whether certifying products, sharing claims or
          optimizing and decarbonizing supply chains, DNV helps companies manage
          risks and realize their long-term strategic goals, improving ESG
          performance and generating lasting, sustainable results. Combining
          sustainability, supply chain and digital expertise, DNV works to
          create new assurance models enabling interaction and transaction
          transparency across value chains. DNV Supply Chain and Product
          Assurance provides various ESG assessment and assurance services along
          with a professional evaluation of ESG processes and reports to ensure
          the data and practices are accurate and trustworthy. DNV is working to
          create new assurance models enabling interaction and transaction
          transparency across value chains to bridge the gaps among consumers,
          producers and suppliers.
        </p>
        <p>
          SaaS-based ESG solutions of Updapt enable businesses to track, perform
          carbon footprint accounting, monitor and generate reports aligned to
          Global Sustainability Standards and Frameworks such as GRI, TCFD,
          SASB, UNSDG, BRSR, along with deep analytics supporting net-zero goals
          of businesses across all sizes and industries and have accomplished
          engagements with key stakeholders such as Investors, Rating Agencies,
          Lenders, Board, Regulators, Customers, Supply Chain Partners.
        </p>
        <p>
          Collaboration of Updapt and DNV SCPA would add significant value to
          the Sustainability Value Chain through robust tech and assurance
          solutions for the market and building the confidence of multiple
          stakeholders.{" "}
        </p>
      </div>
    </>
  );
}

export default NewsroomMediaOne;
