import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";

function BlogTwentyFive() {
  return (
    <>
      <div className="BlogTwentyFive-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Internal Carbon Pricing</title>
          <meta
            name="description"
            content="Carbon risk and its effects are fast approaching, as seen in January 2021, when the Bank of England alerted businesses about the surge in carbon price to $100 per ton."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">Internal Carbon Pricing</div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Meenakshi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Feb 20, 2023</div>
              <div className="blog-read-time">2 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog25.jpg").default}
            alt="blog-25"
          />
        </div>
        {/* <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>Did you know?</b>
            </p>
          </div>
        </div> */}
        <p>
          Carbon risk and its effects are fast approaching, as seen in January
          2021, when the Bank of England alerted businesses about the surge in
          carbon price to $100 per ton.
        </p>
        <p>
          Further, the UK Government has set out a system for businesses to
          report their carbon-related risks by 2050, aligned with the Task Force
          on Climate-related Financial Disclosures (TCFD) standards. Some
          industries, specifically those that invest in other areas of the
          economy, will expect to undergo a commencing effect of interest where
          there are carbon-related business risks. Also, with governments signed
          up to carbon reduction targets in line with the Paris Agreement, many
          are introducing carbon pricing mechanisms to cut emissions; this makes
          the timeline for change far more straightforward than it has ever
          been.
        </p>
        <p>Internal carbon pricing generally takes one of three forms:</p>
        <p>
          <b>Internal carbon fee</b>
        </p>
        <p>
          These are monetary values on each ton of carbon emissions, which is
          readily understandable throughout the organisation. The fee creates a
          dedicated revenue or investment stream to fund the company&#39;s
          emissions reduction efforts. The observed price range for companies
          using an internal carbon fee ranges from $5 to $20 per metric ton.
        </p>
        <p>
          <b>Shadow price</b>
        </p>
        <p>
          It is a speculative price on carbon to help long-term business plan
          their investment strategies. They also encourage companies for
          low-carbon investments and prepare for future regulation using an{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG platform
          </a>
          . Certain companies employ a shadow price much higher than the
          standard $2-$893 per ton.
        </p>
        <p>
          <b>Implicit price</b>
        </p>
        <p>
          This pricing is based on the value the company agrees to spend to
          reduce greenhouse gas emissions and the cost of abiding by government
          regulations.
        </p>
        <p>
          <b>Benefits of setting an Internal carbon pricing</b>
        </p>
        <p>
          Internal carbon pricing is a crucial risk-reduction means with
          numerous benefits that focus on climate action and can be combined
          with other methods to transition towards the advancement of low-carbon
          projects.
        </p>
        <p>
          Establishing an internal carbon price can offer significant benefits.
          While the drivers are specific to each company, the general benefit
          schemes are as follows,
        </p>
        <p>
          <ul>
            <li>
              Producing carbon concerns a considerable aspect of business
              operations
            </li>
            <li>Eliminating risks in the future carbon price</li>
            <li>Analysing carbon its risk</li>
            <li>Future-proof business strategy</li>
            <li>Initiate sustainability endeavours</li>
            <li>Raising awareness among employees and customers</li>
            <li>
              Responding to the climate emergency concerns by investors and
              customers
            </li>
            <li>Mitigating carbon emissions</li>
          </ul>
        </p>
        <p>
          Business managers can control transition risk, support corporate
          values, and enhance the investment decision-making process.
        </p>
      </div>
    </>
  );
}
export default BlogTwentyFive;
