import React, { useState } from "react";
import CaseStudyEight from "../components/CaseStudyEight";

function CaseStudyEightContainer() {
  const [caseStudyData] = useState({
    title: "Fast Moving Consumer Goods case study",
    img: "fmcgCase.jpg",
    content:
      "Client is a Singapore based conglomerate that has completed 75 years of successful operations, spanning over Asia, Africa and Europe, employing more than 20,000 people, across 10 plus countries, and reaching over one billion customers. The business verticals include Consumer goods, Fintech, logistics and Infrastructure & Industrials, shared among 15 plus subsidiaries. Each vertical has multiple business entities with varied level of operations such as manufacturing, packaging, retail distribution, banking and financial services, infrastructure development etc. The manufacturing and production businesses also are of multiple types, including Noodles production, Pulp & paper production, Edible oil production etc. With such diverse business operations, the need to achieve sustainable growth gains utmost importance. There is no one size fits all approach when dealing with such a conglomerate. The reporting system must be flexible enough to adapt to the requirements set by agencies across geographies and entities",
  });
  const [downloadModalBool, setDownloadModalBool] = useState(false);
  return (
    <CaseStudyEight
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
    />
  );
}

export default CaseStudyEightContainer;
