import React from "react";
import { withRouter } from "react-router-dom";
import assets from "../../../shared/constants/assetVariables";

function Investor() {
  return (
    <section className="investor-section-wrapper">
      <h3 className="investor-section-title">Investors</h3>
      <div className="home-investor-section">
        <div className="investor-logo-wrapper">
          <img src={assets.images.accentureLogo} alt="investor-accenture" />
        </div>
        <div className="investor-content-wrapper">
          {/* <h2>CIIE</h2> */}
          <p>
            Updapt is part of the Accenture Sustainability Value Accelerator at
            CIIE, which aims to accelerate startups with technology solutions
            that can help businesses to identify & analyze risks, patterns, and
            opportunities, or to deliver & measure efficiency & efficacy of
            products, systems, operations, decisions to achieve and enhance
            sustainability.
          </p>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Investor);
