import React, { useState } from "react";
import CaseStudyThree from "../components/CaseStudyThree";

function CaseStudyThreeContainer() {
  const [caseStudyData] = useState({
    title: "Pharma case study",
    img: "pharmaCase.jpg",
    content:
      "Client is India's one of the largest pharma company with a revenue of over USD 2 billion dollars, present in more than 80 countries and with over 47 manufacturing facilities across 6 countries. The organisation has a sustainability commitment to achieve Carbon and Water neutrality by the year 2025. Responsible resource consumption and sustainable operations form part of the organisation's vision and values. With the growing complexity and volume of the operations, Client needed a robust, real time, and fully digitised ESG solution. The solution needed to have the flexibility to accommodate the various business cases, embedded control mechanisms, advanced visualisations to drill down to the granular level of data, and the ability to generate highly analytical dashboards and reports. ",
  });
  const [downloadModalBool, setDownloadModalBool] = useState(false);
  return (
    <CaseStudyThree
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
    />
  );
}

export default CaseStudyThreeContainer;
