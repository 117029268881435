import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFive() {
  return (
    <section className="pa-feature-section feature-5">
      <div className="pa-feature-container">
        <div className="datatrack-grey-circle"></div>
        <div className="yellow-circle"></div>
        <div className="pa-feature-wrapper">
          <div className="pa-feature-content-wrapper">
            <div className="pa-feature-content">
              <h2 className="pa-feature-title">
                Proactively Manage ESG Risks with Comprehensive Assessments
              </h2>
              <p className="pa-feature-description">
                Identify and address ESG risks across your portfolio with our
                comprehensive risk assessment features. Gain actionable insights
                into potential vulnerabilities and proactively manage
                ESG-related risks.
              </p>
            </div>
          </div>
          <div className="pa-feature-image-wrapper">
            <div className="pa-feature-image">
              <object
                data={assets.images.paImage5}
                aria-label="paImage5"
              ></object>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFive;
