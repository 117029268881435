import React from "react";
import "../SCSS/styles.scss";

function BlogTwo() {
  return (
    <>
      <div className="BlogTwo-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>2 min read</span>
        </div> */}
        <div className="blog-title">
          The Need for a Sustainable Business Behavior
        </div>
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Dec 9, 2020</div>
              <div className="blog-read-time">2 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: Dec 9, 2020</div> */}
        <div className="blog-content">
          <div className="blog-picture">
            <img
              className="picture"
              src={require("../../../../../assets/Blogs/blog-2a.jpg").default}
              alt="Blog-2a"
            />
          </div>
          <p>
            The world has faced some unprecedented challenges during the recent
            year, erratic climate patterns, wildfires across the Amazon and
            Australia; a consequence of climate change, and even the recent
            COVID19 pandemic have impacted communities and the economy
            worldwide. Given these challenges, there is a need to make changes
            in business decision making processes and ensure that there is focus
            on creating long term value for all businesses.
          </p>
          <p>
            On the other hand, now we also deeply care about the values of the
            companies we work for. Sustainability approaches in a company’s
            operations are becoming more and more important. Integrating
            sustainability to a business has shown that it creates long term
            value by taking into account how the organization operates with
            minimizing its negative impacts on the environment, society and the
            economy.
          </p>
          {/* <div className="blog-picture">
            <img
              className="picture"
              src={require("../../../../../assets/Blogs/blog-11.svg")}
              alt="Blog-11"
            />
          </div> */}
          {/* <div className="highlight">
          <div className="left">
            <div className="sub-header">
              What do sustainability approaches in businesses mean?
            </div>
            <p>
              a) The business should focus on having zero to minimum negative
              impacts on the planet and its people.
            </p>
            <p>
              b) The business should focus on improving performance in
              environmental, social and governance areas (ESG) .
            </p>
          </div>
          <div className="right">
            {" "}
            <img
              className="picture"
              src={require("../../../../../assets/Blogs/Blog2.png")}
            />
          </div>
        </div> */}
          {/* <div className="sub-header">How is this done?</div> */}
          <p>
            Businesses initially chose to meet their social responsibilities
            through charitable donations and philanthropic activities.
            Executives have often been reluctant to integrate sustainability
            into their company’s core business strategy as they believe that the
            costs of these practices (opting for renewable energy, climate
            proofing the business, social audits, etc) outweigh their benefits.
            They fail to see its role in long term value creation. More
            recently, they have begun incorporating sustainability and socially
            responsible practices into core business operations. Transparency
            across all operations in a business has become more and more
            prevalent as companies are now taking various measures to become
            more sustainable. A number of factors have contributed to this
            development over the years, which mainly involves the major
            stakeholders.
          </p>
          <p>
            Investors now favor companies with better ESG performance; they look
            at a company’s carbon footprint, impacts on natural resources,
            community development initiatives and board diversity.
          </p>
          <p>
            Employees prefer working in a conducive work environment, which
            comprises safe, healthy, fair and ethical working conditions; no
            negative impacts to the surrounding communities and a good brand
            image.
          </p>
          <p>
            Consumers prefer a brand that is known to take positive steps
            towards fair and ethical working conditions, fair treatment of
            customers, environmental stewardship, responsible marketing and
            advertising, quality products.
          </p>
          <p>
            Sustainability in business is now redefining the corporate ecosystem
            by taking steps such that it creates values for all its stakeholders
            - employees, customers, surrounding communities, shareholders and
            the whole planet. It is crucial for companies to establish good
            relationships with all its stakeholders, any conflict here can cause
            major disruptions across operations. We can see that it is now
            gaining a strong foothold, because stakeholders want to know how an
            organization will deal with impacts of disruptions due to social
            causes (Black Lives Matter protests, etc.) or natural calamities,
            both internally and externally.
          </p>
          <p>
            Many large firms like Nike, Kering group; Unilever, Google, etc.
            have made strong commitments towards sustainability. They have
            chosen to address material issues pertaining to their business in a
            transparent and effective manner. By embarking on this journey
            towards becoming more and more sustainable, they are paving the way
            for other companies to follow suit.
          </p>
        </div>
      </div>
    </>
  );
}
export default BlogTwo;
