import React from "react";
import Intro from "./Intro";

import DesktopFaq from "./DesktopFaq";
import SmallScreenFaq from "./SmallScreenFaq";

function Faq(props) {
  return (
    <>
      <Intro />
      {props?.width > 992 ? (
        <DesktopFaq
          tabSelected={props?.tabSelected}
          handleTabClick={props?.handleTabClick}
          faqData={props?.faqData}
          width={props?.width}
        />
      ) : (
        <SmallScreenFaq faqData={props?.faqData} />
      )}
    </>
  );
}

export default Faq;
