/** @format */

import React from "react";
import "../SCSS/styles.css";
import Intro from "./Intro";
import Posts from "./Posts";
import MetaTags from "react-meta-tags";

function Podcast({ podcastData, gotoPodcastInfo, handleClick }) {
  return (
    <>
      <MetaTags>
        <title>Podcast | Updapt</title>
        <meta name="description" content="Lets talk about ESG and more." />
        <meta property="og:title" content="Podcast | Updapt" />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>

      <Intro />

      <Posts
        podcastData={podcastData}
        gotoPodcastInfo={gotoPodcastInfo}
        handleClick={handleClick}
      />

      {/* <Subscribe /> */}
    </>
  );
}
export default Podcast;
