import React from "react";
import "../SCSS/styles.css";
function Section2() {
  return (
    <div className="section-container">
      <div className="section-2-inner-container">
        <div className="left-section">
          <div className="bg-img">
            <img src={require("../../../../assets/images/ma-2.svg")} className="section2-img" />
          </div>
        </div>
        <div className="right-section">
          <h2 className="section2-heading">
            Tailor Assessments with Traditional or Double Materiality
          </h2>
          <p className="section2-description">
            Choose between traditional materiality (financial materiality) or double materiality
            (financial and impact materiality) to tailor your assessments for optimal ESG
            performance.
          </p>
          <div className="datatrack-grey-circle-right"></div>
          <div className="dot-grid-2"></div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
