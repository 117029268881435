import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Licenses({ licencingDetails }) {
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="home-licensing-section">
      <div className="home-licensing-container">
        <div className="home-licensing-content">
          <h3 className="home-licensing-title">
            Partnerships and Initiatives we support
          </h3>
        </div>
        <div className="home-licensing-slider">
          <Slider {...settings}>
            {licencingDetails.map((licence, index) => (
              <div className="brand-logo" key={licence.images}>
                <img
                  src={licence.images}
                  alt={licence.name}
                  height="110"
                  width="100%"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Licenses;
