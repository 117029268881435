import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";

function BlogTwentyOne() {
  return (
    <>
      <div className="BlogTwentyOne-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Where does the world stand with SDG 2030 today?</title>
          <meta
            name="description"
            content="The United Nations adopted 17 Sustainable Development Goals in 2015 as an action to eradicate poverty, sustain the earth, and secure the goal of peace and prosperity by the year 2030."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">
          Where does the world stand with SDG 2030 today?
        </div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Alice Patricia</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Sep 15, 2022</div>
              <div className="blog-read-time">2 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/giftboxes.jpg").default}
            alt="blog-21"
          />
        </div>
        {/* <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>Did you know?</b>
            </p>
          </div>
        </div> */}

        <p>
          The United Nations adopted <b>17 Sustainable Development Goals</b> in
          2015 as an action to eradicate poverty, sustain the earth, and secure
          the goal of peace and prosperity by the year 2030.
        </p>

        <p>
          As of 2022, reports suggest substantial progress has been made based
          on internationally comparable data, primarily sourced through several{" "}
          <a
            href="/solutions/esg-reporting"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG software
          </a>{" "}
          or{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            Sustainability Tools
          </a>{" "}
          for SDG monitoring. The indicators of the global SDG database grew
          from 115 in 2016 to 217 in 2022. However, there still are crucial data
          gaps in geographic scope, promptness, and division level, making it
          challenging to understand the accurate rate of progress towards the
          2030 Agenda.
        </p>

        <p>
          8 of the 17 SDGs in less than half of the 193 countries have
          internationally comparable data from 2015, while Goal 3 (Good health
          and wellbeing) and Goal 7 (Affordable and clean energy) have the
          highest data availability. Almost 80% of the countries have at least
          one data point since 2015, and around 20% have data for Goal 13
          (Climate action). Data of only 7 out of 21 indicators requiring
          disaggregation by sex and age are available. Only 2 out of 10 SDG
          indicators are available for disaggregation by disability status.
        </p>

        <p>
          COVID-19 posed a severe challenge to the struggling national
          statistical systems and aroused the importance of stronger statistical
          and ICT foundations like{" "}
          <a
            href="/solutions/esg-reporting"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG Accounting Tool
          </a>
          ,{" "}
          <a
            href="/solutions/esg-reporting"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG Software
          </a>{" "}
          or a{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            Sustainability Tool
          </a>
          . 96 % of the country's restrictions on face-to-face data collection
          are due to national lockdown measures in May 2020. In May 2021,
          disruptions in this data collection were still occurring in 57% of the
          countries.
        </p>

        <p>
          Countries dependent on in-person data collection before COVID-19 were
          significantly affected, while countries with knowledge of remote data
          collection, like an{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG platform
          </a>
          , had considerable benefits. For example, in the United Kingdom, the
          quick implementation of a time-use survey during the pandemic profited
          from previous experimentation with remote data collection.
        </p>

        <p>
          However, delegates who attended sustainable development meetings
          declared that the SDGs are not being achieved at the pace needed to
          meet the 2030 deadline. On some level, only slight improvements have
          been made.
        </p>

        <p>
          Many recommendations are provided for almost all the SDGs, for
          example:
        </p>

        <p>
          <b>SDG 6 Clean water and sanitation:</b>
          <ul>
            <li>
              Meeting drinking water, sanitation, and hygiene targets by 2030
              will require a fourfold increase in the pace of progress.
            </li>
            <li>The world's wetlands are being lost at an alarming rate.</li>
            <li>
              Early remediation of water pollution will require active
              monitoring, which is sorely lacking in the poorest countries.
            </li>
            <li>
              Stress on water resources in Northern Africa and Western Asia is
              already at dangerous levels.
            </li>
            <li>
              Most countries still lack cooperation agreements on shared water
              resources, a potential source of conflict.
            </li>
          </ul>
        </p>

        <p>
          <b>SDG 7 Affordable and Clean Energy:</b>
          <ul>
            <li>
              Intensified efforts are needed in the least developed countries to
              jump-start access to clean cooking fuels and technologies.
            </li>
            <li>
              Meeting global energy and climate objectives will require a
              significant push in the deployment of renewables, with massive
              financial mobilization.
            </li>
            <li>
              The target for global energy efficiency remains within reach, but
              only with significant investment on a systematic scale.
            </li>
            <li>
              Despite the growing urgency of climate change, international
              public financing for renewable energy had already shown before the
              pandemic.
            </li>
          </ul>
        </p>
        <p>And a few more progress statements for the other 15 goals.</p>
        <p>
          As per a few reports, SDGs are set to reach by 2073 if the current
          trends remain the same, and there has been minimal progress which
          clearly shows that the world is not on track to achieve the goals by
          2030. It is time for the Nations, industries and other organizations
          to work together and collectively get the SDGs back on track.
        </p>
      </div>
    </>
  );
}
export default BlogTwentyOne;
