import React, { useState, useReducer, useEffect } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";
import PartnerForm from "../component/PartnerForm";
const initialFormObj = {
  firstName: "",
  lastName: "",
  email: "",
  jobTitle: "",
  companyName: "",
  mobile: "",
  partnershipType: "",
  message: "",
  optin: false,
};

function PartnerFormContainer(props) {
  const [formObj, setFormObj] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialFormObj
  );
  const [mobile, setMobile] = useState("");
  const [errors, setErrors] = useState({});
  const [issubbmitted, setIsSubbmitted] = useState(false);

  const handleTextOnChange = (event, type) => {
    if (type === "agree") {
      setFormObj({ ...formObj, optin: event.target.checked });
    } else {
      const { name, value } = event.target;
      setFormObj({ [name]: value });
    }
  };

  const validate = () => {
    let errors = {};

    const isText = RegExp(/^[A-Z ]+$/i);
    const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    if (!formObj.firstName) {
      errors.firstName = "Please enter firstName";
      NotificationManager.warning(errors.firstName, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isText.test(formObj.firstName)) {
        errors.firstName = "Firstname accepts text only";
        NotificationManager.warning(errors.firstName, "", 2000);
        setErrors(errors);
        return;
      }
    }
    if (!formObj.lastName) {
      errors.lastName = "Please enter last name";
      NotificationManager.warning(errors.lastName, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isText.test(formObj.lastName)) {
        errors.lastName = "Lastname accepts text only";
        NotificationManager.warning(errors.lastName, "", 2000);
        setErrors(errors);
        return;
      }
    }
    if (!formObj.email) {
      errors.email = "Please enter email";
      NotificationManager.warning(errors.email, "", 2000);
      setErrors(errors);
      return;
    } else if (!isEmail.test(formObj.email)) {
      errors.email = "Please enter a valid email address.";
      NotificationManager.warning(errors.email, "", 2000);
      setErrors(errors);
      return;
    } else {
      const domain = formObj.email.split("@");
      if (
        domain[1].toLowerCase().includes("gmail") ||
        domain[1].toLowerCase().includes("yahoo") ||
        domain[1].toLowerCase().includes("rediffmail")
      ) {
        errors.email = "Please enter a valid work email";
        NotificationManager.warning(errors.email, "", 2000);
        setErrors(errors);
        return;
      }
    }
    if (!formObj.jobTitle) {
      errors.jobTitle = "Please enter job title";
      NotificationManager.warning(errors.jobTitle, "", 2000);
      setErrors(errors);
      return;
    }
    if (!formObj.companyName) {
      errors.companyName = "Please enter company name";
      NotificationManager.warning(errors.companyName, "", 2000);
      setErrors(errors);
      return;
    }
    if (!mobile) {
      errors.mobile = "Please enter mobile number";
      NotificationManager.warning(errors.mobile, "", 2000);
      setErrors(errors);
      return;
    } else {
      setFormObj({ ["mobile"]: mobile });
    }

    if (!formObj.partnershipType) {
      errors.message = "Please select partnership type";
      NotificationManager.warning(errors.message, "", 2000);
      setErrors(errors);
      return;
    }

    if (!formObj.message) {
      errors.message = "Please enter your message";
      NotificationManager.warning(errors.message, "", 2000);
      setErrors(errors);
      return;
    }
    if (formObj.optin === false) {
      errors.optin = "Please accept Terms and Conditions to continue";
      NotificationManager.warning(errors.optin, "", 2000);
      setErrors(errors);
      return;
    }
    setErrors(errors);
  };

  const handleSendRequest = async (e) => {
    e.preventDefault();
    validate();
    setIsSubbmitted(true);
  };

  useEffect(() => {
    const postInfo = async () => {
      if (!Object.keys(errors).length && issubbmitted) {
        try {
          await axios.post("/contact/sales/api/partner", {
            firstName: formObj.firstName,
            lastName: formObj.lastName,
            email: formObj.email,
            jobTitle: formObj.jobTitle,
            companyName: formObj.companyName,
            mobile: formObj.mobile,
            message: formObj.message,
            partnershipType: formObj.partnershipType,
          });
          await axios.post("/notification/partner-form", {
            firstName: formObj.firstName,
            lastName: formObj.lastName,
            email: formObj.email,
            jobTitle: formObj.jobTitle,
            companyName: formObj.companyName,
            mobile: formObj.mobile,
            partnershipType: formObj.partnershipType,
            message: formObj.message,
          });
          props.history.push("/company-success", {
            from: "contact",
            name: formObj.firstName,
          });
        } catch (error) {
          NotificationManager.error(
            "Please write to us at info@updaptcsr.com",
            "Error"
          );
          throw error;
        }
      }
    };
    postInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <PartnerForm
      formObj={formObj}
      handleTextOnChange={handleTextOnChange}
      handleSendRequest={handleSendRequest}
      mobile={mobile}
      setMobile={setMobile}
    ></PartnerForm>
  );
}

export default withRouter(PartnerFormContainer);
