import React from "react";
import "../SCSS/styles.scss";


function BlogNine() {
  return (
    <>
      <div className="BlogNine-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <div className="blog-title">
          Sustainability and the Fashion Industry
        </div>
        {/* <div className="update-date">Updated: December 25, 2019</div> */}
        <div className="blog-author-details">
            {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */} 
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Dec 25, 2019</div>
              <div className="blog-read-time">4 min read</div>
            </div>
          </div>
        </div>

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog9.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-4.svg")}
            alt="Blog-4"
          />
        </div>
        <p>
          We often read reports of incidence of forest fires destroying natural
          habitats and erratic climatic conditions resulting in natural
          disasters like flooding. Such events negatively impact our ecosystem.
          These are some of the evident impacts our actions have on the
          environment. However, the consequences of our subconscious actions are
          often overlooked due to lack of awareness, our retail shopping sprees
          being one of them.
        </p>

        <p>
          According to a report published by UNEP, the fashion industry is
          responsible for 10% of annual global carbon emissions, more than all
          our international flights and maritime activities combined. Apart from
          its carbon footprint, the production and distribution of crops,
          fibers, and garments used in fashion all contribute to differing forms
          of environmental pollution, including that of water, air, and soil.
          The textile industry is one of the major contributors to freshwater
          pollution. Developing countries like India, China, Bangladesh and
          Indonesia that are known to have the largest textile manufacturing
          industries, face repercussions due to this.
        </p>
        <p>
          The biggest contributor to the adverse effects caused by the fashion
          industry is ‘Fast Fashion”. This term has created quite a buzz over
          the past few years. Previously, luxury brands like Gucci, Louis
          Vuitton, etc followed a seasonal approach in launching new lines of
          products. Through fast fashion, everyone now has access to the most
          recent trends at affordable prices. Therefore, what was once just
          considered to be something reserved for the most privileged citizens
          of society, has now transformed into an easily attainable commodity.
          Owing to this increased accessibility, and hence demand, textile
          manufacturers have accelerated their pace of design and production.
          Brands like Forever 21, H&M and Zara now launch new lines of products
          much more frequently (ranging from weekly to monthly).
        </p>
        <p>
          The colossal demand drives the fashion industry into exploiting its
          workers with inhumane working conditions and low wages. In addition to
          this, much of the clothing manufactured by fast fashion brands doesn’t
          last very long as they are made with fibers of cheaper quality. As a
          result, a large portion of it ends up in landfills; we seldom see
          clothes being donated or recycled. A report by Ellen MacArthur
          Foundation claims that less than 1% of used clothing is recycled into
          new garments due to which there is a loss of approximately USD 500
          billion. The different aspects of fast fashion such as trend
          replication, rapid production, cheaper quality and competitive pricing
          of products have negative effects on our environment and society.
        </p>
        <p>
          The growing interest in sustainability has now entered the fashion
          sector, paving the way for sustainable fashion. It is defined as
          clothing and accessories that are manufactured, marketed and used in a
          sustainable manner, taking into account both environmental and
          socio-economic aspects. This requires improvement across all stages of
          the product’s life cycle from raw material sourcing (supply chain),
          design, manufacturing, marketing, sales, usage and disposal of the
          product.
        </p>
        <p>
          From an environmental conservation perspective, people are now slowly
          opting for fabrics made out of natural fibers (cotton, hemp, etc) and
          innovative fibers (made from fibers from crops like banana, pineapple,
          etc.) over cheap synthetics like polyester, nylon, etc. Most textile
          manufacturers are now maximising the utilization of renewable energy
          at various stages of the production and transportation processes.
          Companies are also taking measures to limit the amount of waste
          generated in various stages of the product’s lifecycle.
        </p>
        <p>
          From a socio-economic perspective, textile companies should opt for
          raw materials that are sourced ethically; improve working conditions
          for workers in garment manufacturing industries and across the supply
          chain and align with the best practices and international codes of
          conduct. They must also encourage sustainable/responsible consumption,
          washing practices and hence better the attitudes of people towards
          fashion. Complete sustainability can be achieved, only when the entire
          supply chain is made sustainable.
        </p>

        <p>
          Many famous brands of are now releasing sustainability inspired
          clothing lines. For example, Stella McCartney now uses recycled
          polyester and plastic along with organic cotton in their line of
          clothing. Brands like Gucci showcased their lines of clothing in
          carbon neutral runway shows in the New York and Milan fashion week
          this year. Gucci also announced that the entire company is now “carbon
          neutral within its own operations and across the entire supply chain
          accounting for all the GHG emissions we generate." Fast fashion brand
          H&M is also adopting sustainability in its manufacturing by utilising
          more organic and recycled cotton in their clothing line.
        </p>
        <p>
          Ultimately, the final choice when it comes to clothing lies with the
          consumers. Consumers are now becoming more and more conscious about
          how their clothing choices affect society and the environment. The
          best decision one can make is to pick clothing that is durable and
          responsibly made and wear them for as long as feasible. Donating
          clothes to the right causes and organizations is another great
          decision.
        </p>
        <p>
          Businesses in the apparel industry can slowly but steadily shift their
          operations and do better without incurring many losses financially.
          With the new sustainability and ESG standards and regulations
          introduced, simply adhering to them will go a long way in fixing brand
          image. Also, in the long run, sustainable fashion will easily be able
          to make significant monetary gain, as we enter a new era of social and
          environmental responsibility.
        </p>
      </div>
    </>
  );
}
export default BlogNine;
