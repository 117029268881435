import React from "react";
import "../SCSS/styles.css";
function Section6() {
  return (
    <div className="section-container section-container-6" style={{ paddingBottom: "0" }}>
      <div className="section-6-inner-container">
        <div className="yellow-circle"></div>
        <div className="left-section">
          <div className="datatrack-grey-circle-left"></div>
          <div className="bg-img">
            <img src={require("../../../../assets/images/ma-6.svg")} className="section6-img" />
          </div>
        </div>
        <div className="right-section">
          <h2 className="section6-heading">Streamline Materiality to Prioritize Key ESG Issues</h2>
          <p className="section6-description">
            <span>Updapt MA</span> simplifies the materiality assessment process, ensuring you can
            prioritize the most critical ESG issues while staying aligned with global standards and
            stakeholder expectations.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Section6;
