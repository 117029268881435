import React from "react";
import Intro from "./Intro";
import FeatureOne from "./FeatureOne";
import FeatureTwo from "./FeatureTwo";
import FeatureThree from "./FeatureThree";
import FeatureFour from "./FeatureFour";
import FeatureFive from "./FeatureFive";
import MetaTags from "react-meta-tags";

function PortfolioAssessment(props) {
  return (
    <>
      <MetaTags>
        <title>ESG Portfolio Assessment Software With Advance Reporting</title>
        <meta
          name="description"
          content="Updapt ESG Portfolio Assessment Software Improves Entities ESG Portfolios and makes Flawless Decisions with advanced monitoring and reporting capabilities."
        />
        <meta
          property="og:title"
          content="ESG Portfolio Assessment Software With Advance Reporting"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <Intro></Intro>
      <FeatureOne></FeatureOne>
      <FeatureTwo />
      <FeatureThree />
      <FeatureFour />
      <FeatureFive />
    </>
  );
}

export default PortfolioAssessment;
