import React from "react";
import Lottie from "react-lottie-player";
import lottieJson from "./17848";
function Intro() {
  return (
    <section className="page-banner" style={{ paddingBottom: "50px" }}>
      <div className="container">
        <div className="page-content-wrapper">
          <div className="page-badge">MATERIALITY ASSESSMENT</div>
          <h1 className="page-title">
            Integrate Your Financial Success with Social Responsibility Seamlessly
          </h1>
          <p className="page-description">
            Empower your business to balance financial performance with positive social and
            environmental impact, confidently meeting stakeholder expectations and regulatory
            standards.
          </p>
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{
              width: 350,
              height: 350,
              position: "absolute",
              right: -40,
              top: 124,
              opacity: "0.5",
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default Intro;
