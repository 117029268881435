import React from "react";
import "../SCSS/styles.scss";

function BlogFour() {
  return (
    <>
      <div className="BlogFour-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>3 min read</span>
        </div> */}
        <div className="blog-title">Sustainability and the Pandemic</div>
        {/* <div className="update-date">Updated: Jun 19, 2020</div> */}
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Jun 19, 2020</div>
              <div className="blog-read-time">3 min read</div>
            </div>
          </div>
        </div>

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog4.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-4.jpg").default}
            alt="Blog-4"
          />
        </div>

        <p>
          The Corona-virus has been wreaking havoc across all parts of the world
          since the start of this year. We are currently amidst a global
          pandemic, which has threatened our lives and livelihood to a great
          extent. Something of this magnitude was last seen during the Spanish
          flu over a century ago. Infection rates and deaths are mounting;
          movement is restricted in most parts of the world, including air
          travel; economic and industrial activities have also been cut down.
          Governments have resorted to extraordinary measures to help keep
          people and the economy safe from the consequences of the COVID-19
          pandemic.
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            Our world has finite resources and maximizing private gain
            undoubtedly leads to a collective loss of wealth.
          </div>
        </div>
        <p>
          The crisis holds earnest lessons that will help us tackle the issues
          of sustainability and climate change. For it's successful management,
          governments and industries have had to effectively engage and
          coordinate with each of their stakeholders. Until now, Corporate
          Social Responsibility in companies mainly consisted of employee
          engagement programs and philanthropic activities; but the impacts of
          the pandemic have made companies consider integrating environmental,
          social and governance issues into their businesses on a more serious
          note.
        </p>
        <p>
          COVID-19 has exposed vulnerabilities of our current society on a
          fundamental level. It has shown us that we are not as resilient, by
          exposing our socio-economic flaws and that human activities are
          strongly responsible for climate change. Even though the pandemic has
          contributed to the enhancement of ecological health in the form of
          reduction in emission levels across the world, it has reduced economic
          activity and many businesses are failing because of this. Our world
          has finite resources and maximizing private gain undoubtedly leads to
          a collective loss of wealth. For example, multinational companies run
          large factories but do not take all the necessary steps to preserve
          the environment in their surroundings and eventually end up
          contributing to climate change.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            We have started to recognize and value the essential workers and
            their role in our communities. The environment around us has started
            to recover, nature has proven to be resilient.
          </div>
        </div>
        <p>
          The pandemic has shown us that public health is the key driver for
          everything, from community development to a thriving economy. We will
          face challenges and risks along the road to recovery. In order to
          tackle the socio-economic challenges exposed due to the pandemic, each
          stakeholder should take ownership of their actions and its impact on
          the future. To do this, businesses should first define their purpose
          to serve the society. Once this is done, it needs to be acted upon to
          make people’s lives better in all strata of society. An inclusive
          economy approach should be adopted, where importance is given to make
          sure that domestic supply chains are more resilient by sourcing raw
          materials locally from ethical and sustainable sources. As a result,
          production units are closer to the markets, thereby reducing emissions
          drastically. Sites can be designed to be more compliant with
          environmental regulations and policies, and equipment should be
          operated at maximum energy efficiency. .
        </p>
        <p>
          The next step is to make sure all the stakeholders adopt a sense of
          sustainability and integrate it into their daily life. On a personal
          front, the pandemic has brought about huge changes in our lifestyle
          and behavior. We are now more cautious of our individual actions in
          society. Most of these changes will stick around until things return
          to normal, which could take a while. It has made communities support
          each other unlike ever before. We have started to recognize and value
          the essential workers and their role in our communities. The
          environment around us has started to recover, nature has proven to be
          resilient. The skies are now clearer than before, birds and other
          wildlife are also venturing closer to us. Air quality in huge cities
          like New Delhi has improved drastically. Dolphins have returned to the
          canals of Venice.
        </p>
        <p>
          As a response to current times, systemic changes are being adopted and
          facilitated by governments and businesses, these along with individual
          actions are critical in securing our future on this planet. It is
          laying more emphasis on issues such as climate change, socio-economic
          inequality, and poverty. Therefore, highlighting the need of taking
          care of each other and our planet.{" "}
        </p>
      </div>
    </>
  );
}
export default BlogFour;
