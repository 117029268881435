import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFour() {
  return (
    <section className="analytics-feature-section">
      <div className="analytics-feature-container">
        <div className="analytics-feature-wrapper">
          <div className="analytics-feature-image-wrapper">
            <div className="analytics-feature-image">
              <img src={assets.images.analyticsImage4} alt="updapt" />
            </div>
          </div>
          <div className="analytics-feature-content-wrapper">
            <div className="analytics-feature-content">
              <h2 className="analytics-feature-title">Peer Analysis</h2>
              <p className="analytics-feature-description">
                Measure your ESG performance against peers across a number of
                metrics and industry parameters through the functions of
                Strategy, Target, Performance & Result using our powerful
                analytics.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFour;
