import React, { useState, useEffect } from "react";
import Faq from "../components/Faq";

function PricingContainer() {
  const [tabSelected, setTabSelected] = useState(1);

  const handleTabClick = (id) => {
    setTabSelected(id);
  };
  // 1- Get the size of window
  const windowInnerSize = [window.innerWidth, window.innerHeight];

  // 2- Define the state variable windowSize and pass windowInnerSize as its initial value
  const [windowSize, setWidowSize] = useState(windowInnerSize);
  useEffect(() => {
    const changeWindowSize = () => {
      setWidowSize([window.innerWidth, window.innerHeight]);
    };
    /* 3- add a 'resize' eventListener to window
        so whenever the size of window changes
     the state variable windowSize changes and the component  re-renders */
    window.addEventListener("resize", changeWindowSize);

    // 4- cleanup the 'resize' eventListener
    return () => window.removeEventListener("resize", changeWindowSize);
  }, []);

  const [width, height] = windowSize;
  const [faqData] = useState([
    {
      id: 1,
      name: "Security",
      faq: [
        {
          question: "Is my SaaS application data safe in the cloud?",
          answer:
            "Our cloud services are secured with high-level cloud security. We offer increased security and manageability with dedicated VPC hosting to ensure the safety of our user's data.",
        },
        {
          question: "How can I know my data is safe?",
          answer:
            "We back ourselves up with reliable data security and privacy policies ingrained in our product engineering and service delivery procedures.",
        },
      ],
    },
    {
      id: 2,
      name: "Pricing",
      faq: [
        /*{
        question: "Is there a free trial available?",
        answer:"Currently, we do not offer a free trial. However, you can schedule a live demo with our sales team to obtain a complete tour of the Updapt platform."

      },*/
        {
          question: "How many users can I have in Updapt platform?",
          answer:
            "Updapt supports multi-user access and the number of users depends on licenses purchased.",
        },
        {
          question:
            "How do I know which pricing plan is right for my business?",
          answer:
            "We will walk you through the features included in each plan during the live demo to determine which is the best fit for your business.",
        },
        {
          question: "How will I be billed and charged for using Updapt?",
          answer: [
            "You'll be charged an annual subscription fee for any of the plans you've chosen.",
          ],
        },
      ],
    },
    {
      id: 3,
      name: "Data",
      faq: [
        {
          question: "How many years of data can I add?",
          answer:
            "Updapt allows you to add historic data for up to 10 years. You can simply manage and collect data over time, track goals, and assess the results.",
        },
        {
          question: "What data do I need for the analysis?",
          answer:
            "Updapt collects ESG data such as energy consumption (electricity, heat), water consumption, waste, number of employees, employee training and diversity, and so on to provide a range of analyses. We discuss which specific data needs to be collected for your company at the beginning of our collaboration. You can then use the tool to collect specific data and track the progress of the data collection. ",
        },
      ],
    },
    {
      id: 4,
      name: "General",
      faq: [
        {
          question: "Can Updapt help reduce my company's carbon footprint?",
          answer:
            "Yes, Updapt can help you minimize your carbon footprint by providing an accurate analysis of your GHG emissions, including a breakdown of scope 1, 2, and 3 emissions as well as an overview of your significant emission hotspots.",
        },
        {
          question: "Why should I use the Updapt ESG Tool?",
          answer:
            "ESG metrics is getting mainstream and is a core part of business strategy for businesses and funds worldwide. ESG performance has an impact on Balance Sheet performance and is critical for enhancing shareholder value with requirements stemming for multiple market participants such as Investors, Rating Agencies, Regulators, Lenders, Customers, Board.",
          answer_1:
            "A robust process to capture scattered and auditable data is imperative to measure ESG performance and generate reports for various stakeholders. SaaS-based ESG solution of Updapt can be used by enterprises to track, monitor, and report ESG performances in line with Global Sustainability Standards such as GRI, TCFD, BRSR, etc, across their operations and supply chain digitally, along with performing carbon footprint accounting automatically. Our solution would enable businesses to enhance ESG scores through deep analytics and also ensure strong internal controls comforting key stakeholders.",
        },
      ],
    },
    /* {
      id:5,
       name:"ESG Expertise",
      faq:[]
     },*/
    {
      id: 6,
      name: "Support",
      faq: [
        {
          question: "What kind of support does Updapt offer?",
          answer:
            "Updapt looks forward to assisting you with your query via email, phone, and live chat. We reply to your queries in the order that they are received as quickly as possible.",
        },
        {
          question: "Who do I get in contact with if I have questions?",
          answer:
            "Ask your question right now by visiting our Contact page. We’ll get back to you as soon as possible. ",
        },
        {
          question:
            "Will I be assigned a dedicated account manager to assist me with the setup?",
          answer:
            "Updapt provides dedicated support through an account manager who will walk you through the whole onboarding process, including account setup and management.",
        },
        {
          question:
            "How does Updapt support the attainment of net-zero goals for my company?",
          answer:
            "Digital ESG Solution of Updapt enables businesses to track and monitor their emissions against targets set, peers and also for supply chain partners, along with related dashboards. Additionally, projects identified towards decarbonization can be monitored, supporting net-zero goals of the company. Updapt has also tied up with partners in the space of climate tech, water, and waste management solutions helping companies in their net-zero journey.",
        },
        {
          question:
            "Does Updapt support all reporting frameworks with Sustainability Reporting Standards",
          answer:
            "ESG experts at Updapt have created a comprehensive taxonomy across various ESG themes and industries covering all ESG parameters, which have been mapped to commonly used Sustainability Standards across the globe. In case any specific Sustainability Standard Reporting is required by any client, the same is easily configurable in our Digital ESG solution.",
        },
      ],
    },
    /*  {
      id:7,
       name:"Saas",
      faq:[{
        question: "What is Software-as-a-Service (SaaS)?",
        answer:""
      }]
     },*/
  ]);
  return (
    <Faq
      tabSelected={tabSelected}
      handleTabClick={handleTabClick}
      faqData={faqData}
      width={width}
      height={height}
    ></Faq>
  );
}

export default PricingContainer;
