/** @format */

import React from "react";
import Newsletter from "../component/newsletter";
import { withRouter } from "react-router-dom";

function NewsletterContainer(props) {
  const queryParams = new URLSearchParams(props.location.search);
  const month = queryParams.get("month");
  const year = queryParams.get("year");
  const handleClick = (link) => {
    props.history.push(link);
  };
  return (
    <Newsletter
      newslettersData={props.newslettersData}
      handleClick={handleClick}
      month={month}
      year={year}
    ></Newsletter>
  );
}
export default withRouter(NewsletterContainer);
