const podcastValues = [
  {
    title: "Introduction and Recap COP27",
    image: "images/podcast-theme.jpg",
    url: "introduction-and-recap-cop27",
    date: "Jan 01, 2023",
    duration: "10 min",
    type: "podcast",
    host: "Meenakshi Venkatachalapathy",
    content:
      "Our focused podcast series concentrates on hot topics and trending ESG industry news.",
    synopsis:
      "COP27, the 27th session of the conference of the parties to the United Nations Framework Convention on climate change, is the topic for our podcast episode for environment and sustainability enthusiasts. Join us as our speaker, Meenakshi Venkatachalapathy, Sustainability consultant at Updapt, give us a quick recap of the #CO27 event and discuss trends in growth and decision-making. The podcast divulges Updapt's perspectives on the changing climate and our focus on the continuous pursuit of sustainability and economic and social responsibility.",
    podcast_audio: "podcast1.mp3",
    google_podcast:
      "https://podcasts.google.com/u/1/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9jZjMxYzNjYy9wb2RjYXN0L3Jzcw/episode/ZGVjOTYxOWItNWQ3ZC00Nzc1LWIyNmYtZDI0ODYzMzVlYzMw?sa=X&ved=0CAUQkfYCahcKEwjwq9q5oIv8AhUAAAAAHQAAAAAQUg",
    spotify_podcast: "https://open.spotify.com/show/3JLT83OfgqebGWu01tFYNK",
  },
  {
    title:
      "Satish Ramchandani from Updapt: Software to monitor and cut carbon emissions",
    image: "images/podcast-2.jpg",
    url: "software-to-monitor-and-cut-carbon-emissions",
    date: "Jan 10, 2023",
    duration: "28 min",
    type: "podcast",
    host: "Bharti Chhatre Krishnan",
    synopsis:
      "CSR and ESG were once used interchangeably, and mostly referred to programmes for underserved communities (for example- water coolers in village schools). This information was typically kept in excel spreadsheets and was reviewed near reporting deadlines. However, net zero pledges are changing ESG reporting. Organizations must now continuously measure their carbon footprints because regulators, investors, suppliers, as well as millennial customers, want to know their green credentials. It's no surprise that ESG tech is a growing opportunity, with investors across the world queuing up to invest in ESG tech companies.",
    podcast_audio: "podcast2.mp3",
    google_podcast:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xNjczMTQzLnJzcw/episode/QnV6enNwcm91dC0xMTQ4MzUyNw?sa=X&ved=0CAUQkfYCahcKEwiQucGDj9r6AhUAAAAAHQAAAAAQAQ&hl=en-IN",
    spotify_podcast: null,
  },
];

export default podcastValues;
