import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterThirteen() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter October 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterThirteen-container">
        <MetaTags>
          <title>
            California Legislators Approve Bill Mandating Full Value Chain
            Emissions Disclosure by Companies.
          </title>
          <meta
            name="description"
            content=" A new proposed California law that could require most large U.S.
            companies to disclose their full value chain greenhouse gas (GHG)
            emissions passed the state's Assembly."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          California Legislators Approve Bill Mandating Full Value Chain
          Emissions Disclosure by Companies.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/oct2023-1.jpeg").default}
            alt="oct2023-1"
          />
        </figure>
        <p>
          A new proposed California law that could require most large U.S.
          companies to disclose their full value chain greenhouse gas (GHG)
          emissions passed the state's Assembly. The proposed legislation,
          having secured a solid majority vote in the state's Assembly, is now
          set to return to the Senate before landing on Governor Gavin Newsom's
          desk for final approval.
        </p>
        <p>
          If enacted into law, this bill will require companies with annual
          revenues exceeding $1 billion that operate in California to report
          their emissions comprehensively. This includes emissions from all
          scopes, notably encompassing supply chains, with a deadline of 2027
          for compliance. Moreover, the legislation mandates third-party
          verification of emissions data, ensuring accuracy and reliability.
        </p>
        <p>
          This move aligns with ongoing efforts by the U.S. Securities and
          Exchange Commission (SEC) to establish climate disclosure rules for
          U.S. firms. California's proposed law goes beyond the SEC's draft
          rules by applying to all large companies and including all Scope 3
          emissions. It underscores the rising significance of emissions
          transparency and sustainability reporting.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The bill enhances environmental accountability, provides data for
            informed policymaking, extends California's influence globally,
            fosters green industries, and reinforces the state's leadership in
            climate action, benefiting both the environment and the economy.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Ghana Unveils Ambitious $550 Billion Energy Transition and Investment
          Plan for Net-Zero by 2060.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/oct2023-2.jpeg").default
              }
              alt="oct2023-2"
            />
          </figure>
        </div>
        <p>
          Ghana is taking steps towards a sustainable future with its newly
          launched Energy Transition and Investment Plan, unveiled by President
          Nana Addo Dankwa Akufo-Addo during a Global Africa Business Initiative
          event in New York. This visionary plan outlines Ghana's commitment to
          combat climate change while fostering economic growth.
        </p>
        <p>
          The plan charts a credible path for Ghana to achieve net-zero
          energy-related carbon emissions by 2060. It envisions low-carbon
          solutions across key sectors such as oil and gas, industry, transport,
          cooking, and power. By pursuing this plan, Ghana seeks international
          support for its energy transition, presenting a remarkable $550
          billion investment opportunity for sustainable development. Notably,
          this initiative has the potential to generate 400,000 jobs within
          Ghana's economy.
        </p>
        <p>
          Ghana's prior Energy Transition Framework aimed for net-zero emissions
          by 2070, but this new plan demonstrates increased ambition, targeting
          net-zero by 2060.
        </p>
        <p>
          The plan proposes sectoral changes and four key decarbonization
          technologies: renewables, low-carbon hydrogen, battery electric
          vehicles, and clean cookstoves. These innovations are expected to
          cover over 90% of the targeted emissions reduction by 2060.
        </p>
        <p>
          {" "}
          The country can achieve net-zero emissions with this plan while
          showcasing that climate action and economic development can go hand in
          hand.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Ghana's green transition presents businesses with the prospect of
            market expansion, job generation, innovation prospects,
            international partnerships, bolstered reputation, and enduring
            viability. In an increasingly eco-conscious global environment, this
            transition empowers businesses to thrive while championing
            sustainability.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EFRAG and GRI Make Sustainability Reporting Easier for Businesses.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/oct2023-3.jpeg").default}
            alt="oct2023-3"
          />
        </figure>
        <p>
          In a significant development, EFRAG and GRI have achieved seamless
          alignment between the European Sustainability Reporting Standards
          (ESRS) and the Global Reporting Initiative (GRI) Standards. This
          pivotal collaboration eradicates the burden of "double reporting" for
          companies, streamlining sustainability disclosures and establishing a
          more user-friendly reporting framework.
        </p>
        <p>
          The ESRS, created by EFRAG and adopted by the European Commission,
          establishes guidelines for reporting sustainability impacts,
          opportunities, and risks under the upcoming Corporate Sustainable
          Reporting Directive (CSRD), set to apply from 2024. This directive
          will expand reporting obligations to over 50,000 companies, demanding
          more comprehensive disclosures on environmental, social, and
          sustainability-related matters.
        </p>
        <p>
          GRI Standards are globally recognized for sustainability reporting,
          known for their consistency across industries. Key alignment areas
          include the adoption of a double materiality approach, covering both
          how sustainability issues affect companies and a company's impact on
          society and the environment.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The alignment between ESRS and GRI Standards reduces reporting
            complexity, saving time and resources. Businesses can seamlessly
            adapt their reporting practices, ensuring compliance with evolving
            sustainability regulations while efficiently communicating their
            commitment to stakeholders.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Hong Kong Central Bank Sets Climate Change Guidelines for Banks.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/oct2023-4.jpeg").default}
            alt="oct2023-4"
          />
        </figure>

        <p>
          The Hong Kong Monetary Authority (HKMA), the central banking
          institution of Hong Kong, has unveiled a significant directive aimed
          at guiding banks towards a net-zero economy. This move underscores the
          HKMA's commitment to ensuring the stability and integrity of the
          financial system, particularly the banking sector, in the face of
          climate change.
        </p>
        <p>
          Key principles outlined by the HKMA include the imperative for banks
          to establish clear objectives aligned with the Paris Agreement's
          climate goals. These objectives encompass not only the implementation
          of transition strategies but also robust risk management processes.
          Banks are urged to align their targets with the Paris Agreement's
          objectives, aiming to limit global temperature increases to well below
          2°C, ideally 1.5°C.
        </p>
        <p>
          Additionally, banks are encouraged to integrate climate considerations
          into their internal operations, from governance structures to product
          offerings. They are advised to engage with clients, gathering
          information on the unique risks and opportunities their sectors face
          in the transition to a net-zero economy.
        </p>
        <p>
          Transparency is emphasized, with banks encouraged to enhance the
          transparency of their transition planning processes and regularly
          update their plans in response to evolving climate scenarios and
          sectoral pathways.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The HKMA's directive helps Hong Kong banks by improving climate risk
            management, aligning strategies with global climate goals, enhancing
            client engagement, fostering transparency, ensuring global
            relevance, and promoting long-term sustainability, thus addressing
            critical challenges in the face of climate change.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          UK and Germany Forge Hydrogen Partnership for Net Zero Advancement.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/oct2023-5.jpeg").default}
            alt="oct2023-5"
          />
        </figure>
        <p>
          In a significant development for the global hydrogen industry, the UK
          and Germany have entered into a crucial partnership agreement to boost
          hydrogen's role in their energy sectors and accelerate international
          trade in this clean energy source.
        </p>
        <p>
          The Joint Declaration of Intent, signed by the UK's Minister for
          Energy Efficiency and Green Finance, and Germany's State Secretary for
          Energy, solidifies their commitment to promoting low-carbon hydrogen.
          Both nations will emphasize the role of hydrogen in their energy mix
          and work together to advance renewable hydrogen technologies.
        </p>
        <p>
          This partnership builds upon substantial investments made by both
          countries in hydrogen development, further aligning with their goals
          of achieving net zero emissions by 2050 and enhancing energy security.
          It also underscores their joint commitment to transitioning away from
          fossil fuels toward cleaner, diversified energy alternatives.
        </p>
        <p>
          The collaboration is founded on five key pillars, including the
          acceleration of hydrogen projects, international leadership in
          hydrogen markets, research and innovation, trade promotion, and joint
          market analysis. These efforts aim to make hydrogen technologies more
          accessible, reduce energy costs, and stimulate private investments in
          hydrogen technology and projects.
        </p>
        <p>
          This agreement marks a critical step towards realizing the potential
          of hydrogen as a sustainable energy source, benefiting not only the UK
          and Germany but also contributing to broader global efforts to combat
          climate change and ensure energy security. The partnership will play a
          vital role in shaping the future of the European hydrogen economy and
          fostering international cooperation on clean energy solutions.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The UK-Germany hydrogen partnership benefits businesses by creating
            a thriving hydrogen market, fostering innovation, and facilitating
            trade. This collaboration provides opportunities for companies to
            invest in and develop hydrogen technologies, access a growing
            customer base, and position themselves as leaders in the emerging
            clean energy sector, contributing to economic growth and
            sustainability.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterThirteen;
