import React from "react";

function Intro() {
  return (
    <section className="page-banner">
      <div className="container">
        <div className="page-content-wrapper">
          <div className="page-badge">PORTFOLIO ASSESSMENT</div>
          <h1 className="page-title">
            Empower Private Equity Firm with Advanced ESG Monitoring and
            Reporting.
          </h1>
          <p className="page-description">
            Experience unparalleled assessment, monitoring and improvement of
            your portfolio's ESG performance with Portfolio Assessment.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Intro;
