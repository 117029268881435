import React from "react";
import "../SCSS/Styles.scss";

function BlogEight() {
  return (
    <>
      <div className="BlogEight-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <div className="blog-title">
          What happens when we discard our phone for a ‘smarter’ one?
        </div>
        {/* <div className="update-date">Updated: Jan 2, 2020</div> */}
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Jan 2, 2020</div>
              <div className="blog-read-time">4 min read</div>
            </div>
          </div>
        </div>

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog8.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-8a.jpg").default}
            alt="Blog-8a"
          />
        </div>
        <p>
          The market for electrical and electronic equipment has seen an
          exponential growth over the past two decades. Current trends indicate
          that the number of these devices is likely to continue to increase
          over the years to come. Their lifespan is short, and as a result these
          products are subject to constant discarding and replacement. This, in
          turn, generates tons of wastes called electronic waste (e-waste).
          E-waste mainly consists of equipment like computers, laptops, mobile
          phones, television sets among others and their parts that are rendered
          obsolete or have reached the end of their life.
        </p>

        <p>
          E-waste is now one of the fastest growing waste streams in the world
          in terms of volume and environmental impact. In 2016, approximately
          44.7 million metric tons of e-waste was generated worldwide. The
          amount of worldwide e-waste generation is expected to exceed 50
          million tons by 2020, equivalent to the weight of all the commercial
          aircraft ever built. These electronic devices are usually a complex
          mixture of many hazardous chemicals that can be harmful to human and
          environmental health. The toxic substances are usually present in
          circuit boards which contain heavy metals like lead and cadmium;
          batteries which contain cadmium and lithium; cathode ray tubes which
          contain lead oxide and barium; poly vinyl chloride (PVC) coated copper
          cables; mercury in switches and so on. They also contain large amounts
          of plastic. However, along with these hazardous chemicals, e-waste
          contains precious metals like gold, platinum, silver, palladium and
          selenium in minute quantities.
        </p>
        <p>
          The devices are disposed in 3 ways: they are recycled, incinerated or
          deposited in landfills. Improper disposal of e-waste has adverse
          impacts on the organisms and environmental health as it can release
          heavy metals into the air, water and soil. Incineration of e-waste
          results in the emission of toxic gases and fumes into the atmosphere,
          and the consequent inhalation of which causes damage to health.
          However, most e-waste is dumped in landfills; and this allows the
          toxins to leach into groundwater and the nearest surface water body
          during rains. Reagents used in extraction processes can contaminate
          the soil. The repercussions caused by these toxins are both long and
          short term as they are persistent and bio-accumulative in nature. As
          the devices contain precious metals, several formal and informal
          e-waste recycling sectors have emerged; they try to recover valuable
          metals from the discarded devices.
        </p>
        <p>
          While recycling and recovery of e-waste has economic benefits, the
          waste dumping sites are a major environmental and health hazard. This
          is seen mainly in the informal sector where small children can also be
          seen rummaging through electronic waste. They segregate end-of-life
          electrical and electronic products, take them apart, put them through
          acid wash, and also burn circuit boards in the hope of extracting
          precious metals. These processes are carried out in unorganized
          facilities without any proper safety precautions and procedures. In
          order to tackle this, we need to focus on minimizing the damage to the
          environment and human health in the areas surrounding the waste
          processing facilities. Usage of personal protective equipment,
          optimizing the recovery processes and developing adsorbing substances
          to help reduce the release of toxic fumes and gases during e-waste
          recovery processes can be the first step towards minimizing the
          harmful impacts of e-waste disposal.
        </p>
        <p>
          As is the case with most sectors, the businesses involved in the
          manufacture and sale of electronics have the opportunity to come up
          with some very practical solutions to the e-waste problem. One of the
          biggest reasons for the ignorance of e-waste as a legitimate issue is
          the lack of awareness. Bringing it into public consciousness, in the
          same way as it was done with plastics, is important. Once this is
          achieved, it becomes all the more important for businesses to make the
          right choices when it comes to the procurement of materials being
          used, the sustainability of its design, durability to ensure longer
          life and repair opportunity. It is also essential to have take-back
          schemes in place, for eg. HP’s return and recycling program; which
          ensures that the e-waste generated from these devices is handled in an
          efficient manner. Having your own e-waste program has many benefits, a
          salvage value can be obtained for the company, lessening the need to
          refine more of these materials. It can reduce overall energy
          consumption and establish a successful circular economy, which is
          ideal for any business. An alternative can be external parties
          (companies or recyclers), who can help in securely removing the data
          and refurbishing the equipment for reuse. By just throwing out your
          e-waste, there’s always a security threat of confidential data
          possibly being extracted from these equipment. Responsible third-party
          recyclers eliminate that possibility.
        </p>
        <p>
          The IT sector is now continuously expanding as technology is being
          integrated into almost every aspect of our lives. Keeping this in
          mind, appropriate solutions for managing e-waste become more
          essential. Consumers can play their part by only getting a new tech
          product when their old one is unusable. However, consumer intervention
          can only go so far, if the businesses that sell it to them do not take
          an immediate stance against it.
        </p>
      </div>
    </>
  );
}
export default BlogEight;
