import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterSeven() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter April 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterSeven-container">
        <MetaTags>
          <title>
            UAE is the first Gulf country to commit to net-zero emissions by
            2050.
          </title>
          <meta
            name="description"
            content="Ahead of hosting COP28 later this year, The UAE government signed a Net Zero by 2050 charter, launched initially as a net zero 2050 strategic initiative at Expo 2020 in Dubai, followed by an announcement with the UN secretary general and at the COP27."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>

        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          UAE is the first Gulf country to commit to net-zero emissions by 2050.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/apr2023-1.jpg").default
              }
              alt="apr2023-1"
            />
          </figure>
        </div>
        <p>
          Ahead of hosting COP28 later this year, The UAE government signed a
          Net Zero by 2050 charter, launched initially as a net zero 2050
          strategic initiative at Expo 2020 in Dubai, followed by an
          announcement with the UN secretary general and at the COP27.
        </p>
        <p>
          As part of UAE's ambitious strategic initiatives, the government has
          invested Dh600 billion in clean and renewable energy sources in the
          next three decades, strengthening its commitment to reducing carbon
          emissions by 2050.
        </p>
        <p>
          In terms of the coordination of efforts, the Ministry of Climate
          Change and Environment (MOCCAE) will lead and coordinate with other
          critical stakeholders like civil society, foreign governments, and
          international organisations, including the International Renewable
          Energy Agency from various sectors such as energy, economy, industry,
          infrastructure, transport, waste, agriculture, and the measure to
          mitigate the effects of climate change on biodiversity, the
          environment, biosecurity and public health.
        </p>
        <p>
          The charter also presents the development of action plans, policies
          and strategies for climate action. It relies on measuring and
          monitoring greenhouse gas emissions in the seven emirates and
          identifying the activities and procedures that generate such
          emissions.
        </p>
        <p>
          UAE's initiatives align with the Paris agreement as they began
          financing clean energy projects over a decade ago. One of the
          country's most significant advantages is owning the world's most
          extensive solar facilities and being the first Arab country to develop
          a peaceful nuclear energy programme. With its long commitment to
          protecting the environment and embracing clean energy, the government
          is leading efforts to drive innovation in agritech, particularly in
          reducing water and energy use in farming.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            This is a significant step in tackling climate change at a national
            level, and this would also welcome other countries to collaborate
            with the UAE in developing climate mitigation solutions and creating
            opportunities for sustainable socio-economic development. However,
            UAE is a significant oil and gas producer, so the government must
            start drafting a detailed plan to transition from fossil fuels,
            making the collaboration more promising.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Ocean Treaty was signed as a historic agreement to protect the world's
          oceans.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/apr2023-2.jpg").default}
            alt="apr2023-2"
          />
        </figure>
        <p>
          The Ocean Treaty, or the high seas treaty, was agreed upon at UN
          headquarters in New York after two weeks of intense exchange of talks,
          although the negotiations had been held up for years due to
          disagreements on funding and fishing rights.
        </p>
        <p>
          Interestingly, the last international agreement on ocean protection
          was signed 40 years ago in 1982, called the UN Convention on the Law
          of the Sea.
        </p>
        <p>
          Although two-thirds of the world's oceans are currently considered
          international waters, where all the countries have the rights to fish,
          ship and research, only about 1% of such waters are known as high seas
          and are protected, while the marine life living outside the protected
          waters has been at risk from climate change, overfishing and shipping
          traffic.
        </p>
        <p>
          According to the International Union for Conservation of Nature (IUCN)
          and its latest assessment of global marine species, nearly 10% were at
          risk of extinction, including Sharks, whales, and abalone species with
          high use as seafood and for drugs.
        </p>
        <p>
          The pillar coral is just one of the 26 corals now listed as Critically
          Endangered in the Atlantic Ocean, where almost half of all corals are
          now at elevated risk of extinction due to climate change and other
          impacts.
        </p>
        <p>
          Growing concerns that the mining processes disturb animal breeding
          grounds, create noise pollution and be toxic to marine life. Climate
          change has significantly increased marine heat waves that can lead to
          extreme events like cyclones and other events.
        </p>
        <p>
          The treaty is expected to establish marine protected areas on the high
          seas to achieve the global goal of saving 30% of the world's oceans,
          including limiting the fishing numbers, the routes of shipping lanes
          and exploration activities like deep sea mining - when minerals are
          taken from a seabed 200m or more below the surface.
        </p>
        <p>
          The International Seabed Authority that oversees licensing will
          monitor any future activities in the deep seabed to be subject to
          strict environmental regulations and oversight to ensure that they are
          carried out sustainably and responsibly.
        </p>
        <p>
          Equally, sharing marine genetic resources across countries was one of
          the significant issues addressed, according to the IUCN Ocean team.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The historical moment of this treaty is that it recognizes oceans as
            an essential part of the earth's ecosystem, acknowledging the threat
            of marine biodiversity and calling out for action.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          The IPCC's latest Climate change 2023: Synthesis report calls for
          immediate action against climate change.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/apr2023-3.jpg").default}
            alt="apr2023-3"
          />
        </figure>
        <p>
          An eight-year-long synthesis report was released by the
          Intergovernmental Panel on Climate Change (IPCC) in March 2023,
          summarising the impacts and risks of climate change with its
          mitigation and adaptation strategies.
        </p>
        <p>
          The report identifies the interdependence of climate, ecosystems and
          biodiversity, and human societies through its status and trends,
          future climate change risks, and its near-term and long- term
          responses.
        </p>
        <p>
          It is considered the most in-depth climate change assessment involving
          700 scientists in 91 countries, and it took eight years to complete
          the entire cycle of reports.
        </p>
        <p>
          The Synthesis report has integrated the findings of the following
          working groups: physical science foundations, impacts, adaptations,
          vulnerabilities, and mitigation. which specifies the effects of 1.5C
          of global warming on land and oceans, as well as the changing climate
          on the cryosphere and oceans.
        </p>
        <p>A few key areas that were deeply noticed are:</p>
        <p>
          Earth's unprecedented climate is due to human-caused activities,
          principally through emissions of greenhouse gases from energy use,
          land-use change, lifestyle and consumption patterns, and production,
          especially since industrialization.
        </p>
        <p>
          Every fraction of temperature of warming will intensify the climate
          impacts on people and ecosystems with its future risks with severe
          water scarcity, higher temperatures, the spread of diseases, the
          quality of agricultural productivity, extreme floods, and storms.
        </p>
        <p>
          Adaptation measures can effectively build resilience, with 170
          countries having climate policies in place, but in many nations, the
          efforts are still in the planning phase, which is reactive and focused
          on immediate impacts or near-term risks, which must change with more
          climate finances to scale solutions.
        </p>
        <p>
          The areas beyond adaptation to climate change impact due to economic,
          political and social obstacles, including lack of technical support or
          inadequate funding, require an urgent need to minimize and address the
          losses and damages as discussed and presented at COP27.
        </p>
        <p>
          Working with indigenous people and local communities will be a
          meaningful collaboration to include ecosystem-based adaptation
          measures like the protection, restoration and sustainable management
          of ecosystems and sustainable agricultural practices like integrating
          trees into farmlands and increasing crop diversity.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The report's conclusion is for across countries and industries to
            cut emissions by 50% by 2030, scale up climate investments 3-6
            times, rank up existing technologies rapidly and protect highly
            vulnerable communities worldwide.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Canada approves new guidelines for climate-related reporting for
          central banks and insurance companies.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/apr2023-4.jpg").default}
            alt="apr2023-4"
          />
        </figure>
        <p>
          Canada's financial regulator, The Office of the Superintendent of
          Financial Institutions (OSFI), has released a set of new requirements
          for the federally regulated financial institutions (FRFIs) to publish
          climate disclosures aligned with the Task Force on Climate - related
          Financial Disclosures (TCFD) framework.
        </p>
        <p>
          The expected outcome of this new release is to understand and mitigate
          the potential impacts of climate-related risks in the financial system
          and work on its business strategy to set up appropriate governance and
          risk management practices.
        </p>
        <p>
          The FRFI is also expected to articulate the roles and responsibilities
          of different business lines and oversight functions in managing
          climate-related risks and incorporate climate- related risks into its
          internal control framework, policies, and practices, including in its
          portfolio of exposures (credit, market, operational, insurance and
          liquidity).
        </p>
        <p>
          Regarding calculations of GHG emissions, the FRFI is expected to use
          the latest GHG Protocol Corporate Accounting and Reporting Standard
          and the latest GHG Protocol Corporate Value Chain (Scope 3) Accounting
          and Reporting Standard or comparable reporting standards.
        </p>
        <p>
          Under the governance category, the disclosure expectations are to:
          Describe the board of directors' oversight of climate-related risks
          and opportunities.
        </p>
        <p>
          While under the strategy, the expectation is to highlight the
          climate-related risks and opportunities the FRFI has identified over
          the short, medium and long term, the impact these risks and
          opportunities have on its business, strategy, and financial planning,
          as well as to describe the FRFI's climate transition plan and the
          resilience of the FRFI's process, taking into consideration of
          different climate-related scenarios, including a method which limits
          warming to the level aligned with the latest international agreement
          on climate change or lower.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            With growing pressure from policymakers, regulators, and investors
            to channel global financial flows to sustainable investments,
            climate-related disclosures are vital for capital market
            development. Canada making it mandatory for the FRFI is the first
            step of progress.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Prague Stock Exchange launches its first ESG reporting guidelines for
          Czech companies.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/apr2023-5.jpg").default}
            alt="apr2023-5"
          />
        </figure>
        <p>
          The Prague Stock Exchange adopted sustainability reporting guidelines
          aimed at offering issuers guidance on how to approach ESG reporting in
          line with investors' growing expectations and emerging EU regulations.
        </p>
        <p>
          The guidelines are developed with the technical assistance of the
          European Bank for Reconstruction and Development (EBRD), Deloitte
          Sustainability Consulting Central Europe and Frank Bold Advisory and
          technical cooperation support from the Taiwan Business - EBRD
          specialised corporation fund.
        </p>
        <p>
          EBRD is the first to consider the latest EU sustainable finance
          regulatory requirements and has assisted other stock exchanges like
          the Warsaw Stock Exchange, the North Macedonian Stock Exchange, and
          the Bucharest Stock Exchange in developing and publishing ESG
          reporting guidelines.
        </p>
        <p>
          As part of its commitment to supporting the Czech Republic's
          transition to a low-carbon and climate-resilient economy, the EBRD has
          invested €111 million in the Czech Republic in 2022, 80 per cent of it
          into green projects with the target to increase its green financing.
        </p>
        <p>
          The EBRD also hold a record of €2.35 billion investment in central
          Europe, with its 70 per cent in projects furthering the green economy
          transition from new renewable energy generation and e-vehicles to
          green bonds and similar instruments.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Transitioning to a low-carbon and climate-resilient economy is
            crucial to shift towards greener or sustainable assets, which
            requires more detailed ESG data. Without a uniform standard and
            evolving regulatory practices for sustainability reporting, The EBRD
            guidelines are set to be consistent and comparable enough for
            corporate ESG data and increase issuers' awareness of its methods.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterSeven;
