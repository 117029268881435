import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureOne() {
  return (
    <section className="reporting-feature-section">
      <div className="reporting-feature-container">
        <div className="reporting-feature-wrapper">
          <div className="reporting-feature-content-wrapper">
            <div className="reporting-feature-content">
              <h2 className="reporting-feature-title">
                Global Sustainability Reporting
              </h2>
              <p className="reporting-feature-description">
                Report comparable and credible metrics and disclosures using an
                efficient reporting system with globally adopted up-to-date
                sustainability standards such as GRI, TCFD, CSRD, ESRS, BRSR
                CDP, IFRS, etc.
              </p>
            </div>
          </div>
          <div className="reporting-feature-image-wrapper">
            <div className="reporting-feature-image">
              <img src={assets.images.reportingImage1} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="background-image-1">  
             <img src={assets.images.solutionGaphicElemet2} alt="updapt" />
             </div> */}
    </section>
  );
}

export default FeatureOne;
