import React, { useState } from "react";
import Resource from "../components/resource";
import resourceValues from "../../../shared/constants/resourceValues.js";
import podcastValues from "../../../shared/constants/podcastValues";

function ResourceContainer() {
  const [newslettersData] = useState(resourceValues);
  const [mediaData] = useState(resourceValues);
  const [podcastData] = useState(podcastValues);

  return (
    <Resource
      newslettersData={newslettersData}
      mediaData={mediaData}
      podcastData={podcastData}
    ></Resource>
  );
}
export default ResourceContainer;
