import React, { useState, useEffect } from "react";
import Job from "../components/Job";
import { withRouter, useParams } from "react-router-dom";
import jobs from "../../../../../shared/constants/jobs.json";

function JobContainer() {
  const [jobData, setJobData] = useState({});

  const { jobId } = useParams();

  useEffect(() => {
    if (jobId && jobs) {
      const filteredJob = jobs.find((ele) => ele.jobId === jobId);
      setJobData(filteredJob);
    }
  }, [jobId]);

  return <Job jobData={jobData} />;
}

export default withRouter(JobContainer);
