import React from "react";
import Accordian from "./Accordian";

function DesktopFaq({ faqData }) {
  return (
    <>
      <section className="faq-section" id="faq">
        <div className="faq-section-container">
          <div className="faq-container">
            <div className="faq-category-wrapper">
              <div className="faq-category-list">
                {faqData?.map((faqData, index) => (
                  <Accordian
                    title={faqData?.name}
                    key={faqData.name.toString()}
                  >
                    {faqData?.faq?.map((data, index) => (
                      <li key={data.question} className="faq-qa-list-item">
                        <h1 className="question">{data?.question}</h1>
                        <p className="answer">{data?.answer}</p>
                        {data?.answer_1 && (
                          <p className="answer">{data?.answer_1}</p>
                        )}
                      </li>
                    ))}
                  </Accordian>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DesktopFaq;
