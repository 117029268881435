import React from "react";
import "../SCSS/styles.css";
import assets from "../../../../../shared/constants/assetVariables";

function WorkOpportunities({
  opportunities,
  searchTerm,
  handleChange,
  searchResults,
  handleJob,
}) {
  const getTypeClassName = (type) => {
    if (type === "remote") {
      return "remote";
    } else if (type === "onsite") {
      return "onsite";
    } else {
      return "hybrid";
    }
  };
  return (
    <div className="work-opportunities-container">
      <div className="main-title">Work Opportunities</div>
      <div className="search-container">
        <div className="search-cover">
          <img src={assets.icons.searchIcon} alt="searchIcon" />
          <input
            type="text"
            placeholder="Search for Jobs"
            value={searchTerm}
            onChange={handleChange}
          />
        </div>
      </div>
      {searchResults.length === 0 ? (
        <div className="no-jobs-found">No jobs found</div>
      ) : (
        <div className="grid-container">
          {searchResults?.map((data, i) => {
            return (
              <div key={data.jobId} className="grid-item">
                <div className="title" title={data.title}>
                  {data.title}
                </div>
                <div className="hours">{data.hours}</div>
                {/* <div className="description">{data.description}</div> */}
                <div className="location-cover">
                  <img src={assets.icons.location} alt="location" />
                  {data.location}
                </div>
                <div className={`type-cover ${getTypeClassName(data.type)}`}>
                  {data.type}
                </div>
                <div className="apply-now-btn" onClick={() => handleJob(data)}>
                  Apply Now
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default WorkOpportunities;
