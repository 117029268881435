import React, { useState } from "react";
import CaseStudyNine from "../components/CaseStudyNine";

function CaseStudyNineContainer() {
  const [caseStudyData] = useState({
    title: "Facility Management case study",
    img: "facilityManagement.jpg",
    content:
      "Client is a multi-award-winning integrated facilities management provider in the UAE. It commands an exceptionally high level of client retention and has enjoyed continuous growth since its formation in 2002. Integrated facilities management (FM) provider, has implemented several targeted strategies and innovations to intensify efforts to provide sustainable FM solutions to its clients and support the UAE's vision to create a more sustainable environment. The organisation has launched 'Together for Sustainability' — a holistic approach to reducing the environmental impact of its operations through targeted sustainability programmes.",
  });
  const [downloadModalBool, setDownloadModalBool] = useState(false);
  return (
    <CaseStudyNine
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
    />
  );
}

export default CaseStudyNineContainer;
