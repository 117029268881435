import React from "react";
import assets from "../../../shared/constants/assetVariables";
function Security({ securityDetails }) {
  return (
    <section className="home-security-section">
      <div className="home-security-container">
        <div className="home-security-content">
          <h3 className="home-security-title">
            {securityDetails[0].pageTitle}
          </h3>
          <p className="home-security-description">
            We understand the value of data security. With our robust system of
            data securities, <br />
            we secure your data and provide high resiliency.
          </p>
        </div>

        <div className="home-security-wrapper">
          <div className="home-security-image-wrapper">
            <div className="home-security-image">
              <img
                src={assets.images.homeSecurityImage}
                alt="updapt"
                width="100%"
                height="100%"
              />
            </div>
          </div>

          <div className="home-security-content-wrapper">
            <div className="home-security-card-wrapper">
              {securityDetails[0].plansDetails.map((plans, index) => (
                <div className="home-security-card" key={plans.name}>
                  <div className="home-security-card-content">
                    {/*   <div className="security-icon">
                <img
                           src={plans.icon}
                           alt="icon"
                           className="image-icon"
                         />
            </div> */}
                    <h3 className="home-security-card-title">{plans.name}</h3>
                    <p
                      className="home-security-card-description"
                      dangerouslySetInnerHTML={{ __html: plans.description }}
                    ></p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Security;
