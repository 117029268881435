import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureOne() {
  return (
    <>
      <section className="erm-feature-section feature-1">
        <div className="erm-feature-container">
          <div className="erm-feature-wrapper">
            <div className="erm-feature-content-wrapper">
              <div className="erm-feature-content">
                <h2 className="erm-feature-title">
                  Efficiently manage and mitigate risks
                </h2>
                <p className="erm-feature-description">
                  Provides comprehensive support in all steps of risk management
                  in your enterprise. Identify and manage risks, assess its
                  potential impact, conduct risk assessments and analysis,
                  select strategies for risk mitigation, and take necessary
                  actions.
                </p>
              </div>
            </div>
            <div className="erm-feature-image-wrapper">
              <div className="erm-feature-image">
                <object
                  data={assets.images.ermImage1}
                  aria-label="ermImage1"
                ></object>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureOne;
