//SmallScreensNavbar.js
import React from 'react';
import  NavBarComponent  from './Navbar';

function SmallScreensNavbar({isMobileView,dropdown,  setMobileView, handledropDown}){
    // declare 'translate' as a state variable
    
    return(
        <> 
            <div className="toggle-menu"     onClick= {()=> setMobileView(!isMobileView)}>
						<span className="bar"></span>
						<span className="bar"></span>
						<span className="bar"></span>
					</div>
             {/*hide and show the sidebar list based on whether translate is true or false*/}
            
                <NavBarComponent
               isMobileView={isMobileView} dropdown={dropdown} handledropDown={handledropDown}
               setMobileView={setMobileView}
               onClick = {()=>setMobileView(false)}
                    //set translate to true to hide the sidebar list
                />
             
        </>
    )
}
export default SmallScreensNavbar;
