import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureThree() {
  return (
    <section className="grant-feature-section">
      <div className="grant-feature-container">
        <div className="grant-feature-wrapper">
          <div className="grant-feature-content-wrapper">
            <div className="grant-feature-content">
              <h1 className="grant-feature-title">
                Track Performance of CSR Initiatives{" "}
              </h1>
              <p className="grant-feature-description">
                Geth the data you need to track progress, gain insights, and
                share the results. Ensure the transparency and accountability of
                your CSR projects with impact Assessments.
              </p>
            </div>
          </div>
          <div className="grant-feature-image-wrapper">
            <div className="grant-feature-image">
              <img src={assets.images.sampleImage} alt="updapt" />
            </div>
            <div className="dot-shape3">  
             <img src={assets.images.homeBannerImageDot} alt="updapt" />
             </div>
          </div>
        </div>
      </div>
      
    </section>
  );
}

export default FeatureThree;
