import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterTwentyTwo() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter July 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-twenty-two-container">
        <MetaTags>
          <title>Denmark Unveils World's First Agricultural Carbon Tax.</title>
          <meta
            name="description"
            content="
           Denmark has announced the world's first carbon tax targeting agricultural emissions. This initiative is part of the 'Green Tripartite' agreement, aiming to significantly reduce the country's greenhouse gas emissions. 
            "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          Denmark Unveils World's First Agricultural Carbon Tax.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/july2024-1.jpg").default}
            alt="july2024-1"
          />
        </figure>
        <p>
          Denmark has announced the world's first carbon tax targeting
          agricultural emissions. This initiative is part of the 'Green
          Tripartite' agreement, aiming to significantly reduce the country's
          greenhouse gas emissions. From 2030, Danish farmers will be required
          to pay 120 Danish kroner (€16) per metric ton of emitted carbon
          dioxide equivalent, with the tax increasing to 300 kroner (€40) by
          2035.
        </p>
        <p>
          This innovative approach is expected to reduce emissions by 1.8
          million tonnes of CO2e in its first year of implementation,
          contributing to Denmark's ambitious goal of cutting 70% of its total
          emissions by 2030. The government has also allocated €5.3 billion for
          reforesting 250,000 hectares of agricultural land by 2045 and setting
          aside 140,000 hectares of lowland to further reduce emissions.
        </p>
        <p>
          Agriculture is a significant contributor to Denmark's emissions,
          projected to account for 46% by 2030. This tax is designed to
          incentivize farmers to adopt new technologies and sustainable
          practices. Despite the potential economic impact, with some farms
          possibly seeing a rise in negative net income, the tax is seen as a
          necessary step towards achieving long-term environmental
          sustainability and innovation in the agricultural sector.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            By incentivizing farmers to adopt sustainable practices and
            technologies, this tax aims to reduce emissions by 1.8 million
            tonnes of CO2e in its first year. Additionally, it promotes the
            reforestation of agricultural land and the preservation of lowlands,
            enhancing carbon sequestration and biodiversity. The carbon tax
            fosters innovation and long-term sustainability in the agricultural
            sector, setting a global example for integrating environmental
            accountability into farming practices.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Switzerland Proposed Expansion in Sustainability Reporting.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/july2024-2.jpg").default
              }
              alt="july2024-2"
            />
          </figure>
        </div>
        <p>
          Switzerland is set to enhance its sustainability reporting rules,
          aligning them with international standards like the EU's Corporate
          Sustainability Reporting Directive (CSRD). The proposal includes
          broader reporting obligations for companies with over 250 employees or
          significant financial thresholds, expanding the covered entities from
          300 to around 3,500. New requirements will mandate disclosures on
          environmental impact, human rights, and anti-corruption measures, with
          third-party assurance.
        </p>
        <p>
          <b>Key Points:</b>
        </p>
        <p>
          <ol>
            <li>
              Expanded reporting to firms with over 250 employees or CHF 50
              million sales.
            </li>
            <li>Coverage increases from 300 to 3,500 companies.</li>
            <li>
              Mandates disclosures on environmental, social, and governance
              (ESG) issues.
            </li>
            <li>Includes external assurance requirements.</li>
            <li>
              Aligns with EU's CSRD for consistency in international
              sustainability standards.
            </li>
          </ol>
        </p>
        <p>
          The new ordinance is based on the recommendations of the Task Force on
          Climate-related Financial Disclosures (TCFD), emphasizing the
          importance of 'double materiality.' This concept requires companies to
          report both the financial risks posed by climate change to their
          operations and the impact of their activities on the climate. This
          approach aims to provide a comprehensive view of corporate
          sustainability efforts, aligning with broader international practices.
        </p>
        <p>
          Many Swiss companies with significant operations in Europe will need
          to comply with the stricter European standards. The trend underscores
          the increasing demand from investors, consumers, and NGOs for detailed
          and comparable ESG information. Even smaller companies, though not
          directly affected by the new rules, are encouraged to adopt similar
          practices to maintain competitiveness and meet the growing
          expectations of larger corporations and stakeholders.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            To prepare for the new sustainability reporting requirements,
            companies should conduct a gap analysis to identify areas needing
            improvement, enhance data collection systems with advanced software,
            engage stakeholders, train staff on the new regulations, and utilize
            third-party verification to ensure compliance and credibility.
            Implementing technology like AI and blockchain can further
            streamline data accuracy and transparency.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Canada Enforces Stricter Regulations on Environmental Claims.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/july2024-3.jpg").default}
            alt="july2024-3"
          />
        </figure>
        <p>
          Canada has recently enacted stringent legislation aimed at curbing
          corporate deception regarding environmental benefits, reinforcing its
          commitment to genuine environmental transparency. The new rules,
          integrated into the Competition Act, require companies to substantiate
          any environmental claims with verifiable evidence based on
          internationally accepted methodologies. Violations can result in hefty
          penalties, including fines of up to $10 million or 3% of a company's
          global revenue.
        </p>
        <p>
          These regulations are part of a broader effort to address misleading
          environmental marketing practices. Deceptive claims about the
          environmental benefits of a product or service have become a
          significant concern as consumers increasingly prioritize
          sustainability. For example, recent investigations into the Canadian
          Gas Association and major banks highlight the pervasive nature of this
          issue and the importance of regulatory oversight.
        </p>
        <p>
          Legal experts suggest that while these measures protect consumers and
          ensure market integrity, they may impose significant compliance
          burdens on businesses. Companies are urged to conduct thorough audits
          of their environmental claims and ensure that all marketing materials
          are accurate and backed by solid evidence. The law aims to foster a
          more trustworthy market where environmental benefits are real and not
          just marketing gimmicks.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The new regulations are crucial for companies as they emphasize
            transparency and accountability, thereby protecting their reputation
            and avoiding significant financial penalties. To prepare, companies
            should audit their environmental claims, ensuring accuracy and
            compliance with the new standards. Implementing robust verification
            processes and maintaining clear, substantiated marketing materials
            can help companies navigate these regulations effectively and build
            consumer trust.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU Introduces Enhanced SFDR Guidelines to Boost Transparency in
          Sustainable Finance.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/july2024-4.jpg").default}
            alt="july2024-4"
          />
        </figure>
        <p>
          The European Supervisory Authorities (ESAs) have proposed significant
          updates to the Sustainable Finance Disclosure Regulation (SFDR) to
          enhance transparency and combat greenwashing in financial markets. The
          ESAs introduced two new categories for financial products:
          "Sustainable" and "Transition". The Sustainable category encompasses
          products that are already aligned with environmental or social
          sustainability standards, while the Transition category includes
          products that aim to become sustainable over time by following a clear
          pathway and meeting specific criteria.
        </p>
        <p>
          These updates are part of the EU's broader Action Plan on financing
          sustainable growth, which seeks to establish uniform rules for
          financial market participants regarding sustainability risk
          integration and adverse impact consideration. To simplify complex
          sustainability information for investors, the ESAs proposed a
          sustainability indicator to grade financial products, thereby making
          it easier for investors to understand the sustainability profile of
          various financial instruments.
        </p>
        <p>
          The ESAs also suggested improvements in disclosure practices for
          financial products that do not qualify for the new categories. These
          include mandatory transparency on sustainability features and
          restrictions on the use of ESG-related terms in marketing unless
          specific criteria are met. This comprehensive approach aims to create
          a more reliable and efficient sustainable finance market, aligning
          with both EU and global environmental and social objectives.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            To adopt the updated SFDR, companies should evaluate and align their
            financial products with the new 'Sustainable' or 'Transition'
            categories, adjusting portfolios as necessary. Enhancing disclosure
            practices is crucial, requiring clear, detailed sustainability
            information in regulatory documents and marketing materials.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EFRAG and TNFD Unveil Comprehensive Mapping to Harmonize
          Nature-Related Disclosures.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/july2024-5.jpg").default}
            alt="july2024-5"
          />
        </figure>

        <p>
          The Taskforce on Nature-related Financial Disclosures (TNFD) and the
          European Financial Reporting Advisory Group (EFRAG) have unveiled a
          comprehensive correspondence mapping to align the European
          Sustainability Reporting Standards (ESRS) with TNFD's recommended
          disclosures. This initiative, a product of over two years of
          collaboration, aims to enhance transparency and consistency in
          corporate sustainability reporting, particularly focusing on
          nature-related impacts and dependencies.
        </p>
        <p>
          The correspondence mapping highlights significant commonalities
          between the ESRS and TNFD standards. Both frameworks emphasize the
          need to disclose nature-related impacts, risks, and opportunities. The
          ESRS employs a double materiality approach, assessing both financial
          and environmental impacts, a principle also supported by TNFD. This
          alignment facilitates compliance for companies under the Corporate
          Sustainability Reporting Directive (CSRD), simplifying their reporting
          processes and ensuring consistency across disclosures.
        </p>
        <p>
          A notable aspect of this alignment is the integration of TNFD's LEAP
          approach within the ESRS framework. The LEAP approach is designed to
          help companies identify and assess their nature-related issues
          comprehensively. It covers various sustainability matters, including
          pollution, water, biodiversity, and ecosystems. Additionally, both
          ESRS and TNFD organize their reporting structures around the four
          pillars of the Task Force on Climate-related Financial Disclosures
          (TCFD): Governance, Strategy, Risk Management, and Metrics and
          Targets. This structured alignment ensures that companies can provide
          clear, consistent, and relevant data on nature-related sustainability
          matters, supporting better decision-making and fostering sustainable
          development.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Adopting the TNFD and EFRAG's correspondence mapping is crucial for
            companies as it streamlines compliance with the Corporate
            Sustainability Reporting Directive (CSRD) and ensures consistent,
            high-quality sustainability reporting. This alignment enhances
            transparency, helps identify and manage nature-related risks and
            opportunities, and supports better decision-making. Additionally, it
            provides robust data for stakeholders, fostering trust and promoting
            sustainable development, which can lead to improved investor
            confidence and competitive advantage.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTwentyTwo;
