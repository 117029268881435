import React from "react";
import Intro from "./Intro";
import FeatureOne from "./FeatureOne";
import FeatureTwo from "./FeatureTwo";
import FeatureThree from "./FeatureThree";
import FeatureFour from "./FeatureFour";
import FeatureFive from "./FeatureFive";
import MetaTags from "react-meta-tags";
function Grant(props) {
  return (
    <>
    <MetaTags>
        <title>
          Best Sustanibility Reporting Software | Sustanibility Tool By Updapt
        </title>
        <meta
          name="description"
          content="Find and compare top Sustainability software on Capterra, with our free and interactive tool and the key features include environmental social governance (ESG) data tracking, reporting tools.
          "
        />
        <meta property="og:title" content="UpdaptCSR | U Grant" />
      </MetaTags>
    <Intro></Intro>
    <FeatureOne></FeatureOne>
    <FeatureTwo/>
    <FeatureThree/>
    <FeatureFour/>
    <FeatureFive/>
    
 </>
  );
}

export default Grant;
