import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";

function BlogTwentyThree() {
  return (
    <>
      <div className="BlogTwentyThree-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>How can we achieve decarbonization?</title>
          <meta
            name="description"
            content="Decarbonization is critical in limiting global warming and for companies declaring carbon neutral or net zero by 2050."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">How can we achieve decarbonization?</div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Meenakshi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Nov 09, 2022</div>
              <div className="blog-read-time">2 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Rectangle23.jpg").default}
            alt="blog-21"
          />
        </div>
        {/* <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>Did you know?</b>
            </p>
          </div>
        </div> */}

        <p>
          Decarbonization is critical in limiting global warming and for
          companies declaring carbon neutral or net zero by 2050. It is also
          becoming a global imperative and a priority for governments,
          companies, and society. The 2015 Paris Agreement set an ambition to
          limit global warming to well below 2 C above pre-industrial levels and
          pursue efforts to limit to 1.5 C – to be carbon neutral.
        </p>

        <p>
          <b>
            To achieve decarbonization means to bring changes to the economy
            with decarbonizing strategies, but how simple can it be?
          </b>
        </p>

        <p>
          It begins with reducing the greenhouse gas emissions produced by the
          combustion of fossil fuels and involves using zero-carbon renewable
          energy sources such as wind, solar, hydropower, geothermal and
          biomass, which now make up one-third of global power capacity.
        </p>
        <p>
          Consequently, decarbonization will also require absorbing carbon from
          the atmosphere by capturing emissions and enhancing carbon storage in
          agricultural lands and forests.
        </p>

        <p>
          The carbon dioxide and methane emissions that are warming the plant
          mainly come from the power generation, industry, transport, buildings,
          agriculture, and land use sectors. There are always some
          energy-related emissions (direct or indirect) in most industries with
          their manufacturing and other activities. Understanding an industry
          perspective is essential while looking at various sectors and
          identifying their respective practices.
        </p>
        <p>
          <b>
            Decarbonizing strategies can be precise to the industry, for
            example:
          </b>
          <ul>
            <li>
              Energy efficiency is a critical decarbonization strategy, and so
              is a clean grid.
            </li>
            <li>
              Fuel decarbonization focuses on reducing the emissions intensity
              of liquid and gaseous fuels.
            </li>
            <li>
              {" "}
              Electrification and carbon capture has a significant positive
              impact towards net zero.
            </li>
          </ul>
        </p>

        <p>
          Businesses need a more holistic approach that looks at critical
          opportunities to transition to a low-carbon economy, including other
          factors like market frameworks, policies, and financing.
        </p>

        <p>
          Greenhouse gas emissions must be consistently avoided right from
          sourcing (supply chain), production (100% green energy), use-phase,
          and recycling (second life or closed loop recycling). It is an ongoing
          effort that starts with{" "}
          <a
            href="/solutions/esg-analytics"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG Analysis
          </a>{" "}
          and{" "}
          <a
            href="/solutions/esg-data-management"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG Data Management
          </a>{" "}
          , especially with carbon-intensive industries, to set ambitious goals.
          As the least approach towards Neutrality, currently, unavoidable
          emissions can be offset by investments in climate protection projects.
        </p>

        <p>
          <b>What are your company's decarbonization strategies?</b>
        </p>
      </div>
    </>
  );
}
export default BlogTwentyThree;
