import React from "react";

function Policy() {
  return (
    <section className="cookie-policy-section">
      <div className="cookie-policy-container">
        <div className="cookie-policy-content-wrapper">
          <h1 className="cookie-policy-content-title">
            Cookie Policy and Preferences
          </h1>

          <p className="cookie-policy-content-description">
            On your first visit to this Website you were asked (by a
            notification banner) to accept our use of cookies and similar
            technologies and we'd like to explain how we use these technologies.
          </p>

          <p className="cookie-policy-content-description">
            To learn about what cookies and similar technologies are, we
            recommend that you visit the following third party website:
            www.allaboutcookies.org
          </p>

          <p className="cookie-policy-content-description">
            Like many websites, we use cookies for a variety of purposes. Some
            cookies are necessary for the functionality of the Website, others
            log data about your device hardware and your interaction with our
            Website.
          </p>

          <p className="cookie-policy-content-description">
            We use cloud based servers hosted by third party service providers
            (which may be located outside the EEA) to collect Website traffic
            information.
          </p>

          <p className="cookie-policy-content-description">
            On this Website we use:
          </p>

          <h1 className="cookie-policy-content-title">Functional cookies</h1>
          <p className="cookie-policy-content-description">
            These cookies are required for basic Website functionality and are
            therefore always enabled. For instance when you exit our cookie
            banner a cookie is dropped on your device reminding your browser not
            to re-serve this pop-up box when you later visit this Website.
          </p>

          <h1 className="cookie-policy-content-title">Performance cookies</h1>

          <p className="cookie-policy-content-sub-title">Analytics</p>

          <p className="cookie-policy-content-description">
            We use Google Analytics to collect information about how visitors
            use and interact with our Website. The cookies collect information
            about:
            <ul className="cookie-policy-content-list">
              <li className="cookie-policy-content-list-item">
                The number of visitors to our Website,
              </li>
              <li className="cookie-policy-content-list-item">
                The pages or content with the most traffic,
              </li>
              <li className="cookie-policy-content-list-item">
                Approximately where in the world you are visiting the Website
                from; and
              </li>
              <li className="cookie-policy-content-list-item">
                How you have found our Website e.g. from a direct search; link
                on a third-party website; or link in one of our emails.
              </li>
            </ul>
          </p>

          <p className="cookie-policy-content-description">
            We use this information to help improve the Website, for instance by
            prioritizing updates of the most read content or the most used
            functionality.
          </p>

          <p className="cookie-policy-content-sub-title">Content testing</p>
          <p className="cookie-policy-content-description">
            If we are working on updates to our Website we occasionally display
            original content to some visitors and updated content to other
            visitors to help us assess which version best optimizes user
            experience. When we do this we drop a cookie on your device to
            remember which version of the page you were served.
          </p>

          <h1 className="cookie-policy-content-title">
            Advertising cookies & Social media cookies
          </h1>

          <p className="cookie-policy-content-description">
            Sometimes we pair up with social media and advertising partners such
            as Facebook, Google and LinkedIn who drop cookies on your device
            when you visit our Website in order to remember that you have looked
            at our Website/expressed an interest in our products ("Third-party
            cookies"). This means that when you later visit these third party
            websites, or visit a website which has agreed to host adverts for
            our online advertising platform, the adverts displayed to you are
            more tailored to your apparent interests. If you click on these
            adverts they will re-direct you back to our Website.
          </p>

          <p className="cookie-policy-content-description">
            Our third-party partners may pass us information about the total
            number of views, shares, and click-throughs these adverts have
            received.
          </p>

          <h1 className="cookie-policy-content-title">Conversion tracking</h1>

          <p className="cookie-policy-content-description">
            In order to see how successfully our marketing campaigns or other
            website goals are performing we sometimes use conversion pixels,
            which fire a short line of code to tell us when you have clicked on
            a particular button on our Website or reached a particular page
            (e.g. a thank you page once you have completed the procedure for
            downloading content or have completed one of our forms).
          </p>

          <p className="cookie-policy-content-description">
            Unless otherwise specified the above cookies remain when you close
            your browser.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Policy;
