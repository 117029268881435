import React, { useState } from "react";
import NewLandingPage from "../components/NewLandingPage";
import resourceValues from "../../../shared/constants/resourceValues.js";

function NewLandingPageConatiner() {
  const productsList = [
    {
      id: "1",
      name: "Updapt SCM",
      description: "(Supply Chain Management)",
      contents:
        "Send customized questionnaires, collect responses, assign scores and generate risk scores.",
      productLogo: require("../../../assets/landing/SCM.svg"),
      logoColor: "#FF9341",
      bgColor: "#F9E1D3",
      productUrl: "/solutions/supplier-assessment",
    },
    {
      id: "2",
      name: "Updapt NetZero",
      description: "(Emission reduction Management)",
      contents:
        "Measure, reduce, offset and report carbon emission seamlessly and make faster progress in your decarbonisation journey",
      productLogo: require("../../../assets/landing/Net Zero.svg"),
      logoColor: "#65B2A4",
      bgColor: "#DDEEEB",
      productUrl: "/solutions/netzero",
    },
    {
      id: "3",
      name: "Updapt ERM",
      description: "(Enterprise Risk Management)",
      contents:
        "Identify, assess and monitor the various risk affected an organisation. Generate risk score, create action points and track the status of on-going risks across facilities & functions.",
      productLogo: require("../../../assets/landing/ERM.svg"),
      logoColor: "#C15428",
      bgColor: "#F8EBE6",
      productUrl: "/solutions/enterprise-risk-management",
    },
    {
      id: "4",
      name: "Updapt BA",
      description: "(Borrower's Assessment)",
      contents:
        "Send customized questionnaires to evaluate a borrower's creditworthiness and ESG performances. Bespoke Framework & dashboard Management.",
      productLogo: require("../../../assets/landing/BA.svg"),
      logoColor: "#D5B34A",
      bgColor: "#FBF7EC",
      productUrl: "/solutions/supplier-assessment",
    },
    {
      id: "5",
      name: "Updapt PE",
      description: "(Private Equity)",
      contents:
        "Monitor your portfolio's ESG performance, generate reports, and understand the risk associated with it",
      productLogo: require("../../../assets/landing/PE.svg"),
      logoColor: "#4EAADE",
      bgColor: "#D8ECF8",
      productUrl: "#",
    },
  ];
  const [blogsData] = useState(resourceValues);
  const homeBlogsData = blogsData.slice(blogsData.length - 4, blogsData.length);

  return <NewLandingPage productsList={productsList} resourceBlogs={homeBlogsData} />;
}

export default NewLandingPageConatiner;
