import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFour() {
  return (
    <section className="reporting-feature-section">
      <div className="reporting-feature-container">
        <div className="reporting-feature-wrapper">
          <div className="reporting-feature-image-wrapper">
            <div className="reporting-feature-image">
              <img src={assets.images.reportingImage4} alt="updapt" />
            </div>
          </div>
          <div className="reporting-feature-content-wrapper">
            <div className="reporting-feature-content">
              <h2 className="reporting-feature-title">
                Measure your ESG Performance
              </h2>
              <p className="reporting-feature-description">
                Get a detailed, intuitive suite of ESG analyses custom-made for
                your industry, including the UN SDGs, Gap Analysis, and ESG
                Mapping. Using the self-assessment feature, generate a
                Transparency Score against global ESG standards to see where you
                stand in terms of ESG performance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFour;
