import React from "react";
import { NavLink } from "react-router-dom";

function BrsrDemoBanner() {
  return (
    <section>
      <div className="brsr-demo-banner-outer-container">
        <div
          className="brsr-demo-banner-heading-container"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <p className="brsr-demo-banner-heading">Stay ahead of </p>
          <p className="brsr-demo-banner-heading">regulatory changes </p>
          <p className="brsr-demo-banner-heading">and lead with transparency</p>
          <NavLink to="/demo" className="brsr-demo-banner-demo-btn">
            Book a demo Now
          </NavLink>
        </div>
        <div
          className="brsr-demo-banner-image-container"
          data-aos="fade-left"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <img
            src={require("../../../assets/brsrLandingPage/Demo1.svg")}
            alt="demo"
          />
        </div>
      </div>
    </section>
  );
}

export default BrsrDemoBanner;
