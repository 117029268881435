import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterFourteen() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter November 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterFourteen-container">
        <MetaTags>
          <title>
            Australia has unveiled the proposed climate-related reporting
            standards based on the IFRS framework.
          </title>
          <meta
            name="description"
            content="Australia has unveiled proposed climate-related reporting standards, building on the International Sustainability Standards Board (ISSB) of the IFRS Foundation's sustainability disclosure standards. "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          Australia has unveiled the proposed climate-related reporting
          standards based on the IFRS framework.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/nov2023-1.jpg").default}
            alt="nov2023-1"
          />
        </figure>
        <p>
          Australia has unveiled proposed climate-related reporting standards,
          building on the International Sustainability Standards Board (ISSB) of
          the IFRS Foundation's sustainability disclosure standards.
        </p>
        <p>
          The Australian Accounting Standards Board (AASB) released an exposure
          draft, following the government's plan to enforce mandatory
          climate-related financial disclosure requirements for companies and
          financial institutions, beginning in 2024 for large businesses and
          phased in over the next three years for smaller entities. The AASB's
          draft aligns with international reporting frameworks, taking cues from
          the ISSB standards.
        </p>
        <p>
          Notable modifications include restricting climate-related disclosure
          requirements to risks and opportunities related to climate change,
          excluding non-greenhouse gas emissions. The proposal also provides
          flexibility for reporting Scope 3 value chain emissions and suggests
          the inclusion of 15 Scope 3 categories as examples, rather than
          mandatory disclosures.
        </p>
        <p>
          For financial institutions, the Australian standard encourages
          consideration of additional disclosures rather than mandating them.
          Entities not exposed to material climate-related risks or
          opportunities must disclose this determination and explain the basis
          for it. These standardized, internationally aligned reporting
          requirements aim to enhance consistency, transparency, and
          accountability for both Australians and investors.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The Australian government's introduction of IFRS-based
            climate-related reporting standards enhances transparency,
            bolstering investor confidence and aiding in risk mitigation. These
            standards align with global practices, fostering accountability and
            demonstrating the government's commitment to addressing climate
            change.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Brazil to Mandate Annual Sustainability Reporting from 2026.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/nov2023-2.jpg").default
              }
              alt="nov2023-2"
            />
          </figure>
        </div>
        <p>
          Brazil's Securities and Exchange Commission (CVM) and Ministry of
          Finance have announced a mandatory requirement for public companies in
          Brazil to submit annual sustainability and climate-related
          disclosures, commencing in the year 2026. This pivotal step is
          underpinned by the newly established sustainability and
          climate-related disclosure standards released by the International
          Sustainability Standards Board (ISSB) of the IFRS Foundation.
        </p>
        <p>
          The ISSB, which was inaugurated in November 2021 during the COP26
          climate conference, was born out of a global need expressed by
          investors, corporations, governments, and regulators for a
          standardized framework of disclosure that enables a uniform
          comprehension of the impact of sustainability risks and opportunities
          on a company's future.
        </p>
        <p>
          Brazil is the latest jurisdiction to join a growing roster of nations,
          including the United Kingdom and Australia, in adopting these globally
          recognized standards. Furthermore, the International Organization of
          Securities Commissions (IOSCO), a leading international policy forum
          for securities regulators, has urged regulators worldwide to
          incorporate the ISSB standards into their sustainability reporting
          regulatory frameworks.
        </p>
        <p>
          This mandate is a significant component of Brazil's Ecological
          Transformation Plan, which aims to facilitate the transition to a
          green economy, backed by substantial investments of up to $350 billion
          in both public and private infrastructure.
        </p>
        <p>
          In preparation for the mandatory reporting, public companies and
          investment funds in Brazil will have the opportunity to commence
          sustainability reporting voluntarily in line with the IFRS standards
          beginning in 2024. By 2026, mandatory reporting will be enforced for
          public companies. From 2027 onwards, sustainability reporting will be
          due within three months of the fiscal year's conclusion or
          concurrently with the release of financial statements, whichever comes
          first.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The introduction of mandatory sustainability reporting in Brazil
            promises several positive outcomes for businesses and companies.
            These include enhanced transparency, improved risk assessment,
            access to sustainable capital, competitive advantage, innovation,
            and operational efficiency. Alignment with global standards will
            strengthen the commitment to long-term sustainability.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Singapore's Central Bank Sets the Standard for Net-Zero Financial
          Transition.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/nov2023-3.jpg").default}
            alt="nov2023-3"
          />
        </figure>
        <p>
          Singapore's Monetary Authority of Singapore (MAS), the country's
          central bank and financial regulatory body, has issued a series of
          consultation papers that introduce comprehensive guidelines for
          financial institutions, encompassing banks, insurers, and asset
          managers. These guidelines clearly delineate MAS's expectations
          regarding the development of robust transition plans for achieving a
          net-zero economy and addressing the physical impacts of climate
          change.
        </p>
        <p>
          A notable element in these guidelines emphasizes an engagement-centric
          approach over divestment when crafting transition strategies.
          Financial institutions are urged to actively collaborate with clients
          and portfolio companies, addressing both physical and transition
          risks, and assisting them in reducing their carbon footprint while
          enhancing climate resilience. According to MAS, the blanket withdrawal
          of financial support from companies with credible transition plans may
          hinder their decarbonization efforts.
        </p>
        <p>
          MAS further advises financial institutions to adopt a multi-year
          perspective in their transition planning, extending beyond
          conventional timelines for comprehensive risk assessment and
          integrated climate mitigation and adaptation measures. This approach
          includes a holistic evaluation of physical and adaptive climate risk,
          encompasses environmental risks beyond climate concerns, and considers
          the intricate interplay of these factors in transition planning.
          Financial institutions are also directed to disclose pertinent
          information to stakeholders, offering insights into their responses to
          climate-related risks and their governance and risk management
          processes.
        </p>
        <p>
          MAS's approach acknowledges that short-term emissions increases may be
          inevitable within these transition plans, provided they contribute to
          climate-positive outcomes consistent with a net-zero trajectory.
          Regulatory support is deemed crucial to assist financial institutions
          in these endeavors, underscoring MAS's role in establishing precise
          supervisory expectations for transition planning within the financial
          sector.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The issuance of comprehensive net-zero transition guidelines by MAS
            in Singapore will address critical challenges by promoting climate
            resilience, reducing carbon emissions, and facilitating sustainable
            economic growth. These guidelines empower financial institutions to
            actively support clients and portfolio companies in their transition
            to a net-zero economy, contributing to environmental preservation
            and long-term economic stability.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU Council Advances Stricter CO2 Emission Standards for Heavy-Duty
          Vehicles.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/nov2023-4.jpg").default}
            alt="nov2023-4"
          />
        </figure>

        <p>
          The European Union Council has recently reached a pivotal 'general
          approach' consensus on an initiative aimed at enhancing and
          modernizing CO2 emission standards for heavy-duty vehicles. This
          proposed reform is designed to drive further reductions in CO2
          emissions within the road transport sector, and it introduces new
          targets for 2030, 2035, and 2040. These stringent regulations align
          with the European Union's steadfast commitment to combat climate
          change.
        </p>
        <p>
          Moreover, the proposal seeks to stimulate the growth of zero-emission
          vehicles within the extensive EU heavy-duty vehicle fleet while
          safeguarding innovation and maintaining the sector's competitiveness.
          This 'general approach' now stands as the foundation for forthcoming
          negotiations with the European Parliament to finalize the legislation.
          The Council's approach artfully balances the overarching objective to
          minimize the environmental footprint of the heavy-duty vehicle
          industry while allowing member states some latitude in implementing
          the revised regulation, emphasizing innovation and bolstering the EU's
          competitive stance.
        </p>
        <p>
          Incorporating a more comprehensive scope, the proposal expands the
          regulatory reach to encompass nearly all new heavy-duty vehicles,
          including smaller trucks, urban buses, coaches, and trailers.
          Nonetheless, exemptions are provided for specific categories, such as
          small-volume manufacturers, vehicles used in specialized sectors, and
          vocational vehicles like garbage trucks.
        </p>
        <p>
          To remain consistent with the EU's 2030 climate goals and beyond, the
          Council maintains the Commission's emissions targets. This includes a
          45% reduction by 2030, increased from the prior 30%, followed by 65%
          by 2035 and a notable 90% reduction by 2040.
        </p>
        <p>
          Additionally, a compelling development is the introduction of a 100%
          zero-emission target for urban buses by 2035, accompanied by an
          interim target of 85% by 2030. A thorough review clause, initiated in
          2027, will assess the effectiveness of these targets, alongside a
          focus on infrastructure development and a carbon correction factor.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The European Union's efforts to enhance CO2 emission standards for
            heavy-duty vehicles are pivotal in addressing pressing challenges.
            These regulations not only contribute to improved air quality,
            heightened energy efficiency, and the well-being of citizens, but
            they also have positive implications for the automotive industry.
            The adoption of more fuel-efficient vehicles translates into reduced
            operational costs. In essence, these measures foster sustainability
            and eco-friendly practices within the logistics sector, aligning
            harmoniously with overarching climate objectives.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          UN Global Compact Unveils Guidance for Sustainable Infrastructure in
          Belt and Road Initiative.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/nov2023-5.jpg").default}
            alt="nov2023-5"
          />
        </figure>
        <p>
          The UN Global Compact recently introduced new business guidance on
          sustainable infrastructure during an event in Beijing, attended by
          prominent figures from government, industry, and academia. These
          guidance tools aim to promote sustainable infrastructure development
          under the Belt and Road Initiative (BRI), the world's largest
          corporate sustainability initiative.
        </p>
        <p>
          Two key resources were unveiled: Global Compact Ten Principles Applied
          in Infrastructure Sectors under the Belt and Road Initiative, focusing
          on human rights, labor, environment, and anti-corruption principles,
          and Maximizing Impact towards the SDGs, a tool for assessing and
          guiding companies in advancing sustainable infrastructure within the
          BRI.
        </p>
        <p>
          These resources emphasize the pivotal role of businesses in the BRI,
          encouraging innovation, economic growth, and responsible resource
          management to ensure that infrastructure projects serve both immediate
          and long-term sustainable development goals.
        </p>
        <p>
          The reports also shed light on the alignment between the BRI and the
          Sustainable Development Goals (SDGs), offering practical insights and
          tools to help companies adhere to the UN Global Compact's Ten
          Principles, covering human rights, labor, environment, and
          anti-corruption.
        </p>
        <p>
          A High-Level Steering Committee highlighted the critical role of the
          Action Platform in promoting responsible business participation to
          accelerate SDGs through infrastructure projects. The project's
          successful completion was facilitated by the UN Peace and Development
          Fund (UNPDF), which fostered collaboration among diverse stakeholders
          and provided essential resources.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Infrastructure development is vital for economic growth and societal
            progress. Hence, it is paramount that infrastructure projects
            prioritize inclusivity, sustainability, and ethical business
            conduct. These guidance reports serve as essential tools for
            optimizing logistical efficiency, mitigating emissions, and
            extending the reach of global trade networks. Furthermore, it
            promotes the seamless integration of renewable energy sources,
            thereby fortifying sustainable power generation systems.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterFourteen;
