import React from "react";

function Intro() {
  return (
    <section className="page-banner">
      <div className="container">
        <div className="page-content-wrapper">
          <h1 className="page-title">Media</h1>
          <p className="page-description">
          Latest company news, media mentions, and other useful information
          </p>
        </div>
      </div>
    </section>
  );
}

export default Intro;
