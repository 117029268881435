/** @format */

import React from "react";

function Posts({ handleClick, blogsData }) {
  return (
    <section className="blogs-post-archive">
      <div className="container">
        <div className="row">
          {blogsData
            .map(
              (data) =>
                data.type === "blogs" && (
                  <div
                    className="blogs-post-grid"
                    key={data.url}
                    onClick={() => {
                      handleClick(`/resource/blogs/${data.url}`);
                    }}
                  >
                    <div className="blogs-grid">
                      <div className="feature-image">
                        <img
                          alt={data.title}
                          src={
                            data.image.split(".")[1].toLowerCase() !== "svg"
                              ? require(`../../../../assets/${data.image}`)
                                  .default
                              : require(`../../../../assets/${data.image}`)
                          }
                        />
                      </div>
                      <div className="blogs-content">
                        <h3
                          className="blogs-title"
                          onClick={() => {
                            handleClick(`/resource/blogs/${data.url}`);
                          }}
                        >
                          {data.title}
                        </h3>

                        <p
                          className="blogs-description"
                          dangerouslySetInnerHTML={{ __html: data.content }}
                        ></p>
                        <div className="blogs-badge">{data.type}</div>

                        <div className="blogs-meta">
                          <span className="date">{data.date}</span>
                          <span className="status">{data.status}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )
            .reverse()}
        </div>
      </div>
    </section>
  );
}

export default Posts;
