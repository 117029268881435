import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureOne() {
  return (
    <section className="grant-feature-section">
      <div className="grant-feature-container">
        <div className="grant-feature-wrapper">
          <div className="grant-feature-content-wrapper">
            <div className="grant-feature-content">
              <h1 className="grant-feature-title">Plan and Collaborate </h1>
              <p className="grant-feature-description">
                Set CSR objectives, collect data and easily Collaborate with{" "}
                {/* <br /> */}
                internal teams, non-profits, and volunteers
              
              </p>
            </div>
          </div>
          <div className="grant-feature-image-wrapper">
            <div className="grant-feature-image">
              <img src={assets.images.sampleImage} alt="updapt" />
            </div>
            <div className="dot-shape1">  
             <img src={assets.images.homeBannerImageDot} alt="updapt" />
             </div>
          </div>
        </div>
      </div>
  
    </section>
  );
}

export default FeatureOne;
