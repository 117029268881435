import React from "react";
import assets from "../../../shared/constants/assetVariables";
import Demo from "./Demo";
import SubscriptionForm from "../../SubscriptionForm";
import { NavLink, withRouter } from "react-router-dom";

function Footer({ scrollToTop, isVisible, location, year }) {
  return (
    <>
      {!(
        location.pathname.match(/^\/resource\/blogs/) ||
        location.pathname.match(/^\/resource\/newsletter/) ||
        location.pathname.match(/^\/resource\/case-study/) ||
        location.pathname.match(/^\/resource\/newsroom/) ||
        location.pathname.match(/^\/demo/) ||
        location.pathname.match(/^\/thankyou/)
      ) && <Demo></Demo>}

      <footer id="footer" className="footer-section">
        <div className="footer-container">
          <div className="footer-menu-section">
            <div className="footer-menu-wrapper">
              <div className="footer-solution-menu">
                <div className="footer-widget">
                  <h3 className="widget-title">Solutions</h3>
                  <ul className="footer-menu">
                    <li className="footer-menu-list">
                      <NavLink to="/solutions/esg-data-management" className="menu-title-link">
                        ESG Data Management
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink to="/solutions/esg-reporting" className="menu-title-link">
                        ESG Reporting
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink to="/solutions/esg-analytics" className="menu-title-link">
                        ESG Analytics
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink to="/solutions/netzero" className="menu-title-link">
                        NetZero
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink
                        to="/solutions/enterprise-risk-management"
                        className="menu-title-link">
                        Enterprise Risk Management
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink to="/solutions/supplier-assessment" className="menu-title-link">
                        Supplier Assessment
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink to="/solutions/portfolio-assessment" className="menu-title-link">
                        Portfolio Assessment
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink
                        to="/solutions/double-materiality-assessment"
                        className="menu-title-link">
                        Double Materiality Assessment
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-company-menu">
                <div className="footer-widget">
                  <h3 className="widget-title">Company</h3>
                  <ul className="footer-menu">
                    <li className="footer-menu-list">
                      <NavLink to="/about" className="menu-title-link">
                        About Us
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <a href="/faqs" className="menu-title-link">
                        FAQs
                      </a>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink to="/resource/blogs" className="menu-title-link">
                        Blogs
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink to="/resource/newsletter" className="menu-title-link">
                        Newsletters
                      </NavLink>
                    </li>

                    <li className="footer-menu-list">
                      <NavLink to="/contact" className="menu-title-link">
                        Contact Us
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink to="/company/careers" className="menu-title-link">
                        Careers
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-legal-menu">
                <div className="footer-widget">
                  <h3 className="widget-title">Legal</h3>
                  <ul className="footer-menu">
                    <li className="footer-menu-list">
                      <NavLink to="/privacy" className="menu-title-link">
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <NavLink to="/cookiepolicy" className="menu-title-link">
                        Cookie Policy
                      </NavLink>
                    </li>
                    <li className="footer-menu-list">
                      <img
                        src={assets.icons.gdprIcon}
                        alt="gdpr-icon"
                        className="gdpr-icon"
                        height="100%"
                        width="100%"
                      />
                    </li>
                    <li className="footer-menu-list">
                      <img
                        src={assets.icons.isoIcon}
                        alt="iso-icon"
                        className="gdpr-icon"
                        height="100%"
                        width="100%"
                      />
                    </li>
                    <li className="footer-menu-list">
                      {/* <img
                        src={assets.icons.dunsNumber}
                        alt="gdpr-icon"
                        className="duns-icon"
                        height="100%"
                        width="100%"
                      /> */}
                      {/* <p className="footer-duns-no">
                        D-U-N-S Number: 861518720
                      </p> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-contact-menu">
                <div className="footer-widget">
                  <h3 className="widget-title">Connect with us</h3>
                  <p className="footer-subscription">Stay updated with ESG insights.</p>
                  <div className="footer-subscription-form">
                    <SubscriptionForm formName="footer-subscribe-form" />
                  </div>

                  <div className="footer-email">
                    <a href="mailto:hello@updaptcsr.com">hello@updaptcsr.com</a>
                  </div>

                  <ul className="footer-social-link">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/updapt-csr/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <div className="icon">
                          <img
                            src={assets.icons.linkedinIcon}
                            alt="gdpr-icon"
                            className="linked-in"
                            height="100%"
                            width="100%"
                          />
                          <img
                            src={assets.icons.hoverLinkedinIcon}
                            alt="gdpr-icon"
                            className="hover-linked-in"
                            height="100%"
                            width="100%"
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/UpdaptCSR"
                        target="_blank"
                        rel="noopener noreferrer">
                        <div className="icon">
                          <img
                            src={assets.icons.twitterIcon}
                            alt="gdpr-icon"
                            className="twitter-icon"
                            height="100%"
                            width="100%"
                          />
                          <img
                            src={assets.icons.hoverTwitterIcon}
                            alt="gdpr-icon"
                            className="hover-twitter"
                            height="100%"
                            width="100%"
                          />
                        </div>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.facebook.com/pages/category/Business-Service/Updapt-CSR-107035927438749/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <div className="icon">
                          <img
                            src={assets.icons.fbIcon}
                            alt="gdpr-icon"
                            className="facebook-icon"
                            height="100%"
                            width="100%"
                          />
                          <img
                            src={assets.icons.hoverFbIcon}
                            alt="gdpr-icon"
                            className="hover-facebook"
                            height="100%"
                            width="100%"
                          />
                        </div>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.instagram.com/updaptcsr/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <div className="icon">
                          <img
                            src={assets.icons.igIcon}
                            alt="gdpr-icon"
                            className="ig-icon"
                            height="100%"
                            width="100%"
                          />
                          <img
                            src={assets.icons.hoverIgIcon}
                            alt="gdpr-icon"
                            className="hover-instagram"
                            height="100%"
                            width="100%"
                          />
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-right-section">
            <p>
              {" "}
              ©Updapt<span>&#174;</span> All Rights Reserved {year}{" "}
            </p>
          </div>
        </div>
      </footer>
      {isVisible && (
        <div className="scroll-to-top" onClick={scrollToTop}>
          <img src={assets.icons.arrowUp} alt="scroll-to-top" height="100%" width="100%" />{" "}
        </div>
      )}
    </>
  );
}

export default withRouter(Footer);
