import React from "react";
import "../SCSS/styles.css";

function UpdaptDescription() {
  return (
    <div className="description-outer-container">
      <h2 className="description-heading">
        UpdaptESG (ESG Data Management, Reporting & Analysis Software)
      </h2>
      <p className="description-content">
        The cutting-edge digital{" "}
        <a
          href="/solutions/esg-reporting"
          target="_blank"
          rel="noopener noreferrer"
          className="landing-page-content-href">
          ESG reporting software
        </a>{" "}
        is designed to empower companies in their sustainability journey. Our comprehensive solution
        simplifies the collection, tracking, analysis, and consolidation of ESG data with precision
        and ease. With our extensive expertise and experience, we provide a scalable and tailored
        solution for mid & large organizations to address their diverse ESG needs.
      </p>
    </div>
  );
}

export default UpdaptDescription;
