import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFour() {
  return (
    <section className="scm-feature-section scm-feature-4">
      <div className="scm-feature-container">
        <div className="scm-feature-wrapper">
          <div className="scm-feature-image-wrapper">
            <div className="scm-feature-image">
              <img src={assets.images.scmImage4} alt="updapt" />
            </div>
          </div>
          <div className="scm-feature-content-wrapper">
            <div className="scm-feature-content">
              <h2 className="scm-feature-title">
                Enhanced visibility through real-time dashboards
              </h2>
              <p className="scm-feature-description">
                Get a full picture of your suppliers risks and opportunities
                through real-time dashboards and reports so that you can make
                better strategic decisions faster and spot trends and identify
                suppliers that need the most action.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFour;
