import React from "react";
import "../SCSS/styles.css";

function Section_1() {
  return (
    <div className="section-container">
      <div className="section-1-inner-container">
        <div className="left-section">
          <h2 className="section1-heading">
            Easily Analyze Financial and Socio-Environmental Impacts
          </h2>
          <p className="section1-description">
            <span>Updapt MA</span> offers a comprehensive materiality solution, enabling you to
            analyze financial risks along with environmental and societal impacts. Stay ahead of
            sustainability trends and make data-driven decisions to meet both financial and{" "}
            <span className="highlight">ESG goals</span>
          </p>
        </div>
        <div className="right-section">
          <div className="bg-img">
            <img src={require("../../../../assets/images/ma-1.svg")} className="section1-img" />
          </div>
          {/* <img src={require("../../../../assets/images/ma-1.svg")} className="section1-img" /> */}
        </div>
      </div>
    </div>
  );
}

export default Section_1;
