import React from "react";
import assets from "../../../../shared/constants/assetVariables";
import { NavLink } from "react-router-dom";
import MetaTags from "react-meta-tags";

function PartnerBanner() {
  return (
    <>
      <MetaTags>
        <title>Partner with Updapt | Join with us! Become with partner</title>
        <meta
          name="description"
          content="Delivering details about our user friendly software to your clients, your clients, network, and community Through that can accelerate your business growth."
        />
        <meta
          property="og:title"
          content="Partner with Updapt  | Join with us! Become with partner"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <section className="theme-banner">
        <div className="partner-banner-wrapper-position">
          <img
            src={assets.images.partnership}
            alt="banner"
            className="partner-mobile-banner-image"
            //data-aos="fade-left"
            //data-aos-delay="500"
            // data-aos-duration="1000"
            height="80%"
            width="100%"
          />
        </div>

        <div className="home-banner-container">
          <div className="home-banner-wrapper">
            <div className="partner-banner-content-wrapper">
              <div className="header-text sm-width-100 xs-width-100">
                <div className="banner-wrapper">
                  <h1
                    className="main-title"
                    // data-aos="fade-right"
                    //  data-aos-delay="0"
                    // data-aos-duration="0"
                  >
                    Become our partner
                  </h1>
                  <p
                    className="sub-title"
                    //  data-aos="fade-right"
                    //  data-aos-delay="100"
                    // data-aos-duration="500"
                  >
                    Partnering with us can lead to accelerated business growth
                    for you. Grow revenue by delivering our simple, easy-to-use
                    software that promotes transparency, auditing, and goal
                    setting to your clients, network, and community.
                  </p>

                  <div
                    className="home-banner-button"
                    //data-aos="fade-right"
                    // data-aos-delay="250"
                    // data-aos-duration="1000"
                  >
                    <NavLink
                      to="/company/partner-form"
                      className="home-banner-get-started-btn"
                    >
                      Become Our Partner
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PartnerBanner;
