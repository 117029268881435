import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureOne() {
  return (
    <section className="data-management-feature-section">
      <div className="data-management-feature-container">
        <div className="data-management-feature-wrapper">
          <div className="data-management-feature-content-wrapper">
            <div className="data-management-feature-content">
              <h2 className="data-management-feature-title">
                Streamlined Data Collection and Management
              </h2>
              <p className="data-management-feature-description">
                Our interactive user interface simplifies data collection by
                offering both manual user input and data import options such as
                excel sheets, CSV files, and API integration.
              </p>
            </div>
          </div>
          <div className="data-management-feature-image-wrapper">
            <div className="data-management-feature-image">
              <img src={assets.images.dataTrackImage1} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="background-image-1">  
             <img src={assets.images.solutionGaphicElemet2} alt="updapt" />
             </div> */}
    </section>
  );
}

export default FeatureOne;
