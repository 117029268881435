import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFive() {
  return (
    <section className="data-management-feature-section">
      <div className="data-management-feature-container">
        <div className="data-management-feature-wrapper">
          <div className="data-management-feature-content-wrapper">
            <div className="data-management-feature-content">
              <h2 className="data-management-feature-title">
                Measure and Track GHG Emissions
              </h2>
              <p className="data-management-feature-description">
                Effortlessly calculate GHG scope 1, 2 and 3 emissions with the
                flexibility of setting location-based emission factors and
                calorific values of fuels.
              </p>
            </div>
          </div>
          <div className="data-management-feature-image-wrapper">
            <div className="data-management-feature-image">
              <img src={assets.images.dataTrackImage5} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFive;
