import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterTwo() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter November 2022
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterTwo-container">
        <MetaTags>
          <title>
            Singapore has new sustainable private banking and wealth management
            guidelines now!
          </title>
          <meta
            name="description"
            content="The Association of Banks in Singapore (ABS) has launched new sustainable private banking and wealth management guidelines that comprise principles for private banks..."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          Singapore has new sustainable private banking and wealth management
          guidelines now!
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/images/img_1.jpg").default}
              alt="media-01"
            />
          </figure>
        </div>
        <p>
          The Association of Banks in Singapore (ABS) has launched new
          sustainable private banking and wealth management guidelines that
          comprise principles for private banks to integrate sustainability
          considerations with their business models and practices. The
          guidelines were developed with the help of technical reports from
          Worldwide Fund for Nature (Singapore Limited) to comprehensively
          establish a baseline for sustainability practices in private banking
          activities, from wealth planning and investments to financing.
        </p>
        <p>
          <div
            className="Newsletter-subtitle"
            // style={{ paddingTop: "25px", fontSize: "20px" }}
          >
            The guidelines have identified a set of approaches like Exclusion,
            Integration, Thematic and Impact:
          </div>
          <br />
          <ul>
            <li>
              To exclude and avoid sectors, activities or companies from the
              investment based on criteria such as norms and standards, business
              conduct or values/ethical principles.
            </li>
            <li>
              To include ESG factors or{" "}
              <a
                href="/solutions/esg-analytics"
                target="_blank"
                className="newsletter-content-href"
              >
                ESG Analysis
              </a>{" "}
              systematically and explicitly in investment and decision-making to
              optimize{" "}
              <a
                href="/solutions/esg-data-management"
                target="_blank"
                className="newsletter-content-href"
              >
                ESG risk management
              </a>{" "}
              with return expectations.
            </li>
            <li>
              Invest in generating positive, measurable social and environmental
              impact alongside a financial return.
            </li>
          </ul>
          <br />
          On the same lines, the Private Banking Industry Group (PBIG)
          sustainability task force launched a new standard industry training
          benchmark to upskill private banking managers relationship around
          sustainability. The task force studied 12 sustainable finance
          technical skills and competencies, recommending the top four as
          critical for banking:
          <ul>
            <li>Sustainable Investment Management</li>
            <li>Carbon Markets and Decarbonisation strategies management</li>
            <li>Non-financial industry sustainability developments</li>
            <li>Climate change management</li>
          </ul>
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>

          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            By framing sustainable investment approaches, Singapore’s new
            guidelines will promote greater clarity and transparency among
            private bankers and their clients and increase the interest in
            sustainable investment of the banking community in Singapore.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Only some Climate goals are GHG reduction targets or decarbonization
          strategies, and vice versa
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/img_2.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          One of the global firms conducted an interim{" "}
          <a
            href="/solutions/esg-reporting"
            target="_blank"
            className="newsletter-content-href"
          >
            ESG assessment
          </a>{" "}
          for more than 100+ companies about their climate targets to understand
          and improve the alignment with corporate reporting and better support
          investor engagement. The collaboration included:
          <ul>
            <li>leading climate research and data organizations</li>
            <li>environment schools, and</li>
            <li>
              a steering committee, including investor signatories and experts
              from the investor networks.
            </li>
          </ul>
          <br />
          <div
            className="Newsletter-subtitle"
            // style={{ paddingTop: "25px", fontSize: "20px" }}
          >
            The key findings of the disclosure framework are:
          </div>
          <ul>
            <li>
              75% of the 159 companies have set a net zero emissions by 2050 (or
              sooner) goal that covers, at least, their scope 1 and 2 GHG
              emissions.
            </li>
            <li>
              92% of the assessed companies have a board-level committee
              responsible for climate change.
            </li>
            <li>
              And most focus companies which are 91%, have committed to the
              fundamental aspects of the TCFD framework.
            </li>
          </ul>
          <br />
          Although the majority have set net zero goals, they could look more
          ambitious and well aligned with the 1.5C, as only 21% and 12% of short
          and medium-term greenhouse gas reduction targets are set. And only 19%
          of the focus companies have produced quantified decarbonization
          strategies. The organization has also identified that only a few
          companies have explicitly committed to aligning their CAPEX
          expenditures with long-term GHG goals.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Long-term targets are fundamental to a net zero ambition, while
            corporate decarbonization strategies, including short-medium marks,
            are a key priority and need broader consideration across sectors.
            Companies must analyze the outcome while setting climate goals and
            understand the difference between climate goals and decarbonization
            strategies.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Munich Re will no longer invest or provide insurance for oil and gas
          projects
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/img_3.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          Munich Re, one of European’s largest insurance companies and the
          world’s biggest reinsurer, has established a framework or an
          underwriting guideline for the decarbonization goals of its insurance,
          reinsurance, and investment activities as in line to reaching net zero
          emissions by 2050. And such an ambition requires the company to reduce
          the net greenhouse gas (GHG) emissions of the investment portfolio by
          25-29% by 2025 and reduce coal-related exposure in its insurance
          business by 35%.
        </p>
        <p>
          As of 1 April 2023, Munich Re will no longer invest in
          contracts/projects that cover the planning, financing, construction,
          or operation of any new oil and gas fields, new midstream
          infrastructure related to oil like storage and transport, and new
          oil-fired power plants, which have not yet been under construction or
          operation as of 31 December 2022.
        </p>
        <p>
          For the same time frame, Munich Re will cease to conduct new direct
          investments in pureplay Oil & Gas companies. As of 1 January 2025, the
          company will require a credible commitment to net-zero greenhouse gas
          emissions by 2050, including corresponding short and mid-term
          milestones from listed integrated O&G companies with the highest
          relative and absolute emissions.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>

          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            The continued consumption of coal, oil and gas is driving global
            warming to dangerous levels. The Fossil fuel industry is not just
            majorly responsible for greenhouse gas emissions but also air and
            water pollution. Following Munich Re’s decision, 43 per cent of the
            global reinsurance market by premiums has restricted cover for oil
            and gas projects, and one of the primary reasons is that the
            projects are incompatible with the 1.5C climate pathway.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Companies increasingly disclose climate-related information in
          financial filings, says TCFD
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/image_4.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          Task Force on Climate-related Financial Disclosures (TCFD) released a
          status report this October, which presented data for the fiscal year
          2021, looking at the state of climate-related financial disclosures
          with TCFD-Aligned Reporting by public companies, asset managers and
          asset owners. The report further reviews TCFD implementation and
          milestones over five years.
        </p>
        <p>
          The scope of the review includes financial filings, annual reports,
          integrated reports, sustainability reports, and other related reports
          of 1,434 public companies in eight industries (banking, insurance,
          energy, materials and buildings, transportation, agriculture., food,
          and forest products, technology and media, and consumer goods.
        </p>
        <p>
          As per the report, the percentage of companies disclosing TCFD-aligned
          information is continuously growing, with 40% of average companies,
          including 43% in energy, and 15% of companies from technology and
          media, disclosing 11 recommendations of TCFD. And there is an 11% to
          23% increase in the regional disclosure, where European stands at 60%,
          followed by Asia pacific at 36% and 29% for North American companies.
        </p>
        <p>
          <div
            className="Newsletter-subtitle"
            // style={{ paddingTop: "25px", fontSize: "20px" }}
          >
            Key findings of the report are:
          </div>
          <ul>
            <li>
              Disclosure of climate-related information has increased since
              2019.
            </li>
            <li>
              Reporting on climate-related risks and opportunities is higher
              than any recommended disclosure.
            </li>
            <li>
              Reporting on risk management processes is below average but
              steadily improving.
            </li>
            <li>
              Disclosure of the resilience of the company’s strategies under
              different climate-related scenarios increased from 6% to 16% over
              the three years.
            </li>
            <li>
              While the levels of disclosure of climate-related metrics and
              targets are relatively high, growth related to metrics has slowed.
            </li>
            <li>
              Governance remains the least disclosed recommendation, and most
              companies must disclose information on specific scenarios.
            </li>
            <li>
              Companies in the technology and Media industry disclose less than
              other groups reviewed.
            </li>
            <li>
              49% of the companies with a market capitalization greater than
              $12.2 billion are disclosing information aligning with the TCFD
              recommendations.
            </li>
            <li>
              Over 60% of asset managers and over 75% of asset owners indicated
              they currently report climate-related information to their clients
              and beneficiaries, respectively.
            </li>
          </ul>
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Based on the TCFD’s latest status release, companies should consider
            leveraging public disclosures of peers and other types of companies
            when developing and enhancing their climate-related financial
            disclosures and effectively engage a wide range of stakeholders
            across the company to report on the metrics equally.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          The London Stock Exchange launched its own voluntary carbon market
          (VCM)
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/img_4.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          As a recent announcement, The London Stock Exchange launched its
          voluntary carbon market (VCM), supporting direct investment on the
          path to net zero.
        </p>
        <p>
          With more and more companies pledging to reach net-zero targets and
          reduce their greenhouse-gas emissions, many businesses need help to
          eliminate their emissions or even mitigate them within the given
          period. For the same reasons, it will be necessary to use carbon
          credits to offset emissions that cannot be reduced. The demand for
          such carbon credits is increasing and is estimated to be worth $50
          billion in 2030.
        </p>
        <p>
          According to the London Stock Exchange's public market framework, the
          voluntary market will enable funds and operating companies to raise
          capital to be channelled into projects that reduce GHG emissions in
          the atmosphere. Carbon credits are expected to be generated by
          nature-based and technology-led approaches and designed to support
          businesses to offset their residual or unavoidable emissions.
        </p>
        <p>
          <div
            className="Newsletter-subtitle"
            // style={{ paddingTop: "25px", fontSize: "20px" }}
          >
            The framework has set up a six-step approach:
          </div>
          <p>
            <span style={{ fontWeight: "bold" }}>Step 1: </span>
            The entity and its advisors identify projects that will generate
            carbon credits in line with market rules.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Step 2: </span>
            The entity is admitted to the London Stock Exchange and applies for
            the voluntary carbon market designation if the strategy and approach
            are aligned with the rules.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Step 3: </span>
            Once admitted, market investors buy shares in the entity.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Step 4: </span>
            Entity implements projects and provides reporting against defined
            milestones for credit issuance.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Step 5: </span>
            Entity issues carbon credits as a dividend ‘in-specie’ to investors
            or a cash dividend defined at admission.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Step 6: </span>
            Investors can continue to buy and sell shares.
          </p>
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3> Updapt Views: </h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            The voluntary carbon markets have evolved rapidly and are now used
            by both the private and public sectors. Although it is one of the
            mechanisms to help companies manage currently unavoidable and
            residual carbon emissions, businesses must equally focus on setting
            up their direct decarbonisation strategies, work on{" "}
            <a
              href="/solutions/esg-data-management"
              target="_blank"
              className="newsletter-content-href-updapt-views"
            >
              ESG management
            </a>
            . And not just consider the VCM as a substitute for decarbonising
            the processes but look at it as a speeding path to a low carbon
            economy.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTwo;
