import React from "react";
import "../SCSS/styles.css";
function Section5() {
  return (
    <div className="section-container">
      <div className="section-5-inner-container">
        <div className="left-section">
          <div className="content-container">
            <div className="inner-container">
              <h2 className="section5-heading">Effortlessly Engage Key Stakeholders</h2>
              <p className="section5-description">
                Easily identify, organize, and engage with your key stakeholders, ensuring seamless
                communication and alignment.
              </p>
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="bg-img">
            <img src={require("../../../../assets/images/ma-5.svg")} className="section5-img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section5;
