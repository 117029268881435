import React from "react";
import Intro from "./Intro";
import OurTeam from "./OurTeam";
import MetaTags from "react-meta-tags";
function AboutUs(props) {
  return (
    <>
      <MetaTags>
        <title>
          Sustainability System | Sustainability Reporting & Management Tool -
          About Us
        </title>
        <meta
          name="description"
          content="Updapt offers data-driven analysis, automated reporting, and powerful insights to help companies improve their ESG data management and reduce risks related to ESG issues."
        />
        <meta
          property="og:title"
          content="Sustainability System | Sustainability Reporting & Management Tool - About Us"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <Intro pageDetails={props.pageDetails}></Intro>
      <section className="about-section">
        <div className="about-container">
          <div className="about-sub-content">
            <h1 className="about-sub-title">Meet the Team</h1>
            <p className="about-sub-description">
              Our team blends creative, technical and strategic talents. Quality
              is important to us,
              <br /> and we work hard to develop solutions that matter.
            </p>
          </div>

          <OurTeam
            teamMembersOne={props.teamMembersOne}
            teamMembersTwo={props.teamMembersTwo}
          ></OurTeam>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
