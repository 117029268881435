import React from "react";
import assets from "../../../shared/constants/assetVariables";
function ContactCards({ contactDetails }) {
  function getMapClassName(place) {
    switch (true) {
      case place.includes("India"):
        return "india-map";
      case place.includes("USA"):
        return "usa-map";
      case place.includes("UAE"):
        return "uae-map";
      case place.includes("Nigeria"):
        return "nigeria-map";
      case place.includes("UK"):
        return "uk-map";
      default:
        return "tanzania-map";
    }
  }
  return (
    <>
      <div className="international-presence-heading">
        {" "}
        <p>Our Global Presence</p>
        <img
          className="world-map-img"
          src={assets.images.worldmap}
          alt="worldmap"
          style={{ paddingTop: "3%" }}
        />
      </div>

      <section className="contact-info-section">
        <div className="background-image">
          <span className="dot-shape">
            {" "}
            <img src={assets.images.homeBannerImageDot} alt="updapt" />
          </span>
          <span className="back-image">
            {" "}
            <img src={assets.images.contactBottomImage} alt="updapt" />
          </span>
        </div>
      </section>

      <div className="heading">
        <p>Office Locations</p>
      </div>

      <section className="contact-location-section">
        <div className="container">
          <div className="row">
            {contactDetails.address.map((address, index) => (
              <div className="contact-address-wrapper" key={address.place}>
                <div className={getMapClassName(address.place)}>
                  <div className="contact-address-card">
                    <div className="card-icon">
                      <img
                        src={address.img}
                        alt="location"
                        className="image-icon"
                      />
                    </div>
                    <h1 className="card-title">{address.place}</h1>
                    <p className="card-description">
                      {address.value}
                      <br />
                      {address.value1}
                      <br />
                      {address.value2 ? <span>{address.value2}</span> : ""}
                      <span>
                        {address.contact1}
                        {address.contact2 ? (
                          <span>{address.contact2}</span>
                        ) : (
                          ""
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
export default ContactCards;
