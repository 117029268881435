import React, { useState } from "react";
import Reporting from "../components/Reporting";

function ReportingContainer() {
  const [whyFeatures] = useState([
    {
      header: "A centralized ESG data management and decision-making solution",
      desc:
        "Tracking, analysing and consolidating ESG data on a spreadsheet from different locations can be a time-consuming and error prone activity. U-Data Track provides a single interface that comprehends all company wide ESG data accurately to support effective decision-making and gives you time to focus on the task of value creation.",
    },
    {
      header: "Monitor and record your GHG emissions with precision",
      desc:
        "Today, investors, governments and communities demand greater accuracy, transperancy and action on accounting for greenhouse gas emissions from various sectors.U-Data track, with its vast repository of  globally recognised emission data and the ability to translate all energy related data to total GHG emissions with one click is a tailor made solution for emission reporting.",
    },
    {
      header:
        "Not just the E of ESG, but also monitor and report the S and G aspects effortlessly",
      desc:
        "Collating and deriving key performance indicators related to employee health and safety, supplier screening and compliance with ESG policies, community support are also crucial factors of ESG. U-Data Track provides you the dedicated modules to manage H&S metrics, supplier compliance and community funding projects.",
    },
    {
      header:
        "Intuitive leadership dashboards for data driven decision making.",
      desc:
        "To drive a successful sustainability management sytem, it is critical to have a holistic view of the organisation's ESG performance. This equips leaders to make sound decisions. U-Data track provides a customised dashboard for all aspects of ESG, from energy, water, waste, emissions to employee H&S.",
    },
  ]);
  return <Reporting whyFeatures={whyFeatures}></Reporting>;
}

export default ReportingContainer;
