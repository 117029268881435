import React from "react";
import FormSection from "./FormSection";
import MetaTags from "react-meta-tags";
import { useLocation } from "react-router-dom";

function Demo({
  mobile,
  setMobile,
  handleTextOnChange,
  handleSendRequest,
  formObj,
  setSelectedOption,
  selectedOption,
  options,
  customStyles,
}) {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  return (
    <>
      {" "}
      {path !== "uae-esg-reporting-software" && (
        <MetaTags>
          <title>Request Demo | Updapt</title>
          <meta
            name="description"
            content="Get a Free Demo of Updapt's ESG Tool"
          />
          <meta property="og:title" content="Request Demo | Updapt" />
          <meta
            property="og:image"
            content="%PUBLIC_URL%/static/media/banner.jpg"
          />
        </MetaTags>
      )}
      {/*  <Intro></Intro>*/}
      <FormSection
        formObj={formObj}
        handleTextOnChange={handleTextOnChange}
        handleSendRequest={handleSendRequest}
        mobile={mobile}
        setMobile={setMobile}
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        customStyles={customStyles}
      />
    </>
  );
}

export default Demo;
