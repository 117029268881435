import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterNineteen() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter April 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-nineteen-container">
        <MetaTags>
          <title>
            The Securities and Exchange Commission Enhances Climate-Related
            Disclosures for Companies.
          </title>
          <meta
            name="description"
            content="The Securities and Exchange Commission (SEC) has adopted amendments to its rules under the Securities Act of 1933 and the Securities Exchange Act of 1934, mandating companies to furnish detailed climate-related information in their registration statements and annual reports. "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          The Securities and Exchange Commission Enhances Climate-Related
          Disclosures for Companies.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/apr2024-1.jpg").default}
            alt="apr2024-1"
          />
        </figure>
        <p>
          The Securities and Exchange Commission (SEC) has adopted amendments to
          its rules under the Securities Act of 1933 and the Securities Exchange
          Act of 1934, mandating companies to furnish detailed climate-related
          information in their registration statements and annual reports. These
          amendments aim to furnish investors with critical information on
          climate-related risks that could materially impact a company's
          business operations, strategy, and financial condition.
        </p>
        <p>
          <b>
            The SEC's final rules mandate registrants to disclose the following
            climate-related information:
          </b>
        </p>
        <p>
          <ol>
            <li>
              Companies must disclose climate-related risks that materially
              impact or are likely to impact their business strategy,
              operations, or financial condition.
            </li>
            <li>
              There must be clear communication on the actual and potential
              material impacts of climate-related risks on the company's
              strategy, business model, and outlook.
            </li>
            <li>
              Companies are required to provide both quantitative and
              qualitative details on efforts and expenditures made to mitigate
              or adapt to material climate-related risks.
            </li>
            <li>
              The rules mandate disclosure of the board of directors' oversight
              of climate-related risks and the management's role in assessing
              and managing such risks.
            </li>
            <li>
              Companies need to describe their processes for identifying,
              assessing, and managing material climate-related risks.
            </li>
            <li>
              The company needs to disclose climate-related targets or goals, it
              must disclose any related material expenditures and impacts on
              financial estimates and assumptions.
            </li>
            <li>
              Large accelerated filers and accelerated filers are required to
              disclose material Scope 1 and Scope 2 emissions, with assurance
              reports provided at specified levels of assurance.
            </li>
            <li>
              Companies must disclose the financial impacts of severe weather
              and other natural conditions, including capitalized costs,
              expenditures, and losses, in a note to the financial statements.
            </li>
            <li>
              Disclosure Related to Carbon Offsets and RECs, If carbon offsets
              and renewable energy credits are a material component of a
              company's climate-related targets or goals, this must be
              disclosed.
            </li>
            <li>
              {" "}
              Companies must describe how their financial statement estimates
              and assumptions were impacted by risks and uncertainties
              associated with severe weather events, climate-related targets, or
              transition plans.
            </li>
          </ol>
        </p>
        <p>
          These rules are an extension of the SEC's longstanding commitment to
          evolving disclosure requirements in line with emerging economic
          realities and technological advancements. The final rules focus on
          providing investors with clear, decision-useful information on
          climate-related risks, directly within companies' SEC filings.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            These mandated disclosures are aimed at enhancing transparency and
            providing investors with detailed and reliable information on how
            climate-related risks impact registrants, facilitating informed
            investment and voting decisions.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Canada Introduces New Sustainability Disclosure Standards for the
          constatation.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/apr2024-2.jpg").default
              }
              alt="apr2024-2"
            />
          </figure>
        </div>
        <p>
          The Canadian Sustainability Standards Board (CSSB) has introduced its
          inaugural Canadian Sustainability Disclosure Standards (CSDS), marking
          an advancement in sustainability reporting within Canada. Aimed at
          setting a new benchmark for transparency in sustainability related
          disclosures, these proposed standards seek to enhance consistency and
          comparability in reporting practices.
        </p>
        <p>
          Aligning with the International Sustainability Standards Board's
          (ISSB) foundational standards while proposing Canada-specific
          amendments, the CSSB's initiative underscores a commitment to
          sustainability as an integral component of economic operations. Open
          for public consultation, these standards are a call to action,
          inviting comprehensive feedback to refine and improve the
          sustainability disclosure framework, reflecting a broad spectrum of
          viewpoints and contributing to a more sustainable and resilient
          future.
        </p>
        <p>
          In a strategic alignment with the global baseline established by the
          International Sustainability Standards Board (ISSB), the Canadian
          Sustainability Standards Board (CSSB) has proposed the Canadian
          Sustainability Disclosure Standards (CSDS) 1 and 2. These standards
          are designed to harmonize with the IFRS S1 and S2, incorporating
          Canadian-specific modifications to address local needs effectively.
          Alongside these proposals, a discussion paper is open for public
          comment, outlining the CSSB's approach to adapting the IFRS
          Sustainability Disclosure Standards for Canadian application.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The Canadian government's initiative to establish the Canadian
            Sustainability Disclosure Standards (CSDS) primarily enhances
            transparency and accountability in corporate sustainability
            practices. This move enables businesses to align their reporting
            with global benchmarks while incorporating Canadian-specific
            modifications, fostering greater comparability and consistency.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          European Council Approves Legislation for Corporate Due Diligence on
          Human Rights and Environmental Impact.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/apr2024-3.jpg").default}
            alt="apr2024-3"
          />
        </figure>
        <p>
          The European Council has successfully ratified a landmark legislative
          framework that obliges companies to address their adverse effects on
          human rights and the environment. This advancement follows a consensus
          to refine and adjust the scope of the new mandate and its timeframe
          for implementation. This strategic decision is set to bolster the
          Corporate Sustainability Due Diligence Directive (CSDDD), enhancing
          its viability.
        </p>
        <p>
          This milestone concludes a multi-year endeavor to refine the
          regulation, initiated with comprehensive studies on directors' duties
          and sustainable corporate governance, alongside due diligence
          requirements across supply chains. These studies paved the way for the
          proposed CSDDD, delineating corporate responsibilities to mitigate and
          address negative impacts on societal and environmental well-being
          throughout their operations and in specific downstream activities.
        </p>
        <p>
          Notable adjustments include a phased introduction of the legislation,
          ensuring a gradual compliance pathway for businesses, and the
          exclusion of certain activities from the legal framework. This revised
          directive aims to maintain the essential elements of the initial
          agreement while incorporating the necessary adjustments for broader
          acceptance and effectiveness.
        </p>
        <p>
          The European Council's proactive approach in refining the CSDDD
          underscores a commitment to sustainable corporate practices. This
          legislative achievement marks a significant step forward in aligning
          corporate operations with environmental and human rights standards,
          demonstrating a balanced approach to regulatory development and
          corporate accountability.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Companies can integrate the new legislation by establishing
            comprehensive due diligence processes to assess and mitigate their
            environmental and human rights impacts. This involves conducting
            thorough supply chain audits, engaging in transparent reporting, and
            developing remediation strategies for identified issues. Adapting
            corporate policies to align with these obligations ensures
            responsible business operations and contributes to sustainable
            development goals.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Nigeria Mandates Eco-Friendly Reporting Standards by 2028.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/apr2024-4.jpg").default}
            alt="apr2024-4"
          />
        </figure>
        <p>
          Nigeria is on a path to a more sustainable future, asking all its
          businesses to adopt eco-friendly reporting by 2028. This move, led by
          the government, is about bringing the country's corporate activities
          in line with the broader goals of sustainability that the world is
          striving towards. It's a clear signal that Nigeria is serious about
          doing its part for the planet while continuing to grow economically.
        </p>
        <p>
          The government's call to action is straightforward: businesses need to
          look closely at their environmental footprint and work towards being
          more transparent and sustainable. It's a push towards not just
          thinking about the here and now but considering the long-term health
          of our environment. This initiative is seen as a vital step in weaving
          environmental consciousness into Nigeria's economic fabric,
          encouraging new and innovative ways of operating that are kinder to
          our planet.
        </p>
        <p>
          By setting these eco-friendly reporting standards, the Nigerian
          government is gently nudging the business community towards a more
          active role in achieving the country's environmental objectives. It's
          a strategy that hopes to make Nigeria an example of how sustainable
          practices can be integrated into the heart of business, aiming to
          build a stronger and greener economy for future generations.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The new rule from the Nigerian government that asks businesses to
            start eco-friendly reporting by 2028 is a big change. It means
            companies have to think through their current operational processes
            and how they can work towards being more sustainable. It is also a
            chance to lead the way in green practices. To navigate this
            transition successfully, businesses should consider early
            investments in sustainable technologies and employee training
            programs aimed at enhancing environmental awareness.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Singapore Launches Grant Program to Support Corporate Sustainability
          Reporting.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/august2023-1.jpg").default
            }
            alt="apr2024-5"
          />
        </figure>

        <p>
          The Singaporean government has unveiled a Sustainability Reporting
          Grant to aid companies in covering up to 30% of the expenses
          associated with crafting their initial sustainability reports. This
          move aligns with the government's recent decision to mandate
          climate-related reporting for both listed and large non-listed
          companies, adhering to the standards set by the IFRS Foundation's
          International Sustainability Standards Board (ISSB). Disclosure for
          listed companies is set to commence in 2025, with large non-listed
          companies following in 2027.
        </p>
        <p>
          During a presentation at the Ministry of Trade and Industry's
          Committee of Supply Debate, it was communicated that the forthcoming
          reporting obligations necessitate businesses to enhance their ability
          to monitor and disclose their carbon footprint. To facilitate this
          transition, substantial funding support will be provided, specifically
          targeting large enterprises to initiate their sustainability reporting
          endeavors.
        </p>
        <p>
          As regulatory demands for climate and sustainability disclosures
          escalate worldwide, companies are encountering increasing costs to
          comply with non-financial disclosure requirements. Research indicates
          that corporate issuers spend an average of $533,000 annually on
          climate-related reporting. The Singaporean initiative aims to mitigate
          these financial burdens for companies, particularly large entities
          with annual revenues exceeding $100 million, by offering grants that
          cover a significant portion of the costs for producing their first
          sustainability report.
        </p>
        <p>
          The government has committed to supporting small and medium-sized
          enterprises (SMEs) in developing their sustainability reporting
          capabilities. Although sustainability reporting is not compulsory for
          SMEs, the government plans to subsidize up to 70% of the costs for
          SMEs to engage with carbon service providers and prepare their
          inaugural sustainability reports, with additional support in the
          following two years. This comprehensive support framework underscores
          Singapore's commitment to advancing corporate sustainability and
          climate accountability.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Singapore's Sustainability Reporting Grant empowers companies to
            adopt sustainability practices by offsetting reporting costs, and
            enhancing their reputations as environmentally responsible entities.
            It encourages operational improvements and efficiency gains, leading
            to potential cost savings. Early adopters can navigate future
            regulatory landscapes more effectively.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterNineteen;
