import React from "react";
import assets from "../../../shared/constants/assetVariables";
import { NavLink } from "react-router-dom";

function GetStarted() {
  return (
    <section className="theme-banner">
      <div className="banner-wrapper-position">
        <img
          src={assets.images.homeBannerImage}
          alt="banner"
          className="mobile-banner-image"
          //data-aos="fade-left"
          //data-aos-delay="500"
          // data-aos-duration="1000"
          height="80%"
          width="100%"
        />
        {/*  <div className="theme-banner-wrapper">
            <div className="banner-card-1"  data-aos="fade-left"
                                   >
            <img src={assets.images.homeBanner1} alt="updapt" className="screen-one"/>
         
              </div>
              <div className="banner-card-2" data-aos="fade-left"
                                     data-aos-delay="500">
              <img src={assets.images.homeBanner2} alt="updapt" className="screen-two"/>
              </div>
              <div className="banner-card-3" data-aos="fade-left"
                                     data-aos-delay="1000">
              <img src={assets.images.homeBanner3} alt="updapt" className="screen-three"/>
              </div>
              <div className="banner-card-4" data-aos="fade-left"
                                     data-aos-delay="1500">
              <img src={assets.images.homeBanner4} alt="updapt" className="screen-four"/>
              </div>
              <div className="banner-card-5" data-aos="fade-left"
                                     data-aos-delay="2000">
              <img src={assets.images.homeBanner5} alt="updapt" className="screen-five"/>
              </div>
             </div>
              */}
      </div>

      <div className="home-banner-container">
        <div className="home-banner-wrapper">
          <div className="home-banner-content-wrapper">
            <div className="header-text sm-width-100 xs-width-100">
              <div className="banner-wrapper">
                <h1
                  className="main-title"
                  // data-aos="fade-right"
                  //  data-aos-delay="0"
                  // data-aos-duration="0"
                >
                  Sustainability Data Management Software
                </h1>
                <p
                  className="sub-title"
                  //  data-aos="fade-right"
                  //  data-aos-delay="100"
                  // data-aos-duration="500"
                >
                  Manage your end-to-end sustainability journey with a
                  simplified process that lets you manage, report, and analyze
                  ESG data in one platform.
                </p>

                <div
                  className="home-banner-button"
                  //data-aos="fade-right"
                  // data-aos-delay="250"
                  // data-aos-duration="1000"
                >
                  <NavLink to="/free-trial" className="home-banner-explore-btn">
                    Start your free trial
                  </NavLink>

                  <NavLink to="/demo" className="home-banner-get-started-btn">
                    Get a demo
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="slider">
                  <LogoCarousel />
                </div> */}
    </section>
  );
}

export default GetStarted;
