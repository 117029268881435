import React, { useState } from "react";
import CaseStudyFive from "../components/CaseStudyFive";

function CaseStudyFiveContainer() {
  const [caseStudyData] = useState({
    title: "Realty case study",
    img: "realtyCase.jpg",
    content:
      "Client is one of India's finest real estate developers that has offered more than 50,000 homes in the last five to seven years. The company develops residential real estate, focusing on affordability while offering premium and luxury housing, office spaces, retail spaces, and digital infrastructure properties in Mumbai, Thane, Pune, Bangalore & London. From the sustainability point of view, Client has committed to being a net zero emission organization by 2035, with 3,359 employees as of 2022. The company is also committed to setting de-carbonization targets with Science Based Targets Initiatives (SBTi), for which digital transformation seems the right need of change/transition that client required, especially with Updapt, that maintains the database and provide an analytical dashboard and easy reporting system. ",
  });
  const [downloadModalBool, setDownloadModalBool] = useState(false);
  return (
    <CaseStudyFive
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
    />
  );
}

export default CaseStudyFiveContainer;
