import React, { useState } from "react";
import AboutUs from "../components/AboutUs";
import assets from "../../../shared/constants/assetVariables";

function AboutUsContainer() {
  const [pageDetails] = useState({
    title: "Meet the Team",
    subtitle:
      "Our team blends creative, technical, and strategic talents. Quality is important to us, and we work hard to develop solutions that matter.",
  });

  const [teamMembersOne] = useState([
    {
      name: "Mithun Suvarna",
      linkedInUrl: "https://www.linkedin.com/in/mithun-suvarna-ba50a057/",
      role: " Co-Founder & CEO",
      roleDesc:
        "Environmental, Social and Governance (ESG) Expert having 15 years of experience in Corporate Sustainability & Responsible Investment majorly at Thomson Reuters. Has thorough understanding of all major ESG Guidelines namely GRI,SASB, SDG, CDP, TCFD, BRSR and IR, etc. Holds MBA in Finance & Marketing.",
      profilePic: assets.images.mithunPic,
    },
    {
      name: "Satish Ramchandani",
      linkedInUrl: "https://www.linkedin.com/in/satish-ramchandani-936a042/",
      role: "Co-Founder & Chief Business Officer",
      roleDesc:
        "20 years of experience in Sales & Marketing, Strategy, Governance and CSR. Leadership roles with firms such as CRISIL (S&P Ratings), Edelweiss Financial Services, Bank of America Merrill Lynch. Chartered Accountant and MBA by qualification.",
      profilePic: assets.images.satishPic,
    },
    {
      name: "Supreeth Rao",
      linkedInUrl: "https://www.linkedin.com/in/supreeth-rao-695375b7/",
      role: "Co-Founder & CFO",
      roleDesc:
        "Experience in Advisory, Taxation, Finance & Litigation. Worked for Ernst & Young (EY) and advised clients in various industries including pharma, services, IT & trading.",
      profilePic: assets.images.supreethPic,
    },
    {
      name: "Sangath D B",
      linkedInUrl: "https://www.linkedin.com/in/sangathbabu/",
      role: "Co-Founder & CTO",
      roleDesc:
        "Worked as Technology Solution Architect & Project Manager for 30+ projects across the globe; holds a B.E. degree in Computer Science & Engineering.",
      profilePic: assets.images.sangathPic,
    },
  ]);

  const [teamMembersTwo] = useState([
    {
      name: "Erik Solheim",
      linkedInUrl: "https://no.linkedin.com/in/erik-solheim-a9464b225",
      role: "Advisory Board Member",
      roleDesc:
        "15+ years of experience in Energy, Carbon Emissions Analysis and SRI.",
      profilePic: assets.images.ericSolheimPic,
    },

    {
      name: "Maryam Telmesani",
      linkedInUrl: "https://www.linkedin.com/in/maryam-telmesani-60780b83/",
      role: "Advisory Board Member",
      roleDesc:
        "15+ years of experience in Energy, Carbon Emissions Analysis and SRI.",
      profilePic: assets.images.maryamTelmesani,
    },
    {
      name: "Dr. Sanmi Olowosile",
      linkedInUrl: "https://www.linkedin.com/in/olowosile-sanmi/",
      role: "Advisory Board Member",
      roleDesc:
        "15+ years of experience in Energy, Carbon Emissions Analysis and SRI.",
      profilePic: assets.images.sanmiO,
    },
    {
      name: "Vineet Agarwal",
      linkedInUrl: "https://www.linkedin.com/in/vineet-agarwal-10a5829/",
      role: "Advisory Board Member",
      roleDesc:
        "15+ years of experience in Energy, Carbon Emissions Analysis and SRI.",
      profilePic: assets.images.vineetAgarwal,
    },
  ]);

  return (
    <AboutUs
      teamMembersOne={teamMembersOne}
      teamMembersTwo={teamMembersTwo}
      pageDetails={pageDetails}
    ></AboutUs>
  );
}

export default AboutUsContainer;
