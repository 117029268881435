import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureOne() {
  return (
    <section className="analytics-feature-section">
      <div className="analytics-feature-container">
        <div className="analytics-feature-wrapper">
          <div className="analytics-feature-content-wrapper">
            <div className="analytics-feature-content">
              <h2 className="analytics-feature-title">
                Get Detailed Insights{" "}
              </h2>
              <p className="analytics-feature-description">
                Focus on leading data-based indicators by using dashboards
                integrated with configurable graphs, KPIs, and comprehensive
                filters.
              </p>
            </div>
          </div>
          <div className="analytics-feature-image-wrapper">
            <div className="analytics-feature-image">
              <img src={assets.images.analyticsImage1} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="background-image-1">  
             <img src={assets.images.solutionGaphicElemet2} alt="updapt" />
             </div> */}
    </section>
  );
}

export default FeatureOne;
