import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterSeventeen() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter February 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-seventeen-container">
        <MetaTags>
          <title>
            EBA Proposes Guidelines for Banks on ESG and Climate Transition Risk
            Management.
          </title>
          <meta
            name="description"
            content="The European Banking Authority (EBA) has introduced proposed guidelines to empower banks in managing environmental, social, and governance (ESG) risks amid the transition to a climate-neutral economy."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          EBA Proposes Guidelines for Banks on ESG and Climate Transition Risk
          Management.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/feb2024-1.jpg").default}
            alt="feb2024-1"
          />
        </figure>
        <p>
          The European Banking Authority (EBA) has introduced proposed
          guidelines to empower banks in managing environmental, social, and
          governance (ESG) risks amid the transition to a climate-neutral
          economy. These guidelines, part of the EBA's sustainable finance
          roadmap, stress the integration of ESG considerations into banks' risk
          management frameworks.
        </p>
        <p>
          Central to the proposed guidelines is the requirement for banks to
          conduct regular assessments of ESG risk materiality. This involves
          employing diverse data-driven approaches to identify risks across
          various risk categories such as credit, market, operational, and
          reputational risks. Additionally, banks are tasked with developing
          transition plans to address climate-related risks and align with
          regulatory objectives.
        </p>
        <p>
          The guidelines aim to address observed deficiencies in incorporating
          ESG risks into business strategies and risk management frameworks. By
          enhancing banks' resilience, they aim to facilitate the EU's
          transition to a more sustainable economy.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The proposed guidelines bolster banks' risk management by
            integrating ESG factors, enhancing their ability to identify and
            address environmental and social risks. By developing transition
            plans, banks can adapt to regulatory changes and market shifts,
            ensuring resilience in the face of a transitioning economy.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Canada Implements Regulations for 100% Zero Emission Vehicle Sales by
          2035.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/feb2024-2.jpg").default
              }
              alt="feb2024-2"
            />
          </figure>
        </div>
        <p>
          Canada has introduced a comprehensive regulation pertaining to the
          availability of electric vehicles, stipulating annual escalations in
          the percentage of new zero emissions light duty vehicles sold,
          encompassing passenger cars, SUVs, and light trucks, with the ultimate
          objective of achieving 100% zero emissions vehicle (ZEV) sales by
          2035.
        </p>
        <p>
          Initially introduced in draft form, subsequent to the publication
          earlier that year of the government's 2030 Emissions Reduction Plan,
          delineating its roadmap to attain interim climate objectives of
          reducing GHG emissions by 40% - 45% by 2030, this regulation marks a
          significant milestone. The strategy outlined plans to institute ZEV
          sales mandates for light vehicles, in tandem with other initiatives
          aimed at electrifying transportation, such as funding allocations for
          charging infrastructure and EV incentives.
        </p>
        <p>
          The impetus behind these regulations is to address a primary
          contributor to emissions in Canada, with the transportation sector
          responsible for approximately 25% of the nation's GHG footprint, and
          light-duty vehicles constituting roughly half of the sector's
          emissions.
        </p>
        <p>
          Commencing in 2026, the new standard mandates that a minimum of 20% of
          new vehicle sales by auto manufacturers and importers must consist of
          ZEVs, escalating to 60% by 2030 and culminating in a 100% requirement
          by 2035. Government data reveals that battery electric and plug-in
          hybrid sales collectively represented just under 8% of new vehicle
          registrations in the initial half of the year.
        </p>
        <p>
          This regulation delineates ZEVs as encompassing battery-electric
          vehicles, hydrogen-powered fuel cell vehicles, and plug-in hybrid
          vehicles meeting specified criteria for electric-only range. According
          to an official government statement heralding the finalization of the
          new standard, its objective is to enhance the accessibility of ZEVs
          for consumers, while concurrently supporting the Canadian auto
          industry's battery and automotive supply chain.
        </p>
        <p>
          The government underscores that this standard is congruent with
          emerging regulations across North America, notably mirroring the
          mandate announced last year in California, which mandates that all new
          car, pickup truck, and SUV sales in the state must be zero emissions
          vehicles by 2035. This precedent has been followed by the
          implementation of analogous standards by 10 other states.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Adopting Canada's regulation for 100% Zero Emission Vehicle Sales by
            2035 positions companies as leaders in sustainable practices,
            fostering a positive corporate image. Compliance drives innovation
            in green technology, attracting environmentally conscious consumers.
            Moreover, anticipating future regulations ensures long-term
            viability, aligning businesses with global sustainability trends.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          New Legislation in Australia Requires Climate Reporting from
          Companies.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/feb2024-3.jpg").default}
            alt="feb2024-3"
          />
        </figure>
        <p>
          Australia is proposing new legislation mandating climate-related
          reporting for large and medium-sized companies. This legislation aims
          to enhance transparency and accountability regarding climate risks and
          opportunities, as well as greenhouse gas emissions throughout the
          value chain.
        </p>
        <p>
          The proposed law builds upon consultation efforts and aligns with
          international sustainability standards. It mandates reporting on
          material climate-related risks, emissions metrics, and governance
          processes. Initially targeting large companies, the legislation will
          gradually expand to include medium and smaller entities over the
          coming years.
        </p>
        <p>
          The legislation would apply to all public companies and large
          proprietary companies required to provide audited annual financial
          reports to the Australian Securities and Investments Commission (ASIC)
          that meet specific size thresholds, starting with companies with over
          500 employees, revenues over $500 million or assets over $1 billion,
          as well as asset owners with more than $5 billion in assets, which
          would begin reporting for fiscal years starting from July 1, 2024.
          Medium-sized companies (250+ employees, $200 million+ revenue, $500
          million assets) would be required to begin reporting for years
          beginning from July 2026, while smaller companies (100+ employees, $50
          million+ revenue, $25 million+ assets) would begin the following year.
        </p>
        <p>
          Companies will be required to report on Scope 1, 2, and 3 emissions,
          with a phased approach for Scope 3 reporting. Assurance requirements
          similar to financial reporting will be introduced, ensuring the
          credibility of climate-related disclosures. The legislation aims to
          foster early adoption and capacity building for effective climate risk
          reporting.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Mandatory climate reporting empowers companies strategically by
            providing data-driven insights into emissions and environmental
            impacts. This informs sustainable decision-making, enhancing
            resilience and competitive advantage.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          US Government Pledges $104 Million for Net-Zero Energy Projects Across
          Federal Facilities.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/feb2024-4.jpg").default}
            alt="feb2024-4"
          />
        </figure>
        <p>
          The US government has committed $104 million to fund net-zero energy
          projects across 31 federal facilities as part of the Assisting Federal
          Facilities with Energy Conservation Technologies (AFFECT) program.
          This allocation, drawn from a $250 million fund initiated last year,
          aims to propel the nation toward achieving net-zero emissions by 2050.
          With 300,000 federal buildings in its portfolio, the government
          recognizes the urgency of enhancing energy efficiency to combat
          climate change
        </p>
        <p>
          Initiatives encompass rooftop solar installations, heat pump systems,
          and other measures to reduce reliance on conventional fuel sources.
          The anticipated benefits include a projected $29 million reduction in
          energy and water costs within the first year, coupled with a
          substantial decrease in greenhouse gas emissions. Collaborative
          efforts with the Defense Department will strengthen energy resilience
          at military installations across the USA.
        </p>
        <p>
          These investments underscore a bipartisan commitment to sustainability
          and emission reduction, aligning with broader governmental objectives
          to mitigate climate change and enhance energy security.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Investing in net-zero energy projects includes significant
            reductions in energy consumption and greenhouse gas emissions across
            federal facilities. These investments enhance national energy
            security by reducing reliance on fossil fuels and mitigating the
            impact of energy price fluctuations and it will contribute to job
            creation and economic growth through the expansion of the renewable
            energy industry and related sectors.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          The UAE plans to launch the first sustainability accreditation
          framework 'EcoMark' for MSMEs.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/feb2024-5.jpg").default}
            alt="feb2024-5"
          />
        </figure>

        <p>
          The UAE has plans to develop a sustainability accreditation system
          tailored for small and medium-sized enterprises (SMEs). The EcoMark
          accreditation aims to bolster SMEs' competitiveness within the green
          economy by simplifying and standardizing sustainability regulations
          globally.
        </p>
        <p>
          Based on ISO standards and digitally facilitated, EcoMark
          certification offers comprehensive resources to guide SMEs through the
          accreditation process, including document guidelines, a roadmap for
          advancing sustainability levels, and standardized application
          procedures. Oversight of the framework will be centralized in the UAE.
        </p>
        <p>
          Highlighting the significance of SMEs in driving a transition to a
          low-carbon economy, emphasis was placed on the need for tailored
          support to unlock their potential, especially for underrepresented
          groups like women and youth.
        </p>
        <p>
          The EcoMark initiative aligns with discussions held at COP's inaugural
          Trade Day in Dubai, reinforcing the UAE's commitment to
          multilateralism and inclusive global trade. The UAE aims to facilitate
          broader access to sustainable markets, advancing a vision of a more
          equitable global economy.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The UAE government's plan to introduce the EcoMark green
            accreditation framework for MSMEs enhances the competitiveness of
            MSMEs within the global green economy by streamlining and
            standardizing sustainability regulations, reducing complexities
            associated with compliance. By recognizing the pivotal role of
            MSMEs, the initiative aims to unlock their potential, reduce time
            and resource constraints, and enable them to participate more
            effectively in the global trading system.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterSeventeen;
