import React, { useState } from "react";
import CaseStudySeven from "../components/CaseStudySeven";

function CaseStudySevenContainer() {
  const [caseStudyData] = useState({
    title: "Waste management industry case study",
    img: "wasteManagementCase.jpg",
    content:
      "The client is a large waste management company and manages 7 million tons of waste every year and is also the first to establish a waste management facility specifically for biomedical, Industrial, and integrated municipal in the country. In addition to its growing international presence, it has over 85 operating locations in India, Singapore, the UAE, Qatar, Saudi Arabia, Kuwait, Oman, and the US. The company provides a comprehensive environment management service under various waste management categories. The client's commitment is to focus on energy efficiency, decarbonization, water management, circular economy principles, and Inclusion and Diversity practices.",
  });
  const [downloadModalBool, setDownloadModalBool] = useState(false);
  return (
    <CaseStudySeven
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
    />
  );
}

export default CaseStudySevenContainer;
