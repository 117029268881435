import React, { useState } from "react";
import WorkOpportunities from "../components/WorkOpportunities";
import { withRouter } from "react-router-dom";
import jobs from "../../../../../shared/constants/jobs.json";

function WorkOpportunitiesContainer(props) {
  const [opportunities] = useState(jobs);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(opportunities);

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    const filteredResults = opportunities.filter((item) =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleJob = (data) => {
    window.open(`/company/careers/job/${data.jobId}`, "_blank", "noopener");
  };

  return (
    <>
      <WorkOpportunities
        opportunities={opportunities}
        searchTerm-={searchTerm}
        handleChange={handleChange}
        searchResults={searchResults}
        handleJob={handleJob}
      />
    </>
  );
}

export default withRouter(WorkOpportunitiesContainer);
