import React from "react";
import "../SCSS/styles.scss";

function BlogFive() {
  return (
    <>
      <div className="BlogFive-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>2 min read</span>
        </div> */}
        <div className="blog-title">World Environment Day</div>
        {/* <div className="update-date">Updated: Jun 5, 2020</div> */}
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Jun 5, 2020</div>
              <div className="blog-read-time">2 min read</div>
            </div>
          </div>
        </div>

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog5.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-8.jpg").default}
            alt="Blog-8"
          />
        </div>

        <p>
          We rely on nature for everything - the air we breathe, the water we
          consume, energy, food and everything else that makes our planet
          habitable. Recent events such as the bush fires across Australia and
          the Amazon rain-forest, the corona-virus pandemic and locust
          infestations across various parts of Africa and Asia and their impacts
          on our lives and the economy, have reiterated this interdependence of
          humans with nature.
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            This year in 2020 the chosen theme for WED is ‘Time for Nature’. It
            is the UN’s flagship day to celebrate and promote awareness and
            action for the environment and has become a global platform for
            public outreach
          </div>
        </div>
        <p>
          The World Environment Day is celebrated annually on the 5th of June,
          this year in 2020 the chosen theme for WED is ‘Time for Nature’. It is
          the UN’s flagship day to celebrate and promote awareness and action
          for the environment and has become a global platform for public
          outreach which includes participation of over 143 countries across the
          world. So far, it has been a campaign for various environmental issues
          ranging from global warming, wildlife trade, marine pollution to even
          air pollution.
        </p>
        <p>
          This year’s theme focuses on providing essential infrastructure that
          supports life on earth. Now more than ever, we are confronted with the
          fragility of life and its connection to our planet. Anthropogenic
          activities have been pushing the endurance of nature beyond its
          limits. If we continue on this path, the implications on our planet
          will be dreadful - a number of species are already disappearing and we
          might be on the verge of another mass extinction and the risk of
          collapse of our food and health systems.
        </p>

        <p>
          The outbreak of the COVID-19 pandemic has stressed upon the fact that
          when we destroy biodiversity, it will also bring down the various
          systems that support our daily lives including huge economies. It has
          altered our living conditions and our way of life. This situation
          calls on us to recognize our reliance on the health of our planet and
          take immediate action to conserve nature and natural resources. It
          calls for collective action from governments, industries and all other
          stakeholders to act now in order to ensure that our planet will be
          habitable for the future generations. Governments across the world
          must try to adopt circular economy practices and also protect the
          remaining wild spaces. They must ensure stronger and stricter
          environmental regulations and guidelines across all industrial
          sectors.
        </p>
        <p>
          On the other hand, the industries must adopt and finance greener
          measures across their operations - green infrastructure, accounting
          for environmental supply chains, efficient use and conservation of
          natural resources used across their supply chain. On a personal level,
          we need to rethink our lifestyle choices in terms of what we buy and
          our consumption patterns. It is important for us to buy and consume
          responsibly.
        </p>
        <p>
          Now, as we start to rebuild our economy, we must not forget the
          lessons taught to us by the loss of our fragile ecosystem. Together
          with various environmental regulations, guidelines and the Sustainable
          Development Goals, we must restore our ecosystems in order to sustain
          a healthy planet. It's time to put nature at the center of our
          decision making process -environmental conservation must not take a
          back seat at the cost of economic development.
        </p>
      </div>
    </>
  );
}
export default BlogFive;
