import React, { useState, useReducer, useEffect, useRef } from "react";
import { NotificationManager } from "react-notifications";
import ApplicationForm from "../components/ApplicationForm";
import { withRouter } from "react-router-dom";
import Axios from "axios";

const initialFormObj = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  optin: false,
};
function ApplicationFormContainer(props) {
  const [formObj, setFormObj] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialFormObj
  );
  const [mobile, setMobile] = useState("");
  const [errors, setErrors] = useState({});
  const [issubbmitted, setIsSubbmitted] = useState(false);
  const formData = useRef(new FormData());
  const fileInputRef = useRef(null);
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleTextOnChange = (event, type) => {
    if (type === "file") {
      const file = event.target.files[0];

      if (file?.type && file?.type.split("/")[1] === "pdf") {
        formData.current.append("file", file);

        formData.current.append("jobTitle", props.jobData.title);
        formData.current.append("hours", props.jobData.hours);
        formData.current.append("location", props.jobData.location);
        formData.current.append("type", props.jobData.type);
      }
      if (
        (file?.type && file?.type.split("/")[1] !== "pdf") ||
        file?.size >= 9437184
      ) {
        if (file?.size >= 9437184) {
          NotificationManager.warning(
            "Pdf file should be less than 9mb",
            "",
            3000
          );
        } else {
          NotificationManager.warning("Only pdf file allowed", "", 3000);
        }

        fileInputRef.current.value = "";

        formData.current = new FormData();
      }
    }
    if (type === "agree") {
      setFormObj({ ...formObj, optin: event.target.checked });
    } else {
      const { name, value } = event.target;
      setFormObj({ [name]: value });
    }
  };
  const validate = () => {
    let errors = {};

    const isText = RegExp(/^[A-Z ]+$/i);
    const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    if (!formObj.firstName) {
      errors.firstName = "Please enter first name";
      NotificationManager.warning(errors.firstName, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isText.test(formObj.firstName)) {
        errors.firstName = "First name accepts text only";
        NotificationManager.warning(errors.firstName, "", 2000);
        setErrors(errors);
        return;
      }
    }
    if (!formObj.lastName) {
      errors.lastName = "Please enter last name";
      NotificationManager.warning(errors.lastName, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isText.test(formObj.lastName)) {
        errors.lastName = "Last name accepts text only";
        NotificationManager.warning(errors.lastName, "", 2000);
        setErrors(errors);
        return;
      }
    }

    if (!formObj.email) {
      errors.email = "Please enter email";
      NotificationManager.warning(errors.email, "", 2000);
      setErrors(errors);
      return;
    } else if (!isEmail.test(formObj.email)) {
      errors.email = "Please enter a valid email address";
      NotificationManager.warning(errors.email, "", 2000);
      setErrors(errors);
      return;
    }
    //  else {
    //   const domain = formObj.email.split("@");
    //   if (
    //     domain[1].toLowerCase().includes("gmail") ||
    //     domain[1].toLowerCase().includes("yahoo") ||
    //     domain[1].toLowerCase().includes("rediffmail")
    //   ) {
    //     errors.email = "Please enter a valid work email";
    //     NotificationManager.warning(errors.email, "", 2000);
    //     setErrors(errors);
    //     return;
    //   }
    // }

    if (!mobile) {
      errors.mobile = "Please enter mobile number";
      NotificationManager.warning(errors.mobile, "", 2000);
      setErrors(errors);
      return;
    } else {
      setFormObj({ ["mobile"]: mobile });
    }
    if (!fileInputRef.current.value) {
      errors.file = "Please upload CV";
      NotificationManager.warning(errors.file, "", 2000);
      setErrors(errors);
      return;
    }

    if (formObj.optin === false) {
      errors.optin = "Please accept Terms and Conditions to continue";
      NotificationManager.warning(errors.optin, "", 2000);
      setErrors(errors);
      return;
    }
    setErrors(errors);
  };

  const handleSendRequest = async (e) => {
    e.preventDefault();
    validate();
    setIsSubbmitted(true);
  };

  useEffect(() => {
    const postInfo = async () => {
      if (!Object.keys(errors).length && issubbmitted) {
        setSubmitLoader(true);
        formData.current.append("firstName", formObj.firstName);
        formData.current.append("lastName", formObj.lastName);
        formData.current.append("email", formObj.email);
        formData.current.append("mobile", mobile);
        try {
          await Axios.post("/careers/job/api", formData.current);

          props.history.push("/career-success", {
            from: "career",
            name: formObj.firstName,
          });

          setFormObj(initialFormObj);
          setMobile("");
        } catch (error) {
          NotificationManager.error(
            "Please write to us at info@updaptcsr.com",
            "Error"
          );
          throw error;
        }
        setSubmitLoader(false);
      }
    };
    postInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <ApplicationForm
      mobile={mobile}
      setMobile={setMobile}
      handleTextOnChange={handleTextOnChange}
      handleSendRequest={handleSendRequest}
      formObj={formObj}
      fileInputRef={fileInputRef}
      submitLoader={submitLoader}
    />
  );
}

export default withRouter(ApplicationFormContainer);
