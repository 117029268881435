import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureThree() {
  return (
    <section className="erm-feature-section feature-3">
      <div className="erm-feature-container">
        <div className="erm-feature-wrapper">
          <div className="erm-feature-content-wrapper">
            <div className="erm-feature-content">
              <h2 className="erm-feature-title">
                Manage risk mitigation plans collaboratively
              </h2>
              <p className="erm-feature-description">
                Work collaboratively with team members to communicate all the
                necessary actions taken in managing the risks.
              </p>
            </div>
          </div>
          <div className="erm-feature-image-wrapper">
            <div className="erm-feature-image">
              <object
                data={assets.images.ermImage3}
                aria-label="ermImage3"
              ></object>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureThree;
