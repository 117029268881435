import React from "react";
import "../SCSS/styles.scss";

function BlogTen() {
  return (
    <>
      <div className="BlogTen-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <div className="blog-title">
          Responsible Companies for a Responsible Nation
        </div>
        {/* <div className="update-date">Updated: December 24, 2019</div> */}
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Dec 24, 2019</div>
              <div className="blog-read-time">4 min read</div>
            </div>
          </div>
        </div>

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog10.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-3.jpg").default}
            alt="Blog-3"
          />
        </div>
        <div className="sub-heading">An insight to CSR in India</div>
        <p>
          Corporate Social Responsibility (CSR) has ambiguous definitions.
          However, these varying definitions mainly focus on the impacts
          businesses have on our environment and society.
        </p>

        <p>
          Earlier, CSR mainly focused on philanthropic activities that
          constituted donations to various charities that focused on sectors
          such as education, empowerment, and the environment. Currently, it
          integrates related concepts such as Corporate Citizenship, Corporate
          Sustainability as well as Business Responsibility. Corporate Social
          Responsibility helps address the social and environmental impacts of
          businesses as it focuses on the well-being of all the stakeholders.
          Hence, along with philanthropic activities, CSR now includes
          activities that build an organization’s positive image by reducing its
          negative impacts on the environment and society.
        </p>
        <p>
          India, as an emerging economy, faces a lot of challenges in the
          development front. Among the developmental issues, the National
          priorities are skills development, health and hygiene, clean, and safe
          water. These areas provide numerous opportunities for CSR activities.
          CSR funding can hence be leveraged to tackle these developmental
          issues. Over the years, we have seen a significant increase in
          activities aligned with these national priorities. The newly
          introduced CSR regulations act as a means to address developmental
          issues in India with the aid of the Corporate Sector.
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            India is the first country in the world to make Corporate Social
            Responsibility mandatory by law.{" "}
          </div>
        </div>

        <p>
          India is the first country in the world to make Corporate Social
          Responsibility mandatory by law. The CSR regulatory frameworks in
          India mainly constitutes the following:
        </p>
        <p>
          <b> a. Section 135 of the Companies Act, 2013:</b> The Companies Act
          passed in 2013 set forth a set of conditions/rules/regulations for the
          implementation of CSR in companies. According to section 135 of the
          companies act 2013, it is made mandatory that companies spent 2% of
          average net profits during the three immediately preceding financial
          years for CSR. This applies to companies that have:(a) The net worth
          of Rs 500 crore or more; (b) turnover of Rs 1000 crore or more; (c)
          net profit of Rs 5 crore or more.
        </p>
        <p>
          <b> b. Companies (Corporate Social Responsibility) Rules, 2014:</b>{" "}
          The Companies Rules, 2014 also gives provision for more than two
          companies to collaborate and undertake CSR programs. The Board's
          Report of the company from the financial year 2014 shall include an
          annual report on CSR. In the case of a foreign company, the balance
          sheet shall contain an Annexure regarding the report on CSR.
        </p>
        <p>
          <b> c. Schedule VII of the Act.</b> The CSR handbook published by PwC
          states that there are around 6000 companies that must comply with this
          CSR regulation put forth by the government. This law also mandates the
          formation of a separate CSR board/committee in each of these
          companies. It also puts forth conditions that are to be followed by
          companies before spending on CSR; some of them being:
        </p>

        <p>
          - Programs benefiting only the company employees are not considered as
          CSR activities. Money spent on capacity building of staff under the
          CSR banner cannot exceed 5% of the expenditure.
        </p>
        <p>
          - CSR expenditure shall include all expenditure including contribution
          to the corpus, or projects related to CSR.
        </p>
        <p>
          -The contribution made to any poptical parties cannot amount to CSR
          activity. According to the Companies Act, 2013, activities which may
          be included by companies in their Corporate Social Responsibipty
          Policies are activities relating to: —
        </p>

        <p>1. Eradicating extreme hunger and poverty;</p>
        <p>2. Promotion of education;</p>
        <p>3. Promoting gender equality and empowering women;</p>
        <p>4. Reducing child mortality and improving mental health;</p>
        <p>
          5. Combating Human Immunodeficiency Virus, Acquired Immune Deficiency
        </p>
        <p>6. Ensuring environmental sustainability;</p>
        <p>7. Employment enhancing vocational skills;</p>
        <p>8. Social business projects;</p>
        <p>
          9. Contribution to Prime Minister’s National Relief Fund or any other
          fund set up by the Central Government or State Governments for
          socio-economic development and relief and funds for the welfare of the
          Scheduled Castes, the Scheduled Tribes, other backward classes,
          minorities, and women.
        </p>
        <p>10. Such other matters as may be prescribed.</p>

        <div className="sub-header">
          <div className="sub-header-content">
            Trends in India show that most developmental initiatives are focused
            mainly on eradicating the two (SDG 1 and 8) while, India is lagging
            on accomplishing targets for the other SDGs.
          </div>
        </div>
        <p>
          CSR spending is crucial as it can open new markets for an
          organization. For example, let us consider CSR activities that focus
          on areas like education; by investing in education the organization
          can eventually gain more insights on products or services lacking in
          the sector. This, in turn, opens an avenue for the organization to
          create a new line of products catering to the education sector. Also,
          in rapidly growing economies like India, CSR spending can extensively
          help in training and capacity building for skilled labor.
        </p>
        <p>
          The 17 Sustainable Development Goals (SDGs) and their corresponding
          169 targets converge with the CSR activities described in The
          Companies Act, 2013. Therefore, the CSR regulations can be used to set
          a framework to accomplish all targets set by the SDGs. In India,
          poverty and economic inequality are the biggest challenges. Trends in
          India show that most developmental initiatives are focused mainly on
          eradicating the two (SDG 1 and 8) while, India is lagging on
          accomplishing targets for the other SDGs. To achieve Sustainable
          Development, we must consider all the SDGs equally, and this can be
          accomplished by a combined effort of the Government and the Corporate
          Sector.
        </p>
      </div>
    </>
  );
}
export default BlogTen;
