import React from "react";
import "../SCSS/styles.scss";

import MetaTags from "react-meta-tags";

function BlogSixteen() {
  return (
    <>
      <div className="BlogSixteen-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Understanding the Greenhouse Gases - CO2</title>
          <meta
            name="description"
            content="Our planet is becoming warmer. The heat is melting ice caps, changing weather patterns, and disrupting the Earth's ecosystem.
          "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">Let's talk about Carbon Taxes.</div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Deeksha</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Nov 23, 2021</div>
              <div className="blog-read-time">3 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog16.jpg").default}
            alt="blog-16"
          />
        </div>
        <p>
          The first warning of global warming came a century ago. With the
          discovery of the greenhouse effect, scientists have been trying to
          understand the role of carbon in climate change. Today, with 440 ppm
          of CO2 in the atmosphere, we may be facing a global climate threat. Is
          there a realistic way to cut carbon emissions and stop global warming?
          Well, we aren't totally helpless. We've got some options to stop this
          climate crisis, and one of them is Carbon Taxes. Some people believe
          it is the way out of this crisis, while others do not. So what's the
          deal?
        </p>

        <p>
          A carbon tax is pretty simple; it's basically a tax paid by businesses
          and industries that produce carbon dioxide through their operations.
          Fossil fuels are subject to a carbon tax based on the amount of CO2
          they emit. We already know how the burning of fossil fuels has led to
          increased CO2 pollution contributing to climate change. Putting a
          price on pollution seems like a smart way to stabilize the situation.
        </p>

        {/* <p>
          Since the 2000s, the amount of CO2 in the atmosphere has increased
          significantly, mainly due to the burning of fossil fuels. These
          human-caused emissions can remain in the atmosphere for long periods,
          trapping more heat and continuously warming the planet. CO2 especially
          has a significant impact on the global climate as it lingers for about
          300 to 1,000 years. But it's interesting to note that though the
          concentration of CO2 in the atmosphere is just 0.04% compared to other
          gases, it is still the primary contributor to global warming.
        </p> */}

        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                Who has to pay a carbon tax?
              </b>
            </p>
          </div>
        </div>

        <p>
          Under carbon taxing, the emitters pay a fixed price for every tonne of
          CO2 released. Carbon taxes can likely be imposed on the sale of fuel
          from "upstream" producers (eg., coal mines or natural gas and oil
          wells) to initial consumers (e.g., electric utilities, oil and gas
          refineries, and fuel transporters). The price is then passed on to
          "downstream" consumers (i.e., businesses and households) who pay for
          carbon-intensive goods and services.
        </p>
        <p>
          The idea is that the tax will incentivize companies to lower their
          carbon emissions and develop innovative carbon-reducing technologies.
          By imposing greater taxes on carbon-based fuels, industries can cut
          pollution and focus on cleaner alternatives such as renewables. Carbon
          taxes can create significant revenue for countries if they are
          utilized to compensate for the economic harm caused by fossil fuel
          consumption.
        </p>
        <p>
          Carbon taxes are now in place in over 27 countries, including Canada,
          Chile, China, Sweden, Singapore, etc. India's carbon price policy is
          still developing. There is no explicit carbon pricing or market-based
          mechanism in place, although there are some schemes in place,
          including the Perform, Achieve, and Trade (PAT) scheme, Renewable
          Purchase Obligations (RPO), and Renewable Energy Certificates (REC),
          among others.
        </p>
        {/* <p>
          Today carbon dioxide levels are higher than at any point in the past.
          We are adding more CO2 into the atmosphere than natural processes can
          remove. Plants, oceans, and soil are natural carbon sinks that can
          absorb and store CO2. But at the current rate of CO2 emission, it
          would take ages to clean up the mess. Hence cutting down GHG emissions
          and reducing our carbon footprint is the only way to avoid this global
          catastrophe.
        </p> */}
        {/* <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 2: China and India are the primary contributors to climate
            change, and both countries must take action.
          </b>
        </p> */}
        {/* <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-13(graph).svg")}
            alt="Blog-13(graph)"
          />
        </div>
        <p>
          Fact: China and India are two of the world's largest GHG emitters due
          to their heavy reliance on coal. At the same time, these countries are
          also the world's top solar energy harnessers. Due to historically high
          emissions by developed economies such as Europe and the United States,
          the developing economies are now being forced to reduce their
          emissions. Now, when it's time for these developing countries to
          progress, they're being forced to implement high-cost climate-change
          mitigation measures. This does not appear to be equitable, and wealthy
          economies must compensate these countries for combating climate
          change. Under the current climate crisis, all the nations will have to
          help each other collectively.
        </p>

        // {/* <div className="sub-header">
        //   <div className="sub-header-content">
        //     So, is it realistic for electric vehicles to contribute
        //     significantly to sustainability in these challenging scenarios?
        //   </div> */}
        {/* </div> */}

        {/* <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 3: Renewables are expensive and can only work when it's windy
            and not cloudy.
          </b>
        </p>
        <p>
          Fact: Today, renewables are just as cheap as any other source of
          energy. According to the International Renewable Energy Agency
          (IRENA), renewable energy costs continue to reduce "substantially"
          year on year. A continuous supply of energy can be ensured by using a
          mix of powers such as solar, wind, natural gas, etc. Furthermore,
          rapid advances in battery capacity can help capture energy while it is
          available and store it for later use.
        </p>

        <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 4: Individuals cannot make any difference in the climate change
            cause.
          </b>
        </p>
        <p>
          Fact: There are a lot of things that individuals can do to fight
          climate change. Sustainable lifestyle practices by putting the 3 Rs-
          Reduce, Reuse, and Recycle into practice and choosing renewables over
          fossil fuels can help a great deal. Reducing meat intake by adopting a
          flexitarian diet (one mainly vegetarian but allows for the occasional
          meat dish) can also help reduce more than 40% carbon footprint.
          However, it wouldn't be right to say that only lifestyle changes can
          reduce emissions. Given the scope of the problem, a policy-driven
          strategy, in addition to individual initiatives, is required.
        </p> */}
        {/* <p>
          Climate change is real and is a global issue, and it will affect every
          country and individual if measures are not taken to address it. People
          and regulatory bodies working together can have a significant impact
          on people and the planet.
        </p> */}
        {/* <p>
          Only a coherent makeover, in which all elements are tied to a common
          goal, can help us economically and environmentally.
        </p> */}
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                So why are carbon taxes so controversial?
              </b>
            </p>
          </div>
        </div>
        <p>
          A carbon tax can be regressive - it raises prices for electricity,
          gasoline, home heating fuels, and other fossil fuel-based energy
          supplies, forcing consumers to pay a higher percentage of their income
          for basics like gasoline, electricity, and food. Also, the tax's
          opponents argue that it would harm countries' GDPs, particularly
          developing countries that rely on high-emissions industries. There
          could also be undesirable consequences, such as "carbon leakage"
          whereby businesses may outsource production to areas with fewer
          regulations, resulting in the loss of employment and resources.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                So, where does that leave us?
              </b>
            </p>
          </div>
        </div>
        <p>
          Carbon taxes may be an effective tool for reducing greenhouse gas
          emissions. However, for it to be effective, carbon tax must be used in
          conjunction with other measures like revenue-recycling mechanisms or
          other means to offset the regressive effect so that people are not
          made worse off by increasing the cost of fossil fuels.
        </p>
        <p>
          In the end, the effectiveness of the carbon taxes comes down to the
          details. Is the tax too low or too high? Does it affect only a few
          geopolitical areas? Will a revenue-neutral carbon tax help speed
          things up? Only a well-written carbon tax policy can make a
          significant impact on CO2 emissions and climate change.{" "}
        </p>
      </div>
    </>
  );
}
export default BlogSixteen;
