import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterEight() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter May 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterEight-container">
        <MetaTags>
          <title>
            Hong Kong Exchange Requires Climate Disclosures from 2024.
          </title>
          <meta
            name="description"
            content="Hong Kong's Stock Exchange is set to require all listed companies to provide climate-related disclosures in line with the International Sustainability Standards Board's (ISSB) upcoming Climate Standard."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>

        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          Hong Kong Exchange Requires Climate Disclosures from 2024.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/may2023-1.jpg").default
              }
              alt="may2023-1"
            />
          </figure>
        </div>
        <p>
          Hong Kong's Stock Exchange is set to require all listed companies to
          provide climate-related disclosures in line with the International
          Sustainability Standards Board's (ISSB) upcoming Climate Standard. The
          new rules will be in force from January 1, 2024, and are expected to
          significantly increase the reporting of many companies, particularly
          in Scope 3 emissions and scenario analysis to determine climate
          resilience.{" "}
        </p>
        <p>
          The new proposal also includes interim provisions to help issuers ramp
          up their climate reporting, with quantitative disclosures required for
          the first two years in some areas. The disclosures will cover various
          topics, including transition plans, Scope 3 emissions, scenario
          analysis-based climate resilience, and governance of climate-related
          risks and opportunities.
        </p>
        <p>
          According to a few experts, the proposal aims to accelerate the
          building of resilience and the sustainability journey for issuers.
          Climate change is a global concern for which investors demand more
          information on the impacts of climate issues and related policy
          changes on issuer's assets, business operations, and financials.
        </p>
        <p>
          The proposed rules call for reporting on internal carbon prices and
          disclosure of the use of climate-related considerations in executive
          remuneration. It also requires mandate disclosure of metrics and
          targets, such as the amount and percentage of assets vulnerable to
          transition and physical risks aligned with climate-related
          opportunities.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The new rules are expected to drive companies to develop and
            implement more robust climate strategies, ultimately reducing
            greenhouse gas emissions and increasing climate resilience. By
            requiring reporting on Scope 3 emissions and scenario analysis,
            companies will be better equipped to address climate-related risks
            and opportunities.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          The Reserve Bank of India has proposed a new framework for accepting
          green deposits in India.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/may2023-2.jpg").default}
            alt="may2023-2"
          />
        </figure>
        <p>
          To encourage regulated entities to offer green deposits to customers,
          The Reserve Bank of India (RBI) has released a new guideline for
          regulated entities to boost green finance and mitigate climate-related
          financial risks.
        </p>
        <p>
          To protect the interest of the depositors, help the customers achieve
          their sustainability agenda, and address greenwashing concerns, the
          new framework will come into effect from the 1st of June and applies
          to small finance banks and deposit-taking non-banking financial
          companies. While large banks like Federal Bank, IndusInd Bank and DBS
          Banks in India already accept green deposits.
        </p>
        <p>
          According to the RBI's framework, lenders can issue green deposits on
          a cumulative or non-cumulative basis, which the depositor could renew
          or withdraw on maturity. All conditions applicable to other public
          warranties will also be relevant to green deposits. The proceeds from
          such deposits will be utilized for various sustainable activities like
          renewable energy, energy efficiency, clean transportation, and others.
        </p>
        <p>
          A comprehensive policy with the details of all the aspects of the
          issuance and allocation of green deposits is yet to be approved. Where
          a financing framework for an adequate distribution of green warranties
          covering the eligible green activities, the process for project
          evaluation, the allocation of proceeds, and the particulars of the
          temporary allocation will be prepared and be presented to carry out an
          external review to receive opinions from the external reviewer before
          the implementation.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The announcement of the new framework came out just in time when
            environmental, social and governance are gaining focus in India.
            Green deposits provide more significant opportunities to create a
            greener future and encourage financial institutions to integrate
            more ESG factors into their investment decisions. Such
            central-driven initiatives help support India's commitment to the
            Paris Agreement and SDGs.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          New Net Zero Standard by IIGCC for Oil and Gas Companies.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/may2023-3.jpg").default}
            alt="may2023-3"
          />
        </figure>
        <p>
          The Institutional Investors Group on Climate Change (IIGCC) brings the
          investment community together to work towards a net zero and climate
          change, supporting investors in generating returns.
        </p>
        <p>
          The new framework draws investor expectations and corporate engagement
          priorities for oil and gas companies and acts as a comprehensive
          assessment framework for investors to assess the alignment in their
          transition plans with net zero and 1.5 0 climate scenarios.
        </p>
        <p>
          The framework supplements the Climate Action 100+ Net Zero Company
          Benchmark by aligning with all the 90 metrics. The standard will be
          used for initial public assessments of the largest European and North
          American oil and gas companies in the last quarter of 2023, where the
          list of companies for assessment will be finalized by June 2023.
        </p>
        <p>
          This is a result of IIGCC's two-year collaborative process with the
          investor groups and the Transition Pathway Initiative (TPI), where a
          pilot study was tested in September 2021 with five major European oil
          and gas companies. The results of the pilot study project greater
          transparency over decarbonization plans including from setting targets
          to offsets. However, the pilot study also states the requirement to
          improve the alignment of targets to the 1.5o C scenario.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Oil and Gas are one of the major sectors to face challenges in the
            transition to a low-carbon economy. For such companies, the IIGCC
            net zero standard helps provide clear guidance on how to work on the
            strategies and what investors would like to expect in the assessment
            of a net zero transition plan. It is also said that this framework
            would level the disclosure landscape for the sector.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          G7 Urges Global Adoption of Mandatory Climate-Related Financial
          Disclosures.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/may2023-4.jpg").default}
            alt="may2023-4"
          />
        </figure>
        <p>
          G7 climate and environment ministers have called for mandatory
          climate-related financial disclosures to accelerate sustainable
          finance and help achieve global climate goals. The call comes as
          several major economies are implementing sustainability reporting
          regimes, such as the EU's Corporate Sustainable Reporting Directive
          (CSRD), set to take effect in 2024, and the US SEC's anticipated
          climate disclosure rules.
        </p>
        <p>
          The G7 ministers highlighted the International Sustainability
          Standards Board's importance in developing a practical, flexible, and
          interoperable global baseline. They called for mandatory climate
          reporting beyond the G7 and urged more partners to join this effort.
        </p>
        <p>
          The Sapporo summit's communique pledged to increase renewable energy
          generation, accelerating a broad range of renewable energy sources.
          The ministers set collective goals to advance offshore wind capacity
          by 150 GW and increase solar PV to over 1 TW by 2030.
        </p>
        <p>
          The communique included a section on natural gas and LNG investment,
          stating that investment in the gas sector could be appropriate to
          address potential market shortfalls provoked by the crisis, but only
          if implemented without creating lock-in effects and consistent with
          climate objectives.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            This move is crucial to increase transparency and accountability
            among businesses and encourage investors to make informed decisions
            about where to invest their money. The momentum towards climate
            reporting is expected to change with the upcoming release of the
            IFRS Foundation's International Sustainability Standards Board's
            sustainability-related and climate-related disclosure standards.
            These efforts could positively impact the world economy by promoting
            sustainable investment and reducing environmental risks.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          New launch - CDP's annual reporting includes plastic-related
          disclosures from 2023.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/may2023-5.jpg").default}
            alt="may2023-5"
          />
        </figure>
        <p>
          CDP has included a new set of questions and metrics related to plastic
          in its environmental disclosure to encourage companies nearly 7,000
          and more to start reporting on it. The framework is out with requests
          from more than 740 investors with US$136 trillion in assets.
        </p>
        <p>
          The companies are asked to report on their production, consumption and
          disposal of plastic polymers, durable plastics, and plastic packaging
          that is incorporated into CDP's water security questionnaire.
        </p>
        <p>
          The questions are built on the existing UNEP's Global Commitment
          Framework and are working to widen the scope with consulting expertise
          and support from The Ellen MacArthur Foundation, The Pew Charitable
          Trusts, and Minderoo Foundation. The current details also cover the
          potential impacts on the environment, business risks, and targets in
          relevance with the negotiations of the UN Plastics Treaty.
        </p>
        <p>
          Especially the highly impacted sectors such as packaging are one of
          the largest users of plastics globally, food and beverage that uses
          plastic packaging for their products and petrochemical companies that
          produce a majority of all single-use plastic waste and others like
          fashion/apparel, with their online stores contribute to plastic
          packaging waste.
        </p>
        <p>
          The plastic pollution crisis cost not just financial and legal risks
          but also significant physical, technological, regulatory, and
          reputational risks for both companies and investors of any and every
          industry globally.
        </p>
        <p>
          Corporate liability is no joke, where more than six large companies
          faced a shareholder petition in 2022 asking for more disclosures on
          reducing plastic waste. In the same year, more than 55 financial
          institutions formed a Plastic Solutions Investor Alliance to work with
          publicly traded companies on plastic pollution.{" "}
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Tracking and reporting plastic-related information by CDP will help
            capital markets and supply chain members in their financial or
            procurement decisions. Many countries like Canada and India have
            recently introduced laws to ban the use of single-use plastics and
            include recycled content requirements for plastic packaging and
            labelling to be detailed.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterEight;
