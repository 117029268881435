import React from "react";
import { Link, useHistory } from "react-router-dom";

function Intro(props) {
  let history = useHistory();

  return (
    <>
      {/* <section className="thankyou-banner-section">
    <div className="thankyou-banner-container">
     
      <h1 className="thankyou-title">
     Thank you
      </h1>
      <p className="thankyou-description">
      Drop us a message and we will get back to you.
      </p>
      <div className="thankyou-banner-button" 
              >
            
              <NavLink
                to="/contact"
                className="thankyou-banner-contact-btn"
              >
               Talk to us
              </NavLink>
            </div>
      {/*<h1 className="contact-sub-title">
        Please fill out the form below and we will respond to you as soon as
        possible
  </h1>
    </div>
  </section> */}
      <section className="thankyou-banner-section">
        <div className="thankyou-circle">
          <div className="thankyou-circle-1"></div>
          <div className="thankyou-circle-2"></div>
          <div className="thankyou-circle-3"></div>
          <div className="thankyou-circle-4"></div>
          <div className="thankyou-circle-5"></div>
        </div>
        <div className="thankyou-banner-container">
          {history.location.state.from === "contact" && (
            <div className="thankyou-content">
              <h1 className="thankyou-title">
                Thank you {history.location.state.name} for unlocking <br /> new
                business opportunities for borderless growth.{" "}
              </h1>
              {/* <p className="thankyou-description">
                We’ll get back to you as soon as possible.
                <br />
                Until then, make sure to check out the following resources.
              </p> */}
            </div>
          )}
          {history.location.state.from === "demo" && (
            <div className="thankyou-content">
              <h1 className="thankyou-title">
                Thank you {history.location.state.name} for requesting a Demo!
              </h1>
              <p className="thankyou-description">
                One of our ESG Product Experts will get back to you within 24
                hours.
                <br /> If you need immediate assistance, you can call us{" "}
                <Link to="/contact">here</Link>.
                <br />
                We’re looking forward to speaking with you.
              </p>
            </div>
          )}
          {history.location.state.from === "free-trial" && (
            <div className="thankyou-content">
              <h1 className="thankyou-title">
                Thank you {history.location.state.name} for requesting a Free
                Trial!
              </h1>
              <p className="thankyou-description">
                One of our ESG Product Experts will set up your Free Trial
                account and get back to you shortly.
              </p>
            </div>
          )}
          {history.location.state.from === "career" && (
            <div className="thankyou-content">
              <h1 className="thankyou-title">
                Thank you {history.location.state.name} for your application!
              </h1>
              <p className="thankyou-description">
                We appreciate the time and effort you put into applying with us.
                Our team will carefully review your application and if your
                qualifications align with our requirements, we will contact you
                to proceed with your candidacy.
              </p>
              <br />
              <p className="thankyou-description">
                We wish you the best of luck in your job search.
              </p>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Intro;
