import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFour() {
  return (
    <section className="erm-feature-section feature-4">
      <div className="erm-feature-container">
        <div className="erm-feature-wrapper">
          <div className="erm-feature-image-wrapper">
            <div className="erm-feature-image">
              <object
                data={assets.images.ermImage4}
                aria-label="ermImage4"
              ></object>
            </div>
          </div>
          <div className="erm-feature-content-wrapper">
            <div className="erm-feature-content">
              <h2 className="erm-feature-title">
                Receive instant alerts & act accordingly
              </h2>
              <p className="erm-feature-description">
                Set up automated alerts to respond quickly to overdue tasks,
                thereby improving the overall efficiency of the risk management
                process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFour;
