import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureThree() {
  return (
    <section className="scm-feature-section">
      <div className="scm-feature-container">
        <div className="scm-feature-wrapper">
          <div className="scm-feature-content-wrapper">
            <div className="scm-feature-content">
              <h2 className="scm-feature-title">
                Create Custom assessments with ease
              </h2>
              <p className="scm-feature-description">
                Create assessment questionnaires customized to your company's
                needs and implement them quickly to better understand the ESG
                risks associated with a supplier.
              </p>
            </div>
          </div>
          <div className="scm-feature-image-wrapper">
            <div className="scm-feature-image">
              <img src={assets.images.scmImage3} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureThree;
