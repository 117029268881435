import React, { useState } from "react";
import Grant from "../components/Grant";

function GrantContainer() {
  const [whyFeatures] = useState([
    {
      header: "Simplify your CSR fund management",
      desc:
        "Identifying social initiatives in line with the company's committment and financial budget can be a daunting task. U-Grant provides a dedicated single interface to record and manage all program specifc finances and budgets. Use our insightful dashboards to monitor funding frequency.",
    },
    {
      header: "CSR dashboards to track performance of your initiatives",
      desc:
        "Managing non-profits RFPs, tracking multiple fund allocations, training and volunteering of employees is a cumbersome and error prone activity. Dashboards are a rich visual option to accumulate insights and monitor progress of various aspects of CSR projects. U grant provides dedicated dashboards for Funds, NGO projects by SDGs, beneficiaries spread and many more features.",
    },
    {
      header: "Align with UN Sustainable Development Goals labelled programs",
      desc:
        "U-Grant module provides a unique marketplace for local and international NGOs and their related programs. Select non-profits based on experience,locations and programs delivered.This easy to use and interpret interface also labels projects that align with the UN SDGs. Therefore, assisting you to fund projects that matter to your business.",
    },
    {
      header: "Assess and audit your social impact program wise",
      desc:
        "Analysing social impact on beneficiaries is crucial to any program. Assessing the impact of each program manually requires large resources and investment.Our inbuilt easy to use online assessment surveys and audit process added with a mobile interface for mobility reduces stress on your Internal team.",
    },
  ]);
  return <Grant whyFeatures={whyFeatures}></Grant>;
}

export default GrantContainer;
