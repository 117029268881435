import React from "react";
import "../scss/styles.scss";
import MetaTags from "react-meta-tags";

function BlogEighteen() {
  return (
    <>
      <div className="BlogEighteen-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Understanding Climate Justice</title>
          <meta
            name="description"
            content="In December 2019, Typhoon Ursula ravaged the Philippines, displacing thousands of people.
          "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">Understanding Climate Justice</div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Deeksha</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Mar 08, 2022</div>
              <div className="blog-read-time">3 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/b19.jpg").default}
            alt="blog-19"
          />
        </div>
        <p>
          In December 2019, Typhoon Ursula ravaged the Philippines, displacing
          thousands of people and causing significant economic damage. In the
          same year, two deadly cyclones swept through Mozambique, one of the
          world's poorest countries.
        </p>
        <p>
          Millions of people in southern Madagascar struggled to get enough to
          eat as food sources entirely disappeared in 2021. People had to
          survive on locusts, fruits, and cactus leaves due to a famine-like
          situation caused by drier rainy seasons.
        </p>
        <p>
          Catastrophic events are already impacting people around the globe due
          to climate change. However, the impacts of climate change are anything
          but equal. Despite having contributed the least to climate change, the
          poorest countries are the most affected. This unfair situation has led
          to calls from NGOs and developing countries for <b>Climate Justice</b>
          .
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                What is Climate Justice?
              </b>
            </p>
          </div>
        </div>
        <p>
          Different groups of people are affected by climate change in different
          ways. Take Pacific Island nations, for example, which produce very
          little carbon into the atmosphere each year. Yet, due to climate
          change, they are experiencing devastating effects such as increasing
          sea levels and soil erosion, disrupting their lives.
        </p>
        <p>This is unjust.</p>
        <p>
          Climate justice is about recognizing that climate change affects
          various groups differently and that certain groups and countries will
          be more vulnerable to its effects than others. It is also about
          acknowledging that the people who are least to blame for climate
          change bear the brunt of its repercussions.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                Climate change has resulted in inequities due to various
                reasons.
              </b>
            </p>
          </div>
        </div>
        <p>
          Climate change has many impacts, including decreased food production,
          limited access to water, and increased health-related risks. However,
          these impacts are not evenly distributed globally for various reasons.
          Let's take a look at a few of them.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "18px" }}>
                Varying physical geography
              </b>
            </p>
          </div>
        </div>
        <p>
          Some areas are more vulnerable to climate change due to their physical
          geography. For example, the Maldives, located in the Indian Ocean, are
          only 1.5 metres above sea level. Even a slight rise in sea levels can
          cause havoc.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "18px" }}>
                Inequal Historical Emissions
              </b>
            </p>
          </div>
        </div>
        <p>
          Historical emissions matter today because nations are debating how
          quickly they must cut net emissions to zero. Wealthier countries, such
          as Europe and the United States, have been using fossil fuels and
          emitting climate-changing emissions for nearly a century. Thus, they
          have reaped significant benefits from their use. However, current big
          polluters like China, India and Brazil, which have contributed the
          least to present climate change due to historically low emissions, are
          now bearing an unfair share of the responsibility of saving the
          planet.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "18px" }}>
                Uneven Adaptive Capacity
              </b>
            </p>
          </div>
        </div>
        <p>
          Some countries are better equipped to adapt to climate change than
          others. Both Bangladesh and the Netherlands are low-lying and
          vulnerable to rising sea levels. Yet, The Netherlands has a higher
          ability than Bangladesh to adapt and minimize flooding due to better
          engineering skills and financial assistance. This uneven development
          causes inequalities between nations to fight against climate change.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "18px" }}>
                Inequitable Social Conditions
              </b>
            </p>
          </div>
        </div>
        <p>
          Rising sea levels and drought are already threatening indigenous
          communities' homes and livelihoods. The Biloxi-Chitimacha-Choctaw
          tribe, for example, has lost nearly all of their territory due to
          climate change and has relocated to higher ground.
        </p>
        <p>
          Climate change also has the potential to worsen socio-economic
          inequity. Climate impacts can put low-income communities, racial
          minorities, people with disabilities, older people, and women at risk.
          Economically deprived people may find it challenging to buy flood or
          fire insurance, restore their homes, or pay large medical bills after
          a disaster.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                Can climate justice be achieved?
              </b>
            </p>
          </div>
        </div>
        <p>
          Climate justice initiatives are gaining momentum. Significant efforts
          have been made by many organizations and individual networks towards
          climate change adaptation and mitigation activities, reinforcing human
          rights, and recognizing climate change victims.
        </p>
        <p>
          For climate justice to operate, the costs and benefits of addressing
          climate change need to be fairly based on <b>responsibility</b> and{" "}
          <b>capacity</b>.
        </p>
        <p>
          It is essential to recognize climate justice as a part of a broader
          problem, as it is closely linked to social justice, racial justice,
          gender justice, etc. One cannot address climate justice without
          addressing these issues.
        </p>
        <p>
          Climate justice is not just a debate about who is responsible for
          today's climate change or carbon reduction. It's not only about
          finding solutions to the climate crisis. Rather, it's about doing it
          equitably.{" "}
        </p>
      </div>
    </>
  );
}
export default BlogEighteen;
