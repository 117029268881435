import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterFour() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter January 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterFour-container">
        <MetaTags>
          <title>
            Indian Parliament passes an Energy Conservation bill to promote
            non-fossil energy sources.
          </title>
          <meta
            name="description"
            content="The parliament of India have passed the energy conservation bill in both houses ( Lokha
Sabha and Rajya Sabha)..."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          Indian Parliament passes an Energy Conservation bill to promote
          non-fossil energy sources
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/images/jan1.jpg").default}
              alt="media-01"
            />
          </figure>
        </div>
        <p>
          The parliament of India have passed the energy conservation bill in
          both houses ( Lokha Sabha and Rajya Sabha), amending the energy
          conservation act 2001 to promote energy efficiency and conservation
          and to specify a carbon credit trading scheme.
        </p>
        <p>
          <div
            className="Newsletter-subtitle"
            style={{ paddingTop: "25px", marginBottom: "10px" }}
          >
            Key features of the bill amendment 2022:
          </div>
          <ul>
            <li>
              The central government is asked to specify a carbon credit trading
              scheme that implies a tradeable permit to produce a set amount of
              carbon dioxide or other greenhouse gas emissions.
            </li>
            <li>
              The Act will specify energy standards, setting different
              consumption thresholds for various non-fossil sources and consumer
              categories.
            </li>
            <li>
              The Bill asks the central government to provide for an “Energy
              Conservation and Sustainable Building Code” with norms for energy
              efficiency, conservation, use of renewable energy, and other
              requirements for green buildings. And the code applies to
              commercial buildings, office and residential buildings meeting
              specific criteria.
            </li>
            <li>
              The Bill expands the scope to include vehicles (as defined under
              the Motor Vehicle Act, 1988) and vessels (including ships and
              boats).
            </li>
          </ul>
          <br />
          Failure to meet the threshold obligations will be punishable with a
          penalty of up to Rs 10 lakh with an additional penalty of up to twice
          the price of oil equivalent energy consumed above the prescribed norm.
          Vehicle manufacturers violating fuel consumption norms will be liable
          to pay a fine of up to Rs 50,000 per unit of vehicles sold.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Although there still are a few questions that must be answered, like
            the appropriate ministry to regulate the carbon credit trading
            scheme and the details or specifications on interchanging the
            certificates that can be both renewable energy certificates and
            carbon credits. The energy conservation bill 2022 empowers the
            central government to specify specific schemes and act accordingly.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          COP15 Biodiversity conference: Nearly 200 countries set new goals to
          end biodiversity loss
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/jan2.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          The United Nations Biodiversity Conference was held in Montreal,
          Canada, to assemble governments worldwide and agree to a new set of
          goals for nature over the next decade.
        </p>
        <p>
          The conference, from the 7th to the 19th of December 2022, looked at
          implementing the protocols of the Convention on Biological Diversity
          (CBD) to deal with the fair and equitable sharing of benefits from the
          use of nature and the safe transport, handling and labelling of Living
          Modified Organisms.
        </p>
        <p>
          The conference discussed protecting the Earth, pesticides, preventing
          extinctions, government subsidies, plastic pollution, invasive
          species, and nature restoration. The countries committed to preserving
          nearly a third of Earth for nature by 2030 while respecting indigenous
          and traditional territories in the expansion of new protected areas.
          Indigenous-led conservation models must become the norm to take actual
          actions on biodiversity as they represent only 5% of humanity but
          protect 80% of Earth’s biodiversity.
        </p>
        <p>
          At the same time, an agreement is struck to develop a funding
          mechanism for Digital Sequence Information (DSI) in the coming years
          to track back the original country of digitized genetic information
          that we get from nature (rainforests, peatlands, coral reefs, and
          other rich ecosystems).
        </p>
        <p>
          Also, target 15 of the agreement requires governments to ensure that
          large and transnational companies disclose “their risks, dependencies
          and impacts on biodiversity, which will change business practices for
          good.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Biodiversity loss is rapidly shooting up the agenda of corporate
            risks. Whether we name it to halt and reverse or an enhanced
            implementation mechanism, can the discussions be called a
            transformational agreement for nature, yet?
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Only 1.3% of companies are leading with the newly released 2022 CDP
          environmental scores
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/jan3.jpg").default}
            alt="media-01"
          />
        </figure>
        <p
          style={{ fontWeight: "600", marginTop: "10px", marginBottom: "5px" }}
        >
          What does it mean?
        </p>
        <p>
          330+ global companies worth nearly US $11 trillion in market
          capitalization have been highlighted for their environmental
          transparency and action on climate change, forests, and water security
          by CDP, of which:
        </p>
        <p>
          <ul>
            <li>283 companies made the 2022 Climate Change A list</li>
            <li>25 companies made the 2022 Forests A list</li>
            <li>103 companies made the 2022 water security A list</li>
          </ul>
        </p>
        <p>
          There is a 34% increase, with 280+ companies scoring an A in 2022 for
          their climate change compared to 2021; progress still needs to be made
          faster on deforestation and water security. There is a 4% increase in
          forest A-listers and a 12.7% decrease from 118 to 103 in 2022 in the
          number of water security A-listers.
        </p>
        <p>
          CDP measures the comprehensiveness of disclosures, awareness and
          management of environmental risks and best practices associated with
          environmental leadership, such as setting ambitious and meaningful
          targets by scoring companies A to D-.
        </p>
        <p>
          To receive an A or B score, companies must demonstrate awareness of
          their impacts on the environment, deforestation, and water security.
        </p>
        <p>
          CDP’s scoring methodology is fully aligned with the Taskforce for
          Climate-Related Financial Disclosures (TCFD) and with major
          environmental standards, assessing the level of detail and
          comprehension in response and providing a comparable dataset across
          the market.
        </p>
        <p>
          Questionnaires are scored in categories for each sector across the
          three themes. Each scoring category at management and leadership
          levels is weighted according to its relative importance to the overall
          score based solely on the information disclosed in the CDP response,
          and it doesn’t consider any other sources.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            CDP disclosure and scores play a crucial role in keeping companies
            on a journey of continuous environmental improvement and are also
            one of the best ways to show the progress or the alignment with the
            1.5-degree. Companies must start sincerely disclosing their
            practices beyond regulatory pressure.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Ireland's plans for developing offshore wind are taking actual shape
          with the government's Maritime Area Consents (MACs)
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/jan4.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          Ireland's Minister for the Environment, Climate and Communications
          issued Maritime Area Consents (MACs) to the first phase of seven
          offshore wind projects. According to the government, the award of MACs
          ensures that only projects with the most significant viability to
          deliver Ireland's ambitious energy targets progress into the planning
          system.
        </p>
        <p>
          The award follows a comprehensive assessment by the Department of the
          Environment, Climate and Communications of each project’s financial
          and technical competency.
        </p>
        <p>
          Ireland plans to bring in multiple government departments and State
          agencies to reduce emissions from the electricity sector targeting 7GW
          of offshore wind by the end of the decade and develop a longer-term
          plan to harness the potential 30GW of offshore floating wind power in
          Atlantic waters.
        </p>
        <p>
          One of the consent projects includes the Dublin offshore wind farm
          comprising up to 61 turbines off the coast of South Dublin and
          Wicklow, with a capacity to generate between 600 and 900 megawatts of
          renewable energy once it is connected to the national grid, is
          considered as a sustainable source of clean, green renewable
          electricity for the Irish market.
        </p>
        <p>
          As the following steps, Dublin array, along with the other phase one
          projects, can move into the final planning stages of the project in
          2023 and submit a planning application to An Bord Pleanala, Ireland’s
          National Independent planning body, where the project proposals will
          undergo environmental assessment and finalise the design.
        </p>
        <p>
          The Renewable Electricity Support Scheme (RESS) projects are expected
          to procure approximately 2.5GW of electricity generating capacity,
          producing enough energy to supply up to about 840,000 homes at peak
          production.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Offshore wind energy is at the heart of a plan that will cut CO2
            emissions in the electricity sector by two-thirds and increase the
            renewable energy share of electricity demand to 70 per cent by 2030
            from its current 35 per cent particularly in Mexico.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Switzerland addresses greenwashing with sustainable investment label
          rules
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/jan5.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          According to the Swiss Federal Council's new proposal, Green financial
          products and funds in Swiss financial markets must align with specific
          sustainability goals and disclose the steps to achieve the goals. Much
          of the proposal is to prevent greenwashing and identify the
          sustainability characteristics affecting financial products or
          services.
        </p>
        <p>
          Most companies worldwide are concerned about greenwashing, where
          clients, investors and insured persons are misled about the
          sustainability or contribution to sustainability of financial products
          without clarity about legal and reputational risks in sustainable
          investments.
        </p>
        <p>
          Sustainable investments must pursue at least one of the following
          objectives or a combination of both - Alignment with one or more
          specific sustainability goals and contributions to achieving one or
          more specific sustainability goals.
        </p>
        <p>
          Switzerland has no legislative or regulatory requirements on
          transparency or compliance with specific sustainability criteria for
          financial services. However, the Swiss Financial Market Supervisory
          Authority (FINMA) recommends sustainability reporting as the best
          practice.
        </p>
        <p>
          To avoid misleading, The Asset Management Association Switzerland
          (AMAS), the Swiss Bankers Association (SBA) and Swiss Sustainable
          Finance (SSF) are taking essential steps in the form of
          recommendations, and voluntary self-regulation and request that the
          information should be public, easily accessible, transparent, and
          comparable, and service providers must describe the alignment with the
          sustainable investment process.
        </p>
        <p>
          Under the impact investment approach, the provider must also disclose
          the management process used to achieve the desired impact, the
          selection process for its target investments, coordination with other
          investors, the strategy for influencing the target company, escalation
          procedures and techniques for assessing the effectiveness of
          engagement.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3> Updapt Views: </h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Any financial product or service described as green should be able
            to assess the extent to which it pursues an environmental objective;
            there should also be regular, efficient, and appropriate reporting
            on the defined sustainability goals. Companies can also get their
            principles verified by an independent third party to ensure the
            credibility of the sustainability goals.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterFour;
