import React from "react";
import "../SCSS/Styles.scss";
import MetaTags from "react-meta-tags";

function BlogFifteen() {
  return (
    <>
      <div className="BlogFifteen-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Understanding the Greenhouse Gases - CO2</title>
          <meta
            name="description"
            content="Our planet is becoming warmer. The heat is melting ice caps, changing weather patterns, and disrupting the Earth's ecosystem.
          "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">
          Understanding the Greenhouse Gases - CO2
        </div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Deeksha</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Nov 16, 2021</div>
              <div className="blog-read-time">3 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog15.jpg").default}
            alt="blog-15"
          />
        </div>
        <p>
          Our planet is becoming warmer. The heat is melting ice caps, changing
          weather patterns, and disrupting the Earth's ecosystem. Despite their
          critical role in the greenhouse effect, we've been hearing how
          greenhouse gases, or GHGs, play a role in the current climate crisis.
          In this article, we'll explore GHGs and more.
        </p>

        <p>
          As you may know, greenhouse gases like CO2, water vapour, methane,
          nitrous oxide, etc., play a vital role in the greenhouse effect, a
          natural process for keeping the Earth warm. Earth receives energy in
          the form of sunlight which passes through its atmosphere. Some of this
          energy is reflected into space, while others are trapped by greenhouse
          gases, which keep the Earth warm enough to sustain life. So you see,
          the greenhouse effect isn't a bad thing nor the GHGs. Without the
          GHGs, the planet would be frozen and uninhabitable for life to thrive.
          So what changed? Why are GHGs blamed now for the current climate
          crisis?
        </p>

        <p>
          Since the 2000s, the amount of CO2 in the atmosphere has increased
          significantly, mainly due to the burning of fossil fuels. These
          human-caused emissions can remain in the atmosphere for long periods,
          trapping more heat and continuously warming the planet. CO2 especially
          has a significant impact on the global climate as it lingers for about
          300 to 1,000 years. But it's interesting to note that though the
          concentration of CO2 in the atmosphere is just 0.04% compared to other
          gases, it is still the primary contributor to global warming.
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                Why is CO2 responsible for global warming since it only makes up
                0.04% of the atmosphere?.
              </b>
            </p>
          </div>
        </div>

        <p>
          Oxygen, nitrogen, and argon make up the highest percentage (99%) of
          gases in the atmosphere, while carbon dioxide is just 0.04%. CO2 and
          other GHG gases have three or more atoms, and hence they can trap heat
          enormously. On the other hand, oxygen and nitrogen contain molecules
          with two atoms, and therefore they are incapable of trapping heat.
        </p>
        <p>
          Oxygen and nitrogen molecules keep colliding into CO2 and water
          vapour. As a result, the heat from these GHGs is shared between oxygen
          and nitrogen molecules. This effect causes the atmosphere to act as a
          blanket and keeps Earth warm. So when the concentration of GHGs in the
          atmosphere increases, this blanket starts to become thicker. Hence it
          becomes more difficult for the heat to rise to the top and escape.
        </p>
        <p>
          The current CO2 level in the atmosphere is 414 ppm (parts per
          million). According to the World Climate Research Programme (WCRP)
          study, if CO2 concentrations double from pre-industrial levels, the
          Earth's average global temperature will rise by 2 to 5 degrees
          Celsius, causing devastating effects. The oceans are also getting
          warmer, increasing the water vapour in the atmosphere, thus amplifying
          the greenhouse effect.
        </p>
        <p>
          Today carbon dioxide levels are higher than at any point in the past.
          We are adding more CO2 into the atmosphere than natural processes can
          remove. Plants, oceans, and soil are natural carbon sinks that can
          absorb and store CO2. But at the current rate of CO2 emission, it
          would take ages to clean up the mess. Hence cutting down GHG emissions
          and reducing our carbon footprint is the only way to avoid this global
          catastrophe.
        </p>
        {/* <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 2: China and India are the primary contributors to climate
            change, and both countries must take action.
          </b>
        </p> */}
        {/* <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-13(graph).svg")}
            alt="Blog-13(graph)"
          />
        </div>
        <p>
          Fact: China and India are two of the world's largest GHG emitters due
          to their heavy reliance on coal. At the same time, these countries are
          also the world's top solar energy harnessers. Due to historically high
          emissions by developed economies such as Europe and the United States,
          the developing economies are now being forced to reduce their
          emissions. Now, when it's time for these developing countries to
          progress, they're being forced to implement high-cost climate-change
          mitigation measures. This does not appear to be equitable, and wealthy
          economies must compensate these countries for combating climate
          change. Under the current climate crisis, all the nations will have to
          help each other collectively.
        </p>

        {/* <div className="sub-header">
          <div className="sub-header-content">
            So, is it realistic for electric vehicles to contribute
            significantly to sustainability in these challenging scenarios?
          </div> */}
        {/* </div> */}

        {/* <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 3: Renewables are expensive and can only work when it's windy
            and not cloudy.
          </b>
        </p>
        <p>
          Fact: Today, renewables are just as cheap as any other source of
          energy. According to the International Renewable Energy Agency
          (IRENA), renewable energy costs continue to reduce "substantially"
          year on year. A continuous supply of energy can be ensured by using a
          mix of powers such as solar, wind, natural gas, etc. Furthermore,
          rapid advances in battery capacity can help capture energy while it is
          available and store it for later use.
        </p>

        <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 4: Individuals cannot make any difference in the climate change
            cause.
          </b>
        </p>
        <p>
          Fact: There are a lot of things that individuals can do to fight
          climate change. Sustainable lifestyle practices by putting the 3 Rs-
          Reduce, Reuse, and Recycle into practice and choosing renewables over
          fossil fuels can help a great deal. Reducing meat intake by adopting a
          flexitarian diet (one mainly vegetarian but allows for the occasional
          meat dish) can also help reduce more than 40% carbon footprint.
          However, it wouldn't be right to say that only lifestyle changes can
          reduce emissions. Given the scope of the problem, a policy-driven
          strategy, in addition to individual initiatives, is required.
        </p> */}
        {/* <p>
          Climate change is real and is a global issue, and it will affect every
          country and individual if measures are not taken to address it. People
          and regulatory bodies working together can have a significant impact
          on people and the planet.
        </p> */}
        {/* <p>
          Only a coherent makeover, in which all elements are tied to a common
          goal, can help us economically and environmentally.
        </p> */}
        <p>
          Read{" "}
          <a
            href="https://www.updaptcsr.com/resource/blogs/Understanding-the-Greenhouse-Gases-CH4"
            target="_blank"
            style={{ color: "blue" }}
            rel="noopener noreferrer"
            // text-decoration-color="red"
          >
            {/* <style>{(color = "blue")}</style> */}
            Understanding the Greenhouse Gases - CH4
          </a>{" "}
          to know about CH4 and its role in global warming.{" "}
        </p>
      </div>
    </>
  );
}
export default BlogFifteen;
