import React from "react";

function Category({ tabSelected, handleTabClick, faqData }) {
  return (
    <div className="faq-category-wrapper">
      <ul className="faq-category-list">
        {faqData.map((faqData, index) => (
          <li
            key={faqData.name.toString()}
            className={
              tabSelected === faqData.id
                ? "faq-category-item active"
                : "faq-category-item"
            }
            onClick={() => {
              handleTabClick(faqData.id);
            }}
          >
            {/* <span className="icon-circle">
    <span className="ti-bar-chart"></span>
  </span> */}
            <h4 className="category-title">{faqData.name}</h4>
          </li>
        ))}

        {/*    <li className={
                tabSelected === 2 ? "faq-category-item active" : "faq-category-item"
              }
              onClick={() => {
                handleTabClick(2);
              }}>
        <span className="icon-circle">
          <span className="ti-bar-chart"></span>
        </span>
        <h4 className="category-title">Pricing</h4>
      </li>
      <li className={
                tabSelected === 3 ? "faq-category-item active" : "faq-category-item"
              }
              onClick={() => {
                handleTabClick(3);
              }}>
        <span className="icon-circle">
          <span className="ti-bar-chart"></span>
        </span>
        <h4 className="category-title">Data</h4>
      </li>
      <li className={
                tabSelected === 4 ? "faq-category-item active" : "faq-category-item"
              }
              onClick={() => {
                handleTabClick(4);
              }}>
        <span className="icon-circle">
          <span className="ti-bar-chart"></span>
        </span>
        <h4 className="category-title">Ganeral</h4>
      </li>
      <li className={
                tabSelected === 5 ? "faq-category-item active" : "faq-category-item"
              }
              onClick={() => {
                handleTabClick(5);
              }}>
        <span className="icon-circle">
          <span className="ti-bar-chart"></span>
        </span>
        <h4 className="category-title">ESG Expertise</h4>
      </li>
      <li className={
                tabSelected === 6 ? "faq-category-item active" : "faq-category-item"
              }
              onClick={() => {
                handleTabClick(6);
              }}>
        <span className="icon-circle">
          <span className="ti-bar-chart"></span>
        </span>
        <h4 className="category-title">Support</h4>
      </li>
      <li className={
                tabSelected === 7 ? "faq-category-item active" : "faq-category-item"
              }
              onClick={() => {
                handleTabClick(7);
              }}>
        <span className="icon-circle">
          <span className="ti-bar-chart"></span>
        </span>
        <h4 className="category-title">SaaS</h4>
      </li>*/}
      </ul>
    </div>
  );
}

export default Category;
