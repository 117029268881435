import React, { useState,useEffect } from "react";
import Header from "../components/Header";

function NavbarContainer() {
 // 1- Get the size of window 
 const windowInnerSize = [window.innerWidth, window.innerHeight];
    let [isMobileView, setMobileView ] = useState(false);
  
 // 2- Define the state variable windowSize and pass windowInnerSize as its initial value
 const [ windowSize, setWidowSize ] = useState(windowInnerSize);
   useEffect(()=>{

        const changeWindowSize = ()=>{
            setWidowSize([window.innerWidth, window.innerHeight]);
        }
        /* 3- add a 'resize' eventListener to window
           so whenever the size of window changes
        the state variable windowSize changes and the component  re-renders */
        window.addEventListener("resize", changeWindowSize);

        // 4- cleanup the 'resize' eventListener
        return ()=> window.removeEventListener('resize', changeWindowSize);
    }, []);


    
 
    const [width, height] = windowSize;


  return (
    <Header
    width={width}
    height={height} 
    isMobileView={isMobileView}
 
    setMobileView={setMobileView}
    ></Header>
  );
}

export default NavbarContainer;
