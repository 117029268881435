import React from "react";
import "../scss/styles.scss";
import MetaTags from "react-meta-tags";

function BlogEighteen() {
  return (
    <>
      <div className="BlogEighteen-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>
            Extreme weather events and how they are linked to Climate Change{" "}
          </title>
          <meta
            name="description"
            content="Climate change conversations have focused too little attention on methane (CH4) over the years.
          "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">
          Extreme weather events and how they are linked to Climate Change
        </div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Deeksha</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Dec 16, 2021</div>
              <div className="blog-read-time">2 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/b18.jpg").default}
            alt="blog-18"
          />
        </div>
        <p>
          Climate disasters are part of the natural cycle of the weather.
          Hurricanes, droughts, wildfires, and floods have always been a part of
          our lives. However, we are currently witnessing such events on a
          larger scale. Are these natural disasters simply due to bad weather?
          Or are they due to climate change?
        </p>
        <p>
          The terms "climate" and "weather" are not synonymous. Weather is how
          the atmosphere acts over short periods, such as days or weeks, whereas
          climate is how the atmosphere behaves over long periods, such as
          decades or centuries.
        </p>
        <p>
          Over the last decade, the number of extreme weather events like heat
          waves, wildfires, floods, droughts, etc., has increased exponentially.
          Scientists believe that there is a stronger link between the planet's
          warming and its changing weather patterns.
        </p>
        {/* <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                Who has to pay a carbon tax?
              </b>
            </p>
          </div>
        </div> */}
        <p>
          The earth's average surface temperature is rising, owing primarily to
          humans burning fossil fuels. As a result, greenhouse gases (GHGs) are
          released into the atmosphere, absorbing heat radiation and preventing
          it from escaping into space, causing the earth to gradually become
          hotter over time.
        </p>
        <p>
          A warmer atmosphere contains more water vapour. As a greenhouse gas,
          water vapour further accelerates the warming process. Consequently,
          the high temperatures melt the ice caps in the polar regions, thus
          reducing the snow cover and increasing the sea level. The exposed land
          and ocean due to the snowmelt absorbs more heat, causing more warming.
          So, as you can see, human activities are continuously warming our
          planet, setting a domino effect.
        </p>
        {/* <p>
          About 60% comes from anthropogenic sources. The agriculture sector is
          estimated to emit 40 to 50% of anthropogenic methane. Agricultural
          emissions come from farming operations, rice production, and livestock
          rearing. Animals such as cows and sheep emit methane and other gases
          during digestion, accounting for more than 70% of agricultural
          emissions.
        </p> */}
        {/* <p>
          Methane is the main component of natural gas. When natural gas is
          extracted and transported, it leaks into the atmosphere. Oil and gas
          account for 20 to 25% of anthropogenic methane, mainly as "fugitive
          methane" released by venting, leaks, and flaring.
        </p> */}
        {/* <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 2: China and India are the primary contributors to climate
            change, and both countries must take action.
          </b>
        </p> */}
        {/* <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-13(graph).svg")}
            alt="Blog-13(graph)"
          />
        </div> */}
        {/* <p>
          Landfills release methane from anaerobic decomposition (decomposition
          without oxygen or very little oxygen). Landfill food waste has a
          significant impact on the climate crisis. Decay can take years without
          oxygen, and landfills often contain very little oxygen flow and lack
          the air movement needed to break down food quickly since they are
          wrapped in plastic bags and surrounded by heaps of more plastic bags.
          Hence food waste in landfills keeps on producing methane. Similarly,
          the breakdown of organic material in wastewater streams can emit
          methane.
        </p>{" "} */}
        {/* <div className="sub-header">
        //   <div className="sub-header-content">
        //     So, is it realistic for electric vehicles to contribute
        //     significantly to sustainability in these challenging scenarios?
        //   </div>
        {/* </div> */}
        {/* <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 3: Renewables are expensive and can only work when it's windy
            and not cloudy.
          </b>
        </p>
        <p>
          Fact: Today, renewables are just as cheap as any other source of
          energy. According to the International Renewable Energy Agency
          (IRENA), renewable energy costs continue to reduce "substantially"
          year on year. A continuous supply of energy can be ensured by using a
          mix of powers such as solar, wind, natural gas, etc. Furthermore,
          rapid advances in battery capacity can help capture energy while it is
          available and store it for later use.
        </p>

        <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 4: Individuals cannot make any difference in the climate change
            cause.
          </b>
        </p>
        <p>
          Fact: There are a lot of things that individuals can do to fight
          climate change. Sustainable lifestyle practices by putting the 3 Rs-
          Reduce, Reuse, and Recycle into practice and choosing renewables over
          fossil fuels can help a great deal. Reducing meat intake by adopting a
          flexitarian diet (one mainly vegetarian but allows for the occasional
          meat dish) can also help reduce more than 40% carbon footprint.
          However, it wouldn't be right to say that only lifestyle changes can
          reduce emissions. Given the scope of the problem, a policy-driven
          strategy, in addition to individual initiatives, is required.
        </p> */}
        {/* <p>
          Climate change is real and is a global issue, and it will affect every
          country and individual if measures are not taken to address it. People
          and regulatory bodies working together can have a significant impact
          on people and the planet.
        </p> */}
        {/* <p>
          Only a coherent makeover, in which all elements are tied to a common
          goal, can help us economically and environmentally.
        </p> */}
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                So, the big question is, does climate change cause extreme
                weather events?
              </b>
            </p>
          </div>
        </div>
        <p>
          Well, the short answer is that extreme weather events are influenced
          by climate change, but it's not as straightforward as one thing
          causing the other. Any weather event could occur by chance; it could
          just result from natural weather variations. So it's better to ask
          about the influence of climate change on an extreme weather event.
        </p>
        {/* <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                So, where does that leave us?
              </b>
            </p>
          </div>
        </div> */}
        <p>
          Climate scientists have been using "Attribution Science" to understand
          the relationship between climate change and extreme weather events.
          Using computer models and mathematical calculations, scientists
          analyze climate data to quantify or measure the effects of climate
          change on weather.
        </p>
        <p>
          Think of it like a doctor studying a patient who has been a lifelong
          smoker, suffering from lung cancer. The doctor cannot say for sure
          that smoking caused lung cancer, but he can say that the patient's
          smoking increased his odds of developing lung cancer significantly.{" "}
        </p>
        <p>
          With the help of attribution science and accurate data and
          calculations, answers to similar questions on the effects of climate
          change can be derived. Computer models are also used to simulate how
          the earth's climate will change over time. Using these models,
          scientists have predicted various outcomes to connect extreme weather
          to human influence.
        </p>
        <p>
          So the bottom line is that as long as climate change continues,
          weather events will keep getting extreme, with millions losing their
          lives to rising sea levels, scorching heatwaves, and intense
          hurricanes. Poorer countries will suffer the most, unable to adapt to
          the changing climate, affecting their economies and food supplies.
        </p>
        <p>
          Adapting to the new normal of harsher weather and reducing the effects
          of climate change is a must. To better handle future disasters,
          countries will need to incorporate extreme weather response strategies
          and prioritize scientific studies on climate change.
        </p>
        <p></p>
      </div>
    </>
  );
}
export default BlogEighteen;
