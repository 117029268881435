import React from "react";
import Intro from "./Intro";
import MetaTags from "react-meta-tags";
import Resources from "./Resources";
function Thankyou({ blogsData, licencingDetails }) {
  return (
    <>
      <MetaTags>
        <title>Thank you</title>
        <meta
          name="description"
          content="For more information or any query please contact us. Registered contact details along with Management information.
          "
        />
        <meta property="og:title" content="UpdaptCSR | Contact US" />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>

      <Intro></Intro>
      <Resources blogsData={blogsData}></Resources>
    </>
  );
}

export default Thankyou;
