import React, { useState, useReducer, useEffect } from "react";
import Subscribe from "../components/Subscribe";
import axios from "axios";

const initialFormObj = {
  email: "",
};

function SubscribeContainer(props) {
  const [formObj, setFormObj] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialFormObj
  );
  const [successStatus, setSuccessStatus] = useState(false);
  const [errors, setErrors] = useState({});
  const [issubbmitted, setIsSubbmitted] = useState(false);
  const [isAlert, setisAlert] = useState(false);

  const handleTextOnChange = (event) => {
    const { name, value } = event.target;
    setFormObj({ [name]: value });
  };

  const validate = () => {
    let errors = {};
    const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

    if (!formObj.email) {
      errors.email = "Please enter email";
      setErrors(errors);
      return;
    } else {
      if (!isEmail.test(formObj.email)) {
        errors.email = "Please enter a valid email address";
        setErrors(errors);
        return;
      }
    }
    setErrors(errors);
  };

  const handleSendRequest = async (e) => {
    e.preventDefault();
    validate();
    setIsSubbmitted(true);
  };
  useEffect(() => {
    const postInfo = async () => {
      if (!Object.keys(errors).length && issubbmitted) {
        setisAlert(true);
        try {
          await axios.post("/newsletter/api/subscribe", {
            email: formObj.email,
          });

          setFormObj(initialFormObj);
          setisAlert(false);
          setSuccessStatus(true);
        } catch (error) {
          errors.warning = "Please write to us at info@updaptcsr.com";
          setErrors({ ...errors });

          throw error;
        }
      }
    };
    postInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setSuccessStatus(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [successStatus]);
  return (
    <Subscribe
      formObj={formObj}
      handleTextOnChange={handleTextOnChange}
      handleSendRequest={handleSendRequest}
      errors={errors}
      isAlert={isAlert}
      successStatus={successStatus}
      setSuccessStatus={setSuccessStatus}
      formName={props.formName}
    ></Subscribe>
  );
}

export default SubscribeContainer;
