import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";

function BlogTwentyFour() {
  return (
    <>
      <div className="BlogTwentyFour-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Benefits of Impact Investment</title>
          <meta
            name="description"
            content="Impact investment market supplies capital to address the world's most crucial challenges in sustainable farming, conventional energy, conservation of resources, and microfinance, along with inexpensive and available essential services"
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">Benefits of Impact Investment</div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Alice Patricia</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Dec 19, 2022</div>
              <div className="blog-read-time">2 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog24.jpg").default}
            alt="blog-24"
          />
        </div>
        {/* <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>Did you know?</b>
            </p>
          </div>
        </div> */}
        <p>
          <b>Benefits of Impact Investment</b>
        </p>
        <p>
          Impact investment market supplies capital to address the world's most
          crucial challenges in sustainable farming, conventional energy,
          conservation of resources, and microfinance, along with inexpensive
          and available essential services.
        </p>

        <p>
          According to the Global Impact Investing Network (GIIN), impact
          investments have the potential to generate social and environmental
          outcomes that are five to ten times more than those of traditional
          investments. It is an investment that intentionally seeks to create
          positive social and ecological results and financial returns that can
          be tailored to fit just about any budget.
        </p>

        <p>
          There are various options, including venture capital, private equity,
          debt, and real estate. And while some investments have a higher risk
          than others, there are also plenty of opportunities with lower risk to
          promote social and environmental solutions through investments that
          yield returns in the following ways:
        </p>

        <p>
          <b>Alignment of financial goals with values</b>
        </p>
        <p>
          Impact investing's convenience is vital if we aim to make a difference
          with every investor reaching their financial goals while upholding
          their values. Capital markets and listed equities can change to make a
          positive impact because they can boost impact investing to the general
          investing public.
        </p>

        <p>
          <b>Support companies that aim to make a positive impact</b>
        </p>
        <p>
          Finding innovative solutions and technologies that can allow humankind
          and the earth to sustain together has become a strong investment
          inflow for the supporters of these goals. Identifying and helping
          these companies is a principle of impact investing. They must be
          placed to grow faster and more profitably in the long term than the
          broader investment universe. The United Nations' 17 Sustainable
          Development Goals (SDGs) are also used as a roadmap for impact
          investing.
        </p>

        <p>
          <b>Gain market-rate returns</b>
        </p>
        <p>
          Doing good while doing well is achievable, and global challenges
          reflect opportunities worldwide. Records from the Global Impact
          Investing Network state that within the estimated $15 billion impact
          investment market, more than half produce market return rates. This
          implies how a solutionist to modern-day problems can be a financial
          winner of the future.
        </p>

        <p>
          <b>Maintain other portfolios</b>
        </p>
        <p>
          Other investments in the portfolio may benefit from impact investing.
          Social impact funds, on average, have lower volatility than comparable
          non-impact funds, according to a recent Morgan Stanley analysis that
          looked at over 10,000 equities mutual funds over the previous seven
          years.
        </p>

        <p>
          <b>Put the capital to use</b>
        </p>
        <p>
          An effective strategy to achieve personal or business social
          responsibility goals is investing in social, health, and environmental
          problems to provide a better return on investment than giving money
          away through grants or contributions.
        </p>

        <p>
          <b>Fulfil customer requirements</b>
        </p>
        <p>
          According to studies, client demand is one of the main reasons
          investment businesses are increasingly delivering impact investments.
          Providing impact investment services is crucial for companies to
          remain competitive in today's trust-based, socially responsible
          investing environment.
        </p>
        <hr
          style={{
            border: "none",
            height: "0.5px",
            background: "#c9c9c9",
            outline: "none",
          }}
        />
        <p></p>
        <p>
          Impact investment goes beyond just improving the environment, like
          helping achieve several objectives, including eradicating poverty and
          hunger, increasing access to high-quality healthcare and education,
          gender equality, justice, and peace-making.
        </p>
        <p>
          However, a simple activity that has a significant impact and enables
          investors to profit from prudent decisions while positively impacting
          the environment and society.
        </p>
      </div>
    </>
  );
}
export default BlogTwentyFour;
