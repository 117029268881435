import React from "react";
import Intro from "./Intro";
import Policy from "./Policy";
import "../SCSS/styles.scss";

function CookiePolicy() {
  return (
    <>
      <Intro></Intro>
      <Policy></Policy>
    </>
  );
}

export default CookiePolicy;
