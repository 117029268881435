import React from "react";
import PartnerBanner from "./PartnerBanner";
import PartnersProgram from "./PartnersProgram";
import PartnerLogoCarousel from "./PartnerLogoCarousel";

function Partner({ programs }) {
  return (
    <div>
      <PartnerBanner />
      <PartnersProgram programs={programs} />
      <PartnerLogoCarousel />
    </div>
  );
}

export default Partner;
