import React from "react";
import "../SCSS/styles.scss";

function BlogSix() {
  return (
    <>
      <div className="BlogSix-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <div className="blog-title">Sea level rise and it's impact</div>
        {/* <div className="update-date">Updated: May 4, 2020</div> */}
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">May 4, 2020</div>
              <div className="blog-read-time">4 min read</div>
            </div>
          </div>
        </div>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-7.jpg").default}
            alt="Blog-7"
          />
        </div>

        <p style={{ marginTop: "15px" }}>
          The oceans make up about 70% of our planet. Oceans sequester large
          amounts of carbon from the atmosphere, making them the largest natural
          carbon sinks. They are also vital in producing large amounts of oxygen
          as well as regulating the Earth’s climate. From marine pollution to
          sea level rise as well as coral bleaching, the health of our oceans is
          being threatened by a number of our activities like over-fishing,
          habitat destruction, unsustainable tourism, to name a few. A direct
          consequence of this are the changes in many oceanic processes like
          alterations in the water cycle, changes in the flow of currents to
          erratic changes in weather across the world.
        </p>

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog6.png")}
          />
        </div> */}
        {/* <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-7.svg")}
            alt="Blog-7"
          />
        </div> */}

        <p>
          Sea level rise is one of the biggest consequences of global warming
          induced climate change. It has been observed that the sea levels have
          been rising at an average of 1.7 mm since 1950. The Paris Climate
          Agreement focuses on reducing carbon emissions in order to limit rise
          of global average temperature to 1.5℃ to help avoid threats from
          rising seas and coastal inundation in the coming decades.
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            In the 2℃ warming scenario, global sea levels could rise up to 30 -
            60 cm by 2100. If the emission levels continue to rise at a rapid
            pace and surpass the 2 ℃ rise, then the rise in sea levels is
            projected to be between 61-100 cm.
          </div>
        </div>
        <p>
          Before we delve into the repercussions of sea level rise on our
          economies and businesses, let us look at what causes this phenomenon.
          The term sea level rise is generally referred to an increase in the
          water levels in the ocean.
        </p>
        <p>
          There are two major causes for the rise in sea level: the first one is
          the melting of land-based ice, such as icecaps and glaciers. With the
          increase of average global temperatures, the polar icecaps and
          glaciers are melting at an accelerated rate. The other cause for this
          is called Thermal Expansion. In this case, the burning of fossil fuels
          results in the emission of greenhouse gases into the atmosphere, the
          oceans absorb a large portion of this heat, resulting in the expansion
          of water, which in turn results in the rise of water levels.
        </p>
        <p>
          Sea level rise will impact all areas along the coasts but to different
          extents based on the region’s geography, development patterns, economy
          and social structure. Higher sea levels also cause changes in weather
          patterns resulting in increased likelihood of storm surges. More than
          700 million people live in coastal areas and are extremely vulnerable
          to rise in sea level and this could result in social disruption,
          migration, and displacement. Due to the submerging of many low-lying
          areas, people lose their homes and livelihoods; resulting in migration
          inward towards the land; this is an example of forced migration due to
          climate change.
        </p>
        <p>
          On an economic front, sea level rise is a major threat, owing to the
          loss of livelihood among coastal communities. In the case of urban
          areas, rising seas also threaten livelihood infrastructure such as
          regional industries. Big metropolises like New York City and Mumbai
          that are located along the coasts are densely populated, leaving them
          extremely vulnerable to drastic consequences. Jakarta, home to about
          10 million people is one of the fastest sinking cities in the world.
          The city is built on a large area of swampy land that is surrounded by
          the Java Sea and has 13 rivers running through it. Studies show that
          the city is sinking by an average of 1-15 cm a year and almost half
          the city is now below the sea level. Land subsidence caused due to
          excessive drilling of bore-wells combined with climate change induced
          sea level rise has created an existential threat to the city and its
          inhabitants, so much that the government is considering moving the
          capital to a less damaged land.
        </p>
        <p>
          Small island nations such as, Maldives in the Indian Ocean and
          Kiribati in the Pacific Ocean, known for their white sand beaches and
          coral reefs, are becoming an epitome of the consequences of sea level
          rise and climate change. They are witnessing coral bleaching, and low
          lying areas on the islands have started to submerge. As a consequence,
          people have to be relocated to higher ground. Sea water intrusion into
          freshwater sources takes place because aquifers on islands are
          shallow. There is also persistent flooding due to changes in tide
          generated by storm surges far away in the oceans.
        </p>
        <p>
          Most regions are wildly unprepared for this scenario due to inadequate
          infrastructure, lack of affordable housing and existing environmental
          hazards along the regions. Storm surges due to erratic changes in
          weather patterns impact critical services like power supply and
          hospitals along the coasts.
        </p>
        <div className="sub-header">
          <div className="sub-header-content">
            It is estimated that in the next few decades, adverse impacts of sea
            level rise will cost our economies up to $1 trillion per year.
          </div>
        </div>
        <p>
          Communities living along the coasts need to be made well aware of the
          situation and should try to take necessary steps before the risks
          unfurl. Combined efforts by policymakers and key stakeholders are
          required to come up with a robust response that supports coastal
          communities, ecosystems as well as the economy. Policies and practices
          in order to reduce emissions in line with the Paris Climate Agreement
          will ensure that the worst climate scenarios are avoided. Therefore,
          limiting the magnitude and pace of rising seas over time.
        </p>
      </div>
    </>
  );
}
export default BlogSix;
