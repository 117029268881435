import React from "react";
import Intro from "./Intro";
import FeatureOne from "./FeatureOne";
import FeatureTwo from "./FeatureTwo";
import FeatureThree from "./FeatureThree";
import FeatureFour from "./FeatureFour";
import MetaTags from "react-meta-tags";

function NetZero(props) {
  return (
    <>
      <MetaTags>
        <title>Emissions management software | Netzero | Updapt</title>
        <meta
          name="description"
          content="Track Your Carbon Footprint With Help of Updapt Emissions management software. Measure and Get Scope-1, Scope-2 and Scope-3 Emissions Reduction Strategies."
        />
        <meta
          property="og:title"
          content="Emissions management software | Netzero | Updapt"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <Intro></Intro>
      <FeatureOne></FeatureOne>
      <FeatureTwo />
      <FeatureThree />
      <FeatureFour />
    </>
  );
}

export default NetZero;
