import React, { useEffect, useState } from "react";
import "../SCSS/styles.css";
function Section4() {
  const [viewWidth, setViewWidth] = useState(window.innerWidth > 950);
  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("on", handleResize);
    window.onload = handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="section-container">
      <div className="section-4-inner-container">
        <div className="left-section">
          {viewWidth > 950 ? (
            <>
              <div className="dot-grid-4"></div>
              <div className="bg-img"></div>
              <img
                src={require("../../../../assets/images/ma-4-1.svg")}
                className="section4-1-img"
              />
              <img src={require("../../../../assets/images/ma-4.svg")} className="section4-img" />
            </>
          ) : (
            <div className="mobile-view">
              <img
                src={require("../../../../assets/images/image4.png").default}
                className="image4"
              />
            </div>
          )}
        </div>
        <div className="right-section">
          <h2 className="section4-heading">Visualize Impact with a Dynamic Materiality Matrix</h2>
          <p className="section4-description">
            Visualize results with a dynamic Materiality Matrix, featuring a heat map that helps to
            identify the material and non-material topics based on the responses collected using the
            assessment.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Section4;
