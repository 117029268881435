import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureThree() {
  return (
    <section className="analytics-feature-section">
      <div className="analytics-feature-container">
        <div className="analytics-feature-wrapper">
          <div className="analytics-feature-content-wrapper">
            <div className="analytics-feature-content">
              <h2 className="analytics-feature-title">
                {" "}
                Identify and Prioritize ESG Issues
              </h2>
              <p className="analytics-feature-description">
                Get in-depth insights on materiality assessment by obtaining
                results from the various stakeholders to arrive at the issues
                most material to your organization.
              </p>
            </div>
          </div>
          <div className="analytics-feature-image-wrapper">
            <div className="analytics-feature-image">
              <img src={assets.images.analyticsImage3} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="background-image-3">  
             <img src={assets.images.solutionGaphicElemet1} alt="updapt" />
             </div> */}
    </section>
  );
}

export default FeatureThree;
