import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterSix() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter March 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterSix-container">
        <MetaTags>
          <title>
            SEBI, capital market regulator in India has released a new set of
            proposals seeking public consultation in ESG disclosure, ESG ratings
            and ESG investing.
          </title>
          <meta
            name="description"
            content="Following the Business Responsibility and Sustainability Reporting (BRSR), SEBI has mandated the disclosure of ESG data by the top 1000 listed companies (based on market capitalization)."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>

        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          SEBI, capital market regulator in India has released a new set of
          proposals seeking public consultation in ESG disclosure, ESG ratings
          and ESG investing
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/newsletter-2023.png")
                  .default
              }
              alt="media-01"
            />
          </figure>
        </div>
        <p>
          Following the Business Responsibility and Sustainability Reporting
          (BRSR), the Securities and Exchange Board of India (SEBI) has mandated
          the disclosure of ESG data by the top 1000 listed companies (based on
          market capitalization). In FY 2021-22, more than 175 companies have
          voluntarily reported on the BRSR framework, and the same reporting is
          mandatory from this financial year (FY 2022-23) onwards.
        </p>
        <p>
          To improve credibility and limit the cost of compliance, the ESG
          Advisory Committee (EAC) has identified a few critical areas and
          developed a BRSR core framework presented in the consultation paper's
          first part. The EAC plans to deliberate a few leadership indicators to
          essential under the current BRSR framework and standardize
          sector-specific disclosures.
        </p>
        <p>
          Under the BRSR core:
          <ul>
            <li>
              The factors are relevant to the manufacturing and service sectors
              in the Indian Context.
            </li>
            <li>
              The Social parameters include job creation and inclusive
              development.
            </li>
            <li>
              The Governance parameters include openness/concentration of
              business, including related party transactions.
            </li>
            <li>
              KPIs include intensity ratios based on revenue and volume,
              including GHG emissions, water consumption, waste generation, etc.
            </li>
          </ul>
        </p>
        <p>
          The ESG disclosure for the supply chain is another primary KPI covered
          under leadership indicators. However, tracking and reporting on many
          ESG metrics can be difficult for small suppliers or unlisted firms.
          ESG disclosures per BSRS Core for the supply chain are requested to
          report on a comply-or-explain basis for the Top 250 companies by
          market capitalization in FY 2024-25.
        </p>
        <p>
          Corporate ESG ratings are generally based on self-reported data
          without third-party assurance. The committee has identified 15 ESG
          parameters with an Indian context to improve transparency in ESG
          ratings and mitigate conflict of interest between ESG rating
          providers. The committee requests that the public evaluate whether
          these parameters are appropriate and contextual to the Indian Domestic
          Markets for its ESG ratings.
        </p>
        <p>
          Finally, the paper discusses ESG investing and ESG funds based on
          consultations with the Association of Mutual Funds in India (AMFI) and
          SEBI. To monitor and evaluate disclosures related to the engagement
          undertaken by AMCs for ESG schemes, including periodic portfolio
          disclosures and maintenance of ESG policy related to investments.
        </p>
        <p>
          Schemes are also expected to reflect the nature and extent of the ESG
          focus related to investment objectives, investment strategy, positive
          screening, impact investing and sustainable goals.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Risks associated with climate change and ESG are significant from an
            economic and financial perspective. As a result of these proposals,
            the reliability of BRSR is expected to increase, greenwashing will
            be prevented, and the regulatory framework for ESG funds will be
            strengthened.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          President Biden's administration sets a new goal to develop an EV
          charging Network
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/march2023-2.jpg").default
            }
            alt="media-01"
          />
        </figure>

        <p>
          The Biden administration announced new standards to ensure that
          electric vehicle charging, funded by the Bipartisan Infrastructure
          law, is convenient, reliable and Made-in-America.
        </p>
        <p>
          The Bipartisan Infrastructure Law (BIL) is a generational investment
          in the nation's infrastructure. And one of the most significant
          investments in bridges, transits, passenger rails and the EV
          infrastructure in US history.
        </p>
        <p>
          The law also invests in lands and resources entrusted to the forest
          service, including many landscapes and watersheds. In the new
          announcement, the BIL invests $7.5 billion in EV charging, $10 billion
          in clean transportation, and over $7 billion in EV battery components,
          critical minerals, and materials.
        </p>
        <p>
          The use of electric vehicles is growing worldwide, and there are now
          more than three million EVs on the road in America and over 130,000
          public chargers. The new goal is to build a Made-in-America electric
          vehicle charging Network of 500,000 chargers on highways and
          communities by 2030.
        </p>
        <p>
          With the commitments from major companies like Tesla, General Motors,
          EVgo, Pilot, Hertz and bp, among others, expanding the public charging
          ports makes reaching the nation's goal of an EV charging network
          easier.
        </p>
        <p>
          To keep America on the path to net-zero emissions by 2050 and to
          create good-paying jobs along the way:
          <ul>
            <li>
              The transportation department, in partnership with the department
              of energy, has finalized new standards to make charging EVs
              convenient and reliable for all Americans, no matter what car they
              drive.
            </li>
            <li>
              Effective immediately, all the EV chargers that the BIL funds are
              required to be built in the United States, including the final
              assembly and all manufacturing processes for any iron or steel.
            </li>
            <li>
              The Federal Highway Administration (FHWA) outlines that by July
              2024, at least 55 per cent of the cost of all components must be
              manufactured domestically.
            </li>
            <li>
              The new Joint Office of Energy and Transportation is interested in
              issuing a funding opportunity for the ride and drive electric
              research and development program.
            </li>
            <li>
              The Department of Energy announced $7.4 million in funding for
              seven projects to develop innovative medium and heavy-duty EV
              charging and hydrogen corridor infrastructure plans.
            </li>
          </ul>
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            EV charging infrastructure continues to play a significant role in
            the EV transition. With the increasing number of EVs globally, there
            is high demand for publicly available EV charging stations. We can
            undoubtedly expect more consumers as the infrastructure improves.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Advertising Standards Authority releases new guidance for
          sustainability claims in the UK
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/march2023-3.jpg").default
            }
            alt="media-01"
          />
        </figure>

        <p>
          UK ad regulator, the Advertising Standards Authority (ASA), with its
          Committee of Advertising Practice (CAP), have announced new guidelines
          for advertisers making environmental sustainability claims to
          consumers.
        </p>
        <p>
          Regarding environmental claims, carbon neutrality and net zero are
          most commonly claimed but have significantly less meaning. Examples
          include the greenest stoves on earth, 100% renewable energy, 100%
          recycled materials, 100% plastic free and so on.
        </p>
        <p>
          To reform and standardize the definition of the claims, the CAP and
          BCAP have requested the below updates that are less likely to mislead.
          <ul>
            <li>
              Information explaining the basis for the claims must help
              consumers understand it and avoid using unqualified "carbon
              neutral", "net zero", or similar claims.
            </li>
            <li>
              Marketers should ensure that the information they include is
              accurate and not let the consumers/customers no longer assume that
              manufactured products generate no or few emissions.
            </li>
            <li>
              Claims based on future goals relating to reaching net zero or
              achieving carbon neutrality should be based on a verifiable
              strategy to deliver the same.
            </li>
            <li>
              The offset claims should comply with the usual standards of
              evidence for objective claims set out in the guidance with the
              offset scheme.
            </li>
          </ul>
        </p>
        <p>
          Sections 11 and 9 of the CAP and the BCAP code further state:
          <ul>
            <li>
              A high level of substantiation must support absolute claims.
              Comparative claims such as greener or friendlier can be justified,
              for example, if the advertised product provides a total
              environmental benefit over the marketer's previous product or
              competitor products and the basis of the comparison is explicit.
            </li>
            <li>
              Environmental claims must be based on the entire life cycle of the
              advertised product or service, unless the advertisement states
              otherwise, and must make clear the limits of the life cycle.
              Claims based on only part of an advertised product or service's
              life cycle must not mislead consumers about the product or
              service's total environmental impact.
            </li>
            <li>
              Advertisements must not suggest that their claims are universally
              accepted if a significant division of informed or scientific
              opinion exists.
            </li>
          </ul>
        </p>
        <p>
          Even from the social responsibility point of view marketing and
          advertisements must be prepared with a sense of responsibility to the
          audience and society and must not encourage behaviour grossly
          prejudicial to protect the environment.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Advertising plays a huge role in influencing consumer behaviour. The
            ads explicitly or implicitly must address the relationship between a
            product or service and the natural environment. However, with the
            current practices, environmental claims are likely to mislead if the
            claim's basis needs to be clarified. So, introducing stricter
            guidelines will also avoid greenwashing.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          The European Commission approved over $550 million for ArcelorMittal,
          a steel and mining company
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/march2023-4.jpg").default
            }
            alt="media-01"
          />
        </figure>
        <p>
          ArcelorMittal, a global steelmaking company, has committed to
          achieving net zero emissions by 2050. It will receive over $550
          million to advance efforts to decarbonize steelmaking as part of state
          aid projects in Germany and Spain.
        </p>
        <p>
          The state aid in Spain will support the construction of a renewable
          hydrogen-based direct reduced iron plan, which, together with a new
          electric arc furnace, will substitute the Gijon plant's blast furnace.
        </p>
        <p>
          Steel produced using electric arc furnace technology is expected to
          avoid the release of over 70 million tonnes of carbon dioxide with
          recycled ferrous scrap as the primary raw material. Over time, the
          plant will phase out natural gas in the gas mix using renewable
          hydrogen with syngas produced from waste and metallurgical gases.
        </p>
        <p>
          The state aid in Germany consists of a $55 million direct grant to
          support constructing and installing a hydrogen production
          demonstration facility. The project's main objective is to reduce
          ArcelorMittal's greenhouse gas emissions in its green steel production
          processes while avoiding the release of over 700,000 tonnes of carbon
          dioxide.
        </p>
        <p>
          Along with the State Aid, the commission will support several EU
          initiatives, including the EU hydrogen strategy, including plans to
          rapidly scale hydrogen production capabilities in Europe, as well as
          the European Green Deal, the EU strategy to achieve a climate-neutral
          economy by 2050, and RePowerEU, the EU plan to end its reliance on
          Russian fossil fuels.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Steelmaking releases more than 3 billion metric tons of carbon
            dioxide yearly, making it the industrial material with the most
            major climate impact. The steel industry must reduce its carbon
            footprint to help reduce global warming. Steel makers worldwide must
            invest in alternative metal-making technologies, such as hydrogen or
            electrochemistry, to reduce iron oxides to steel.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Global bond forecast for 2023 reports growth in GSSSB issuance
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/march2023-5.jpg").default
            }
            alt="media-01"
          />
        </figure>
        <p>
          According to research from one of the most extensive rating agencies,
          the global bond issuance market is estimated to have a modest growth
          of 2.5% in 2023.
        </p>
        <p>
          The Global Green, Social, Sustainable, and sustainability-linked bonds
          (GSSSB) are categorised as using proceeds instruments (green bonds,
          social bonds) and the sustainability-linked bonds, including the
          Environmental Finances Bond Database for non-financial corporates,
          sovereigns, financial institutions, and international public finance
          issuers.
        </p>
        <p>
          Key drivers and figures of the growth include regulation, policy, and
          transparency initiatives that may influence investor demand, the need
          for accelerating climate finance and calls for more investment in
          climate adaptation and resilience.
        </p>
        <p>
          Issuance for the GSSSB asset class is set to reach $900 billion - $1
          trillion in 2023 with 14% - 16% overall market share, while financial
          services will continue to gain market share with green bonds leading
          in the category.
        </p>
        <p>
          The non-financial corporate category is expected to see more
          diversification in sector, geography, and size of issuers. As per the
          Inflation Reduction Act in the US and Europe's REPowerEU, the energy
          sector could play a critical role in the growth with the unprecedented
          energy transition financing that needs to be fostered by government
          plans.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Regulators must ensure the credibility of sustainability-linked
            bonds and not restrict them to specific projects that might help
            other sectors and small issuers participate and stay consistent with
            the growth.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterSix;
