import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterFifteen() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter December 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-fifteen-container">
        <MetaTags>
          <title>
            Dutch Regulator AFM Urges Comprehensive Sustainability Impact
            Disclosures Across All Financial Products.
          </title>
          <meta
            name="description"
            content="The Dutch Authority for the Financial Markets (AFM) has urged comprehensive sustainability impact disclosures for all financial products, regardless of their sustainability features."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          Dutch Regulator AFM Urges Comprehensive Sustainability Impact
          Disclosures Across All Financial Products.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/dec2023-1.jpg").default}
            alt="dec2023-1"
          />
        </figure>
        <p>
          The Dutch Authority for the Financial Markets (AFM) has urged
          comprehensive sustainability impact disclosures for all financial
          products, regardless of their sustainability features. AFM's proposed
          reforms to the EU’s Sustainable Finance Disclosure Regulation (SFDR)
          seek to replace Article 8 and 9 classifications with a new sustainable
          investment labeling system. This move aims to enhance investor
          relevance and steer capital towards impactful sustainable investments.
        </p>
        <p>
          The AFM's stance aligns with the ongoing review of the SFDR framework
          by the European Commission, which is part of the EU's larger Action
          Plan on financing sustainable growth. SFDR mandates transparency for
          financial market participants, integrating sustainability risks and
          disclosing sustainability-related information for financial products.
        </p>
        <p>
          Addressing concerns that SFDR functions more as a labeling scheme than
          a disclosure regime, AFM suggests introducing sustainability labels
          categorizing products as ‘Transition,’ ‘Sustainable,’ and ‘Sustainable
          Impact.’ These categories target varying investor preferences,
          promoting transparency and minimum quality standards for each.
        </p>
        <p>
          AFM proposes universal sustainability disclosures, including impact
          indicators like GHG emissions, biodiversity, and human/labour rights,
          even for non-sustainable financial products. This initiative aims to
          empower investors to evaluate and compare the environmental and social
          impact of different financial products.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            AFM aims to enhance investor relevance and transparency, aligning
            with the European Commission's ongoing SFDR framework review for
            financing sustainable growth. The proposal introduces sustainability
            labels and universal impact disclosures, promoting informed
            investment decisions and environmental awareness.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          FCA Introduces New Rules to Boost Trust and Clarity in Sustainable
          Investing.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/dec2023-2.jpg").default
              }
              alt="dec2023-2"
            />
          </figure>
        </div>
        <p>
          The Financial Conduct Authority (FCA), the regulatory body overseeing
          financial services firms and markets in the UK, has officially
          introduced its latest set of guidelines, known as Sustainability
          Disclosure Requirements (SDR), specifically tailored for asset
          managers and investment labels. This comprehensive framework includes
          a series of measures designed to assist investors in evaluating the
          sustainability attributes of investment products and funds, thereby
          mitigating the risk of greenwashing.
        </p>
        <p>
          In response to the growing demand for investments with positive
          environmental and social impact, the FCA has taken a significant step
          to address this trend. The global Assets Under Management (AUM) in
          Environmental, Social, and Governance (ESG)-oriented funds are
          projected to reach $36 trillion by 2026, yet nearly 70% of investors
          express a lack of trust in the sustainability claims made by
          investment products.
        </p>
        <p>
          The newly introduced measures encompass an anti-greenwashing rule
          applicable to all communications by FCA-authorized firms regarding the
          environmental or social characteristics of financial products or
          services. This rule aims to ensure that claims made are 'fair, clear,
          and not misleading, and consistent with the sustainability profile of
          the product or service'.
        </p>
        <p>
          The guidelines also set standards for using sustainability-related
          terms in marketing and product names, emphasizing accuracy and
          consistency. The staged implementation, starting in 2024, aims to
          bolster consumer confidence and trust in the evolving landscape of
          sustainable investing.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The FCA's Sustainability Disclosure Requirements (SDR) address
            challenges by introducing an anti-greenwashing rule and four clear
            labels, ensuring transparent communication about environmental and
            social aspects. This structured framework empowers companies to
            showcase alignment with sustainability objectives, fostering trust
            and credibility in the evolving landscape of sustainable
            investments.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU Commits €400M to Propel Climate, Biodiversity, and Circular Economy
          Initiatives.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/dec2023-3.jpg").default}
            alt="dec2023-3"
          />
        </figure>
        <p>
          The European Commission has approved over €396 million in funding for
          climate and environmental sustainability projects. These initiatives,
          spanning nature and biodiversity, circular economy, quality of life,
          climate change mitigation and adaptation, and clean energy transition,
          will benefit from new investments under the LIFE Programme—an
          instrumental EU initiative for environmental and climate action.
        </p>
        <p>
          This substantial funding will bolster 171 projects across nearly all
          EU nations, with co-funding requirements expected to catalyze a total
          investment exceeding €720 million—a noteworthy 28.5% increase from the
          previous year. Allocations include €140 million for nature and
          biodiversity restoration, €94 million for circular economy
          enhancements, €65 million for climate change initiatives, and €97
          million for projects improving market and regulatory conditions for
          the clean energy transition.
        </p>
        <p>
          With a 60% increase in funding for the 2021–2027 period, totaling
          €5.43 billion, the European Commission underscores its commitment to
          transformative projects under the LIFE Programme. These ambitious
          projects serve as a testament to dedication in addressing climate
          change, pollution, and biodiversity loss, creating a tangible impact
          on the lives of EU citizens and preserving natural ecosystems.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The 171 funded projects promise innovation, heightened environmental
            efficiency, and improved regulatory conditions. Businesses can
            anticipate enhanced sustainability practices, increased market
            adaptability, and strengthened environmental governance, positioning
            them at the forefront of a progressive and resilient economy.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          US Government Unveils $6 Billion Climate Resilience Plan, Focusing on
          Grid Modernization and Community-Led Initiatives.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/dec2023-4.jpg").default}
            alt="dec2023-4"
          />
        </figure>
        <p>
          The US government has allocated over $6 billion to enhance climate
          resilience in the country, focusing on critical areas such as electric
          grid infrastructure, flood risk reduction, drought resistance, and
          community-level clean energy initiatives.
        </p>
        <p>
          Climate change mitigation has been a primary focus, evidenced by key
          legislation like the Inflation Reduction Act (IRA) and Bipartisan
          Infrastructure Law (BIL). These laws allocate nearly $500 billion
          towards climate-focused investments, covering carbon-free energy,
          manufacturing, clean technologies, and over $50 billion for climate
          resilience and adaptation.
        </p>
        <p>
          A substantial portion, nearly $4 billion, will fortify and modernize
          the US electric grid through the second round of the Grid Resilience
          and Innovation Partnerships (GRIP) Program under the Bipartisan
          Infrastructure Law. Other initiatives include $2 billion in funding
          from the Environmental Protection Agency for community-driven projects
          promoting clean energy and climate resilience.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The US government's $6 billion investment plan prioritizes climate
            resilience, focusing on grid modernization and community-led clean
            energy projects. This strategic initiative, backed by key
            legislation, aims to strengthen infrastructure, empower underserved
            communities, and position the US as a leader in addressing climate
            change challenges, and fostering economic growth and environmental
            justice.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Estonia Advances Green Energy Shift, Prioritizing Renewables Over Oil
          Shale.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/dec2023-5.jpg").default}
            alt="dec2023-5"
          />
        </figure>

        <p>
          Estonia is making rapid strides in its green energy transition,
          favoring renewables over oil shale. The nation's commitment to
          achieving a climate-neutral economy by 2050 underscores its unwavering
          dedication to this transformation. To meet these goals and ensure
          energy security, the International Energy Agency (IEA) stresses the
          need to accelerate the phase-out of oil shale in electricity
          generation and streamline processes for new renewable energy projects.
        </p>
        <p>
          In response to these challenges, Estonia has established a Ministry of
          Climate to oversee energy policies and enact climate legislation. The
          IEA report highlights the necessity for substantial investments
          aligned with institutional frameworks to involve the private sector
          and achieve Estonia's energy ambitions.
        </p>
        <p>
          Despite energy trade with Russia, Estonia is focusing on regional
          energy security and continuing its energy transition. The report
          underscores Estonia's progress in reducing reliance on oil shale and
          advocates aligning fiscal measures with long-term energy and climate
          goals, emphasizing a people-centric transition. The IEA commits to
          ongoing collaboration with Estonia.
        </p>
        <p>
          The report provides recommendations for emissions reduction across
          electricity, transportation, and all sectors, emphasizing the
          importance of clear policies, market regulations, and incentives for
          renewable energy deployment. In the transportation sector, the focus
          is on introducing price signals for energy-efficient and electric
          vehicles, updating energy duties, implementing carbon pricing, and
          gradually phasing out high-polluting transport through taxation.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            As one of Europe's few locations with processing facilities for rare
            earth metals, Estonia can become a crucial player in the critical
            minerals supply chain within Europe. The report underscores
            Estonia's leadership potential, serving as a model for secure clean
            energy transitions. It advocates best practices, encouraging
            knowledge sharing, capacity building, and political support for an
            enduring and economical clean energy future.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterFifteen;
