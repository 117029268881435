import React from "react";

function Accordian({ title, children }) {
  const [isOpen, setOpen] = React.useState(false);
  return (
 

   
      <div className="accordion-wrapper">
        <div className="card">
          <div className="card-header">
            <div className={`card-button ${isOpen ? "open" : "closed"}`}
              onClick={() => setOpen(!isOpen)}
            > {title}</div>
          </div>
       
          <div className={`card-description ${!isOpen ? "closed" : "show"}`} >
            <div className="card-body">
            <ul className="faq-qa-list">
            {children}
            </ul>
            </div>
          
          </div>
         
        </div>
      
      
      </div>
   
  
  );
}

export default Accordian;
