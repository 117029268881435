import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterTwelve() {
  return (
    <>
      <section className="about-banner-section" style={{ padding: "150px 0 62px" }}>
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}>
              Newsletter September 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterTwelve-container">
        <MetaTags>
          <title>
            EU Adopts Rules Requiring Product Emissions Reporting for New Import Carbon Tax.
          </title>
          <meta
            name="description"
            content="Singapore's Accounting and Corporate Regulatory Authority (ACRA) and Singapore Exchange Regulation (SGX RegCo) have proposed a new requirement for public and large private companies in the country to provide climate-related disclosures. "
          />
          <meta property="og:title" content="UpdaptCSR | Sustainability Platform" />
        </MetaTags>
        <div className="Newsletter-title1" style={{ paddingTop: "56px", fontSize: "32px" }}>
          EU Adopts Rules Requiring Product Emissions Reporting for New Import Carbon Tax.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/Newsletter/sep2023-1.jpg").default}
            alt="sep2023-1"
          />
        </figure>
        <p>
          The European Commission has introduced reporting rules for importers under the Carbon
          Border Adjustment Mechanism (CBAM), the EU's new carbon tax on imported goods, aiming to
          align carbon prices paid by European and non-EU producers. Starting in October 2023,
          companies must collect data on imported products' embedded emissions, with reporting due
          by January 2024. This regulation extends through CBAM's transitional phase until the end
          of 2025. CBAM aims to prevent "carbon leakage" where firms shift emissions-intensive
          production to nations with lax environmental policies.
        </p>
        <p>
          CBAM will equalize carbon prices for EU products under the Emissions Trading System with
          those from other countries. Importers will need CBAM certificates to bridge price
          disparities. The new rules mandate importers to report on emissions, including origin,
          production facility location, and emissions source coordinates. Data on direct and
          indirect emissions, such as electricity consumption during production, is required.
          Initially, CBAM targets sectors like iron and steel, cement, and electricity, with limited
          obligations during the transition phase.
        </p>
        <p>
          The EU Commission has issued guidance to assist importers and third-country producers in
          complying with these rules, along with developing IT tools and training resources. These
          measures signify the EU's commitment to climate action and global carbon pricing fairness.
          Stakeholders should monitor these developments closely.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The EU's adoption of the Carbon Border Adjustment Mechanism (CBAM) and import emissions
            reporting rules offers governments revenue opportunities and carbon emission reductions
            while providing businesses with market stability, incentives for green practices, and
            regulatory clarity. This proactive approach also positions the EU as a global leader in
            sustainability and promotes positive trade relations.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Canada's Commitment to Net Zero Electricity by 2035 Released.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/Newsletter/sep2023-2.jpg").default}
              alt="sep2023-2"
            />
          </figure>
        </div>
        <p>
          In a significant move towards achieving its climate goals, the Government of Canada has
          unveiled the proposed Clean Electricity Regulations. These regulations mark a vital step
          in decarbonizing the nation's power grid, aligning with its commitment to achieve a
          net-zero electricity grid by 2035. Canada's dedication to reducing emissions in the
          electricity sector reflects its promise at COP26 in 2021 and its collaboration with the G7
          to achieve predominantly decarbonized electricity sectors by 2035.
        </p>
        <p>
          These regulations, which were anticipated following Canada's commitment with the U.S. in
          2022, offer a technology-neutral approach. This approach empowers regional decision-makers
          to select the most suitable path for transitioning to a clean grid tailored to their
          circumstances. While stringent pollution standards for power generation are set, the
          regulations do not mandate specific energy technologies.
        </p>
        <p>
          By implementing these regulations, Canada anticipates reducing emissions from electricity
          generation by over 340 million tonnes by 2050. A net-zero grid not only supports climate
          action across the economy but also paves the way for clean technologies, reducing energy
          costs and dependency on volatile oil and gas prices.
        </p>
        <p>
          It is a giant leap towards a sustainable future, promoting clean air, good jobs, and a
          greener Canada. Additionally, the regulations allow for fossil fuel-based power generation
          in some circumstances, with strict emissions limits and requirements for carbon capture
          technology. Exemptions are also granted for remote communities with limited access to
          alternative power sources. These regulations lay the foundation for a cleaner, more
          sustainable energy future for Canada.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Canada's push for a net-zero electricity grid by 2035 offers significant economic
            benefits. It promotes job creation, lowers energy costs, attracts investments, reduces
            price volatility, and enhances business reputations. This commitment also encourages
            innovation and provides businesses with regulatory clarity, positioning them well in a
            growing global green market.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Investors Urged to ISSB's Creation of Reporting Standard for Human Rights and Human
          Capital.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/Newsletter/sep2023-3.jpg").default}
            alt="sep2023-3"
          />
        </figure>
        <p>
          In a resounding call for transparency and corporate responsibility, a group of investors,
          collectively managing over $1 trillion in assets, has united behind the International
          Sustainability Standards Board (ISSB), urging the prioritization of global reporting
          standards encompassing both human capital and human rights.
        </p>
        <p>
          The ISSB has initiated a Request for Information (RFI) to shape its two-year work plan.
          Notably, it is focusing on biodiversity ecosystems and human capital and human rights,
          alongside exploring sustainability information integration into financial reporting. This
          process reflects the ISSB's dedication to stakeholder input and evolving sustainability
          priorities.
        </p>
        <p>
          Responding to the ISSB's RFI, a consortium of 24 investors, led by ShareAction, has penned
          a compelling letter. They assert that the ISSB must prioritize research into both human
          capital and human rights disclosure standards.
        </p>
        <p>
          The group demand for comprehensive workforce data is at an all-time high. With the global
          workforce landscape in flux, businesses are grappling with rising resignation rates,
          layoffs, and the complexities of post-pandemic labor markets. Extensive research has
          underscored the link between social and governance scores and firm value, emphasizing the
          vital need for comprehensive social disclosures.
        </p>
        <p>
          The ISSB is well-positioned to tackle human capital and human rights issues with success.
          It has a head start and with various existing investor-focused disclosure frameworks like
          the WDI. With a compelling demand from both investors and businesses for comprehensive
          social standards, the ISSB has unique opportunity to create universally accepted standards
          for human capital and human rights reporting.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Embracing these reporting standards can position businesses and companies as responsible
            and forward-thinking entities, Comprehensive reporting enables businesses to identify
            and mitigate human rights risks more effectively.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          IFC and IDEI Collaborate to Advance Sustainable Construction in Mexico with $800 Million
          Green Bond.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/Newsletter/sep2023-4.jpg").default}
            alt="sep2023-4"
          />
        </figure>
        <p>
          IFC and IDEI have teamed up to advance sustainable construction in Mexico through an $800
          million green bond initiative. The collaboration between the International Finance
          Corporation (IFC), a member of the World Bank, and Internacional de Inversiones (IDEI), a
          specialist in sustainable real estate projects, marks a significant milestone.
        </p>
        <p>
          This green bond represents a transformative opportunity for Mexico's construction
          financing landscape. Its primary goal is to promote widespread adoption of certified
          sustainable construction practices and green financing standards while strengthening the
          nation's climate finance market. The bond will be issued in two series: 500 million pesos
          and 300 million pesos, with IFC providing a 50 percent guarantee to boost investor
          confidence.
        </p>
        <p>
          Addressing climate change is paramount, particularly in Mexico, where many non-urban areas
          rely on non-renewable energy sources. In addition to addressing the housing deficit
          exacerbated by the COVID-19 pandemic, the bond will play a crucial role in reducing
          greenhouse gas emissions in the country.
        </p>
        <p>
          The raised funds will support additional square meters in real estate projects certified
          by EDGE (Excellence in Design for Greater Efficiencies), an IFC initiative streamlining
          green certification for real estate developers, offering a swift and cost-effective
          solution.
        </p>
        <p>
          This partnership signifies a shared commitment to sustainability, promising a more
          environmentally-conscious future while addressing Mexico's housing and climate challenges,
          with the potential for substantial economic growth and development nationwide.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Mexico's green bond initiative promotes sustainable construction, reduces greenhouse gas
            emissions, addresses the housing deficit, and strengthens the climate finance market.
            This endeavor aligns with global sustainability goals, fosters economic growth, and
            improves living conditions for Mexican citizens.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          New Zealand and BlackRock Unite in $1.2 Billion Climate Infrastructure Fund to Drive
          Energy Transition.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/Newsletter/sep2023-5.jpg").default}
            alt="sep2023-5"
          />
        </figure>
        <p>
          New Zealand's government and investment titan BlackRock have unveiled a NZ$2 billion
          (USD$1.2 billion) fund dedicated to advancing the nation's climate infrastructure.
        </p>
        <p>
          This initiative's primary aim is to bolster New Zealand's ambitious pursuit of becoming a
          global renewable energy leader. Contributions to the fund will come from various sources,
          including private sector funds, state-owned enterprises within New Zealand, and
          superannuation funds.
        </p>
        <p>
          The fund's dynamic nature will empower businesses by providing them with access to
          significant capital resources, enabling investments in clean technology and vital
          infrastructure. These investments are poised to expedite New Zealand's ongoing mission to
          reduce emissions, with a particular focus on key areas such as battery storage, wind and
          solar energy generation, green hydrogen production, and expanding electric vehicle
          charging infrastructure.
        </p>
        <p>
          Key targets within the plan include achieving 50% of total final energy consumption from
          renewable sources by 2035 and crafting strategies for a net-zero economy by 2050.
          Additionally, New Zealand has set an audacious goal of achieving 100% renewable
          electricity by 2030.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Transitioning to renewable energy sources and energy-efficient practices can lead to
            long-term cost savings on operational expenses for the businesses, These steps provide a
            strategic advantage, enhance profitability, and position the country as a leader in
            sustainability and innovation.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTwelve;
