import React from "react";
import Solutions from "../components/Solutions";

function SolutionsContainer() {
  return (
    <>
      <Solutions></Solutions>
    </>
  );
}

export default SolutionsContainer;
