/** @format */

import React from "react";
import { NavLink } from "react-router-dom";

function Posts({ podcastData, handleClick }) {
  return (
    <section className="media-post-archive">
      <div className="container">
        <div className="row">
          {podcastData
            ?.map((data, index) => (
              <div className="media-post-grid" key={data.url}>
                <div
                  onClick={() => {
                    handleClick(`/resource/podcast/${data.url}`);
                  }}
                >
                  {/* <NavLink to={`/resource/podcast/${data.url}`}> */}
                  <article className="media-grid">
                    <div className="feature-image">
                      <img
                        alt={data.title}
                        src={
                          data?.image?.split(".")[1] !== "svg"
                            ? require(`../../../../assets/${data.image}`)
                                .default
                            : require(`../../../../assets/${data.image}`)
                        }
                      />
                    </div>

                    <div className="media-content">
                      <a
                        href={data.url}
                        target="_blank"
                        className="media-title"
                        rel="noopener noreferrer"
                      >
                        {data.title}
                      </a>
                      <p
                        className="media-description"
                        dangerouslySetInnerHTML={{ __html: data.synopsis }}
                      ></p>
                      <div className="podcast-badge">{data.type}</div>
                      <div className="podcast-meta-container">
                        <div className="podcast-meta">{data.date}</div>
                        <div className="podcast-meta">{data.duration}</div>
                      </div>
                    </div>
                  </article>
                  {/* </NavLink> */}
                </div>
              </div>
            ))
            .reverse()}
        </div>
      </div>
    </section>
  );
}

export default Posts;
