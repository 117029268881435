import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterFive() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter February 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterFive-container">
        <MetaTags>
          <title>
            The Federal Reserve Board (FRB) begins the pilot climate scenario
            analysis exercise
          </title>
          <meta
            name="description"
            content="The Federal Reserve Board is conducting a climate scenario analysis
            exercise and has released an instruction document to understand..."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>

        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          The Federal Reserve Board (FRB) begins the pilot climate scenario
          analysis exercise
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/feb2023-1.jpg").default
              }
              alt="media-01"
            />
          </figure>
        </div>
        <p>
          The Federal Reserve Board is conducting a climate scenario analysis
          exercise and has released an instruction document to understand
          climate risk-management practices and ensure that banks manage their
          material and financial risks from climate change.
        </p>
        <p>
          As per the document the six of the nation's largest banks, Bank of
          America, Citigroup, Goldman Sachs, JP Morgan Chase, Morgan Stanley,
          and Wells Fargo, will participate in the pilot CSA to analyze the
          impact of scenarios for both physical and transition risks related to
          climate change on specific assets in their portfolios.
        </p>
        <p>
          The Board will gather qualitative and quantitative information over
          the pilot, including details on governance and risk management
          practices, measurement methodologies, risk metrics, data challenges,
          and lessons learned.
        </p>

        <p>The Physical risk exercise is comprised of two components:</p>
        <p>
          A standard physical risk shock (a severe hurricane or series of
          hurricanes resulting in storm surges and flooding in the Northeast
          region of the US with varying levels of severity) applies to all
          participant banks. An idiosyncratic physical risk shock (a hazard
          event or series of events) in a geographic region that each
          participant bank chooses based on the materiality of its business
          model and credit exposures.
        </p>
        <p>
          The Transition Risk Module scenarios have been designed by the Network
          of Central Banks and Supervisors for Greening the Financial System
          (NGFS) and include:
        </p>
        <p>
          The current Policies Scenario assumes that all countries or regional
          groups have implemented policies and will not adopt any new ones. Net
          Zero 2050 Scenario, which assumes stringent policies to control GHG
          emissions, is introduced immediately, and carbon prices increase over
          the time horizon of the exercise.
        </p>
        <p>
          At the beginning of the exercise, the Board will publish details of
          the climate, economic and financial variables that make up the climate
          scenario narratives. For each scenario, the six banks will calculate
          traditional credit risks parameters, such as the probability of
          default, risk rating grade, and loss-given default for each loan.
        </p>
        <p>
          The six banks are the participants and are expected to submit
          completed data templates, supporting documentation and responses to
          qualitative questions to the FRB by July 31, 2023.
        </p>
        <p>
          The Board further makes it clear that the CSA is different from the
          Board's stress tests that assess the capital proceedings for a severe
          recession and says that CSA is exploratory and does not have capital
          consequences. The Board also anticipates being transparent and
          publishing insights from the pilot program that would identify
          potential risks and promote risk management practices.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The severe effects of climate change threaten the global economy. A
            climate scenario analysis is essential to clearly define the risks
            and opportunities that can help an organization assess the potential
            impacts and develop mitigation efforts. The specific study by FRB
            will bring in multiple insights to drive climate-focused decisions.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Delta launches Sustainable Skies Lab to accelerate a sustainable
          future of flight
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/feb2023-2.jpg")}
            alt="media-01"
          />
        </figure>

        <p>
          Delta, one of the primary and oldest airlines in the United States,
          was one of the first major airlines to announce a carbon neutrality
          goal by 2050.
        </p>
        <p>
          Delta's Sustainable Skies Lab is pairing the operational expertise of
          teams with innovators to inspire new ideas and spur industry
          advancement. It is guided by two pillars for its sustainability
          journey, which is to Eliminate Delta's climate impact from flying and
          embed sustainability in every other aspect of what Delta does, which
          further requires optimizing flying operations and partnering to
          accelerate clean fuel availability and deliver more sustainable travel
          experiences and business operations.
        </p>
        <p>
          To support the pillars mentioned above, the lab has identified a few
          forms:
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>
            Internal innovation culture:{" "}
          </span>
          A state of the lab to connect, align and showcase sustainability
          driven work from people in Flight Operations, Technical Operations,
          Inflight Services, Operations & Customer Centre and Fleet.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Innovative Partnerships: </span>
          The airline has announced sustainability partnerships and research
          collaborations with Airbus, Joby Aviation and MIT and is welcoming the
          next generation of newcomers to join and take advantage of Delta's
          global resources and achieve a sustainable travel experience.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>
            Engagement & Ideation Studio experiences:{" "}
          </span>
          Engagement studio is said to open and operate in phases, and the work
          is ongoing to develop Interactive digital walls, virtual reality
          stations, experiential mock-ups, and test new technology to advance
          Delta's sustainability work.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Testing environments: </span>
          Delta will continue testing concepts to reduce its environmental
          impact, and one of the tests is planned for 2023 to decrease aircraft
          drag and emissions.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Delta's commitment to sustainability is setting a path towards
            achieving the ambitious climate goal to include fleet, sustainable
            aviation fuel, operational improvements, and offsets. It is also
            creating a place to showcase advancements and movement for a
            sustainable future of flight and constantly investing towards
            advancing clean air technologies, reducing carbon emissions and
            waste and establishing new projects to mitigate the balance of
            emissions.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Trafigura announces to purchase of 50,000 tons of carbon removal
          credits
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/feb2023-3.jpg").default}
            alt="media-01"
          />
        </figure>

        <p>
          Trafigura Group, one of the world's leading independent commodity
          trading companies, committed to buying at least 50,000 carbon dioxide
          removal credits by the end of 2030 and developing its carbon trading
          desk.{" "}
        </p>
        <p>
          As part of an initiative from the First Movers Coalition (FMC), the
          group chose to announce their commitment during the World Economic
          Forum's Annual Meeting in Davos, Switzerland and announced the
          formation of a dedicated carbon trading team.
        </p>
        <p>
          Trafigura is also a founding member of the FMC, that was introduced
          during the COP26 and is a signatory to the FMC shipping and aluminium
          sector. Other companies joining the FMC's carbon removal commitment
          agree to contract for at least 50,000 tons or at least $25 million of
          durable and scalable net carbon dioxide removal by the end of 2030.{" "}
        </p>
        <p>
          There is also a set criterion that the removals must include solutions
          that can store captured carbon for a minimum of 1,000 years.
        </p>
        <p>
          The new commitment supports emerging carbon removal technologies,
          including Direct Air Capture and Storage (DACS), Bioenergy with Carbon
          Capture and Storage (BECCS) and Biomass Carbon Removal and Storage
          (BICRS). The announced commitment is, however, based on the
          availability of the right technology that aligns with the core pledges
          set out in the FMC.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Carbon credit is just an umbrella term for a permit or a certificate
            to manufacture a fixed amount of carbon emissions, while there is a
            mix of theories in the proposition for buying and selling the
            credits for different organizations and in other countries.
            Businesses must also focus on using sustainable development programs
            via renewable or zero carbon emission fuels to maintain the balance
            and get to net zero.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          UK to ban Single-Use plastic plates and cutlery
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/feb2023-4.jpg").default}
            alt="media-01"
          />
        </figure>

        <p>
          Under the new rules, plastic will be banned from sale in the UK from
          October 2023. Consumers will no longer be able to buy several
          single-use plastics like plates, trays, bowls, cutlery, balloon
          sticks, polystyrene cups and food containers from retailers,
          takeaways, and food vendors. However, the ban will not apply to
          plates, trays and bowls used as packaging for pre-packed food items.
        </p>
        <p>
          According to the UK's Department for Environment, Food & Rural
          Affairs, only 10% of 2.7 billion items of single-use cutlery and 720
          million single-use plates are recycled against their use in England.{" "}
        </p>
        <p>
          The UK government has a 25-year environmental plan, including a goal
          to eliminate all avoidable plastic waste by 2042. Following the
          program, the government banned microbeads in rinse-off personal care
          products, restricted the supply of single-use plastic straws, drink
          stirrers and cotton buds, and implemented a carrier bag charge.
        </p>
        <p>
          There is careful consideration to measure further the other commonly
          littered plastic items, including wet wipes, tobacco filters and
          sachets and explore the banning options following the call for
          evidence. A new research project will explore banning a few more items
          or making mandatory labelling on packaging to help consumers dispose
          of certain items correctly and study the impact of wet wipes on
          blockages in the sewage system to formulate policies accordingly.
        </p>
        <p>
          The government is further planning to address a few items through an
          Extended Producer Responsibility Scheme, a way to attract producers
          with incentives for using recycled materials.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            In the US, California restricted single-use Plastic in June,
            requiring all single-use packaging and single-use plastic to be
            recyclable or compostable by 2032. And the same has been announced
            in most countries, including India, that banned the use of plastic
            last year. Along with the restrictions, companies must also
            encourage the Deposit Return Scheme (DRS) to focus on the biggest
            offender in volume, plastic bottles.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Bosnian Dairy farm produces electricity from organic waste
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/feb2023-5.jpg").default}
            alt="media-01"
          />
        </figure>

        <p>
          In line with the government's efforts to gradually switch energy
          production to renewable sources, the project at Spreca farm, in the
          north of the coal-rich Balkan country, produces electricity from
          organic waste. The farm covers 800 hectares of its own and leased land
          and has a capacity for 2,000 high-yielding cattle.{" "}
        </p>
        <p>
          It is the first of its kind to turn the mixture of cow and chicken
          faeces, corn silage and other organic waste available at the farm into
          biogas. By doing so, the farm can produce more electricity in an hour
          than the average household consumes in a month. And the production is
          operating at 50%-60% capacity due to regulatory requirements but can
          produce 600 kilowatts per hour during the entire operation.
        </p>
        <p>
          Bosnia produces 60% of its electricity from coal-fired plants, and the
          rest is made from hydropower and is the sole electricity exporter,
          with 20% of its GDP for energy production. The Bosnaik-Croat
          Federation and the Serb Republic have pledged to increase the share of
          energy produced from renewable sources by 2030.
        </p>
        <p>
          The economic feasibility of a biogas plant depends on the monetary
          value of the entire range of plant outputs such as the electricity or
          the mechanical power, the heat co-generated by the combustion engine,
          the sanitation effect with COD and BOD (chemical and biological oxygen
          demand) reduction in the runoff of agro-industrial settings and the
          slurry used as fertiliser.
        </p>
        <p>
          The fermentation of the biogas creates thermal energy that is also
          used to heat some farm buildings, and the farm's long-term plan is to
          construct greenhouses heated by thermal energy.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Electricity production from biogas can be a very efficient method
            for producing electricity from a renewable energy source. Although
            the idea is a decade old, it still helps a company become
            self-reliant when high energy prices pose a risk to the agriculture
            sector. However, National framework conditions must be favourable
            and establish guaranteed feed-in price schemes to create
            opportunities for electricity generation from biogas.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterFive;
