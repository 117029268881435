import React from "react";

function Posts({ handleClick, mediaData }) {
  return (
    <section className="newsroom-post-archive">
      <div className="container">
        <div className="row">
          {mediaData
            .map(
              (data, index) =>
                (data.type === "newsroom" ||
                  data.type === "newsroom-media") && (
                  <div className="media-post-grid" key={data.url}>
                    <a
                      href={
                        data.type === "newsroom-media"
                          ? `./newsroom/${data.url}`
                          : data.url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <article className="media-grid">
                        <div className="feature-image">
                          <img
                            alt={data.title}
                            src={
                              data?.image?.split(".")[1] !== "svg"
                                ? require(`../../../../assets/${data.image}`)
                                    .default
                                : require(`../../../../assets/${data.image}`)
                            }
                          />
                        </div>

                        <div className="media-content">
                          <a
                            href={
                              data.type === "newsroom-media"
                                ? `./newsroom/${data.url}`
                                : data.url
                            }
                            target="_blank"
                            className="media-title"
                            rel="noopener noreferrer"
                          >
                            {data.title.replace("newsroom", "")}
                          </a>
                          <p
                            className="media-description"
                            dangerouslySetInnerHTML={{ __html: data.content }}
                          ></p>
                          <div className="website-name">
                            {data.websiteName}
                            <div className="window-open"></div>
                          </div>
                          <div className="media-meta">
                            <div>{data.date}</div>
                            <div>{data.status}</div>
                          </div>
                        </div>
                      </article>
                    </a>
                  </div>
                )
            )
            .reverse()}
        </div>
      </div>
    </section>
  );
}

export default Posts;
