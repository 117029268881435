import React from "react";
import assets from "../../../../shared/constants/assetVariables";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../SCSS/styles.css";

function PartnerLogoCarousel() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 2400,
    autoplay: true,
    autoplaySpeed: 0,
    mobileFirst: true,
    arrows: false,
    pauseOnHover: true,
    cssEase: "linear",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="partner-slider">
      <div className="partner-slide-track">
        <div className="partner-slide">
          <h1 className="partner-slider-header-txt">Our Partners</h1>
          <div className="partner-logo-scroll">
            <Slider {...settings}>
              <div className="img-width">
                <img src={assets.images.aarksee} alt="aarksee" />
              </div>
              <div className="img-width">
                <img src={assets.images.accenture} alt="accenture" />
              </div>
              <div className="img-width">
                <img src={assets.images.ais} alt="ais" />
              </div>
              <div className="img-width">
                <img
                  src={assets.images.blueOceanSystems.default}
                  alt="blueOceanSystems"
                />
              </div>
              <div className="img-width">
                <img src={assets.images.blueSky} alt="blueSky" />
              </div>
              <div className="img-width">
                <img src={assets.images.digeto} alt="digeto" />
              </div>
              <div className="img-width">
                <img src={assets.images.dmWorld.default} alt="dmWorld" />
              </div>
              <div className="img-width">
                <img src={assets.images.dnv} alt="dnv" />
              </div>
              <div className="img-width">
                <img src={assets.images.earthood} alt="earthood" />
              </div>
              <div className="img-width">
                <img
                  src={assets.images.empoweredAnalytics.default}
                  alt="empoweredAnalytics"
                />
              </div>
              <div className="img-width">
                <img
                  src={assets.images.grantThornton.default}
                  alt="grantThornton"
                />
              </div>
              <div className="img-width">
                <img src={assets.images.greenBeeEnergy} alt="greenBeeEnergy" />
              </div>
              <div className="img-width">
                <img
                  src={assets.images.greenConsults.default}
                  alt="greenConsults"
                />
              </div>
              <div className="img-width">
                <img src={assets.images.imss.default} alt="imss" />
              </div>
              <div className="img-width">
                <img src={assets.images.ifcom.default} alt="ifcom" />
              </div>
              <div className="img-width">
                <img src={assets.images.itqan.default} alt="itqan" />
              </div>
              <div className="img-width">
                <img src={assets.images.lithe} alt="lithe" />
              </div>
              <div className="img-width">
                <img src={assets.images.lybra} alt="lybra" />
              </div>
              <div className="img-width">
                <img src={assets.images.nethronix} alt="nethronix" />
              </div>
              <div className="img-width">
                <img src={assets.images.pccs.default} alt="pccs" />
              </div>
              <div className="img-width">
                <img src={assets.images.pgso.default} alt="pgso" />
              </div>
              <div className="img-width">
                <img src={assets.images.raqeeb} alt="raqeeb" />
              </div>
              <div className="img-width">
                <img src={assets.images.riskPro.default} alt="riskPro" />
              </div>
              <div className="img-width">
                <img src={assets.images.rsm.default} alt="rsm" />
              </div>
              <div className="img-width">
                <img src={assets.images.scribe} alt="scribe" />
              </div>

              <div className="img-width">
                <img src={assets.images.skyMax.default} alt="skyMax" />
              </div>
              <div className="img-width">
                <img src={assets.images.suraksha} alt="suraksha" />
              </div>
              <div className="img-width">
                <img src={assets.images.teknoSpec.default} alt="teknoSpec" />
              </div>
              <div className="img-width">
                <img src={assets.images.thinkPlus} alt="thinkPlus" />
              </div>
              <div className="img-width">
                <img src={assets.images.vDimension.default} alt="vDimension" />
              </div>
              <div className="img-width">
                <img src={assets.images.zest} alt="zest" />
              </div>
            </Slider>
          </div>
          <div className="partner-slider-button">
            <NavLink to="/company/partner-form" className="carousal-btn">
              Become Our Partner
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PartnerLogoCarousel;
