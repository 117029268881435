/** @format */

import React from "react";
import "../SCSS/styles.scss";
import Intro from "./Intro";
import Posts from "./Posts";
import MetaTags from "react-meta-tags";
import CaseStudyOne from "../CaseStudyOne";
import CaseStudyTwo from "../CaseStudyTwo";
import CaseStudyThree from "../CaseStudyThree";
import CaseStudyFour from "../CaseStudyFour";
import CaseStudyFive from "../CaseStudyFive";
import CaseStudySix from "../CaseStudySix//";
import CaseStudySeven from "../CaseStudySeven";
import CaseStudyNine from "../CaseStudyNine";
import CaseStudyEight from "../CaseStudyEight";

function CaseStudy({ newslettersData, caseStudyTitle, handleClick }) {
  const caseStudyHash = {
    "esg-reporting-for-cement-companies": <CaseStudyOne />,
    "esg-reporting-for-healthcare-sector": <CaseStudyTwo />,
    "esg-reporting-for-pharma-companies": <CaseStudyThree />,
    "esg-reporting-for-energy-sector": <CaseStudyFour />,
    "esg-reporting-for-real-estate-sector": <CaseStudyFive />,
    "esg-reporting-for-supplier-assessment": <CaseStudySix />,
    "esg-reporting-for-waste-management-companies": <CaseStudySeven />,
    "esg-reporting-for-fmcg-sector": <CaseStudyEight />,
    "esg-reporting-for-facility-management": <CaseStudyNine />,
  };

  const caseStudyComponent = caseStudyHash[caseStudyTitle] || null;
  return (
    <>
      {!caseStudyComponent ? (
        <>
          <MetaTags>
            <title>Case Study | Updapt</title>
            <meta
              name="description"
              content="Transforming Sustainability Challenges into Success Stories"
            />
            <meta property="og:title" content="Case Study | Updapt" />
            <meta
              property="og:image"
              content="%PUBLIC_URL%/static/media/banner.jpg"
            />
          </MetaTags>

          <Intro />

          <Posts newslettersData={newslettersData} handleClick={handleClick} />
        </>
      ) : (
        <>{caseStudyComponent}</>
      )}
    </>
  );
}
export default CaseStudy;
