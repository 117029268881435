import React from "react";
import FreeTrialForm from "./Form";
import FreeTrialFeature from "./Feature";
import assets from "../../../shared/constants/assetVariables";

function FormSection({
  mobile,
  setMobile,
  handleTextOnChange,
  handleSendRequest,
  formObj,
  setSelectedOption,
  selectedOption,
  options,
  customStyles,
}) {
  return (
    <section className="free-trial-form-section">
      <div className="container">
        <div className="row">
          <FreeTrialFeature />

          <FreeTrialForm
            formObj={formObj}
            handleTextOnChange={handleTextOnChange}
            handleSendRequest={handleSendRequest}
            mobile={mobile}
            setMobile={setMobile}
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            customStyles={customStyles}
          />
        </div>
      </div>
      <div className="background-image">
        <span className="dot-shape">
          {" "}
          <img src={assets.images.homeBannerImageDot} alt="updapt" />
        </span>
        <span className="back-image">
          {" "}
          <img src={assets.images.contactBottomImage} alt="updapt" />
        </span>
      </div>
    </section>
  );
}

export default FormSection;
