import React from "react";
import "../SCSS/styles.scss";
import Intro from "./Intro";
import Posts from "./Posts";
import MetaTags from "react-meta-tags";

function Newsroom({ mediaData }) {
  return (
    <>
      <MetaTags>
        <title>Newsroom | Updapt</title>
        <meta
          name="description"
          content="Keep up with the latest ESG news and climate change insights."
        />
        <meta property="og:title" content="Newsroom | Updapt" />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>

      <Intro />

      <Posts mediaData={mediaData} />
    </>
  );
}
export default Newsroom;
