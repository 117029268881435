import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";

function NewsletterTwentyFive() {
  return (
    <>
      <section className="about-banner-section" style={{ padding: "150px 0 62px" }}>
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}>
              Newsletter October 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-twenty-five-container">
        <MetaTags>
          <title>
            Hong Kong Proposes Global Standard Sustainability Reporting to Enhance Corporate
            Transparency.
          </title>
          <meta
            name="description"
            content="
             Hong Kong is taking a significant step towards standardizing sustainability reporting by aligning its new reporting framework with global standards."
          />
          <meta property="og:title" content="UpdaptCSR | Sustainability Platform" />
        </MetaTags>
        <div className="Newsletter-title1" style={{ paddingTop: "56px", fontSize: "32px" }}>
          Hong Kong Proposes Global Standard Sustainability Reporting to Enhance Corporate
          Transparency.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/october2024-1.jpeg").default}
            alt="october2024-1"
          />
        </figure>
        <p>
          Hong Kong is taking a significant step towards standardizing sustainability reporting by
          aligning its new reporting framework with global standards. The Hong Kong Institute of
          Certified Public Accountants (HKICPA) has proposed two key standards, HKFRS S1 and S2,
          mirroring the International Sustainability Standards Board (ISSB) under IFRS guidelines.
          This move aims to provide more consistent and comparable ESG data, offering better
          insights for investors into sustainability risks and opportunities companies face.
        </p>
        <p>
          The introduction of HKFRS S1 and S2 follows a detailed stakeholder consultation and
          technical feasibility study. These standards will primarily apply to publicly accountable
          entities, including banks, insurance firms, and large corporations. By aligning with the
          International Sustainability Standards Board (ISSB) framework, the HKICPA aims to bolster
          investor confidence and position Hong Kong as a leading hub for sustainable finance in the
          region.
        </p>
        <p>
          As part of this initiative, the government plans to release a comprehensive roadmap, which
          will outline the future of sustainability reporting for businesses across various sectors.
          The roadmap will serve as a guide for companies to adapt to these evolving requirements,
          emphasizing the importance of integrating ESG factors into corporate strategies.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Adopting HKFRS S1 and S2 will provide businesses with a comprehensive framework to
            effectively manage sustainability risks and opportunities. Companies will be able to
            benchmark their ESG performance globally, which will enhance their operational
            resilience and competitiveness. Aligning with international standards will open access
            to global markets and investment opportunities, particularly for businesses looking to
            expand beyond Hong Kong.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EPA Introduces New Standards to Strengthen Sustainable Federal Procurement.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/images/october2024-2.jpeg").default}
              alt="october2024-2"
            />
          </figure>
        </div>
        <p>
          The U.S. Environmental Protection Agency (EPA) has introduced updates to its
          Recommendations of Specifications, Standards, and Ecolabels for federal procurement. These
          updates aim to guide government buyers in selecting more sustainable, climate-friendly
          products. The proposal adds 14 new standards across healthcare, laboratories, and clothing
          categories, and expands guidelines for food service ware to include reusable and
          compostable items. The initiative helps reduce environmental impact, including the use of
          harmful chemicals like PFAS.
        </p>
        <p>
          The EPA rigorously assessed the environmental sustainability of third-party standards and
          ecolabels through its Framework for the Assessment of Environmental Performance Standards
          and Ecolabels, ensuring that they meet high environmental performance standards. By
          enhancing federal purchasing strategies, the EPA seeks to streamline the identification of
          eco-friendly products in various sectors. The move aligns with broader sustainability
          goals, which aim to cut federal procurement-related emissions and promote products that
          conserve energy and water. These recommendations will help federal buyers make informed
          decisions, reducing pollution and fostering sustainable practices.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The federal government, as the largest purchaser of goods and services with annual
            spending exceeding $700 billion, is set to create widespread ripple effects across
            industries through the adoption of new EPA standards. These changes will require
            businesses that supply the government to meet higher environmental criteria, likely
            driving innovation and encouraging the adoption of greener production methods. As
            federal agencies prioritize sustainable, eco-friendly products, companies will need to
            align with these standards to stay competitive, leading to more environmentally
            responsible practices across supply chains and industry operations.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Australia Mandates Climate Disclosures to Boost Corporate Transparency.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/october2024-3.jpeg").default}
            alt="october2024-3"
          />
        </figure>
        <p>
          Australia has strengthened corporate responsibility with the passing of the Treasury Laws
          Amendment (Financial Market Infrastructure and Other Measures) Bill 2024. The legislation
          mandates that large businesses and financial institutions include climate-related
          financial disclosures in their annual reports starting in 2025. The Bill has passed both
          the Senate and the House of Representatives.
        </p>
        <p>
          A major amendment by the Senate requires companies to conduct scenario analyses in their
          climate statements. Businesses must now assess how their strategies would hold up under
          two scenarios: a temperature rise exceeding 2°C and one limited to 1.5°C. This helps
          companies prepare for both physical climate risks and the shift to a low-carbon economy.
        </p>
        <p>
          The reporting requirements will be phased in, starting with large entities in 2025.
          Mid-sized firms will have until mid-2026, with smaller companies following by mid-2027.
          This phased approach ensures businesses can adjust while prioritizing corporate climate
          accountability.
        </p>
        <p>
          Companies will need to report on governance, risk management, strategy, and emissions.
          From the second year, they must also disclose Scope 3 emissions. These reports will
          highlight the financial impacts of climate risks on revenue, cash flow, and asset values.
        </p>
        <p>
          The reports will be subject to audits, with standards set by the Auditing and Assurance
          Standards Board (AUASB). Full audit requirements will be phased in by 2030 to ensure the
          reliability of these climate disclosures.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            To prepare for the new climate disclosure law, companies should start by conducting a
            thorough gap analysis of current sustainability practices, ensuring alignment with
            upcoming regulations. Developing robust data collection systems for Scope 1, 2, and 3
            emissions, integrating scenario analysis into strategic planning, and setting clear
            governance structures are critical steps for compliance.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU introduces flight emissions labels to drive transparency in aviation.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/october2024-4.jpeg").default}
            alt="october2024-4"
          />
        </figure>
        <p>
          The European Commission has introduced a Flight Emissions Label (FEL) to improve
          transparency on the environmental impact of air travel, a sector responsible for
          approximately 2-3% of global greenhouse gas (GHG) emissions. As the aviation industry
          expands, its emissions are projected to rise significantly, potentially contributing up to
          22% of global emissions by 2050 if left unregulated. The European Union (EU) aims to
          address this issue by providing passengers with clear carbon footprint data when booking
          flights​.
        </p>
        <p>
          The FEL will use a standardized methodology to calculate CO₂ emissions based on factors
          like aircraft type, average passenger load, and fuel type, including sustainable aviation
          fuels (SAF). This standardization addresses the current problem of inconsistent emissions
          data across airlines, which makes it difficult for passengers to compare flights based on
          environmental impact. The data will be displayed alongside other flight information,
          empowering consumers to choose lower-emission options.
        </p>
        <p>
          As part of the broader EU aviation decarbonization strategy, which includes SAF mandates
          and carbon pricing mechanisms, the FEL is voluntary but expected to gain traction. By
          2025, passengers will be able to compare emissions across airlines on a dedicated website,
          which could significantly influence travel choices​. This initiative complements other EU
          climate goals, such as achieving a 55% reduction in GHG emissions by 2030.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The European Commission, through its labeling initiative, seeks to promote greater
            transparency and authenticity in the environmental claims made by airlines. By ensuring
            accuracy in sustainability efforts, the initiative encourages airlines to adopt more
            fuel-efficient aircraft and embrace sustainable aviation fuels (SAF). This move is
            expected to drive healthy competition across the industry, with airlines striving to
            enhance their environmental performance.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          ESG Emerges as a Key Driver of Sustainable Investing for Asset Owners.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/october2024-5.jpeg").default}
            alt="october2024-5"
          />
        </figure>

        <p>
          A recent global survey shows that ESG (Environmental, Social, and Governance) factors have
          become increasingly critical to asset owners, with over two-thirds acknowledging their
          heightened importance. This shift is seen in how investors are applying ESG principles to
          more of their portfolios, addressing climate transition readiness, labor practices, and
          governance reforms as key concerns in investment decisions. The integration of ESG is
          steadily growing as asset owners seek to align with sustainability goals.
        </p>
        <p>
          While ESG data has improved, asset owners face challenges such as the lack of standardized
          metrics and concerns about the impact on returns. For instance, nearly half of those
          surveyed see the need for more accurate data, particularly in North America. Additionally,
          asset owners are increasingly relying on internal resources for managing ESG initiatives,
          often supported by dedicated ESG teams. Active ownership, including direct engagement with
          companies, is also seen as a crucial strategy for driving ESG initiatives forward, with
          78% of asset owners viewing this approach as highly effective. This trend is particularly
          strong in APAC, where 84% of respondents favor active ownership methods.
        </p>
        <p>
          On a broader level, technological advancements are poised to further enhance ESG
          integration. Many asset owners are optimistic about the role of artificial intelligence
          (AI) in improving data collection, portfolio construction, and ESG analysis. More than 80%
          of respondents expressed hope for increased AI adoption to streamline ESG processes,
          signaling a shift towards more tech-driven approaches to sustainable investing. Meanwhile,
          regulations remain a double-edged sword: while 53% see them as helpful, particularly in
          improving company and fund reporting, others cite burdensome bureaucracy as a hindrance to
          effective ESG implementation.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            ESG has firmly established itself as a core element of investment strategies, extending
            beyond regulatory or ethical obligations to become a key driver of risk management and
            long-term value creation. Asset owners are increasingly recognizing the broader impact
            of their investments, aligning their financial objectives with global sustainability
            goals. By doing so, they are positioning themselves as leaders in driving the transition
            to a more sustainable and responsible future.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTwentyFive;
