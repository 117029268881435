import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterEighteen() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter March 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-eighteen-container">
        <MetaTags>
          <title>
            Japan Introduces Climate Transition Bond to Support Sustainability
            Goals.
          </title>
          <meta
            name="description"
            content="The Japanese government recently announced the successful issuance of its inaugural Climate Transition Bond, raising JPY800 billion (USD$5.3 billion)."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          Japan Introduces Climate Transition Bond to Support Sustainability
          Goals.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/mar2024-1.jpg").default}
            alt="mar2024-1"
          />
        </figure>
        <p>
          The Japanese government recently announced the successful issuance of
          its inaugural Climate Transition Bond, raising JPY800 billion (USD$5.3
          billion). This initiative is part of a broader effort to secure JPY20
          trillion (USD$133 billion) over the next decade, aiming to propel the
          nation toward a carbon-neutral future by 2050. The focus is on
          decarbonizing sectors that are traditionally challenging to adapt,
          aligning with Japan's ambitious goal to reduce greenhouse gas
          emissions by 46% by 2030.
        </p>
        <p>
          To achieve these targets, Japan plans to increase its renewable energy
          share to 36%-38% by 2030, up from less than 20%. An estimated JPY150
          trillion (USD 1 trillion) in investments is required over the next
          decade, with the transition bond proceeds allocated to projects that
          reduce emissions, enhance industrial competitiveness, and support
          economic growth, particularly in hard-to-abate sectors.
        </p>
        <p>
          The investments will follow the government's 'GX (Green
          Transformation) Promotion Strategy', focusing on renewable energy,
          energy efficiency, clean fuel introduction, and infrastructure
          improvements.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            By mobilizing substantial financial resources towards decarbonizing
            hard-to-abate sectors and enhancing renewable energy use, Japan sets
            a global example in integrating financial strategies with ambitious
            climate change mitigation goals.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          China's Stock Exchanges Set New ESG Reporting Standards for Listed
          Companies.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/mar2024-2.jpg").default
              }
              alt="mar2024-2"
            />
          </figure>
        </div>
        <p>
          China's major stock exchanges, including the Shanghai Stock Exchange
          (SSE), Shenzhen Stock Exchange (SZSE), and Beijing Stock Exchange
          (BSE), have introduced new guidelines for sustainability reporting for
          listed companies. This important step requires select larger and
          dual-listed companies to start sharing detailed information on various
          ESG matters by 2026. This initiative aligns China with other countries
          that are also updating their sustainability reporting standards, such
          as the EU, the U.S., Australia, Brazil, Singapore, and the UK.
        </p>
        <p>
          The new rules focus on four main areas: governance, strategy, impact,
          risk, and opportunity management, along with specific goals and
          indicators. This comprehensive approach aims to give investors and
          stakeholders a clearer picture of how companies are addressing and
          managing sustainability challenges. Topics covered include climate
          change, biodiversity, energy use, supply chain security, and
          anti-corruption efforts, among others. The guidelines also emphasize
          the importance of reporting on indirect greenhouse gas emissions,
          which can often be challenging to track.
        </p>
        <p>
          Over 450 large companies are entitled to these mandatory reporting
          requirements, representing about half of the market's value. While the
          BSE encourages smaller companies to adopt these practices voluntarily,
          the primary focus is on larger entities. This step towards more
          transparent sustainability reporting reflects China's commitment to
          responsible business practices and environmental stewardship.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            China's stock exchanges mandating sustainability reporting marks a
            pivotal move towards aligning with global ESG standards, enhancing
            investor confidence, and promoting responsible corporate behavior.
            Companies must now undertake a strategic approach to comply,
            involving understanding guidelines, assessing capabilities, and
            developing a robust reporting framework. This initiative not only
            fosters transparency but also drives operational efficiency and
            long-term sustainable growth.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Singapore Implements Mandatory Climate Reporting in Pursuit of a
          Sustainable Future Commencing in 2025.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/mar2024-3.jpg").default}
            alt="mar2024-3"
          />
        </figure>
        <p>
          Singapore is gearing up for a significant shift towards environmental
          transparency, setting the stage for mandatory climate reporting
          starting in 2025. This policy mandates that both listed and
          significant non-listed companies begin disclosing their climate
          impacts, adhering to the standards set by the International
          Sustainability Standards Board (ISSB).
        </p>
        <p>
          The implementation strategy is methodical and phased. Initially,
          listed companies will step into the action in 2025, paving the way for
          their large non-listed counterparts, which are defined by their
          considerable revenue and asset base, to follow suit in 2027. This
          staggered approach ensures a smooth transition for businesses towards
          full compliance with the new regulations.
        </p>
        <p>
          The reporting framework is comprehensive, covering direct emissions
          (Scope 1 and 2) in the initial phase, before expanding to encompass
          value chain emissions (Scope 3) by 2026 for listed companies, with
          non-listed companies following a similar but slightly delayed
          timeline. This gradual expansion underscores the government's intent
          to foster a deep-rooted understanding and management of environmental
          impact across all business operations.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            To prepare for Singapore's mandatory climate reporting in 2025,
            companies should first understand the ISSB standards and their
            specific reporting obligations. Assessing current data collection
            and reporting processes is crucial to identifying gaps and areas
            needing improvement. Early preparation will ensure a smooth
            transition.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU Commits to Comprehensive Environmental Restoration by 2050.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/mar2024-4.jpg").default}
            alt="mar2024-4"
          />
        </figure>
        <p>
          The European Union has aimed to rehabilitate its natural environments,
          setting a goal to restore at least 20% of its land and sea areas by
          2030, and extending the initiative to encompass all ecosystems
          requiring restoration by 2050. This legislative move is in direct
          response to the concerning state of over 80% of European habitats
          currently classified as being in poor condition. The law outlines
          progressive targets for EU countries to rejuvenate 30% of these
          habitats by 2030, escalating to 60% by 2040 and reaching 90% by 2050,
          underscoring a major push towards the EU's overarching climate and
          biodiversity objectives while also aiming to bolster food security.
        </p>
        <p>
          Particular attention is given to agricultural ecosystems within the
          law, with detailed provisions for the restoration of peatlands and the
          enhancement of biodiversity. This includes the implementation of
          specific indicators to gauge improvements in agricultural landscapes,
          such as the grassland butterfly index and the stock of organic carbon
          in cropland mineral soils. An emergency brake mechanism is also
          integrated, allowing for the temporary suspension of targets under
          exceptional circumstances that could jeopardize essential food
          production for EU consumption, demonstrating a balanced approach to
          environmental restoration and agricultural needs.
        </p>
        <p>
          Moreover, the legislation mandates significant actions beyond
          agriculture, demanding a positive trend in forest ecosystems and the
          planting of an additional three billion trees across the EU. It also
          sets forth objectives to restore at least 25,000 km of rivers to a
          free-flowing state and to prevent any net loss in urban green spaces
          and tree canopy cover. This multifaceted strategy not only signifies
          the EU's transition from conserving to actively restoring nature but
          also reflects a commitment to fulfilling international environmental
          obligations, with a clear acknowledgment of the scientific community's
          role and the youth's advocacy for a sustainable planet.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The EU's nature restoration law marks a significant stride in ESG
            efforts, emphasizing environmental restoration, social well-being,
            and strong governance. It sets ambitious targets for ecosystem
            revival, promising substantial social benefits and fostering
            sustainable investment opportunities. This legislation underscores
            the EU's commitment to sustainability, resilience, and a balanced
            approach to environmental and economic challenges.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Malaysia Advances Towards Global Sustainability Practices with
          Proposed Adoption of ISSB Reporting Standards.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/mar2024-5.jpg").default}
            alt="mar2024-5"
          />
        </figure>

        <p>
          Malaysia's Securities Commission is moving forward with a proposal for
          the adoption of the International Sustainability Standards Board's
          (ISSB) guidelines for mandatory ESG reporting for its publicly listed
          and large companies. This initiative, guided by the Advisory Committee
          on Sustainability Reporting (ACSR), aims to integrate these
          international standards into Malaysia's own National Sustainability
          Reporting Framework (NSRF). This integration seeks to bolster the
          framework with added support for assurance processes and capacity
          building, marking a strategic move towards enhanced corporate
          transparency.
        </p>
        <p>
          The push towards adopting the ISSB's guidelines is part of a broader,
          global trend toward standardized sustainability disclosures. This
          trend is driven by the increasing demand from investors, businesses,
          and regulatory bodies for clear and consistent reporting on how
          sustainability issues impact corporate performance. With the ISSB's
          standards rapidly gaining acceptance around the world, Malaysia's move
          to align with these guidelines is a critical step in maintaining its
          competitive edge in the global market.
        </p>
        <p>
          The consultation process launched by Malaysia's Securities Commission
          is designed to solicit feedback on various aspects of implementing
          these standards, including their scope, timing, and the assurance
          process. This process is crucial for understanding the practicalities
          and challenges of adopting these standards within the Malaysian
          context. By aligning with global practices, Malaysia is not only
          committing to sustainability and transparency but also ensuring its
          economy remains closely integrated with international supply chains
          and practices.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The Malaysian government's initiative to adopt ISSB sustainability
            reporting standards, along with establishing a phased implementation
            timeline, would give companies ample time to adapt. Additionally,
            providing templates and guidelines for reporting could help
            standardize submissions, making it easier for companies to meet the
            new requirements. Collaboration with industry associations to share
            best practices and insights could further streamline the process,
            ensuring a seamless transition for companies towards enhanced
            sustainability reporting.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterEighteen;
