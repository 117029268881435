import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureTwo() {
  return (
    <section className="analytics-feature-section">
      <div className="analytics-feature-container">
        <div className="analytics-feature-wrapper">
          <div className="analytics-feature-image-wrapper">
            <div className="analytics-feature-image">
              <img src={assets.images.analyticsImage2} alt="updapt" />
            </div>
          </div>
          <div className="analytics-feature-content-wrapper">
            <div className="analytics-feature-content">
              <h2 className="analytics-feature-title">
                Climate Change Dashboard
              </h2>
              <p className="analytics-feature-description">
                Forecast your GHG emissions based on past trends with Predictive
                Analysis and set targets and track progress and deviations with
                the 1.5-degree climate change target using our Climate Change
                Dashboard.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureTwo;
