import React, { useState } from "react";
import CaseStudySix from "../components/CaseStudySix";

function CaseStudySixContainer() {
  const [caseStudyData] = useState({
    title: "Supplier assessment case study",
    img: "supplierAssessmentCase.jpg",
    content:
      "One of our pharmaceutical partners have used our supplier assessment module to create a new 'Code of conduct' survey for their 5000+ suppliers in their value chain. They created a custom survey consisting of 52 questions. The survey was created within a week and shared with all the 5000+ suppliers whose details were captured in the database, and they were required to respond to the survey in 7 days. Based on the responses, the tool was able to generate multiple dashboards that provided insight to the client in terms of the supplier's legal compliance, business conduct, human rights and labour employment practices, sustainability and environmental responsibility, transparency and compliance to the various policies and standards in ESG. We also generated dashboards that provided a compliance scoring against each of the suppliers so the client could better understand which of their suppliers were more compliant to the various policies.",
  });
  const [downloadModalBool, setDownloadModalBool] = useState(false);
  return (
    <CaseStudySix
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
    />
  );
}

export default CaseStudySixContainer;
