import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureTwo() {
  return (
    <section className="reporting-feature-section">
      <div className="reporting-feature-container">
        <div className="reporting-feature-wrapper">
          <div className="reporting-feature-image-wrapper">
            <div className="reporting-feature-image">
              <img src={assets.images.reportingImage2} alt="updapt" />
            </div>
          </div>
          <div className="reporting-feature-content-wrapper">
            <div className="reporting-feature-content">
              <h2 className="reporting-feature-title">
                Comply with SEBI's Sustainability Reporting
              </h2>
              <p className="reporting-feature-description">
                Heighten long-term performance and value creation prospects with
                BRSR reporting with an automated data- fetch feature that
                enables multi-year data reporting.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureTwo;
