import React from "react";
import assets from "../../../../shared/constants/assetVariables";
import SubscriptionForm from "../../../SubscriptionForm";
function Subscribe() {

  return (
    <section className="newsletters-subscribe-section">
  <div className="container">
    <div className="row">
    <div className="newsletters-subscribe-content-wrapper">
      <div className="newsletters-subscribe-content">
        <p className="newsletters-subscribe-description">
        Subscribe and get the latest ESG updates sent to your inbox.

        </p>
        <p className="newsletters-subscribe-msg">
        We promise not to use your email for spam!
        </p>
      
        <SubscriptionForm formName="newsletter-subscribe-form"/>
         
      </div>
    </div>
    <div className="newsletters-subscribe-image-wrapper">
      <div className="newsletters-subscribe-image">
        <img src={assets.images.blogsPageImage} alt="updapt" />
      </div>
    </div>
  </div>
   
  </div>
</section>
  );
}

export default Subscribe;