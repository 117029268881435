import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterOne() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter October 2022
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterOne-container">
        <MetaTags>
          <title>UAE strategies toward a green economy by 2025 </title>
          <meta
            name="description"
            content="Like many other countries, UAE aims to reach net-zero carbon emissions or toward a green economy 
            by 2050, and Dubai is becoming the region’s centre for sustainability."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          UAE strategies toward a green economy by 2025
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/images/UAE.jpg").default}
              alt="media-01"
            />
          </figure>
        </div>
        <p>
          Like many other countries, UAE aims to reach net-zero carbon emissions
          or toward a green economy by 2050, and Dubai is becoming the region’s
          centre for sustainability.
        </p>
        <p>
          In recognition of existing and potential environmental and climate
          change risks in the UAE, the concept of the green economy (promoting
          ecological sustainability, resource efficiency, and social
          inclusiveness) has emerged as a strategic priority for the government
          and other institutions. Within the UAE, several financial institutions
          have taken advantage of the opportunities arising from the growing
          demand for sustainable products and services.
        </p>
        <p>
          Dubai International Financial Centre (DIFC) is a leading international
          financial hub in the Middle East and South Asia (MEASA), providing a
          robust, sustainable model for banks and fintechs worldwide.
        </p>
        <p>
          The DIFC and Dubai Financial Market DFM are part of a 23-member group
          under the Dubai{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="newsletter-content-href"
          >
            Sustainable
          </a>{" "}
          Finance Group. Within this come five working groups responsible for
          reporting standards, lending on sustainability norms etc. The Group
          offers broad guidelines in terms of sustainability, at least from the
          financing side of it.
        </p>
        <p>
          The federal and local governments have developed strategies, such as
          the UAE vision 2021, National Green Agenda 2015-2030, and National
          Climate Change Plan 2017-2050, that include sectoral and emirate-level
          strategies. The strategies collectively lay a foundational policy and
          regulatory framework supporting the nation’s pathway toward a green
          economy.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>

          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The United Arab Emirates Securities and Commodities Authority (SCA)
            requires Public Joint Stock Companies (PJSCs), Abu Dhabi Securities
            Exchange (ADX), and Dubai Financial Market (DFM) listed companies to
            adhere to specific{" "}
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="newsletter-content-href-updapt-views"
            >
              ESG disclosure
            </a>{" "}
            requirements complying with the Global Reporting Initiative
            standards and other market-specific standards. With the given scope,
            more regulators in the UAE might choose to roll out a standardized{" "}
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="newsletter-content-href-updapt-views"
            >
              ESG reporting
            </a>{" "}
            format for private and public sector companies.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU proposes deforestation-free products to make it a law
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/EU.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          New{" "}
          <a
            href="https://updaptcsr.com/solutions/esg-analytics"
            target="_blank"
            rel="noopener noreferrer"
            className="newsletter-content-href"
          >
            ESG analysis
          </a>{" "}
          shows that the world can’t reach net-zero by 2050 without ending
          deforestation by 2030 and that 90% of significant forest, land and
          agriculture companies committed to net-zero are missing their
          commitments as there is a lack of action against deforestation.
        </p>
        <p>
          European Commission aims to tackle this, for which 57 participants in
          the European Parliament proposed to implement rules to ensure that
          products sold in Europe are not sourced from deforested or degraded
          land and that goods are produced following human rights provisions.
        </p>
        <p>
          Global deforestation and biodiversity loss are one of the most
          significant threats driving climate change. According to the UN Food
          and Agriculture Organization (FAO), an estimated 420 million hectares
          of forests, representing an area larger than the EU were lost to
          deforestation between 1990 and 2020, with EU consumption responsible
          for approximately 10% of global deforestation.
        </p>
        <p>
          Companies with land-based value chains, particularly in the forest,
          land, and agriculture sectors, are responsible for a significant
          proportion of global GHG emissions. The sectors contribute 22% of
          global emissions, half driven by deforestation.
        </p>

        <p>
          The proposal extends to cover financial institutions to ensure that
          other sectors like banking, insurance and investment activities do not
          support deforestation-related projects. However, the proposal is yet
          to be the final law with EU member states.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Commitment to stop deforestation takes many forms. The European
            Parliament has a great proposal. However, companies must get to the
            bottom of it and commit to 100% traceability and{" "}
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="newsletter-content-href-updapt-views"
            >
              ESG data tracking
            </a>{" "}
            to avoid deforestation. Companies must take greater leverage and
            control over their supply chains and allow them to quickly identify
            and fix any links with deforestation and adopt stricter No
            Deforestation Policies.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          California Bans on Gas-Powered Vehicles by 2035
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/California.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          California regulators officially approved a ban on selling new
          gasoline-powered vehicles by 2035. California is the centre of U.S.
          car culture, and the rule banning cars and light trucks marks a
          historic step in the battle against climate change.
        </p>
        <p>
          The California Air Resources Board issued the rule, forcing automakers
          to speed up the production of cleaner vehicles by 2026 until sales of
          only zero-emission cars, pickup trucks, and SUVs.
        </p>
        <p>
          However, the policy is not to ban people from continuing to drive gas
          cars or from buying and selling them from the used markets after 2035.
          The rule will allow automakers to sell up to 20% of plug-in hybrids,
          which have gas engines, by 2035 and require 35% of total new vehicle
          sales to be powered by batteries or hydrogen by 2026 and 68% by 2030.
        </p>
        <p>
          The rigid changes come with challenges, and the one with EV vehicles
          comes installing enough charging stations across the state and having
          adequate access to materials needed to make batteries for electric
          vehicles. These challenges are additionally linked to external factors
          like Supply chain, labour, critical mineral availability, pricing, and
          the ongoing shortages.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>

          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            The transportation sector represents one of the largest sources of
            greenhouse gas emissions globally, with the ultimate goal to
            decarbonize the sector. California’s rule is undoubtedly seen as a
            positive step. The Inflation Reduction Act, signed by President Joe
            Biden, provides funding for electric vehicle tax credits and clean
            vehicle manufacturing facilities, which comes helping to the
            automotive industries to oversee the challenges and speed up the
            process.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Are the G7 countries aligning with the Paris Agreement for climate
          change?
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/G7Countries.jpg").default
            }
            alt="media-01"
          />
        </figure>
        <p>
          According to a 2022 analysis, corporate emissions targets across all
          G7 countries fall short of global climate goals. The G7's private
          sector is vital in keeping the Paris Agreement's 1.5 degrees Celsius
          target alive more critically than ever. During COP26, the number of
          corporates setting climate targets increased rapidly. However, the
          progress against such targets is yet to be evaluated individually at
          the corporate or the industry level.
        </p>
        <p>
          We referred to an{" "}
          <a
            href="https://updaptcsr.com/solutions/esg-analytics"
            target="_blank"
            rel="noopener noreferrer"
            className="newsletter-content-href"
          >
            ESG analysis
          </a>{" "}
          report that used CDP's latest temperature ratings to assess whether
          current corporate emissions reduction targets are ambitious enough to
          meet the Paris Agreement's 1.5-degree Celsius goal. The ratings are
          calculated by comparing the expected rate of change in company
          emissions implied by their targets with science-based global warming
          pathways.
        </p>
        <p>
          The report compared a comprehensive dataset of publicly disclosed
          corporate emissions target, covering more than 4,000 companies
          globally including the science-based global warming targets.
        </p>
        <p>
          The analysis shows that the GHG emissions reduction targets disclosed
          in G7 economies are still only ambitious enough to align with a 2.7 °
          C or 2.4 °C, which includes the emissions from corporate supply chains
          which are Scope 3 emissions. According to the United Nations
          Intergovernmental Panel on Climate Change (IPCC), the difference
          between 1.5 °C and 2 °C will likely expose 2.6 times more extreme and
          potentially dangerous events.
        </p>
        <p>
          As a result, high-emitting companies can heavily impact their
          country's temperature rating, however there are significant
          differences between countries that are driven by different sector
          mixes and target-setting behaviour.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            Progress is mixed across sectors; most companies in the global
            dataset of G7 countries are aligned with 2.5 °C or worse making the
            Paris Agreement more challenging to achieve. To look beyond the G7,
            some regions' most considerable differences are in the power
            generation and infrastructure sectors. The differences in the sector
            progress reflect the importance of long-term domestic{" "}
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="newsletter-content-href-updapt-views"
            >
              ESG policies
            </a>{" "}
            supporting decarbonization, particularly in the strategically
            important sectors.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Global Africa Business Initiative to advance SDGs in the Continent
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/Global.jpg").default}
            alt="media-01"
          />
        </figure>
        <p>
          The United Nations Secretary-General launched the Global Africa
          Business Initiative at a conference during the UN General Assembly in
          New York on the 18th and 19th of September 2022.
        </p>
        <p>
          The United Nations Global Compact organized the meeting representing
          Heads of State, Government, the United Nations, and business sectors,
          such as technology, sports, fashion, and entertainment. To participate
          in an opportunity to discuss and accelerate Africa’s{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="newsletter-content-href"
          >
            sustainable
          </a>{" "}
          economic growth in the private and public sectors.
        </p>
        <p>
          Global Africa Business Initiative is a great move featuring a better
          understanding of Africa’s business ecosystem to unlock its full
          potential and provide insights to yield new and dynamic financing and
          partnerships under the slogan Unstoppable Africa.
        </p>
        <p>
          In Africa, every sector of the continent’s economy, from manufacturing
          to agriculture and services to finance, is seen on a growth trajectory
          and is positioned to be one of the most critical drivers of global
          business with a $2.5 trillion market opportunity.
        </p>
        <p>
          According to the United Nations Conference on Trade and Development,
          Africa’s food-import bill is expected to reach $110 billion by 2025.
          Investments in agriculture and land reform constitute a significant
          issue in securing Africa’s food and security, given the impact of
          climate change on the continent, especially in sub-Saharan Africa.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />

            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: "22px", color: "white", width: "96%" }}>
            The Global Africa Business Initiative is an excellent opportunity
            for African countries to achieve the ambitious targets of the
            Sustainable Development Goals adopted by the United Nations. As it
            is designed to highlight opportunities in almost every sector in the
            continent, it will require rigorous policy review,{" "}
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="newsletter-content-href-updapt-views"
            >
              ESG management
            </a>{" "}
            , behavioural changes, competent and ethical approaches by both the
            public and private sectors.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterOne;
