import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterEleven() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter August 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterEleven-container">
        <MetaTags>
          <title>
            Singapore Proposes Mandatory Climate Reporting for Public and
            Private Companies, Aligned with International Standards.
          </title>
          <meta
            name="description"
            content="Singapore's Accounting and Corporate Regulatory Authority (ACRA) and Singapore Exchange Regulation (SGX RegCo) have proposed a new requirement for public and large private companies in the country to provide climate-related disclosures. "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          Singapore Proposes Mandatory Climate Reporting for Public and Private
          Companies, Aligned with International Standards.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/august2023-1.jpg").default
            }
            alt="august2023-1"
          />
        </figure>
        <p>
          Singapore's Accounting and Corporate Regulatory Authority (ACRA) and
          Singapore Exchange Regulation (SGX RegCo) have proposed a new
          requirement for public and large private companies in the country to
          provide climate-related disclosures. The proposal aligns with the
          recently published disclosure standards by the International Financial
          Reporting Standards (IFRS) Foundation's International Sustainability
          Standards Board (ISSB). The move comes as part of Singapore's
          commitment to the Singapore Green Plan 2030 and aims to solidify its
          position as a global business hub.
        </p>
        <p>
          Currently, climate reporting is mandatory only for listed companies in
          specific sectors, while other listed issuers follow a
          "comply-or-explain" approach based on the recommendations of the Task
          Force on Climate-related Financial Disclosures (TCFD). The new
          proposal aims to expand climate reporting requirements to all listed
          issuers, including overseas companies, business trusts, and REITs,
          starting from fiscal year 2025. Non-listed companies with at least $1
          billion in revenues will be required to report from FY2027.
        </p>
        <p>
          The Sustainability Reporting Advisory Committee (SRAC), formed jointly
          by the regulators, suggested that companies' reporting should adhere
          to the ISSB standards, with some allowances for complex disclosures
          such as Scope 3 emissions reporting. External assurance on Scope 1 and
          2 greenhouse gas reporting will be required for listed issuers and
          large private companies in subsequent years.
        </p>
        <p>
          The regulators have opened a consultation period on the proposed
          mandatory climate-related reporting rules until September 30. The move
          emphasizes the importance of measuring and managing climate risks in
          the transition to a low-carbon economy, offering businesses a strong
          business case for climate reporting to improve performance and gain
          competitive advantage by capitalizing on growth opportunities.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Singapore's proposal for mandatory climate reporting in public and
            private companies demonstrates its commitment to global
            sustainability efforts. Aligning with international standards from
            the ISSB, the move can potentially set a precedent for other
            countries to strengthen climate accountability and encourage
            businesses worldwide to adopt more sustainable practices.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Euronext Introduces New Indices Focused on Companies with
          Science-Based Climate Targets.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/august2023-2.jpg").default
              }
              alt="august2023-2"
            />
          </figure>
        </div>
        <p>
          Euronext, a prominent European market infrastructure provider, has
          unveiled two new indices designed to invest exclusively in companies
          committed to science-based climate targets approved by the Science
          Based Targets initiative (SBTi), aligning with the Paris Agreement's
          1.5°C objective. The newly launched indices, Euronext Europe SBT 1.5°
          and Euronext Eurozone SBT 1.5°, expand the company's portfolio to a
          total of 28 climate indices. These indices will track companies within
          the Europe 500 and Eurozone 300 indices, focusing solely on those with
          validated emissions reduction targets recognized by the SBTi.
        </p>
        <p>
          Additionally, companies associated with unconventional oil & gas,
          coal, controversial weapons, and tobacco activities will be excluded.
        </p>
        <p>
          SBTi, formed in 2015 through collaboration between CDP, WRI, WWF, and
          UN Global Compact, plays a vital role in aligning corporate
          sustainability efforts with global climate goals.
        </p>
        <p>
          In 2021, they introduced a Net Zero Standard, certifying companies'
          commitments to achieving net zero emissions with rigorous criteria
          requiring substantial decarbonization by 2050 and neutralization of
          residual emissions.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The new indices provide investors with opportunities to engage in
            sustainable investing. As Euronext tracks companies committed to
            science-based targets, investors can support businesses actively
            working towards reducing their carbon footprint and aligning with
            long-term climate goals.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          India Introduces Stringent Rules for ESG Funds to Boost Green
          Financing and Transparency.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/august2023-3.jpg").default
            }
            alt="august2023-3"
          />
        </figure>
        <p>
          India's Securities and Exchange Board (SEBI) has introduced new
          disclosure and investment rules for ESG funds to strengthen green
          financing and mitigate greenwashing risks. ESG funds will now be
          required to invest at least 80% of their assets in securities aligned
          with their specific strategies, and monthly ESG scores for holdings
          must be provided by asset managers.
        </p>
        <p>
          SEBI has also created a new ESG investment sub-category, allowing
          mutual funds to offer multiple ESG schemes to investors. The rules
          define various ESG strategies, including Exclusion, Integration,
          Impact Investing, and Sustainable Objectives. Funds must allocate at
          least 65% of their assets to companies reporting on comprehensive
          Business Responsibility and Sustainability Reports.
        </p>
        <p>
          The regulations mandate enhanced disclosures, such as including ESG
          strategy names in fund titles and providing voting rationale for
          ESG-related resolutions.
        </p>
        <p>
          Fund managers will need to furnish examples of ESG strategy
          implementation and engagement efforts. Additionally, independent
          assurance is required to ensure compliance with ESG strategies and
          objectives. These measures aim to advance ESG investing and promote
          transparency and accountability in India's financial markets.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            With monthly ESG scores for holdings and enhanced disclosures, the
            regulations reduce the risk of greenwashing, ensuring that ESG funds
            genuinely align with their stated objectives. This fosters trust
            among investors and stakeholders.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          IOSCO Endorses New IFRS Sustainability Standards for Global
          Consistency in Sustainability Reporting.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/august2023-4.jpg").default
            }
            alt="august2023-4"
          />
        </figure>
        <p>
          In a significant development for sustainability reporting, IOSCO, the
          global policy forum for securities regulators, has officially endorsed
          the new IFRS sustainability and climate-related disclosure standards.
        </p>
        <p>
          These standards, published by the IFRS Foundation's ISSB, aim to
          provide consistent and reliable sustainability information. Now, IOSCO
          is urging its 130 member jurisdictions, covering over 95% of global
          financial markets, to consider incorporating these standards into
          their regulatory frameworks.
        </p>
        <p>
          The move comes after IOSCO had shown early support for the ISSB
          project and worked closely with them through a Technical Expert Group.
          The widespread regulatory adoption of these global
          sustainability-related disclosures is expected to enhance the
          consistency and comparability of information, meeting the demands of
          capital markets.
        </p>
        <p>
          The ISSB is also developing an Adoption Guide to assist regulators in
          implementing these standards effectively.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            As global securities regulator, IOSCO, endorses new IFRS
            sustainability and climate-related disclosure standards by the ISSB,
            this endorsement signals a significant step toward consistent and
            comparable ESG information, encouraging wider adoption and enhancing
            transparency in capital markets.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Canada Takes Steps to Reduce Fossil Fuel Subsidies and Advance Energy
          Transition.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={
              require("../../../../../assets/images/august2023-5.jpg").default
            }
            alt="august2023-5"
          />
        </figure>
        <p>
          The Government of Canada has taken significant steps towards reducing
          its support for the fossil fuel sector, aligning with the 34-nation
          Glasgow Statement agreement signed at the COP26 summit. As part of its
          commitment to shift public finance away from fossil fuels and promote
          the energy transition, Canada has introduced the 'Fossil Fuel
          Subsidies Government of Canada Self-Review Assessment Framework'
          setting criteria for efficient subsidies.
        </p>
        <p>
          The framework emphasizes the need for significant greenhouse gas
          emissions reductions, support for clean and renewable energy, and
          Indigenous economic participation in fossil fuel activities.
        </p>
        <p>
          The framework emphasizes supporting Indigenous economic participation
          in fossil fuel activities and backing abated production processes like
          carbon capture, utilization, and storage. It also takes into account
          projects with a credible plan to achieve net-zero emissions by 2030.
        </p>
        <p>
          Additionally, guidelines have been released to ensure future
          government support adheres to climate and energy priorities and
          excludes funding for programs not aligned with the framework.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The Fossil Fuel Subsidies Government of Canada Self-Review
            Assessment Framework emphasizes clean energy and emissions
            reductions, providing incentives for companies to adopt ESG-focused
            strategies. This can help businesses improve their environmental
            performance, enhance their ESG ratings, and attract socially
            responsible investors.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterEleven;
