import React from "react";
import "../SCSS/styles.css";
import assets from "../../../../shared/constants/assetVariables";
import WorkOpportunities from "../WorkOpportunities";

function Careers() {
  return (
    <div className="careers-container">
      <div className="intro-banner-container">
        <div className="banner-header">
          <div className="main-title">Grow with us</div>
          <div className="sub-title">
            Make an impact by working with the purpose-driven team to build a
            sustainable future through innovative ESG solutions.
          </div>
          <div className="view-career-btn-cover">
            <a className="view-career-btn" href="#work-opportunities-anchor">
              VIEW CAREER OPPORTUNITIES
            </a>
          </div>
        </div>
        <div className="banner-cover">
          <img
            src={assets.images.careersBanner}
            alt="careersBanner"
            className="careers-banner"
          />
        </div>
      </div>
      <div className="section-2">
        <div className="circle-1">
          <div className="circle-2"></div>
          <div className="circle-rotate-1"></div>
          <div className="circle-rotate-2"></div>
        </div>
        <div className="main-title">Why Updapt?</div>
        <div className="sub-title">
          We prioritize innovation, teamwork, and excellence to create a
          positive impact in sustainability by contributing to a meaningful
          change with our mission.
        </div>
      </div>
      <div className="section-3">
        <div className="collage-cover">
          <img src={assets.images.careerCollage} alt="careerCollage" />
          {/* <div className="circle-2"></div>
          <div className="circle-3"></div> */}
        </div>
        <div className="section-3-right-container">
          <div className="content-wrapper">
            <div className="main-title">Enhance your career:</div>
            <div className="sub-title">
              We encourage an innovative culture where curiosity and
              out-of-the-box thinking are valued.
            </div>
          </div>
          <div className="content-wrapper">
            <div className="main-title">Work-Life Balance:</div>
            <div className="sub-title">
              We enable effective management of personal and professional
              commitments thriving within and beyond work through our flexible
              environment.
            </div>
          </div>
          <div className="content-wrapper">
            <div className="main-title">Friendly and Diverse Culture:</div>
            <div className="sub-title">
              Experience a collaborative and inclusive culture where innovation
              thrives. Our friendly professionals foster growth and drive
              impactful change through shared ideas and remarkable
              transformation.
            </div>
          </div>
        </div>
      </div>
      <div id="work-opportunities-anchor"></div>
      <WorkOpportunities />
    </div>
  );
}

export default Careers;
