import React from "react";

import Category from "./Category";
import Security from "./Security";

function DesktopFaq(props) {
  return (
    <>
      <section className="faq-section">
        <div className="faq-section-container">
          <div className="faq-container">
            <Category
              tabSelected={props?.tabSelected}
              handleTabClick={props?.handleTabClick}
              faqData={props?.faqData}
            />
            <Security
              tabSelected={props?.tabSelected}
              faqData={props?.faqData}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default DesktopFaq;
