import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureOne() {
  return (
    <section className="net-zero-feature-section">
      <div className="net-zero-feature-container">
        <div className="net-zero-feature-wrapper">
          <div className="net-zero-feature-content-wrapper">
            <div className="net-zero-feature-content">
              <h2 className="net-zero-feature-title">
                Track and manage your Net Zero Pathway
              </h2>
              <p className="net-zero-feature-description">
                Set decarbonisation targets, obtain insights on the amount of
                emissions to be emitted by the target year, analyse the
                year-on-year progress against the targets and gain valuable
                analytics.
              </p>
            </div>
          </div>
          <div className="net-zero-feature-image-wrapper">
            <div className="net-zero-feature-image">
              <img src={assets.images.nzImage1} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureOne;
