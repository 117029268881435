import React, { useState } from "react";
import NetZero from "../components/NetZero";

function NetZeroContainer() {
  const [whyFeatures] = useState([
    {
      header: "6 impactful ESG analyses tailor-made for your business",
      desc:
        "A detailed, intuitive suite of 6 ESG analyses custom-made for your  industry, helping you gauge your current ESG status and provide key ESG insights and solutions to improve your overall sustainability approach.",
    },
    {
      header: "Benefit from our trusted knowledge base",
      desc:
        "Over 100 industry types, 300+ ESG factors classified, 100+ years of combined ESG domain experience, over 10,000 companies reviewed on ESG parameters, provides us with enough confidence to state that “We can shape your sustainability strategy”.",
    },
    {
      header: "Seamlessly align your sustainability metrics to ESG standards",
      desc:
        "Just select your industry and let us know your current sustainability status; our tool will align it automatically to the latest standards such as GRI, SASB, TCFD and IR.",
    },
    {
      header: "Contribute to the UN Sustainability Development Goals",
      desc:
        "Our tool provides you with quick access to the SDGs which directly aligns to your ESG factors.",
    },
  ]);
  return <NetZero whyFeatures={whyFeatures}></NetZero>;
}

export default NetZeroContainer;
