import React, { useState } from "react";
import assets from "../../../../shared/constants/assetVariables";
import Partner from "../component/Partner";

function PartnerContainer() {
  const [programs] = useState([
    {
      name: "RESELLER",
      features: ["testing", "testing", "testing"],
      description:
        "Add revenue streams with Updapt products. Become a reseller at zero cost and capitalize the global opportunity in the billion-dollar Sustainability space!",
      icon: assets.icons.reseller,
    },
    {
      name: "AFFILIATE PARTNER",
      features: ["testing", "testing", "testing"],
      description:
        "Earn commissions and grow your business by referring clients and affiliating with us; join today to increase your revenue by promoting our industry-leading ESG platform.",
      icon: assets.icons.affliate,
    },
    {
      name: "TECHNOLOGY PARTNER",
      features: ["testing", "testing", "testing"],
      description:
        "Become Updapt Technology Partner and delight your customers by integrating your software and energy management systems with the Updapt product suite.",
      icon: assets.icons.technology,
    },
    {
      name: "CONSULTING PARTNER",
      features: ["testing", "testing", "testing"],
      description:
        "Become Updapt Consulting Partner and complete the Sustainability Value Chain for your customers with SaaS based ESG solutions of Updapt.",
      icon: assets.icons.consulting,
    },
  ]);
  return (
    <>
      <Partner programs={programs} />
    </>
  );
}

export default PartnerContainer;
