import React from "react";

function Intro({ pageDetails }) {
  return (
    <section className="grant-content-section">
      <div className="grant-content-container">
        <div className="grant-badge">GRANT MANAGEMENT</div>
        <h1 className="grant-title">
          {" "}
          Enhance your social impact with seamless <span>Grant Management</span>
        </h1>
        <p className="grant-description">
          Stramline your grant management process and manage all your social
          projects in one place <br /> drive measurable results.
        </p>
      </div>
    </section>
  );
}

export default Intro;
