import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterTwenty() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter May 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-twenty-container">
        <MetaTags>
          <title>
            FCA Introduces Expanded Sustainability Disclosure Rules for
            Portfolio Managers.
          </title>
          <meta
            name="description"
            content="The Financial Conduct Authority (FCA) has initiated steps to extend its Sustainability Disclosure Requirements (SDR) to include portfolio managers, broadening the scope of regulations initially focused on asset managers."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          FCA Introduces Expanded Sustainability Disclosure Rules for Portfolio
          Managers.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/may2024-1.jpg").default}
            alt="may2024-1"
          />
        </figure>
        <p>
          The Financial Conduct Authority (FCA) has initiated steps to extend
          its Sustainability Disclosure Requirements (SDR) to include portfolio
          managers, broadening the scope of regulations initially focused on
          asset managers. This move is aimed at reinforcing transparency and
          accountability in sustainable finance, ensuring that firms' claims
          about sustainability are both clear and substantiated.
        </p>
        <p>
          From now on, all firms making sustainability-related claims will be
          bound by these enhanced guidelines, which are designed to prevent
          greenwashing. Specific stipulations for portfolio managers will become
          mandatory later, focusing on naming and marketing regulations to
          ensure they align closely with the actual sustainability profiles of
          the products offered.
        </p>
        <p>
          Furthermore, the SDR framework introduces a robust labeling regime
          that categorizes investment products based on their sustainability
          focus and impact, which includes labels like "Sustainability Focus"
          and "Sustainability Impact" among others. These labels help clarify
          the sustainability credentials of various financial products,
          facilitating better investor decisions. The labels are based on
          comprehensive criteria such as sustainability objectives, investment
          strategies, and governance, which are tailored to prevent misleading
          claims and enhance investment transparency.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            This structured approach not only aligns with global standards but
            also supports firms in adhering to consistent sustainability
            practices, thereby fostering a more trustworthy investment
            landscape. Firms that proactively adapt to these requirements and
            integrate genuine sustainability measures could gain a reputational
            edge, attracting both sustainability-focused investors and top
            talent interested in ethical investment practices.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Canada Launches Mandatory Reporting for Plastic Producers to Aid Waste
          Reduction.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/may2024-2.jpg").default
              }
              alt="may2024-2"
            />
          </figure>
        </div>
        <p>
          The Canadian government has announced the establishment of the Federal
          Plastics Registry, an innovative initiative aimed at combatting
          plastic waste across the nation. Starting in September 2025, plastic
          producers will be required to document and report the quantities and
          types of plastics they distribute within the market, particularly
          focusing on packaging, electronic and electrical equipment, and
          disposable plastic products. This move is part of Canada's broader
          zero plastic waste strategy, and intending to provide detailed
          insights into the lifecycle of plastics—from production to disposal.
        </p>
        <p>
          The repository aims to harmonize data collection across provincial and
          territorial lines, ensuring that all significant categories of plastic
          products are reported, including both consumer and industrial sources.
          This comprehensive data will enable better regulatory oversight and
          facilitate the enforcement of recycling and waste reduction measures.
          Moreover, the registry will be critical in supporting Extended
          Producer Responsibility (EPR) programs, which require producers to be
          responsible for their products until the post-consumer stage. It will
          also help in the accurate tracking of plastic waste management, from
          recycling to energy recovery.
        </p>
        <p>
          This initiative is a critical step towards achieving Canada's zero
          plastic waste agenda. By establishing a clear picture of plastic use
          across the country, the Federal Plastics Registry will empower
          policymakers to develop targeted solutions to curb plastic pollution
          and promote a more circular plastics economy.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            To optimize the environmental benefits of Canada's new plastic
            registry initiative, companies should focus on enhancing their
            material tracking and data accuracy, actively participate in
            extended producer responsibility programs, and innovate towards more
            sustainable packaging solutions. Engaging in comprehensive lifecycle
            assessments to minimize plastic usage and increase recyclability can
            drive significant environmental improvements.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Singapore Allocates S$35 Million to Advance Sustainable Finance
          Skills.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/may2024-3.jpg").default}
            alt="may2024-3"
          />
        </figure>
        <p>
          Singapore's Monetary Authority (MAS) has announced a commitment of
          S$35 million to foster skills development in sustainable finance over
          the next three years. This funding will help support the training of
          financial professionals in partnership with the Institute of Banking
          and Finance (IBF). This initiative is designed to prepare them for the
          booming ASEAN sustainable finance market, which is expected to reach
          S$4 to 5 trillion within the decade.
        </p>
        <p>
          The plan includes the creation of over 65 executive courses and two
          undergraduate programs specifically tailored for sustainable finance.
          These programs aim to prepare more than 50,000 financial sector
          employees to manage sustainability risks and develop sustainable
          financial products effectively. This educational push addresses the
          increasing integration of sustainability considerations into financial
          roles.
        </p>
        <p>
          According to studies, the Sustainable Finance Jobs Transformation Map
          highlights essential roles and skills needing enhancement in the
          financial sector. This strategy supports the MAS's Finance for Net
          Zero Action Plan, to establish Singapore as a leading hub for
          sustainable finance in Asia, adept at navigating the intricacies of
          the evolving financial landscape related to sustainability.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The S$35 million investment by Singapore in sustainable finance
            education will enable companies to better integrate green
            strategies, enhancing their competitive edge globally. It prepares
            firms to manage and capitalize on emerging green markets, ensuring
            they remain relevant and innovative in a rapidly evolving financial
            landscape.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          UK Implements 10% SAF Requirement for Aviation Fuel by 2030.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/may2024-4.jpg").default}
            alt="may2024-4"
          />
        </figure>
        <p>
          In a bold move towards environmental sustainability, the UK government
          has mandated that sustainable aviation fuel (SAF) must constitute at
          least 10% of the total jet fuel mix by 2030. This initiative is part
          of the broader 'Jet Zero' strategy, which aims for a net-zero
          emissions aviation sector by 2050. SAF, derived from waste oils and
          agricultural residues, offers a significant reduction in greenhouse
          gas emissions, potentially decreasing them by up to 85% compared to
          traditional fuels.
        </p>
        <p>
          The transition to SAF faces challenges, including its limited supply
          and higher costs compared to conventional jet fuels. To support this
          initiative, the UK plans to introduce interim targets, setting a 2%
          SAF requirement by 2025 and extending to 22% by 2040. These measures
          are expected to stimulate the production of approximately 1.2 million
          tonnes of SAF annually, contributing significantly to the UK economy
          and job market.
        </p>
        <p>
          Furthermore, the government is considering a SAF revenue certainty
          scheme to encourage investment in this sector, ensuring stable income
          for producers and investors. This strategy underscores the UK's
          commitment to balancing economic growth with sustainable practices,
          ensuring that the shift towards greener fuels does not adversely
          affect consumers or the aviation industry.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The UK's mandate for a 10% SAF inclusion by 2030 will significantly
            reshape the aviation industry, pushing companies to innovate and
            adapt. To comply, firms can invest in SAF production, form
            partnerships with biofuel developers, and modify supply chains to
            integrate more sustainable fuel sources efficiently.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Japan Advances IFRS-Aligned Sustainability Reporting Standards.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/may2024-5.jpg").default}
            alt="may2024-5"
          />
        </figure>

        <p>
          The Sustainability Standards Board of Japan (SSBJ) has introduced new
          drafts for sustainability and climate reporting standards, aligning
          with the International Financial Reporting Standards (IFRS) issued by
          the International Sustainability Standards Board (ISSB). These
          standards aim to standardize reporting on environmental and governance
          issues, enhancing transparency and accountability among businesses.
        </p>
        <p>
          In recent years, Japan has progressively tightened sustainability
          disclosure requirements for listed companies. These initiatives
          include mandates from the Tokyo Stock Exchange and the Financial
          Services Agency, which require comprehensive reporting on climate and
          sustainability efforts. The proposed standards by SSBJ are expected to
          further solidify these requirements, with a strategic focus on
          integrating global reporting norms.
        </p>
        <p>
          The exposure drafts released by the SSBJ represent a significant step
          towards mandatory sustainability reporting in Japan. They reflect a
          synthesis of international standards with specific adjustments
          suitable for Japanese corporations. Public feedback is currently being
          solicited on these drafts, with the SSBJ aiming to finalize the
          standards by early 2025. This development underscores Japan's
          commitment to global sustainability efforts and the importance of
          stakeholder engagement in shaping effective governance.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            To effectively adopt niche sustainability strategies, companies can
            focus on innovative approaches like adopting circular economy
            practices to minimize waste and maximize resource efficiency.
            Investing in advanced technologies such as AI and blockchain can
            improve data accuracy and traceability in sustainability reporting.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTwenty;
