import React, { useState } from "react";
import CaseStudyFour from "../components/CaseStudyFour";

function CaseStudyFourContainer() {
  const [caseStudyData] = useState({
    title: "Power & Renewables case study",
    img: "powerAndRenewables.jpg",
    content:
      "The company is the countries' largest integrated power company with an installed generation capacity of 14,110 MW. The organisation has a diversified line of business venturing into renewable energy generation, conventional energy generation, transmission and distribution including several others. With over 12.5 Mn distribution consumers and a legacy of 100+ years in the power generation business, the organisation along with its subsidiaries and joint entities is present across the entire power value chain. The organisation with a 30% clean energy portfolio has committed to being Carbon 'Net Zero' before 2045. It has also committed to Water neutrality before 2030 and ensuring Zero waste to landfills before 2030. With the objective of achieving carbon net zero by growing its renewable energy portfolio and targeting carbon intensity reduction scientifically, the organization introduced the Updapt ESG solution across its generation, renewables, transmission, and distribution businesses. With the growing demand to automate and fully digitize the end-to-end data tracking, monitoring, and reporting process the Updapt ESG solution was a natural fit for the organization. The solution on deployment had to check many boxes including a simplified user interface, flexibility to adhere to the various use cases unique to the organisation, and ensure accurate carbon footprint accounting in line with the protocols governing the industry. Moreover, the solution had to also replace a complex manual process thereby standardizing the entire data collection mechanism.  ",
  });
  const [downloadModalBool, setDownloadModalBool] = useState(false);
  return (
    <CaseStudyFour
      caseStudyData={caseStudyData}
      downloadModalBool={downloadModalBool}
      setDownloadModalBool={setDownloadModalBool}
    />
  );
}

export default CaseStudyFourContainer;
