import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function BlogThirtySix() {
  return (
    <>
      <div className="blog-thirty-six-container">
        <MetaTags>
          <title>What are the ESG Frameworks Have in ESG Reporting? Choose the Right One.</title>
          <meta
            name="description"
            content="ESG Reporting has numerous ESG Frameworks Includes BRSR, GRI,TCFD,CSRD,SASB.  It implies a set of comprehensive ESG guidance to help identify the ESG Risk."
          />
          <meta property="og:title" content="UpdaptCSR | Sustainability Platform" />
        </MetaTags>
        <div className="blog-title"></div>
        <h1>What are the ESG Frameworks Have in ESG Reporting? Choose the Right One.</h1>
        <div className="blog-author-details">
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Manikandan S</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">September 05, 2024</div>
              <div className="blog-read-time">7 min read</div>
            </div>
          </div>
        </div>

        <p>
          <b>ESG Frameworks</b> is a set of principles that provides a framework for organizations
          to assess their sustainability performance, including ESG risks and opportunities. ESG
          frameworks are created by various organizations, including governments, NGOs, stock
          exchange authorities, nonprofit organizations, etc.
        </p>
        <p>
          There are multiple frameworks available to assess ESG performance for{" "}
          <NavLink to="esg-software-for-small-businesses-in-2024-updapt" className="hyper-link">
            businesses
          </NavLink>
          ; organizations opt for the frameworks based on their short-term and long-term
          sustainability objectives.
        </p>
        <p>
          Frameworks provide accurate information to{" "}
          <b>
            key stakeholders such as investors, rating agencies, lenders, regulators, boards,
            customers, and supply chain partners about sustainability performance.
          </b>
        </p>
        <p>
          It also helps organizations set goals and implement ESG and sustainability strategies to
          achieve net zero goals.
        </p>
        <p>
          Below are some widely used sustainability frameworks that have been used by organizations
          globally.
        </p>

        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-36-img1.jpg").default}
            alt="Global Reporting Initiative framework"
          />
        </div>
        <p>
          <h1>1. Global Reporting Initiative framework (GRI ESG Framework):</h1>
        </p>
        <p>
          <b>The GRI</b> is an independent international organization that provides a framework for
          businesses, governments, and other entities to understand and communicate their
          sustainability impacts. The GRI Standards are the most widely used framework for
          sustainability reporting globally.
        </p>
        <p>
          It does not encourage a scoring process but instead emphasizes transparency,
          accountability, stakeholder engagement, and materiality assessment.
        </p>
        <p>
          In addition, GRI encompasses a wide range of sustainability topics including environmental
          impact, human practice, data privacy, resource use, and many others.
        </p>
        <p>
          A wide range of organizations across diverse sectors and industries report their ESG
          performance using the GRI framework. This framework is particularly helpful for
          multinational organizations that operate in different regions with various sustainability
          requirements.
        </p>
        <p>
          Moreover, its framework is publicly available for free, making it accessible to
          organizations of all sizes.
        </p>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-36-img2.jpg").default}
            alt="GRI Standards"
          />
        </div>
        <p>
          <b>GRI Standards are Divided Into Three phase: </b>
          <li className="number-type">
            <b>Universal</b>
          </li>
          <li className="number-type">
            <b>Sector</b>
          </li>
          <li className="number-type">
            <b>Topic</b>
          </li>
        </p>

        <p>
          <b>Universal: </b>
        </p>
        <p>
          A set of principles that suits all types of organizations and explains the process and
          methods for preparing sustainability reports.
        </p>
        <p>
          Universal modular is a base for all GRI{" "}
          <NavLink to="esg-software-for-manufacturing-industries" className="hyper-link">
            reporting
          </NavLink>
          .
        </p>
        <p>
          In GRI, the universal standard is a specialized one, because it covers vast topics and now
          incorporates human rights topics. It encompasses:
        </p>
        <p>
          <li>GRI 101: Foundations</li>
          <li>GRI 102: General disclosure</li>
          <li>GRI 103: General disclosure</li>
        </p>
        <p>
          <b>Sector:</b>
        </p>
        <p>
          It provides guidance for specific sectors or industries such as manufacturing and
          agriculture.
        </p>
        <p>
          <b>Topic:</b>
        </p>
        <p>
          Topic standards provide guidance on specific topics such as waste management, emissions,
          and taxes.
        </p>

        <p>
          <b>GRI Reporting Framework Benefits:</b>
        </p>
        <p>
          <li>
            <b>Improve Transparency:</b> <br />
            <p>
              Transparency is the most significant metric expected by investors and stakeholders. It
              helps build credibility.
            </p>
          </li>
          <li>
            <b>Make informed decision making:</b> <br />
            <p>
              Even in complex circumstances, GRI guides you in the right direction and helps you
              make informed decisions with the help of meaningful ESG insights.
            </p>
          </li>
          <li>
            <b>Avoid risks through effective risk management:</b> <br />
            <p>
              With the help of the report, you can easily predict and forecast upcoming
              sustainability risk events in your{" "}
              <NavLink to="/" className="hyper-link">
                ESG
              </NavLink>{" "}
              process and develop the best solutions to mitigate the risk.
            </p>
          </li>
        </p>
        <div className="button-container">
          <NavLink to="/demo" className="nav-btn">
            Get Free Demo
          </NavLink>
          <NavLink to="/free-trial" className="nav-btn">
            Get Free Trial
          </NavLink>
        </div>
        <p>
          <h2>2. TCFD: TCFD ESG Framework</h2>
        </p>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-36-img3.jpg").default}
            alt="TCFD ESG Framework"
          />
        </div>
        <p>
          The TCFD acronym is Task Force on Climate-Related Financial Disclosures, which was created
          by the Financial Stability Board in 2015 and published its official guidelines in 2017. It
          focuses on climate-related financial risks and opportunities for banks, companies,
          investors, and non-financial sectors.
        </p>
        <p>
          Many organizations struggle to determine which metrics to disclose in their annual
          reports. Therefore, it provides comprehensive guidelines for organizations on how to
          disclose climate-related information in their financial filings. It is a valuable
          framework for investors that helps organizations significantly.
        </p>
        <div className="button-container">
          <NavLink to="/solutions/esg-analytics" className="nav-btn">
            ESG Analytics
          </NavLink>
          <NavLink to="/solutions/portfolio-assessment" className="nav-btn">
            Portfolio Assessment
          </NavLink>
        </div>
        <p>
          <b>TCFD Framework Benefits:</b>
        </p>
        <p>
          <li>
            <b>Improved transparency:</b>{" "}
            <p>
              The TCFD framework encourages organizations to provide clear information about
              climate-related risks and opportunities.
            </p>
          </li>
          <li>
            <b>Informed investment decisions: </b>{" "}
            <p>
              By providing information about climate-related financial information, the TCFD
              framework helps investors make informed decisions.
            </p>
          </li>
        </p>

        <p>
          <h3>3. CSRD: Corporate Sustainability Reporting ESG Framework</h3>
        </p>
        <p>
          The acronym for CSRD is Corporate Sustainability Reporting Directives, introduced by the
          European Commission. It is used by larger organizations, including SMEs, and requires them
          to disclose their non-financial information according to European standards.
        </p>
        <p>
          The CSRD helps investors, stakeholders, and policymakers assess organizations'
          non-financial performance. It emphasizes transparency and accountability in sustainability
          practices.
        </p>
        <p>
          <b>Who are all comply with CSRD:</b>
        </p>
        <p>
          <ul>
            <li>Organizations listed on the EU market.</li>
            <li>
              Organizations that are not listed on the EU market but meet the following criteria:
              250 employees, 40 million euros revenue, 20 million euros assets.
            </li>
            <li>Listed SMEs.</li>
            <li>
              Non-European organizations with subsidiaries in Europe and super-flow revenues of 150
              million euros.
            </li>
          </ul>
        </p>
        <p>
          <b>CSRD Reporting Framework Benefits:</b>
        </p>
        <p>
          <ul>
            <li>
              <b>Organizations: </b>Improve brand reputation, gain potential investors, and improve
              risk management through transparency.
            </li>
            <li>
              <b>Investors: </b>Easily identify risks and opportunities, making informed decisions.
            </li>
          </ul>
        </p>
        <p>
          <h4>4. SASB Framework_ sustainability accounting standard board:</h4>
        </p>
        <p>
          The SASB framework is used for industry-specific disclosure of sustainability information
          that focuses on financial performance. It is useful for investors.
        </p>
        <p>
          SASB covers 77 different industries and identifies subsets of environmental, governance,
          and social issues. Its framework emphasizes financial materiality.
        </p>
        <p>
          In addition, through the framework, organizations share their sustainability performance
          with investors. SASB was developed by a non-profit organization in 2011.
        </p>
        <p>
          <b>SASB Benefits:</b>
        </p>
        <p>The SASB framework delivers significant benefits to investors and corporations.</p>
        <p>
          With the help of the SASB reporting, organizations can compare{" "}
          <NavLink
            to="updapt-esg-reporting-software-for-real-estate-portfolio-and-investors"
            className="hyper-link">
            ESG reporting
          </NavLink>{" "}
          across various organizations within the same industries.
        </p>
        <p>
          The SASB framework is fully customizable, providing industry-specific material metrics.
          This saves users time and effort without imposing a reporting burden.
        </p>
        <p>
          The SASB framework addresses materiality issues, thereby effortlessly identifying risks.
          It also enables organizations to communicate effectively with their stakeholders and
          investors.
        </p>
        <p>
          <h5>5. CDP allies carbon disclosure project framework:</h5>
        </p>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-36-img4.jpg").default}
            alt="CDP allies carbon disclosure project framework"
          />
        </div>
        <p>
          <b>The CDP framework</b> focuses on climate change, deforestation, and water management
          and includes a series of questionnaires based on these topics. It helps companies, cities,
          states, and regions report their environmental impact.
        </p>
        <p>
          In April 2024, the CDP framework added new questionnaires to provide a more comprehensive
          environmental assessment.
        </p>
        <p>
          The CDP framework was established in 2020 and operates online. It also helps investors
          make informed decisions.
        </p>
        <p>
          <b>Carbon Disclosure Framework Benefit:</b>
        </p>
        <p>
          <ul>
            <li>
              <b>Risk management:</b>Organizations can effectively identify environmental-related
              risks, including climate change, deforestation, and water security, and develop a
              clear roadmap to mitigate them.
            </li>
          </ul>
        </p>

        <p>
          <h6>6. BRSR:</h6>
        </p>
        <p>
          The BRSR{" "}
          <NavLink to="esg-software-for-pharmaceutical-industries" className="hyper-link">
            reporting
          </NavLink>{" "}
          framework was introduced in India. To provide a more comprehensive and holistic ESG
          assessment, the BRSR framework was replaced by the BRSR (business responsibility and
          sustainability reporting framework), developed by SEBI and based on nine principles.
        </p>
        <p>
          The BRSR framework encourages listed Indian companies to adopt sustainable practices and
          disclose ESG information.
        </p>
        <p>
          <b>BRSR Framework Benefits:</b>
        </p>
        <p>
          <ul>
            <li>
              <b>Informed investment decisions: </b>The BRSR framework provides investors with
              extensive information about the sustainability performance of organizations, enabling
              them to make informed decisions.
            </li>
            <li>
              <b>Enhanced transparency: </b>The BRSR framework requires organizations to measure
              various sustainability parameters. This detailed reporting provides clear insights
              into{" "}
              <NavLink
                to="choose-wisely-esg-and-sustainability-reporting-software-for-your-business"
                className="hyper-link">
                ESG
              </NavLink>{" "}
              performance, promoting transparency.
            </li>
          </ul>
        </p>
        <div className="button-container">
          <NavLink to="/resource/newsletter" className="nav-btn">
            Newsletter
          </NavLink>
        </div>
        <p>
          <b>In a nutshell:</b>
        </p>
        <p>
          Many organizations are making mistakes in preparing{" "}
          <NavLink to="/solutions/esg-reporting" className="hyper-link">
            ESG reports
          </NavLink>{" "}
          and selecting ESG frameworks. This can hinder improvements in transparency and brand
          reputation.
        </p>
        <p>
          Therefore, consider using{" "}
          <NavLink to="updapt-esg-all-in-one-sustainability-software" className="hyper-link">
            ESG software
          </NavLink>{" "}
          specifically designed for all industries, including ESG reporting, ESG frameworks,{" "}
          <NavLink to="details-about-esg-data-management-software-for-2024" className="hyper-link">
            ESG data management
          </NavLink>
          ,{" "}
          <NavLink to="/solutions/netzero" className="hyper-link">
            net zero
          </NavLink>
          ,{" "}
          <NavLink to="/solutions/enterprise-risk-management" className="hyper-link">
            risk management
          </NavLink>
          , and more.
        </p>
        <p>
          <b>Partner with us for your ESG journey.</b>
        </p>
      </div>
    </>
  );
}

export default BlogThirtySix;
