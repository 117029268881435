import React from "react";
import { withRouter } from "react-router-dom";
import assets from "../../../shared/constants/assetVariables";

function Clients({
  handleNextButton,
  clientDetails,
  index,
  handleBackButton,
  characterCount,
  handleReadMore,
  readMoreBool,
}) {
  return (
    <section className="clients-section-wrapper">
      <link rel="preload" href={clientDetails[index]?.clientIcon} as="image" />
      <div className="clients-section-inner-wrapper">
        <div className="clients-section-inner-headers">
          <h3 className="clients-section-title">What our clients say</h3>
        </div>
        <div className="main">
          <div className="main-container">
            {" "}
            <img
              src={assets?.images?.roundImg1}
              style={{ marginLeft: " -300px" }}
              alt="clients-accenture"
            />
          </div>
          <div className="slide-container">
            <div className="back-button">
              {index !== 0 ? (
                <img
                  onClick={handleBackButton}
                  src={assets.images.nextbutton}
                  alt="nextbutton"
                />
              ) : (
                <img
                  onClick={handleBackButton}
                  src={assets.images.grayNextArrow}
                  style={{ cursor: "auto" }}
                  alt="grayNextArrow"
                />
              )}
            </div>
            <div className="home-client-main">
              <div className="back"></div>
              <div
                className={`home-clients-section ${
                  readMoreBool ? "home-clients-section-full-view" : ""
                }`}
              >
                <div className="home-clients-wrapper">
                  <div className="clients-content-wrapper">
                    <div className="clients-content-wrapper-image">
                      <img
                        src={assets.images.quote}
                        style={{ width: "6%" }}
                        alt="clients-accenture"
                      />
                      <h2>
                        <img
                          src={clientDetails[index]?.clientLogo}
                          style={{ width: "100%" }}
                          alt="clientLogo"
                        />
                      </h2>
                    </div>
                    <div className="client-desc">
                      {characterCount > 641 && (
                        <div
                          className={
                            readMoreBool
                              ? "client-desc-content"
                              : "client-desc-content-hidden"
                          }
                        >
                          {clientDetails[index]?.ClientDesc.split("\n").map(
                            (line) => {
                              return (
                                <p key={line}>
                                  {line} <br />
                                  <br />
                                </p>
                              );
                            }
                          )}
                        </div>
                      )}

                      {characterCount < 641 && (
                        <p className="client-desc-content-without-read-more">
                          {clientDetails[index]?.ClientDesc}
                        </p>
                      )}

                      {characterCount > 641 && !readMoreBool && (
                        <div
                          className="read-more-btn"
                          onClick={() => handleReadMore()}
                        >
                          Read more
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="clients-logo-wrapper">
                    <div className="clients-logo-wrapper-icon">
                      <img
                        src={clientDetails[index]?.clientIcon}
                        alt="clients-accenture"
                        className="img"
                        style={{ width: "74px" }}
                        preload="true"
                      />
                    </div>
                    <div className="clients-logo-wrapper-author">
                      <h3 className="author-heading">
                        {clientDetails[index]?.authorName}
                      </h3>
                      <p className="para">{clientDetails[index]?.authorDesc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="next-button">
              {index !== clientDetails.length - 1 ? (
                <img
                  onClick={handleNextButton}
                  src={assets.images.backArrow}
                  alt="backArrow"
                />
              ) : (
                <img
                  onClick={handleNextButton}
                  src={assets.images.grayBackArrow}
                  style={{ cursor: "auto" }}
                  alt="grayBackArrow"
                />
              )}
            </div>
          </div>
          <div className="main-container">
            {" "}
            <img src={assets?.images?.roundImg2} alt="clients-accenture" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Clients);
