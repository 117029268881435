import React from "react";

function Intro() {
  return (
    <section className="page-banner">
      <div className="container">
        <div className="page-content-wrapper">
          <div className="page-badge">SUPPLIER ASSESSMENT</div>
          <h1 className="page-title">
            A smarter way to manage risky suppliers
          </h1>
          <p className="page-description">
            Discover how easily you can manage your suppliers, assess supplier
            risks using custom assessments, evaluate risks, and increase
            visibility into suppliers ESG practices.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Intro;
