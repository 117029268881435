import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFive() {
  return (
    <section className="grant-feature-section">
      <div className="grant-feature-container">
        <div className="grant-feature-wrapper">
          <div className="grant-feature-content-wrapper">
            <div className="grant-feature-content">
              <h1 className="grant-feature-title">Assess and Audit </h1>
              <p className="grant-feature-description">
                Assess the impact of each program using out inbuilt easy to use
                assessment and audit processes
                
              </p>
            </div>
          </div>
          <div className="grant-feature-image-wrapper">
            <div className="grant-feature-image">
              <img src={assets.images.sampleImage} alt="updapt" />
            </div>
            <div className="dot-shape5">  
             <img src={assets.images.homeBannerImageDot} alt="updapt" />
             </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFive;
