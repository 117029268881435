import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureThree() {
  return (
    <section className="data-management-feature-section">
      <div className="data-management-feature-container">
        <div className="data-management-feature-wrapper">
          <div className="data-management-feature-content-wrapper">
            <div className="data-management-feature-content">
              <h2 className="data-management-feature-title">
                Insightful Dashboards
              </h2>
              <p className="data-management-feature-description">
                View and analyze data using dedicated dashboards for each ESG
                metric. Derive meaningful insights, foresee trends, and get
                overall ESG performance snapshots of your organization.
              </p>
            </div>
          </div>
          <div className="data-management-feature-image-wrapper">
            <div className="data-management-feature-image">
              <img src={assets.images.dataTrackImage3} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="background-image-3">  
             <img src={assets.images.solutionGaphicElemet2} alt="updapt" />
             </div> */}
    </section>
  );
}

export default FeatureThree;
