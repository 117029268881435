import React from "react";
import Intro from "./Intro";
import ContactForm from "./Form";
import ContactCards from "./ContactCards";
import MetaTags from "react-meta-tags";
import SalesEnquiry from "./SalesEnquiry";

function ContactUs({
  contactDetails,
  mobile,
  setMobile,
  handleTextOnChange,
  handleSendRequest,
  formObj,
}) {
  return (
    <>
      <MetaTags>
        <title>
          ESG Management Platform | ESG Management Software | ESG Accounting
        </title>
        <meta
          name="description"
          content="For more information or any query, please get in touch with us."
        />
        <meta
          property="og:title"
          content="ESG Management Platform | ESG Management Software | ESG Accounting"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>

      <Intro></Intro>
      <div className="intro-divider"></div>
      <div className="sales-contact-form-wrapper">
        <SalesEnquiry />
        <ContactForm
          formObj={formObj}
          handleTextOnChange={handleTextOnChange}
          handleSendRequest={handleSendRequest}
          mobile={mobile}
          setMobile={setMobile}
        />
      </div>
      <div className="intro-divider">
        <section className="pricing-plan-section">
          <div className="pricing-circle-right">
            <div className="pricing-circle-right-2"></div>
            <div className="pricing-circle-rotate-1"></div>
            <div className="pricing-circle-rotate-2"></div>
          </div>
        </section>
      </div>
      <ContactCards contactDetails={contactDetails}></ContactCards>
    </>
  );
}

export default ContactUs;
