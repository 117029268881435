import React from "react";
import "../SCSS/styles.scss";
import MetaTags from "react-meta-tags";

function BlogSeventeen() {
  return (
    <>
      <div className="BlogSeventeen-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Understanding-the-Greenhouse-Gases-CH4</title>
          <meta
            name="description"
            content="Climate change conversations have focused too little attention on methane (CH4) over the years.
          "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">
          Understanding the Greenhouse Gases - CH4
        </div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Deeksha</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Dec 07, 2021</div>
              <div className="blog-read-time">3 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog17.jpg").default}
            alt="blog-17"
          />
        </div>
        <p>
          Climate change conversations have focused too little attention on
          methane (CH4) over the years. The majority of the strategies to combat
          climate change revolved around CO2 emissions, while the impact of
          methane remained underestimated. Let's look at how methane impacts the
          global climate system and temperature.
        </p>
        <p>
          Carbon dioxide is responsible for half of the global emissions. The
          other half comes from lesser-known but highly potent short-lived
          climate pollutants (SLCPs), such as methane, black carbon,
          hydrofluorocarbons (HFCs), etc.
        </p>
        <p>
          As one of the best-known SLCPs, methane is primarily human-driven.
          Compared to CO2, methane has a relatively short shelf-life; it lasts
          an average of nine years in the atmosphere, while CO2 can remain in
          the atmosphere for about a century. This makes methane an excellent
          target for reducing emissions in a short period.
        </p>
        {/* <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                Who has to pay a carbon tax?
              </b>
            </p>
          </div>
        </div> */}
        <p>
          After carbon dioxide, methane is the second most abundant greenhouse
          gas today, contributing to global warming. According to the IPCC
          report, methane has been calculated to have added approximately 0.5
          degrees Celsius to global warming. But where is all this methane
          coming from?
        </p>
        <p>
          Methane is produced naturally when organic materials break down or
          decompose. Natural processes such as the breakdown of plant material
          in wetlands, the seepage of gas from underground deposits, and cattle
          digestion account for about 40% of methane emissions into the
          atmosphere.
        </p>
        <p>
          About 60% comes from anthropogenic sources. The agriculture sector is
          estimated to emit 40 to 50% of anthropogenic methane. Agricultural
          emissions come from farming operations, rice production, and livestock
          rearing. Animals such as cows and sheep emit methane and other gases
          during digestion, accounting for more than 70% of agricultural
          emissions.
        </p>
        <p>
          Methane is the main component of natural gas. When natural gas is
          extracted and transported, it leaks into the atmosphere. Oil and gas
          account for 20 to 25% of anthropogenic methane, mainly as "fugitive
          methane" released by venting, leaks, and flaring.
        </p>
        {/* <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 2: China and India are the primary contributors to climate
            change, and both countries must take action.
          </b>
        </p> */}
        {/* <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-13(graph).svg")}
            alt="Blog-13(graph)"
          />
        </div> */}
        <p>
          Landfills release methane from anaerobic decomposition (decomposition
          without oxygen or very little oxygen). Landfill food waste has a
          significant impact on the climate crisis. Decay can take years without
          oxygen, and landfills often contain very little oxygen flow and lack
          the air movement needed to break down food quickly since they are
          wrapped in plastic bags and surrounded by heaps of more plastic bags.
          Hence food waste in landfills keeps on producing methane. Similarly,
          the breakdown of organic material in wastewater streams can emit
          methane.
        </p>{" "}
        {/* <div className="sub-header">
        //   <div className="sub-header-content">
        //     So, is it realistic for electric vehicles to contribute
        //     significantly to sustainability in these challenging scenarios?
        //   </div>
        {/* </div> */}
        {/* <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 3: Renewables are expensive and can only work when it's windy
            and not cloudy.
          </b>
        </p>
        <p>
          Fact: Today, renewables are just as cheap as any other source of
          energy. According to the International Renewable Energy Agency
          (IRENA), renewable energy costs continue to reduce "substantially"
          year on year. A continuous supply of energy can be ensured by using a
          mix of powers such as solar, wind, natural gas, etc. Furthermore,
          rapid advances in battery capacity can help capture energy while it is
          available and store it for later use.
        </p>

        <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 4: Individuals cannot make any difference in the climate change
            cause.
          </b>
        </p>
        <p>
          Fact: There are a lot of things that individuals can do to fight
          climate change. Sustainable lifestyle practices by putting the 3 Rs-
          Reduce, Reuse, and Recycle into practice and choosing renewables over
          fossil fuels can help a great deal. Reducing meat intake by adopting a
          flexitarian diet (one mainly vegetarian but allows for the occasional
          meat dish) can also help reduce more than 40% carbon footprint.
          However, it wouldn't be right to say that only lifestyle changes can
          reduce emissions. Given the scope of the problem, a policy-driven
          strategy, in addition to individual initiatives, is required.
        </p> */}
        {/* <p>
          Climate change is real and is a global issue, and it will affect every
          country and individual if measures are not taken to address it. People
          and regulatory bodies working together can have a significant impact
          on people and the planet.
        </p> */}
        {/* <p>
          Only a coherent makeover, in which all elements are tied to a common
          goal, can help us economically and environmentally.
        </p> */}
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                How do we fix the methane issue?
              </b>
            </p>
          </div>
        </div>
        <p>
          Today, we have a lot more information on where methane comes from and
          how to prevent it. Proven technologies like cattle feed additives and
          better manure management can reduce agricultural methane emissions.
          Companies can use strategies like LDAR (Leak Detection and Repair)
          programs and vapour-recovery systems to reduce methane leaks to
          decarbonize their operations significantly. Modern sanitation
          infrastructure and technologies, such as the usage of enclosed lagoons
          or microalgae to prevent gas generation, can improve waste treatment.
        </p>
        {/* <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                So, where does that leave us?
              </b>
            </p>
          </div>
        </div> */}
        <p>
          Companies and governments are now paying closer attention to
          regulating methane emissions as much as CO2 emissions. At the UN COP26
          climate conference in Glasgow, the Global Methane Pledge, an agreement
          to reduce global methane emissions, was announced. The agreement's
          primary goal is to reduce methane emissions by up to 30% by 2030,
          compared to 2020 levels. So far, 90 countries have signed on to the
          pledge.
        </p>
        <p>
          India has abstained from signing the pledge, citing concerns about
          trade, the country's colossal farm sector, and the role of cattle in
          the rural economy. However, India is already a member of the Global
          Methane Initiative (GMI), a voluntary global effort to minimize
          methane gas emissions.{" "}
        </p>
        <p>
          With methane's role in global warming in sharp focus for the first
          time, there is hope that current technologies can reduce methane
          emissions. Therefore, action should be prioritized where it is
          practical. Current measures will most certainly fail if the necessary
          efforts are not made, and the world will continue on its path of
          destruction towards an uncertain future.
        </p>
        <p>
          Read{" "}
          <a
            href="https://www.updaptcsr.com/resource/blogs/understanding-the-greenhouse-gases"
            target="_blank"
            style={{ color: "blue" }}
            rel="noopener noreferrer"
            // text-decoration-color="red"
          >
            {/* <style>{(color = "blue")}</style> */}
            Understanding the Greenhouse Gases - CO2
          </a>{" "}
          to know about CO2 and its role in global warming.{" "}
        </p>
      </div>
    </>
  );
}
export default BlogSeventeen;
