import React from "react";

function Policy() {
  return (
    <section className="privacy-section">
      <div className="privacy-container">
        <div className="privacy-content-wrapper">
          <h1 className="privacy-content-title">
            Eﬀective Date: February 5th, 2020
          </h1>

          <p className="privacy-content-description">
            This Online Privacy Notice (“Privacy Notice”) explains how Updapt
            CSR Private Limited and its subsidiaries (“Updapt CSR Private
            Limited”) handles personal data that it collects and receives from
            users through this website (“Updapt CSR Private Limited Website”).
          </p>
          <h1 className="privacy-content-title">Information We Collect</h1>
          <p className="privacy-content-description">
            The types of personal data that we may collect and receive while you
            use the Updapt CSR Private Limited Website are described in this
            section and include both data that you provide to us and data that
            we collect automatically when you use the Updapt CSR Private Limited
            Website.
          </p>
          <p className="privacy-content-description">
            For purposes of this Privacy Notice, “Personal Data” means any data
            related to you as an identified or identifiable natural person.
            Examples of Personal Data include name, address, telephone number,
            email address, etc.
          </p>
          <h1 className="privacy-content-title">Information You Provide</h1>
          <p className="privacy-content-description">
            Updapt CSR Private Limited collects Personal Data that you provide
            on Updapt CSR Private Limited Website, for example, through a
            contact form in order to enquire about our services, through your
            job application, or to contact Updapt CSR Private Limited. This
            could include:
          </p>
          <ul className="privacy-content-list">
            <li className="privacy-content-list-item">Name</li>
            <li className="privacy-content-list-item">
              Contact data such as your address, phone number, or email address
            </li>
            <li className="privacy-content-list-item">
              Registration data such as your username and password
            </li>
            <li className="privacy-content-list-item">
              Employment, education and other background data when you inquire
              about employment with Updapt CSR Private Limited
            </li>
          </ul>
          <h1 className="privacy-content-title">
            Data That May Be Collected Automatically
          </h1>
          <p className="privacy-content-description">
            When you use Updapt CSR Private Limited Website, we also may collect
            certain usage and device data automatically as described below.
          </p>
          <h1 className="privacy-content-title">IP Address</h1>
          <p className="privacy-content-description">
            We may record the Internet Protocol (“IP”) address of your computer
            or other electronic device when you visit the Updapt CSR Private
            Limited Website. Your IP address is automatically logged in our web
            server logs, to provide you with the essential service of website
            browsing, without which it would not be possible for you to browse
            this website or even be able to read this page.
          </p>
          <p className="privacy-content-description">
            An IP address identifies the electronic device you use to access the
            Sites, which allows us to maintain communication with your computer
            as you move about Updapt CSR Private Limited Website and to
            customize content.
          </p>
          <h1 className="privacy-content-title">
            Cookies and Other Tracking Technologies
          </h1>
          <p className="privacy-content-description">
            We use cookies (and equivalent technologies) to collect and analyze
            information on our website's performance and to enable the website
            to function. Cookies also allow us and third parties to tailor the
            ads you see when you visit our website and other third party
            websites in the same online network, including social networks. You
            can find out more from our Cookie Policy.
          </p>
          <p className="privacy-content-description">
            To learn more about cookies and other tracking technologies,
            including how to disable them, please visit{" "}
            <a
              href="http://www.allaboutcookies.org/"
              className="privacy-content-link"
            >
              http://www.allaboutcookies.org/
            </a>{" "}
            or
            <a
              href=" http://youronlinechoices.eu/"
              className="privacy-content-link"
            >
              {" "}
              http://youronlinechoices.eu/
            </a>
            . Please note that some cookies are essential to the functioning of
            our websites and deleting or disabling them will reduce the sites
            functionality.
          </p>

          <h1 className="privacy-content-title">
            Data Collected from Other Sources
          </h1>
          <p className="privacy-content-description">
            We may combine data about you that is collected through the Updapt
            CSR Private Limited Website with data that we have collected online,
            offline, as well as with data provided to us by third parties.
          </p>

          <h1 className="privacy-content-title">How We Use Your Data</h1>
          <p className="privacy-content-description">
            We use your Personal Data to provide you the services you request,
            communicate with you, improve your experience on the Updapt CSR
            Private Limited Website, process your job application, and for other
            internal business purposes, as described in more detail below.
          </p>

          <h1 className="privacy-content-title">
            Providing Services You Request
          </h1>
          <p className="privacy-content-description">
            If you choose to contact us, we use the Personal Data that you
            provide through Updapt CSR Private Limited Website to respond to
            your questions, provide you the services you request, offer an
            optimal customer experience, and/or based on your consent.
          </p>

          <h1 className="privacy-content-title">Communicating with You</h1>
          <p className="privacy-content-description">
            If you choose to submit your details through Updapt CSR Private
            Limited Website, we may add you to our marketing list and contact
            you about our services. You may unsubscribe from such communications
            at any time using the link provided in emails from us.
          </p>

          <h1 className="privacy-content-title">Customized User Experiences</h1>
          <p className="privacy-content-description">
            We may use your IP address and the data that we obtain automatically
            through the use of cookies or similar tracking technologies to make
            our Updapt CSR Private Limited Website easier to use and navigate as
            well as to personalize the content provided on Updapt CSR Private
            Limited Website by anticipating the information and services that
            may be of interest to you. In some cases, we collect this
            information pursuant to your consent. In other cases, we collect
            this information for our legitimate business interest to optimize
            and customize user experiences.
          </p>

          <h1 className="privacy-content-title">
            Business and Product Improvement
          </h1>
          <p className="privacy-content-description">
            In order to discover new facts that could help Updapt CSR Private
            Limited better understand customer needs and help improve, develop,
            and evaluate services, materials, and incentives to join us, Updapt
            CSR Private Limited analyses the information that you have provided
            for its legitimate interests in business and marketing improvement.
          </p>

          <h1 className="privacy-content-title">
            Service Analytics and Improvement
          </h1>
          <p className="privacy-content-description">
            We may use the information that you provide and the information we
            collect automatically about your use of the Updapt CSR Private
            Limited Website to monitor user traffic patterns and preferences for
            our legitimate business interests of improvement, analytics, and
            optimization of the Updapt CSR Private Limited Website.
          </p>

          <h1 className="privacy-content-title">Data Use and Retention</h1>
          <p className="privacy-content-description">
            We will only keep Personal Data as long as necessary for the
            fulfillment of the purposes outlined above, except if otherwise
            required by applicable laws or legal orders.
          </p>

          <h1 className="privacy-content-title">
            When We Share Your Information With Third Parties
          </h1>
          <p className="privacy-content-description">
            We only share your Personal Data with third parties as described
            below. We do not sell or rent Personal Data to third parties for
            their own marketing purposes.
          </p>

          <h1 className="privacy-content-title">
            Affiliates, Vendors, and Suppliers
          </h1>
          <p className="privacy-content-description">
            We have relationships with various vendors and suppliers, including
            our affiliated companies and recruitment agencies, who help us
            operate our business and for whom it may be necessary to have access
            to your Personal Data in the course of providing services to Updapt
            CSR Private Limited. We will not authorize these parties to use your
            Personal Data for any purpose that is not related to Updapt CSR
            Private Limited’ business operation, and we require them to handle
            your Personal Data collected through the Updapt CSR Private Limited
            Website in accordance with appropriate contractual privacy and
            security provisions and this Privacy Notice.
          </p>

          <h1 className="privacy-content-title">
            Legal Rights and Obligations
          </h1>
          <p className="privacy-content-description">
            In certain limited circumstances, Updapt CSR Private Limited may
            need to disclose your Personal Data in order to comply with a legal
            obligation or demand, such as to comply with reporting obligations
            to our governing regulatory authorities regarding the safety of our
            pharmaceutical products, or in connection with the sale or transfer
            of one of our product lines or divisions, which includes the
            services provided through one or more of the Updapt CSR Private
            Limited Website. In such instances, we will take measures to protect
            your Personal Data to the extent possible. We also reserve the right
            to use Personal Data to investigate and prosecute users who violate
            our rules or who engage in behavior that is illegal or harmful to
            others or to others’ property.
          </p>

          <h1 className="privacy-content-title">Privacy of Children</h1>
          <p className="privacy-content-description">
            Updapt CSR Private Limited does not knowingly collect or use any
            Personal Data directly from children on Updapt CSR Private Limited
            Website. We do not knowingly allow children to communicate with us,
            or to use any of our online services. If you are a parent and become
            aware that your child has provided us with information, please
            contact us using one of the methods specified below, and we will
            work with you to address this issue.
          </p>

          <h1 className="privacy-content-title">Your Privacy Choices</h1>
          <p className="privacy-content-description">
            You may contact Updapt CSR Private Limited to ask questions, discuss
            your concerns, or report privacy concerns. If you communicate with
            or request information from Updapt CSR Private Limited, you may be
            prompted to provide your contact information as well as any Personal
            Data that is relevant to your request.
          </p>

          <p className="privacy-content-description">
            Updapt CSR Private Limited has an appointed Data Protection Officer
            who is contracted through Updapt CSR Private Limited’ parent group
            Premier Research. Please submit your privacy inquiries at{" "}
            <a
              href="mailto:info@updaptcsr.com"
              className="privacy-content-link"
            >
              info@updaptcsr.com
            </a>
            .
          </p>

          <h1 className="privacy-content-title">
            Additional Information for California Residents
          </h1>
          <p className="privacy-content-description">
            If you are a California resident, you have the right to receive
            specific information about how Updapt CSR Private Limited collects,
            uses and discloses your Personal Data, and you may have specific
            rights with respect to your Personal Data. California-specific
            privacy requirements that are not addressed in other sections of
            this Privacy Notice are described in this section.
          </p>

          <p className="privacy-content-description">
            Consistent with the Information we collect section above, we collect
            certain categories and specific pieces of information about
            individuals that are considered Personal Data. As detailed above, we
            may collect this Personal Data from you and other third parties. We
            collect, use and disclose Personal Data for the business and
            commercial purposes described in the How we use your Data and the
            When we share your Information sections above. Under the definition
            of “selling” in the California Consumer Privacy Act, Updapt CSR
            Private Limited does not sell your Personal Data.
          </p>

          <p className="privacy-content-description">
            As a California resident, you have the right to request:
            <ul className="privacy-content-list">
              <li className="privacy-content-list-item">
                Information about the Personal Data that Updapt CSR Private
                Limited has collected, used and disclosed about you over the
                previous 12 months, including: the categories of Personal Data
                collected; the categories of sources from which your Personal
                Data was collected; the business or commercial purposes for
                which Updapt CSR Private Limited collected your Personal Data;
                the categories of your Personal Data that was disclosed for a
                business or commercial purpose; and the categories of third
                parties with whom Updapt CSR Private Limited shared your
                Personal Data;
              </li>
              <li className="privacy-content-list-item">
                The specific Personal Data that Updapt CSR Private Limited has
                collected about you over the previous 12 months; and
              </li>
              <li className="privacy-content-list-item">
                To have your Personal Data deleted.
              </li>
            </ul>
          </p>

          <p className="privacy-content-description">
            Should you exercise any of your privacy rights as a California
            resident, Updapt CSR Private Limited will not discriminate against
            you by offering you different pricing or services, or by providing
            you with a different level or quality of services, based solely upon
            this request. Certain Updapt CSR Private Limited Services, however,
            may require that Updapt CSR Private Limited processes your Personal
            Data in order to provide the Service or to allow us to use and
            disclose your Personal Data to provide the Service. When you
            exercise your right to have your Personal Data deleted, you may lose
            access to certain aspects of Updapt CSR Private Limited Services
            that require your Personal Data to perform the service.
          </p>

          <p className="privacy-content-description">
            Please see the Your Privacy Choices section if you have questions or
            would like to make a request to exercise any of your rights.
          </p>

          <h1 className="privacy-content-title">
            Additional Information for European Economic Area residents
          </h1>
          <p className="privacy-content-description">
            Updapt CSR Private Limited would like to make sure you are fully
            aware of all of your data protection rights. Every user is entitled
            to the following:
            <ul className="privacy-content-list">
              <li className="privacy-content-list-item">
                The right to access – You have the right to request Updapt CSR
                Private Limited for copies of your Personal Data or a
                description of the Personal Data categories we process or a
                description of the processing.
              </li>
              <li className="privacy-content-list-item">
                The right to rectification – You have the right to request that
                Updapt CSR Private Limited correct any information you believe
                is inaccurate. You also have the right to request Updapt CSR
                Private Limited to complete the information you believe is
                incomplete.
              </li>
              <li className="privacy-content-list-item">
                The right to erasure – You have the right to request that Updapt
                CSR Private Limited erase your Personal Data, under certain
                conditions.
              </li>
              <li className="privacy-content-list-item">
                The right to restrict processing – You have the right to request
                that Updapt CSR Private Limited restrict the processing of your
                Personal Data, under certain conditions.
              </li>

              <li className="privacy-content-list-item">
                The right to object to processing – You have the right to object
                to Updapt CSR Private Limited’ processing of your Personal Data
                that are based on legitimate interests, under certain
                conditions.
              </li>

              <li className="privacy-content-list-item">
                The right to data portability – You have the right to request
                that Updapt CSR Private Limited transfer the data that we h
              </li>
            </ul>
          </p>

          <p className="privacy-content-description">
            If you make a request, we have one month to respond to you. If
            Updapt CSR Private Limited will not be able to provide the requested
            information or make the change you request, you will be provided
            with reasons for such decision.
          </p>

          <p className="privacy-content-description">
            Under local law you are also entitled to lodge a complaint with your
            local data protection authority.
          </p>

          <p className="privacy-content-description">
            Please submit your requests and privacy inquiries as described in
            Your Privacy Choices.
          </p>

          <h1 className="privacy-content-title">Data Security</h1>
          <p className="privacy-content-description">
            Updapt CSR Private Limited maintains reasonable technical,
            administrative, organizational and physical security measures and
            controls to secure any Personal Data collected through the Updapt
            CSR Private Limited Website. However, there is always some risk that
            an unauthorized third party could intercept an Internet
            transmission, or that someone will find a way to thwart our security
            systems. We urge you to exercise caution when transmitting Personal
            Data over the Internet, especially any sensitive Personal Data.
            Updapt CSR Private Limited cannot guarantee that unauthorized third
            parties will not gain access to your Personal Data; therefore, when
            submitting Personal Data to Updapt CSR Private Limited Website, you
            must weigh both the benefits and the risks.
          </p>

          <h1 className="privacy-content-title">
            Links to Third-Party Sites and Social Media Plug-ins
          </h1>
          <p className="privacy-content-description">
            This Privacy Notice does not apply to third-party websites to which
            Updapt CSR Private Limited Website may link. Updapt CSR Private
            Limited Website may use social media plug-ins (e.g., the Facebook
            “Like” button, “Share to Twitter” button) to enable you to easily
            share information with others. When you visit Updapt CSR Private
            Limited Website, the operator of the social plug-in can place a
            cookie on your computer or other electronic device that enables that
            operator to recognize individuals who have previously visited our
            Site. If you are logged into the social media website (e.g.,
            Facebook, LinkedIn, Twitter) while browsing on our Updapt CSR
            Private Limited Website, the social media plug-in allows that social
            media website to receive information that you have visited our
            Updapt CSR Private Limited Website. The social media plug-in also
            allows the social media website to share information about your
            activities on our Updapt CSR Private Limited Website with other
            users of their social media website. These sharing settings are
            managed by the social media website and governed by its privacy
            policy.
          </p>

          <h1 className="privacy-content-title">
            Accessing Our Sites Globally
          </h1>
          <p className="privacy-content-description">
            This site is owned and operated by Updapt CSR Private Limited, and
            if you are visiting this site from a country outside India, your
            communication with us will necessarily result in the transfer of
            information across international borders. Personal Data may be
            transferred to other Updapt CSR Private Limited affiliates in
            territories that may not provide a level of protection to Personal
            Data equivalent to that provided by your home country.
          </p>

          <p className="privacy-content-description">
            In compliance with privacy regulations across the globe, Updapt CSR
            Private Limited adheres and cooperates fully with the EU Data
            Protection Authorities (EU DPAs) and the Swiss Federal Data
            Protection and Information Commissioner (FDPIC) regarding human
            resource data (which is any Personal Data about an organization’s
            own employees, past or present, collected in the context of the
            employee relationship) and non-human resource data. Updapt CSR
            Private Limited will comply with advice given by such authorities
            with regard to Personal Data transferred from the European Union and
            Switzerland. Updapt CSR Private Limited commits to resolve
            complaints about your privacy and our collection or use of your
            Personal Data. Persons from the European Union and Switzerland who
            have inquiries or complaints regarding this Privacy Notice should
            first contact us at{" "}
            <a
              href="mailto:info@updaptcsr.com"
              className="privacy-content-link"
            >
              info@updaptcsr.com
            </a>
            .
          </p>

          <p className="privacy-content-description">
            Please note that if your complaint is not resolved through these
            channels, under limited circumstances, a binding arbitration option
            may be available before a Privacy Shield Panel.
          </p>

          <p className="privacy-content-description">
            Any onward transfers of Personal Data to vendors and suppliers
            outside the EEA will be done in compliance with the international
            data transfer restrictions that apply under EU and Swiss data
            protection laws, including, where appropriate, through the use of EU
            model contracts for data transfers to processors.
          </p>

          <h1 className="privacy-content-title">
            Changes to our Privacy Notice
          </h1>
          <p className="privacy-content-description">
            We will only use Personal Data in the manner described in the
            Privacy Notice in effect when the information was collected from you
            or as authorized by you. However, and subject to any applicable
            consent requirements, or if required by law, we reserve the right to
            change the terms of this Privacy Notice at any time.
          </p>

          <p className="privacy-content-description">
            Any changes to this Privacy Notice will be reflected on this page
            with a new effective date. Updapt CSR Private Limited encourages you
            to review this Privacy Notice regularly for any changes.
          </p>

          <p className="privacy-content-description">
            Any Personal Data collected upon your continued use of Updapt CSR
            Private Limited Website will be handled in accordance with the
            currently posted Privacy Notice.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Policy;
