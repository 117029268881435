import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureThree() {
  return (
    <section className="reporting-feature-section">
      <div className="reporting-feature-container">
        <div className="reporting-feature-wrapper">
          <div className="reporting-feature-content-wrapper">
            <div className="reporting-feature-content">
              <h2 className="reporting-feature-title">
                Custom ESG Reports for your Business
              </h2>
              <p className="reporting-feature-description">
                Get customized reports specific to location and ESG indicators,
                generate ESG reports on the supply chain, and provide your
                company's stakeholders with more
                <br /> timely and accurate ESG information.
              </p>
            </div>
          </div>
          <div className="reporting-feature-image-wrapper">
            <div className="reporting-feature-image">
              <img src={assets.images.reportingImage3} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="background-image-3">  
             <img src={assets.images.solutionGaphicElemet1} alt="updapt" />
             </div> */}
    </section>
  );
}

export default FeatureThree;
