import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureOne() {
  return (
    <section className="scm-feature-section">
      <div className="scm-feature-container">
        <div className="scm-feature-wrapper">
          <div className="scm-feature-content-wrapper">
            <div className="scm-feature-content">
              <h2 className="scm-feature-title">
                Confidently identify and manage supplier performance
              </h2>
              <p className="scm-feature-description">
                Efficiently collect, assess, and analyze your suppliers ESG data
                to get a holistic view of the suppliers capabilities and
                exposure to ESG risks.
              </p>
            </div>
          </div>
          <div className="scm-feature-image-wrapper">
            <div className="scm-feature-image">
              <img src={assets.images.scmImage1} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureOne;
