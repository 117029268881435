import React from "react";

function Security({ tabSelected, faqData }) {
  const data = faqData.filter((x) => x.id === tabSelected);
  return (
    <div className="faq-description-wrapper">
      <ul className="faq-qa-list">
        {data[0].faq.map((data, index) => (
          <li className="faq-qa-list-item" key={data.question.toString()}>
            <h1 className="question">{data.question}</h1>
            <p className="answer">{data.answer}</p>
            {data.answer_1 && <p className="answer">{data.answer_1}</p>}
          </li>
        ))}
        {/*   <li className="faq-qa-list-item">
        <h1 className="question">
          Is my SaaS application data safe in the cloud? or Will my SaaS
          data remain secure?
        </h1>
        <p className="answer">
          Our cloud services are secured with high-level cloud security.
          We offer increased security and manageability with dedicated
          VPC hosting to ensure the safety of our user's data.{" "}
        </p>
      </li>
      <li className="faq-qa-list-item">
      <h1 className="question">How can I know my data is safe?</h1>
      <p className="answer">
          We back ourselves up with reliable data security and privacy
          policies ingrained in our product engineering and service
          delivery procedures.
        </p>
      </li>*/}
      </ul>
    </div>
  );
}

export default Security;
