import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterTen() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter July 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterTen-container">
        <MetaTags>
          <title>
            UK and France Launch Joint Biodiversity Credits Initiative.
          </title>
          <meta
            name="description"
            content="The United Kingdom and France have joined forces to launch a
            biodiversity credits initiative to support companies in their efforts
            to contribute to nature recovery."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          UK and France Launch Joint Biodiversity Credits Initiative.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/july2023-1.jpg").default}
            alt="july2023-1"
          />
        </figure>
        <p>
          The United Kingdom and France have joined forces to launch a
          biodiversity credits initiative to support companies in their efforts
          to contribute to nature recovery. The Environment Secretary of the UK
          and French State Minister announced this collaborative agreement in
          June 2023.
        </p>
        <p>
          The global roadmap aims to foster international cooperation to benefit
          people and the planet.
        </p>
        <p>
          A pivotal component of this initiative is establishing a joint
          UK-France advisory panel, which will bring together diverse
          perspectives on biodiversity credits worldwide. Biodiversity credits
          serve as instruments that enable individuals and companies to invest
          in environmental projects that promote biodiversity conservation and
          habitat restoration. These projects can encompass a range of habitats,
          including rainforests, oceans, grasslands, and more.
        </p>
        <p>
          Each credit will provide a record of the location of the environmental
          action, the entities involved in its development, and the metrics used
          for measurement and verification. During the Summit for a New
          Financial Pact in Paris, the UK's Environment Secretary presented a
          plan to amplify global efforts in supporting companies that purchase
          credits contributing to nature recovery credibly.
        </p>
        <p>
          The global roadmap will facilitate the exchange of best practices
          regarding governance mechanisms for credit funding and monitoring
          systems to ensure biodiversity improvements and equitable income
          distribution to indigenous peoples and local communities.
        </p>
        <p>
          The initiative will work towards important international milestones,
          including the 2024 United Nations Biodiversity Conference (COP16),
          where biodiversity financing will be a prominent topic of discussion.
          To steer a joint endeavour, representatives from the UK and France
          will co-chair an advisory panel. This panel will unite experts
          worldwide to form diverse working groups to drive positive change in
          biodiversity conservation and restoration efforts.
        </p>
        <p>
          Through its 10 Point Plan, the UK government has already emphasized
          the urgent need to explore the role of biodiversity credits in closing
          the nature finance gap. France's announcement follows the launch of
          the Positive Conservation Partnerships (PCPs) at COP27, which
          recommended the scaling up of biodiversity credits.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            By collaborating on developing and implementing biodiversity
            credits, the UK and France are leading the way in promoting
            innovative solutions to address the global biodiversity crisis and
            achieve a nature-positive economic system.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Australia Takes Bold Step Towards Climate Risk Management in Financial
          Sector.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/july2023-2.jpg").default
              }
              alt="july2023-2"
            />
          </figure>
        </div>
        <p>
          The country's financial regulator - The Australian Prudential
          Regulation Authority (APRA) has been mandated to incorporate climate
          change risks for the first time.This decision underscores the
          recognition of the importance of climate risk management in the
          financial industry.
        </p>
        <p>
          The updated Statement of Expectations for APRA, released by Australian
          Treasure, emphasizes promoting transparency and adopting climate
          reporting standards. The Steps towards mandating climate-related
          disclosures demonstrate Australia's commitment to proactively
          addressing climate risks and fostering sustainable finance practices.
        </p>
        <p>
          Australia's government launched a consultation paper in December 2022
          outlining plans to develop a climate risk disclosure framework for
          businesses and financial institutions. This paper recognized the
          materiality of physical and transition climate risks to the global
          financial system and stressed the importance of disclosure as a
          crucial tool in managing these risks. In parallel, the Treasury
          Department was entrusted with developing a comprehensive sustainable
          finance strategy, to which climate risk disclosure is integral.
        </p>
        <p>
          APRA has already taken proactive steps to assess climate risk factors
          in the financial system. It conducted its first Climate Vulnerability
          Assessment (CVA) with the country's five largest banks. The assessment
          aimed to model the future financial impact of climate change on these
          banks' businesses and explore potential responses to both physical and
          transition climate risks. APRA's efforts demonstrate a commitment to
          understanding and managing climate-related risks within Australia's
          financial system.
        </p>
        <p>
          In response to the government's new expectations, APRA has released a
          statement of intent reaffirming its dedication to promoting prudent
          practices and transparency regarding climate-related risks. This
          commitment aligns with the government's sustainable finance reforms
          and signifies a positive shift towards greater awareness and action in
          the financial sector.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Australia is making significant strides in addressing climate
            change-related risks within its financial sector. By integrating
            climate risk considerations and fostering transparency, Australia
            sets an example for countries worldwide. Climate change poses
            substantial risks to the stability of financial systems and
            economies, necessitating proactive measures. Australia's mandate for
            APRA to incorporate climate risks sends a clear message that
            regulators and financial institutions must understand, manage, and
            disclose climate-related risks.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          IFRS Releases Global Sustainability and Climate Reporting Standards.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/july2023-3.jpg").default}
            alt="july2023-3"
          />
        </figure>
        <p>
          The International Sustainability Standards Board (ISSB) of the IFRS
          Foundation has officially launched its new global sustainability and
          climate disclosure standards. These standards are expected to serve as
          the foundation for emerging sustainability reporting requirements by
          regulators worldwide, marking a significant milestone in integrating
          sustainability reporting into the broader financial reporting process.
        </p>
        <p>
          Starting in January 2024, the new standards will apply to annual
          reporting periods, and companies will begin issuing disclosures
          against these standards in 2025.
        </p>
        <p>
          The ISSB was established in November 2021 at the COP26 climate
          conference to develop IFRS Sustainability Disclosure Standards. Driven
          by demand from investors, companies, governments, and regulators,
          these standards aim to provide a global baseline of disclosure
          requirements to consistently understand the impact of sustainability
          risks and opportunities on companies' prospects.
        </p>
        <p>
          Regulators in major jurisdictions, including Europe, the UK, and the
          US, have introduced or are preparing mandatory sustainability
          reporting requirements. The ISSB standards are expected to influence
          these regulations heavily.
        </p>
        <p>
          The new standards are "IFRS S1 General Requirements for Disclosure of
          Sustainability-related Financial Information" and "IFRS S2
          Climate-related Disclosures." They include disclosures related to
          general sustainability and climate-specific risks and opportunities,
          encompassing governance, processes, controls, and procedures used to
          monitor and manage these risks and opportunities.
        </p>
        <p>
          IFRS S1 mandates companies to disclose information about
          sustainability-related risks and opportunities that could reasonably
          impact their cash flows, access to finance, or cost of capital in the
          short, medium, or long term. These risks and opportunities arise from
          interactions between the entity and stakeholders, society, the
          economy, and the natural environment throughout the value chain,
          including direct and indirect impacts on business operations.
        </p>
        <p>
          IFRS S2, designed to complement S1, outlines specific climate-related
          disclosures. It requires reporting on greenhouse gas emissions for
          Scopes 1, 2, and 3. The ISSB has granted an extra year for reporting
          on Scope 3 emissions. Additionally, companies must disclose the
          percentage of assets and business activities vulnerable to
          climate-related risks and opportunities, the allocation of capital
          expenditure and investments towards climate-related factors, and how
          climate considerations affect executive remuneration.
        </p>
        <p>
          The ISSB plans to collaborate with jurisdictions and companies to
          support adopting these standards. It will establish a Transition
          Implementation Group to assist companies in applying the standards
          effectively.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The standards will contribute to enhanced decision-making,
            accountability, and the integration of sustainability considerations
            into financial reporting, ultimately promoting a more sustainable
            and resilient global economy.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          The Global Gender Gap Report 2023 Reveals Slowed Advancement Towards
          Gender Parity.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/july2023-4.jpg").default}
            alt="july2023-4"
          />
        </figure>
        <p>
          The World Economic Forum's Global Gender Gap Report 2023 reveals that
          progress towards gender parity has slowed, with the projected year for
          closing the gender gap remaining unchanged at 2154. While the overall
          gender gap has closed by 0.3 percentage points compared to the
          previous year, the rate of change has significantly decreased. The
          report examines four key areas: economic participation and
          opportunity, educational attainment, health and survival, and
          political empowerment.
        </p>
        <p>
          The 2023 report highlights some positive developments. The educational
          attainment gap has improved, with 117 out of 146 countries closing at
          least 95%. However, the economic participation and opportunity gap
          have only closed by 60.1%, and the political empowerment gap by 22.1%.
          Achieving gender parity in these areas would require 131 years for
          overall parity, 169 years for economic parity, and 162 years for
          political parity.
        </p>
        <p>
          The report emphasizes that women continue to face challenges in the
          labor market, especially amidst converging crises such as the COVID-19
          pandemic. Although more women have entered the workforce, the gaps in
          the labor market persist, with women experiencing higher unemployment
          rates than men. In senior leadership positions, women are
          underrepresented, accounting for only 32.2% globally. Furthermore,
          women's participation in STEM must be higher, hindering their access
          to high-growth and high-earning sectors.
        </p>
        <p>
          The report also provides regional highlights. Iceland is the most
          gender-equal country for the 14th consecutive year. Europe surpasses
          North America in gender parity, with 76.3% parity. Latin America and
          the Caribbean have made incremental progress, while Eurasia and
          Central Asia experience stagnation. East Asia and the Pacific have
          experienced a decline, and Sub-Saharan Africa's progress has been
          uneven. Southern Asia, the Middle East, and North Africa still face
          significant challenges in achieving gender parity.
        </p>
        <p>
          The report calls for collective and bold action from both the private
          and public sectors to address these gaps. Increasing women's economic
          participation and achieving gender parity in leadership positions are
          highlighted as crucial steps towards broader gender equality.
        </p>
        <p>
          Organizations that prioritize gender strategies attract top talent and
          perform better economically. Gender parity is also vital for financial
          stability and overall economic performance. Initiatives such as the
          Gender Parity Accelerators and the DEI Lighthouse Programme bring
          together governments and businesses to advance economic parity and
          share successful diversity, equity, and inclusion initiatives. These
          collaborative efforts accelerate progress and create more inclusive
          and resilient societies and economies.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The World Economic Forum's Global Gender Gap Report 2023 highlights
            the importance of gender equality for sustainable development.
            Achieving gender parity contributes to environmental sustainability,
            social equity, and effective governance. It calls for collective
            action by government and business leaders to accelerate progress
            towards gender equality and foster inclusive, sustainable economic
            growth.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Norway Implements Mandate for Solar Panels in New Government and
          Commercial Buildings.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/july2023-5.jpg").default}
            alt="july2023-5"
          />
        </figure>
        <p>
          Norway has taken a significant step towards expanding the use of solar
          power by mandating the installation of solar panels in all new
          government buildings from 2024.
        </p>
        <p>
          The agreement specifies that new government buildings must incorporate
          solar power or locally produced energy, except in cases where
          project-specific circumstances prevent their inclusion. The
          requirement will also extend to more considerable upgrades and
          refurbishment projects. Furthermore, the government plans to introduce
          legislation in 2024 to enforce the same rules for more significant
          commercial buildings.
        </p>
        <p>
          The budget deal also aims to streamline the permitting process for new
          commercial solar power installations and remove barriers to local
          distribution of solar-generated electricity. These measures are
          expected to facilitate the wider deployment of solar power in Norway.
        </p>
        <p>
          To further bolster the country's solar power capacity, Norway has set
          a target of producing eight terawatt hours (TWh) of solar power
          annually by 2030. This target represents approximately 5% of the
          country's annual electricity output, primarily from hydro and wind
          power sources.
        </p>
        <p>
          Solar power contributes only around 0.3 TWh per year in Norway,
          according to the energy regulator NVE. In comparison, neighbouring
          countries like Sweden generate 1.2% of their power from solar energy,
          while Denmark's solar power meets 6.1% of its energy demand.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Norway aims to diversify its energy mix by adopting solar power and
            achieving a more sustainable and resilient future. And starting with
            the government buildings sets an excellent example for the private
            owners and influences other countries to look at the advantages of
            solar power.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTen;
