import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureTwo() {
  return (
    <section className="data-management-feature-section">
      <div className="data-management-feature-container">
        <div className="data-management-feature-wrapper">
          <div className="data-management-feature-image-wrapper">
            <div className="data-management-feature-image">
              <img src={assets.images.dataTrackImage2} alt="updapt" />
            </div>
          </div>
          <div className="data-management-feature-content-wrapper">
            <div className="data-management-feature-content">
              <h2 className="data-management-feature-title">
                Easy Configuration of ESG Metrics
              </h2>
              <p className="data-management-feature-description">
                Configure and calculate ESG metrics using our database
                integrated with the latest standards, methodologies, and
                conversion factors. Our database is updated with the most recent
                DEFRA Emission Factors and IPCC Global Warming Potentials (GWPs)
                for accurate GHG accounting.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureTwo;
