import React from "react";

function Intro({ pageDetails }) {
  return (
    <section className="page-banner" id="faq">
    <div className="container">
      <div className="page-content-wrapper">
        <h1 className="page-title">
        Frequently Asked Questions (FAQs)
        </h1>
       
      </div>
    </div>
  </section>
  );
}

export default Intro;
