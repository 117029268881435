import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureOne() {
  return (
    <>
      <section className="pa-feature-section feature-1">
        <div className="pa-feature-container">
          <div className="pa-feature-wrapper">
            <div className="pa-feature-content-wrapper">
              <div className="pa-feature-content">
                <h2 className="pa-feature-title">Streamline ESG Assessment</h2>
                <p className="pa-feature-description">
                  Stay ahead of the curve by conducting regular ESG assessments
                  of your portfolio. Our platform enables you to track ESG
                  metrics and make informed decisions based on up-to-date
                  insights.
                </p>
              </div>
            </div>
            <div className="pa-feature-image-wrapper">
              <div className="pa-feature-image">
                <object
                  data={assets.images.paImage1}
                  aria-label="paImage1"
                ></object>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureOne;
