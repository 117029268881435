import React from "react";

function Intro() {
  return (
    <section className="page-banner">
      <section className="home-solution-section" id="our-products-section">
        <div className="circle-1">
          <div className="circle-2"></div>
          <div className="circle-rotate-1"></div>
          <div className="circle-rotate-2"></div>
        </div>
      </section>

      <div className="container">
        <div className="page-content-wrapper">
          <h1 className="page-title">Get in Touch</h1>
          <p className="page-description">
            Drop us a message and we will get back to you.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Intro;
