/** @format */

import React from "react";
import Podcast from "../component/Podcast";
import { withRouter } from "react-router-dom";

function PodcastContainer(props) {
  const handleClick = (link) => {
    props.history.push(link);
  };
  return <Podcast podcastData={props?.podcastData} handleClick={handleClick} />;
}

export default withRouter(PodcastContainer);
