import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureFour() {
  return (
    <section className="pa-feature-section feature-4">
      <div className="pa-feature-container">
        <div className="pa-feature-wrapper">
          <div className="pa-feature-image-wrapper">
            <div className="pa-feature-image">
              <object
                data={assets.images.paImage4}
                aria-label="paImage4"
              ></object>
            </div>
          </div>
          <div className="pa-feature-content-wrapper">
            <div className="pa-feature-content">
              <h2 className="pa-feature-title">
                Enhanced Portfolio Due Diligence for Sustainable Investments
              </h2>
              <p className="pa-feature-description">
                Mitigate risks and make informed investment decisions with our
                robust due diligence tools. Assess the ESG performance of
                potential targets, identify red flags, and align your
                investments with sustainability goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureFour;
