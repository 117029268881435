import React from "react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../SCSS/styles.css";

function ApplicationForm({
  mobile,
  setMobile,
  handleTextOnChange,
  handleSendRequest,
  formObj,
  fileInputRef,
  submitLoader,
}) {
  return (
    <section className="job-application-form-section">
      <div className="circle-1">
        <div className="circle-2"></div>
        <div className="circle-rotate-1"></div>
        <div className="circle-rotate-2"></div>
      </div>
      <div className="circle-3">
        <div className="circle-4"></div>
      </div>
      <div className="circle-5"></div>
      <div className="container">
        <div className="form-header">Apply for this Job</div>
        <form className="contact-form-wrapper">
          <div className="row">
            <div className="contact-form-field-name">
              <label className="form-label" htmlFor="contact-fname">
                First Name <span className="required">*</span>
              </label>
              <input
                id="firstName"
                name="firstName"
                className="form-input"
                type="text"
                placeholder="John"
                value={formObj.firstName}
                onChange={handleTextOnChange}
              ></input>
            </div>

            <div className="contact-form-field-name">
              <label className="form-label" htmlFor="contact-lname">
                Last Name <span className="required">*</span>
              </label>
              <input
                id="lastName"
                name="lastName"
                className="form-input"
                type="text"
                placeholder="Smith"
                value={formObj.lastName}
                onChange={handleTextOnChange}
              ></input>
            </div>
            <div className="contact-form-field-email">
              <label className="form-label" htmlFor="contact-email">
                Contact Email <span className="required">*</span>
              </label>
              <input
                id="email"
                name="email"
                className="form-input"
                type="email"
                placeholder="johnsmith@company.com"
                value={formObj.email}
                onChange={handleTextOnChange}
              ></input>
            </div>

            <div className="contact-form-field-mobile">
              <label className="form-label" htmlFor="contact-mobile-code">
                Mobile <span className="required">*</span>
              </label>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                placeholder="Enter phone number"
                defaultCountry="IN"
                value={mobile}
                onChange={setMobile}
              />
            </div>

            <div className="contact-form-field">
              {/* <div className="file-upload">Upload CV (pdf, doc or docx)</div> */}
              <div className="file-upload-cover">
                <label htmlFor="file">Upload CV (pdf)</label>
                <input
                  accept=".pdf"
                  type="file"
                  name="file"
                  id="file"
                  ref={fileInputRef}
                  className="file-upload"
                  onChange={(e) => handleTextOnChange(e, "file")}
                />
              </div>
            </div>
            <div className="contact-us-form-field-email line-gap">
              <div className="agreement-wrapper">
                <div className="chekbox-container">
                  <input
                    type="checkbox"
                    className="agreement-checkbox"
                    onChange={(e) => handleTextOnChange(e, "agree")}
                  />{" "}
                </div>

                <span className="agreement-text-with-checkbox">
                  * Updapt will collect and use the personal information you
                  provide, for the purposes of processing your application and
                  the recruitment process, and in accordance with our{" "}
                  <a
                    href="/privacy"
                    target="_blank"
                    style={{ borderBottom: "1px dotted blue", color: "blue" }}
                  >
                    Privacy Policy
                  </a>
                  . By clicking “Accept” and filing your application you agree
                  to such collection and use.
                </span>
              </div>

              <br />
            </div>

            <div className="contact-form-field-submit">
              <input
                className={
                  submitLoader
                    ? "send-request-btn disabled"
                    : "send-request-btn"
                }
                type="submit"
                value={
                  submitLoader ? "SUBMITTING..." : "SUBMIT YOUR APPLICATION"
                }
                onClick={handleSendRequest}
              ></input>
            </div>
          </div>
        </form>
      </div>
      <NotificationContainer />
    </section>
  );
}

export default ApplicationForm;
