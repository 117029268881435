import React from "react";

function Intro({ pageDetails }) {
  return (
    <>
      <section className="about-banner-section">
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title">About Updapt</h1>
            <p className="about-description">
            Updapt is an ESG Tech Co. providing SaaS solutions to Enterprises and Funds across industries, sizes and geographies to digitally track and monitor ESG Data Lifecycle and compute carbon footprint for multiple sites, businesses and portfolios along with deep Analytics and Reporting aligned to Global Sustainability Standards. 
     </p>
            <p className="about-description">
          
            Our digital solutions would enable businesses to enhance ESG performances through data insights and benchmarking, and have accomplished engagements with key stakeholders such as Investors, Rating Agencies, Lenders, Regulators, Board, Customers, Supply Chain Partners.
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
    </>
  );
}

export default Intro;
