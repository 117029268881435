import React, { useState } from "react";
import Thankyou from "../components/main";
import resourceValues from "../../../shared/constants/resourceValues.js";
import assets from "../../../shared/constants/assetVariables";
function ThankyouContainer() {
  const [licencingDetails] = useState([
    {
      name: "Essential",
      images: assets.images.aa100Image,
    },
    {
      name: "Essential",
      images: assets.images.wwfImage,
    },
    {
      name: "Essential",
      images: assets.images.globalImage,
    },
    {
      name: "Essential",
      images: assets.images.unitedImage,
    },
    {
      name: "Essential",
      images: assets.images.cdpImage,
    },
    {
      name: "Essential",
      images: assets.images.sasbImage,
    },
    {
      name: "Essential",
      images: assets.images.griImage,
    },
    {
      name: "Essential",
      images: assets.images.priImage,
    },
  ]);
  const [blogsData] = useState(resourceValues);
  const homeBlogsData = blogsData.slice((blogsData.length - 4), blogsData.length);
  return <Thankyou   blogsData={homeBlogsData} licencingDetails={licencingDetails}></Thankyou>;
}

export default ThankyouContainer;
