import React from "react";
import assets from "../../../shared/constants/assetVariables";
import { NavLink, withRouter } from "react-router-dom";

function Demo() {
  return (
  
    <section className="demo"
    style={{ backgroundImage: `url(${assets.images.footerDemoBackground})` }}
     //style={{ backgroundImage: `url(${assets.images.footerDemoBackground})` }}
    >
      
      <div className="demo-container">
      <div className="demo-section-title-wrapper">
					<h2 className="demo-section-title">
				Ready to take meaningful action on your ESG journey?
					</h2>
				</div>
        <div className="demo-section-button-wrapper">
       
          <NavLink to="/demo" className="demo-section-button">
         Get a demo
            </NavLink>
			
				</div> 
      </div>
     </section>
  
  );
}

export default withRouter(Demo);
