import React from "react";
import "../SCSS/styles.css";
function Section3() {
  return (
    <div className="section-container">
      <div className="section-3-inner-container">
        <div className="left-section">
          <div className="content-container">
            <div className="inner-container">
              <h2 className="section3-heading">Align IROs with Your Business Priorities</h2>
              <p className="section3-description">
                Customize settings to manage Impacts, Risks, and Opportunities (IROs) effectively,
                ensuring your assessments align with your business priorities.
              </p>
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="img-container">
            <img src={require("../../../../assets/images/ma-3.svg")} className="section3-img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
