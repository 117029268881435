import React from "react";

function Intro({pageDetails}) {

  return (
    <section className="page-banner">
    <div className="container">
      <div className="page-content-wrapper">
        <h1 className="page-title">Cookie Policy</h1>
      </div>
    </div>
  </section>

  );
}

export default Intro;