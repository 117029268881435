import React from "react";
import "../SCSS/styles.scss";

import MetaTags from "react-meta-tags";

function BlogFourteen() {
  return (
    <>
      <div className="BlogFourteen-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Debunking the Myths around Climate Change</title>
          <meta
            name="description"
            content="With Climate Change picking up steam, there has been a lot of confusion and misinformation about it.
          "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
          <meta
            property="og:url"
            content="https://www.updaptcsr.com/resource/blogs/debunking-the-myths"
          />
          <meta
            property="og:description"
            content="With Climate Change picking up steam, there has been a lot of confusion and misinformation about it."
          ></meta>
          <meta
            property="og:image"
            content="../../../../../assets/Blogs/blog14.jpg"
          ></meta>
        </MetaTags>
        <div className="blog-title">
          Debunking the Myths around Climate Change
        </div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Deeksha</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Nov 09, 2021</div>
              <div className="blog-read-time">3 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog14.jpg").default}
            alt="Blog-14"
          />
        </div>
        <p>
          With Climate Change picking up steam, there has been a lot of
          confusion and misinformation about it. Despite scientific facts, data,
          and models confirming the threat of climate change, some deny it. So
          in this post, we will try to refute some of the common myths around
          climate change.
        </p>

        {/* <p>
          Even though certain electric vehicles have been in the market for a
          few years, many individuals are skeptical of their performance and
          contribution to greener mobility.
        </p>
        <p>
          It is important to note that no vehicle will ever be 100% clean.
          However, the introduction of electric vehicles (EVs) has resulted in
          significant advancements in achieving some level of sustainability,
          thanks to lithium-ion batteries.
        </p> */}

        {/* <p>
          Conventional vehicles use ICE (Internal Combustion Engine), whereas
          EVs use electric motors powered by rechargeable lithium-ion batteries.
          These batteries are the same kinds that power your smartphones and
          laptops and use external power sources for charging. As a result, no
          fuel is burned while driving, and hence no CO2 is released into the
          atmosphere.
        </p> */}

        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>
                Myth 1: Planting more trees can solve the problem of climate
                change.
              </b>
            </p>
          </div>
        </div>

        <p>
          Fact: While reforestation can help reduce climate change, it is
          insufficient to reverse the global disaster. Trees capture CO2
          emissions from the atmosphere through photosynthesis and store this
          carbon in biomass, such as tree trunks, roots, and leaves, as well as
          soil. Planting trees may thus aid in climate change mitigation, but
          only if they are not later cut down and burned for energy. Also, most
          projects focus solely on the number of seedlings planted, with little
          attention paid to keeping them alive in the long run. Tree-planting
          projects can sometimes cause more harm than good. Planting trees that
          are not adapted to the local climate, planting only one type of tree
          rather than a mix of species, or planting trees in logged areas do not
          help offset climate change. Tree planting can help address the climate
          emergency, but only if emissions are controlled and existing forests
          are protected and restored.
        </p>
        <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 2: China and India are the primary contributors to climate
            change, and both countries must take action.
          </b>
        </p>
        {/* <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-13(graph).svg")}
            alt="Blog-13(graph)"
          />
        </div> */}
        <p>
          Fact: China and India are two of the world's largest GHG emitters due
          to their heavy reliance on coal. At the same time, these countries are
          also the world's top solar energy harnessers. Due to historically high
          emissions by developed economies such as Europe and the United States,
          the developing economies are now being forced to reduce their
          emissions. Now, when it's time for these developing countries to
          progress, they're being forced to implement high-cost climate-change
          mitigation measures. This does not appear to be equitable, and wealthy
          economies must compensate these countries for combating climate
          change. Under the current climate crisis, all the nations will have to
          help each other collectively.
        </p>

        {/* <div className="sub-header">
          <div className="sub-header-content">
            So, is it realistic for electric vehicles to contribute
            significantly to sustainability in these challenging scenarios?
          </div> */}
        {/* </div> */}

        <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 3: Renewables are expensive and can only work when it's windy
            and not cloudy.
          </b>
        </p>
        <p>
          Fact: Today, renewables are just as cheap as any other source of
          energy. According to the International Renewable Energy Agency
          (IRENA), renewable energy costs continue to reduce "substantially"
          year on year. A continuous supply of energy can be ensured by using a
          mix of powers such as solar, wind, natural gas, etc. Furthermore,
          rapid advances in battery capacity can help capture energy while it is
          available and store it for later use.
        </p>

        <p style={{ marginTop: "25px", marginBottom: "20px" }}>
          <b style={{ color: "black", fontSize: "22px" }}>
            Myth 4: Individuals cannot make any difference in the climate change
            cause.
          </b>
        </p>
        <p>
          Fact: There are a lot of things that individuals can do to fight
          climate change. Sustainable lifestyle practices by putting the 3 Rs-
          Reduce, Reuse, and Recycle into practice and choosing renewables over
          fossil fuels can help a great deal. Reducing meat intake by adopting a
          flexitarian diet (one mainly vegetarian but allows for the occasional
          meat dish) can also help reduce more than 40% carbon footprint.
          However, it wouldn't be right to say that only lifestyle changes can
          reduce emissions. Given the scope of the problem, a policy-driven
          strategy, in addition to individual initiatives, is required.
        </p>
        <p>
          Climate change is real and is a global issue, and it will affect every
          country and individual if measures are not taken to address it. People
          and regulatory bodies working together can have a significant impact
          on people and the planet.
        </p>
        {/* <p>
          Only a coherent makeover, in which all elements are tied to a common
          goal, can help us economically and environmentally.
        </p> */}
      </div>
    </>
  );
}
export default BlogFourteen;
