import React from "react";
import "../SCSS/styles.css";
import assets from "../../../../shared/constants/assetVariables";
import MetaTags from "react-meta-tags";

function Clients({
  index,
  clientDetails,
  handleBackButton,
  handleNextButton,
  clientLogos,

  characterCount,
  handleReadMore,
  readMoreBool,
}) {
  return (
    <>
      <MetaTags>
        <title>Clients - Updapt</title>
        <meta
          name="description"
          content="Updapt is the most sophisticated organisation in the world and provides solutions to various types of organisations with the help of our Enhance ESG software."
        />
        <meta property="og:title" content="Clients - Updapt" />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <div className="clients-main-container">
        <div className="section-2">
          <div className="section-2-inner-container">
            <div className="client-heading">
              <span className="heading">Our Clients</span>
            </div>
            <div className="client-logos">
              {clientLogos.map((ele) => {
                return (
                  <img
                    key={ele}
                    src={
                      ele.split(".")[1] !== "svg"
                        ? require(`../../../../assets/images/client_logos/${ele}`)
                            .default
                        : require(`../../../../assets/images/client_logos/${ele}`)
                    }
                    alt={ele}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="section-1">
          <div className="client-banner">
            <div className="clients-slides">
              <div className="main">
                <div className="slide-container">
                  <div className="back-button">
                    {index !== 0 ? (
                      <img
                        onClick={handleBackButton}
                        src={assets.images.nextbutton}
                        alt="previous"
                      />
                    ) : (
                      <img
                        onClick={handleBackButton}
                        src={assets.images.grayNextArrow}
                        style={{ cursor: "auto" }}
                        alt="next"
                      />
                    )}
                  </div>
                  <div className="home-client-main">
                    <div className="back"></div>
                    <div
                      className={`home-clients-section ${
                        readMoreBool ? "home-clients-section-full-view" : ""
                      }`}
                    >
                      <div className="home-clients-wrapper">
                        <div className="clients-content-wrapper">
                          <div className="clients-content-wrapper-image">
                            <link
                              rel="preload"
                              href={clientDetails[index]?.clientIcon}
                              as="image"
                            />
                            <img
                              src={assets.images.quote}
                              style={{ width: "6%" }}
                              alt="clients-accenture"
                            />
                            <h1>
                              <img
                                src={clientDetails[index]?.clientLogo}
                                style={{ width: "100%" }}
                                alt="clientLogo"
                              />
                            </h1>
                          </div>
                          <div className="client-desc">
                            {characterCount > 641 && (
                              <div
                                className={
                                  readMoreBool
                                    ? "client-desc-content"
                                    : "client-desc-content-hidden"
                                }
                              >
                                {clientDetails[index]?.ClientDesc.split(
                                  "\n"
                                ).map((line) => {
                                  return (
                                    <p key={line}>
                                      {line} <br />
                                      <br />
                                    </p>
                                  );
                                })}
                              </div>
                            )}

                            {characterCount < 641 && (
                              <p className="client-desc-content-without-read-more">
                                {clientDetails[index]?.ClientDesc}
                              </p>
                            )}

                            {characterCount > 641 && !readMoreBool && (
                              <div
                                className="read-more-btn"
                                onClick={() => handleReadMore()}
                              >
                                Read more
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="clients-logo-wrapper">
                          <div className="clients-logo-wrapper-icon">
                            <img
                              src={clientDetails[index]?.clientIcon}
                              alt="clients-accenture"
                              className="img"
                              style={{ width: "74px" }}
                              preload="true"
                            />
                          </div>
                          <div className="clients-logo-wrapper-author">
                            <h1 className="author-heading">
                              {clientDetails[index]?.authorName}
                            </h1>
                            <p className="para">
                              {clientDetails[index]?.authorDesc}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="next-button">
                    {index !== clientDetails.length - 1 ? (
                      <img
                        onClick={handleNextButton}
                        src={assets.images.backArrow}
                        alt="previous"
                      />
                    ) : (
                      <img
                        onClick={handleNextButton}
                        src={assets.images.grayBackArrow}
                        style={{ cursor: "auto" }}
                        alt="next"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Clients;
