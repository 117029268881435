import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./shared/components/ScrollToTop";
import CookieBot from "react-cookiebot";
import "aos/dist/aos.css";
ReactDOM.render(
  <React.StrictMode>
    <CookieBot
      domainGroupId="8f4a7d40-76d5-401a-a855-0cb7aa30e42a"
      language="ES"
    />

    <Router forceRefresh>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
