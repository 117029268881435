import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterTwentyOne() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter June 2024
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="newsletter-twenty-one-container">
        <MetaTags>
          <title>
            GRI Unveils 'CSRD Essential' to Streamline EU Sustainability
            Reporting.
          </title>
          <meta
            name="description"
            content="
            The Global Reporting Initiative (GRI) has introduced a new series titled 'CSRD Essentials' to help companies navigate the complexities of the EU's Corporate Sustainability Reporting Directive (CSRD).
            "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          GRI Unveils 'CSRD Essentials' to Streamline EU Sustainability
          Reporting.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/june2024-1.svg")}
            alt="june2024-1"
          />
        </figure>
        <p>
          The Global Reporting Initiative (GRI) has introduced a new series
          titled 'CSRD Essentials' to help companies navigate the complexities
          of the EU's Corporate Sustainability Reporting Directive (CSRD). This
          initiative aims to clarify and streamline the reporting requirements
          for organizations under the new directive.
        </p>
        <p>
          The CSRD, replacing the Non-Financial Reporting Directive (NFRD),
          significantly broadens the scope and depth of sustainability
          reporting. It now mandates that companies report on their
          environmental and social impacts, as well as the related financial
          risks and opportunities. The directive applies to approximately 42,500
          companies within the EU and numerous others with significant
          operations in the region.
        </p>
        <p>
          'CSRD Essentials' is designed to demystify the directive's
          requirements, providing clear guidance on topics such as the European
          Sustainability Reporting Standards (ESRS), which outline the specific
          disclosure obligations. These standards include general disclosures
          and sector-specific standards, ensuring that all relevant
          sustainability issues are covered comprehensively.
        </p>
        <p>
          The initiative also highlights the importance of double materiality,
          requiring companies to assess both the impact of their activities on
          the environment and society and how sustainability issues affect their
          financial performance. This holistic approach aims to foster
          transparency, accountability, and sustainable business practices
          across Europe.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The GRI CSRD Essentials offers companies a roadmap to navigate the
            intricacies of the CSRD with precision. By distilling complex
            regulations into actionable insights, it empowers organizations to
            seamlessly integrate sustainability into their core operations.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Boosting Climate Action, EPA Strengthens Methane Reporting for Oil &
          Gas Companies.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={require("../../../../../assets/images/june2024-2.svg")}
              alt="june2024-2"
            />
          </figure>
        </div>
        <p>
          The Environmental Protection Agency (EPA) has taken a critical stride
          in its fight against climate change. They've bolstered methane
          emission reporting requirements for oil and gas companies,
          highlighting the crucial role methane reduction plays in mitigating
          global warming.
        </p>
        <p>
          Data from the International Energy Agency (IEA) reveals a concerning
          fact: methane emissions from the oil and gas sector contribute roughly
          40% of the global energy industry's total. Methane, a greenhouse gas
          80 times more potent than carbon dioxide at trapping heat over 20
          years, necessitates significant reduction to achieve global climate
          goals.
        </p>
        <p>
          The EPA's new rule mandates stricter monitoring protocols for oil and
          gas facilities. Companies will now directly measure emissions from key
          sources like pneumatic pumps, well completions, and leak detection and
          repair programs. The rule also updates emissions calculation
          methodologies to ensure that reported data is highly accurate.
        </p>
        <p>
          This new regulation coincides with growing international efforts to
          tackle methane emissions. Over 100 countries signed the Global Methane
          Pledge, aiming for a collective 30% reduction by 2030. The EPA's rule
          aligns with this global initiative, showcasing US leadership in
          curbing potent greenhouse gases.
        </p>
        <p>
          By strengthening methane reporting requirements, the EPA is equipping
          itself with more robust data to pinpoint and address emission hotspots
          within the oil and gas industry. This data-driven approach will be
          instrumental in ensuring the sector fulfills its environmental
          obligations and contributes to a cleaner, more sustainable future.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The EPA's new methane rule tightens monitoring, but oil & gas
            companies can soar above compliance. Embrace cutting-edge drone
            sensors for leak detection, leverage data analytics for predictive
            maintenance, and prioritize fixing high-emission facilities.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Australia Invests in Clean Hydrogen to Power its Future.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/june2024-3.svg")}
            alt="june2024-3"
          />
        </figure>
        <p>
          Australia is making a significant push towards a clean energy future
          with a recent announcement of an A$8 billion (USD 5.3 billion)
          investment in clean hydrogen production and technology over the next
          decade. This substantial commitment is a cornerstone of Australia's
          ambitious plan to achieve net zero emissions by 2050.
        </p>
        <p>
          Clean hydrogen, produced using renewable energy sources like solar and
          wind power, offers a promising solution for decarbonizing sectors that
          are traditionally reliant on fossil fuels. These sectors include heavy
          industries like metals manufacturing and transportation, where
          electrification is challenging.
        </p>
        <p>
          While hydrogen itself is not a new discovery, producing it cleanly -
          without the greenhouse gas emissions associated with traditional
          methods - is a critical step in achieving a sustainable future.
          Currently, around 90 million metric tons of hydrogen are produced
          globally each year, but most of it comes from fossil fuels like
          natural gas through a process called steam methane reforming. This
          process releases carbon dioxide, creating a net positive impact on
          greenhouse gas emissions.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Australia's A$8 billion investment in clean hydrogen can push
            businesses to reduce their carbon footprints, lower energy costs
            through more efficient hydrogen technologies, access new markets,
            create green jobs, and enhance their sustainability credentials,
            aligning with global environmental standards and consumer
            expectations.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Hong Kong Unveils Comprehensive Green Taxonomy for Sustainable
          Finance.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/june2024-4.svg")}
            alt="june2024-4"
          />
        </figure>
        <p>
          The Hong Kong Monetary Authority (HKMA) has introduced the Hong Kong
          Taxonomy for Sustainable Finance,This taxonomy aims to provide a
          standardized framework for classifying and labeling financial products
          based on their environmental sustainability.
        </p>
        <p>
          By establishing a standardized framework, the taxonomy equips
          investors to make informed choices when backing environmentally
          conscious projects and businesses. This will steer capital towards
          genuine green initiatives and away from misleading practices.
        </p>
        <p>
          While initially encompassing 12 activities across sectors like power
          generation, transportation, and construction, the HKMA's taxonomy is a
          work in progress. The framework is designed to evolve, incorporating
          new sectors and activities, including those crucial for a sustainable
          transition.
        </p>
        <p>
          The taxonomy adheres to key principles such as alignment with the
          Paris Agreement, science-based criteria, and the 'Do No Significant
          Harm' (DNSH) approach. These principles ensure that financial
          activities not only contribute positively to environmental goals but
          also avoid adverse impacts on society and ecosystems.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Companies can adopt the Hong Kong Taxonomy by integrating its
            science-based criteria into their investment evaluation processes,
            ensuring alignment with sustainability goals. They should develop
            internal frameworks that prioritize environmentally positive
            projects, utilize transition categories for gradual shifts, and
            regularly assess compliance with global standards to mitigate
            greenwashing risks.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU Council Approves New Ecodesign Regulations for Sustainable
          Products.
        </div>
        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/june2024-5.svg")}
            alt="june2024-5"
          />
        </figure>

        <p>
          The EU Council has approved the Ecodesign for Sustainable Products
          Regulation (ESPR), setting comprehensive environmental requirements
          for nearly all products sold within the EU. This regulation replaces
          the 2009 directive, which was limited to energy-related products. The
          new framework focuses on enhancing product durability, repairability,
          and overall sustainability, supporting the EU's green transition
          across various sectors.
        </p>
        <p>
          The ESPR introduces a Digital Product Passport, providing detailed
          information on a product's environmental impact, including durability,
          recycled content, and availability of spare parts. This initiative is
          expected to empower consumers to make informed, sustainable choices
          while facilitating transparency and regulatory compliance.
        </p>
        <p>
          The regulation also bans the destruction of unsold textiles and
          footwear, addressing significant waste issues in these industries.
          This prohibition will apply universally within two years, with
          exemptions for small and medium-sized enterprises. By extending the
          lifespan of products and promoting recycling, the ESPR aims to
          significantly reduce the EU's carbon footprint.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The new regulations aim to reduce waste by enhancing product
            durability and recyclability, empower consumers with transparent
            environmental impact data through Digital Product Passports, and
            promote resource efficiency. These measures support economic
            sustainability by extending product lifecycles and ensuring
            compliance with EU market access requirements.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterTwentyOne;
