import React from "react";
import MetaTags from "react-meta-tags";
import NewBanner from "./NewBanner";
import UpdaptDescription from "./UpdaptDescription";
import Products from "./Products";
import Benefits from "./Benefits";
import Solutions from "./Solutions";
import Resources from "./Resources";
import GetDemoBanner from "./GetDemoBanner";
import GetFreeTrialBanner from "./GetFreeTrialBanner";
import Demo from "../../Demo";
import Clients from "./Clients";
import SecondaryDescription from "./SecondaryDescription";
import structuredData from "./structuredData.json";

function NewLandingPage({ productsList, resourceBlogs }) {
  return (
    <>
      <MetaTags>
        <title>Sustainability and ESG Reporting Software with Data Management For UAE</title>
        <meta
          name="description"
          content="Updapt is leading ESG Reporting Software Dedicatedly Designed For UAE countries That Include Data management, Emission management. It suits For All industries."
        />
        <meta
          property="og:title"
          content="Sustainability and ESG Reporting Software with Data Management For UAE"
        />
        <meta property="og:image" content="%PUBLIC_URL%/static/media/banner.jpg" />
        <link
          rel="alternate"
          hreflang="en-ae"
          href="https://www.updapt.com/uae-esg-reporting-software/ae/en/"
        />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </MetaTags>
      <NewBanner />
      <UpdaptDescription />
      <Solutions />
      <SecondaryDescription />
      <div className="new-landing-page-slider">
        <Clients />
      </div>
      <Products productsList={productsList} />
      <GetDemoBanner />
      <Benefits />
      <GetFreeTrialBanner />
      <Resources resourceBlogs={resourceBlogs} />
      <Demo />
    </>
  );
}

export default NewLandingPage;
