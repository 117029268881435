import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureTwo() {
  return (
    <section className="net-zero-feature-section">
      <div className="net-zero-feature-container">
        <div className="net-zero-feature-wrapper">
          <div className="net-zero-feature-image-wrapper">
            <div className="net-zero-feature-image">
              <img src={assets.images.nzImage2} alt="updapt" />
            </div>
          </div>
          <div className="net-zero-feature-content-wrapper">
            <div className="net-zero-feature-content">
              <h2 className="net-zero-feature-title">
                Explore a wide range of emission reduction strategies
              </h2>
              <p className="net-zero-feature-description">
                Navigate through a wide range of strategies that can help your
                organisation realise its net zero ambitions. Explore and
                evaluate the options available to your organisation to select
                and implement the plan that fits your requirement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureTwo;
