import React from "react";
import "../SCSS/styles.css";

import Intro from "./Intro";
import Subscribe from "./Subscribe";
import Posts from "./Posts";
import MetaTags from "react-meta-tags";
function Blogs({ handleClick, blogsData }) {
  return (
    <>
      <MetaTags>
        <title>Blog | Updapt</title>
        <meta
          name="description"
          content="Get distinct and vast detailed information about ESG as well as climate changes. In addition,get the latest business trends about esg and global suitability information."
        />
        <meta property="og:title" content="Blog | Updapt" />
        <meta
          property="og:url"
          content="https://www.updaptcsr.com/resource/blogs"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>

      <Intro />

      <Posts handleClick={handleClick} blogsData={blogsData} />

      <Subscribe />
    </>
  );
}
export default Blogs;
