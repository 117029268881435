import React from "react";
import Partner from "../Partners";
import { Route, useHistory } from "react-router-dom";
import PartnerForm from "../PartnersForm";
import Clients from "../Clients";
import Careers from "../Careers";
import Job from "../Careers/Job";

function Company() {
  const history = useHistory();
  let path = window.location.href;
  if (path.includes("/company/partners")) {
    history.replace("/company/esg-partners");
  } else if (path.includes("/company/clients")) {
    history.replace("/company/esg-clients");
  }
  return (
    <div>
      <Route path="/company/esg-partners" exact>
        <Partner></Partner>
      </Route>
      <Route path="/company/partner-form" exact>
        <PartnerForm></PartnerForm>
      </Route>
      <Route path="/company/esg-clients" exact>
        <Clients />
      </Route>
      <Route path="/company/careers" exact>
        <Careers />
      </Route>
      <Route path="/company/careers/job/:jobId" exact>
        <Job />
      </Route>
    </div>
  );
}
export default Company;
