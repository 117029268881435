import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureTwo() {
  return (
    <section className="erm-feature-section feature-2">
      <div className="erm-feature-container">
        <div className="erm-feature-wrapper">
          <div className="erm-feature-image-wrapper">
            <div className="erm-feature-image">
              <object
                data={assets.images.ermImage2}
                aria-label="ermImage2"
              ></object>
            </div>
          </div>
          <div className="erm-feature-content-wrapper">
            <div className="erm-feature-content">
              <h2 className="erm-feature-title">
                Make smarter decisions with dashboards
              </h2>
              <p className="erm-feature-description">
                Delivers in-depth insights into the organization risks through
                real-time dashboards including many visualizations, risk trends
                and more, thereby speeding up the decision making process.
              </p>
              <div className="datatrack-grey-circle"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureTwo;
