import React from "react";
import assets from "../../../../shared/constants/assetVariables";

function FeatureThree() {
  return (
    <section className="pa-feature-section feature-3">
      <div className="pa-feature-container">
        <div className="pa-feature-wrapper">
          <div className="pa-feature-content-wrapper">
            <div className="pa-feature-content">
              <h2 className="pa-feature-title">Effortless ESG Reporting</h2>
              <p className="pa-feature-description">
                Simplify the reporting process and ensure compliance with
                industry regulations. UpdaptPE supports reporting requirements
                such as ILPA (Institutional Limited Partners Association) and
                SFDR (Sustainable Finance Disclosure Regulation), IFC
                (International Finance Corporation), making it effortless for
                you to meet your reporting obligations.
              </p>
            </div>
          </div>
          <div className="pa-feature-image-wrapper">
            <div className="pa-feature-image">
              <object
                data={assets.images.paImage3}
                aria-label="paImage3"
              ></object>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureThree;
