import React from "react";
import "../SCSS/styles.scss";

function BlogThirteen() {
  return (
    <>
      <div className="BlogThirteen-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <div className="blog-title">Are EVs the way of the future?</div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Deeksha</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Oct 28, 2021</div>
              <div className="blog-read-time">4 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-13.jpg").default}
            alt="Blog-13"
          />
        </div>
        <p>
          As fuel costs continue to rise, more individuals are looking for
          alternate solutions, such as electric vehicles. EVs have gained
          popularity in numerous nations throughout the world as a result of the
          recent focus on environmental sustainability. They appear to be a
          great way to tackle climate change while producing zero emissions. Can
          EVs help with the move to sustainability? Lets have a look.
        </p>

        <p>
          Even though certain electric vehicles have been in the market for a
          few years, many individuals are skeptical of their performance and
          contribution to greener mobility.
        </p>
        <p>
          It is important to note that no vehicle will ever be 100% clean.
          However, the introduction of electric vehicles (EVs) has resulted in
          significant advancements in achieving some level of sustainability,
          thanks to lithium-ion batteries.
        </p>

        <p>
          Conventional vehicles use ICE (Internal Combustion Engine), whereas
          EVs use electric motors powered by rechargeable lithium-ion batteries.
          These batteries are the same kinds that power your smartphones and
          laptops and use external power sources for charging. As a result, no
          fuel is burned while driving, and hence no CO2 is released into the
          atmosphere.
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            Despite their significant advantage in terms of energy efficiency,
            EVs have some drawbacks to be addressed.
          </div>
        </div>

        <p>
          Electric vehicles, without a doubt, offer a way to replace fossil
          fuels, which are the source of GHG emissions. So as long as the source
          of energy to power these cars do not come from fossil fuels, EVs are
          good to go. However, if electric vehicles rely on coal-fired power
          plants to charge lithium-ion batteries, the zero-emissions target will
          be pretty much meaningless.
        </p>
        <p>
          Though the overall life-cycle emissions of EVs are lower than
          conventional vehicles, it is important to note that the manufacturing
          process of EVs require more energy and produces more emissions than
          manufacturing a conventional vehicle because of the EVs batteries.
        </p>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-13(graph).svg")}
            alt="Blog-13(graph)"
          />
        </div>
        <p>
          Lithium-ion battery production necessitates the extraction and
          refining of rare-earth metals, which is energy-intensive. Furthermore,
          the chemical composition of these batteries makes recycling
          problematic. As a result, they are incinerated or dumped in landfills.
          Other drawbacks include initial investments, battery replacements,
          charging points, long charging times, and so on.
        </p>

        <div className="sub-header">
          <div className="sub-header-content">
            So, is it realistic for electric vehicles to contribute
            significantly to sustainability in these challenging scenarios?
          </div>
        </div>
        <p>
          Road transportation is the most carbon-emitting sector, accounting for
          the majority of CO2 emissions. EVs have the potential to reduce air
          pollution significantly. When compared to even the most fuel-efficient
          ICE vehicle in the market today, an electric automobile emits over 30%
          less pollution over its lifetime.
        </p>
        <p>
          As more electric vehicles hit the road, there will be a high
          electricity demand. This could provide a roadmap to making renewable
          energy profitable. With energy regulations and financial incentives
          such as loans, grants, and tax credits from the governments,
          renewables like solar and wind are gaining traction.
        </p>

        <p>
          Easy charging options and greener battery production and disposal will
          be the barriers in the EV sector, but solutions such as integrating EV
          charging into a smart building network and repurposing batteries as
          power reserves will help ease the transition.
        </p>
        <p>
          Despite the challenges, electric automobiles have the potential to
          change the transportation sector. Realizing this, many governments are
          already fostering the growth of EVs through laws and efforts that make
          them economical. Several countries, including India, Israel, and
          Germany, have recommended 2030 as a timeframe for banning the sale of
          automobiles using ICEs.
        </p>
        <p>
          Although they are still a new technology, EVs will only improve and
          become more sustainable over time. It will not be an emissions-free
          industry, but it will help reduce emissions generated by the
          transportation sector.
        </p>
        <p>
          Only a coherent makeover, in which all elements are tied to a common
          goal, can help us economically and environmentally.
        </p>
      </div>
    </>
  );
}
export default BlogThirteen;
