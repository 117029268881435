import React, { useState, useReducer, useEffect } from "react";
import ContactUs from "../components/ContactUs";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";

const initialFormObj = {
  firstName: "",
  lastName: "",
  email: "",
  jobTitle: "",
  companyName: "",
  mobile: "",
  message: "",
  optin: false,
};
function ContactUsContainer(props) {
  const [contactDetails] = useState({
    email: "hello@updaptcsr.com",
    phone: ["9820562411", "9741622044"],
    address: [
      {
        img: require("../../../assets/icons/india_flag.svg"),
        place: "Bangalore, India",
        value: "Gopalan Gardenia,",
        value1: "Electronic City, Bengaluru - 560100",
        // contact1: "+91 9742355502",
        // contact2: "+91 97423 55502",
      },
      {
        img: require("../../../assets/icons/india_flag.svg"),
        place: "Mumbai, India",
        value: "Awfis, Aston Building, 10th Floor",
        value1: "Lokhandwala Complex",
        value2: "Andheri (W) Mumbai - 400053",
        // contact1: "+91 7045543542",
        // contact2: "+91 97423 55502",
      },
      {
        img: require("../../../assets/icons/india_flag.svg"),
        place: "Mangalore, India",
        value: "4th Floor, PVS Sadan, Kodialbail,",
        value1: "Mangaluru - 575003",
        // contact1: "+91 9820562411",
        // contact1: "+91 8861065381",
        // contact2: " +91 97423 55502",
      },

      {
        img: require("../../../assets/icons/usa_flag.svg"),
        place: "New York, USA",
        value: "211 East 43rd Street, New York",
        value1: "NY 10017",
        // contact1: "+1 (802) 376 1111",
      },
      {
        img: require("../../../assets/icons/uae_flag.svg"),
        place: "Dubai, UAE",
        value: "104-E1, Mohd. Hamed Saif Alrumhi Building, Al Muteena",
        value1: "Dubai, UAE",
        // contact1: "+91 98330 71259",
      },
      // {
      //   img: require("../../../assets/icons/Nigeria_flag.svg"),
      //   place: "Lagos, Nigeria",
      //   value: "10, Oremeji Street computer village,",
      //   value1: "Ikeja, Lagos state, Nigeria",
      // },
      {
        img: require("../../../assets/icons/uk_flag.svg"),
        place: "London, UK",
        value: "Wilberforce House, Station Road,",
        value1: "London, NW4 4QE",
        // contact1: "+44 (0) 7399412572",
      },
      // {
      //   img: require("../../../assets/icons/Tanzania_flag.svg"),
      //   place: "Dar es Salaam, Tanzania",
      // },
    ],
  });
  const [formObj, setFormObj] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialFormObj
  );
  const [mobile, setMobile] = useState("");
  const [errors, setErrors] = useState({});
  const [issubbmitted, setIsSubbmitted] = useState(false);

  const handleTextOnChange = (event, type) => {
    if (type === "agree") {
      setFormObj({ ...formObj, optin: event.target.checked });
    } else {
      const { name, value } = event.target;
      setFormObj({ [name]: value });
    }
  };

  const validate = () => {
    let errors = {};

    const isText = RegExp(/^[A-Z ]+$/i);
    const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    if (!formObj.firstName) {
      errors.firstName = "Please enter first name";
      NotificationManager.warning(errors.firstName, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isText.test(formObj.firstName)) {
        errors.firstName = "First name accepts text only";
        NotificationManager.warning(errors.firstName, "", 2000);
        setErrors(errors);
        return;
      }
    }
    if (!formObj.lastName) {
      errors.lastName = "Please enter last name";
      NotificationManager.warning(errors.lastName, "", 2000);
      setErrors(errors);
      return;
    } else {
      if (!isText.test(formObj.lastName)) {
        errors.lastName = "Last name accepts text only";
        NotificationManager.warning(errors.lastName, "", 2000);
        setErrors(errors);
        return;
      }
    }

    if (!formObj.email) {
      errors.email = "Please enter email";
      NotificationManager.warning(errors.email, "", 2000);
      setErrors(errors);
      return;
    } else if (!isEmail.test(formObj.email)) {
      errors.email = "Please enter a valid email address";
      NotificationManager.warning(errors.email, "", 2000);
      setErrors(errors);
      return;
    } else {
      const domain = formObj.email.split("@");
      if (
        domain[1].toLowerCase().includes("gmail") ||
        domain[1].toLowerCase().includes("yahoo") ||
        domain[1].toLowerCase().includes("rediffmail")
      ) {
        errors.email = "Please enter a valid work email";
        NotificationManager.warning(errors.email, "", 2000);
        setErrors(errors);
        return;
      }
    }

    if (!formObj.jobTitle) {
      errors.jobTitle = "Please enter job title";
      NotificationManager.warning(errors.jobTitle, "", 2000);
      setErrors(errors);
      return;
    }
    if (!formObj.companyName) {
      errors.companyName = "Please enter company name";
      NotificationManager.warning(errors.companyName, "", 2000);
      setErrors(errors);
      return;
    }
    if (!mobile) {
      errors.mobile = "Please enter mobile number";
      NotificationManager.warning(errors.mobile, "", 2000);
      setErrors(errors);
      return;
    } else {
      setFormObj({ ["mobile"]: mobile });
    }
    if (!formObj.message) {
      errors.message = "Please enter your message";
      NotificationManager.warning(errors.message, "", 2000);
      setErrors(errors);
      return;
    }
    if (formObj.optin === false) {
      errors.optin = "Please accept Terms and Conditions to continue";
      NotificationManager.warning(errors.optin, "", 2000);
      setErrors(errors);
      return;
    }
    setErrors(errors);
  };

  const handleSendRequest = async (e) => {
    e.preventDefault();
    validate();
    setIsSubbmitted(true);
  };

  useEffect(() => {
    const postInfo = async () => {
      if (!Object.keys(errors).length && issubbmitted) {
        try {
          await axios.post("/contact/sales/api/contacts", {
            firstName: formObj.firstName,
            lastName: formObj.lastName,
            email: formObj.email,
            jobTitle: formObj.jobTitle,
            companyName: formObj.companyName,
            mobile: formObj.mobile,
            message: formObj.message,
          });

          props.history.push("/contact-success", {
            from: "contact",
            name: formObj.firstName,
          });

          setFormObj(initialFormObj);
          setMobile("");
        } catch (error) {
          NotificationManager.error(
            "Please write to us at info@updaptcsr.com",
            "Error"
          );
          throw error;
        }
      }
    };
    postInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  return (
    <ContactUs
      contactDetails={contactDetails}
      formObj={formObj}
      handleTextOnChange={handleTextOnChange}
      handleSendRequest={handleSendRequest}
      mobile={mobile}
      setMobile={setMobile}
    ></ContactUs>
  );
}

export default withRouter(ContactUsContainer);
