import React from "react";
import "../SCSS/styles.css";
import assets from "../../../../shared/constants/assetVariables";

function PodcastInfo({
  podcastData,
  // generatePdf,
  playPause,
  audioProps,
  handleAudioSeek,
  fastRate,
  rewind,
  forward,
  muteUnmute,
  changeVolume,
  audioInfo,
  showPlayer,
  showPlayerStatus,
  audioDuration,
  playTime,
  shareContent,
}) {
  return (
    <div className="podcastInfo-main-container">
      <div className="podcastInfo-inner-container">
        <div className="podcast-banner">
          <img
            src={
              require("../../../../assets/images/Podcast-banner.jpg").default
            }
            alt="Podcast-banner"
          />
        </div>
        <div className="podcast-section1">
          <div className="desktop-view">
            <div className="podcast-section1-inner-container">
              <div className="left-section">
                <img
                  src={
                    require(`../../../../assets/${podcastData?.image}`).default
                  }
                  alt="title"
                  className="title-image"
                />

                <div className="subscribe-section">
                  <h3>Listen and Subscribe</h3>

                  <div className="podcast-name">
                    <a
                      href={podcastData.google_podcast}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="subscribe-link-wrapper"
                    >
                      <img
                        src={require("../../../../assets/icons/google-podcast.svg")}
                        alt="google-products"
                        className="subscribe-image"
                      />
                      <span className="subscribe-title">Google Podcast</span>
                    </a>
                  </div>
                  {podcastData?.spotify_podcast !== null && (
                    <div>
                      <a
                        href={podcastData.spotify_podcast}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="subscribe-link-wrapper"
                      >
                        <img
                          src={require("../../../../assets/icons/spotify.svg")}
                          alt="spotify"
                          className="subscribe-image"
                        />
                        <span className="subscribe-title">Spotify</span>
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="right-section">
                <div className="podcast-details">
                  <span className="host-info">
                    Hosted by: {podcastData?.host}
                  </span>
                  <span className="host-info">{podcastData?.date}</span>
                  <h3 className="podcast-title">{podcastData?.title}</h3>
                  <p className="podcast-desc">{podcastData?.synopsis}</p>
                </div>
                <div className="audio-player-warapper">
                  {showPlayerStatus === false ? (
                    <div>
                      <audio id="dummy-player" preload="auto">
                        <source
                          src={require(`../podcast_audio/${podcastData?.podcast_audio}`)}
                          type="audio/mpeg"
                        />
                      </audio>
                      <div className="play-btn-main-section">
                        <div className="play-btn" onClick={showPlayer}>
                          <span className="play-btn-conent">
                            <img
                              src={assets.icons?.play}
                              width="20px"
                              alt="play"
                            />
                            Play
                          </span>
                        </div>
                        <span className="duration">{audioDuration} min</span>
                      </div>
                    </div>
                  ) : (
                    <div className="audio-player">
                      <div>
                        <audio id="player" preload="auto">
                          <source
                            src={require(`../podcast_audio/${podcastData?.podcast_audio}`)}
                            type="audio/mpeg"
                          />
                        </audio>
                      </div>
                      <div className="audio-player-section">
                        <div className="section-1">
                          <button
                            id="paly"
                            className="play-pause-btn"
                            onClick={playPause}
                          >
                            <img
                              src={
                                audioInfo?.paused
                                  ? assets.icons?.play
                                  : assets.icons?.pause
                              }
                              style={
                                audioInfo?.paused
                                  ? { marginLeft: "4px" }
                                  : { marginLeft: "0px" }
                              }
                              alt={audioInfo?.paused ? "play" : "pause"}
                            />
                          </button>
                        </div>
                        <div className="section-2">
                          <div className="audio-seek-bar">
                            <input
                              type="range"
                              min={0}
                              max={String(audioInfo?.duration)} //audioProps?.totalDuratio
                              value={audioInfo?.currentTime || 0}
                              step={1}
                              onChange={(e) => {
                                handleAudioSeek(e);
                              }}
                              className="progress"
                            />
                          </div>
                          <div className="audio-controls-container">
                            <div className="audio-controls-inner-container">
                              <button
                                id="back"
                                onClick={() => rewind(15)}
                                className="forward-backward"
                              >
                                <img
                                  src={assets?.icons?.backward}
                                  width="17px"
                                  alt="backward"
                                />
                              </button>
                              <button
                                id="fast"
                                onClick={() => fastRate(0.5)}
                                className="speed-indicator"
                              >
                                {audioProps?.initSpeed}X
                              </button>
                              <button
                                id="forward"
                                onClick={() => forward(15)}
                                className="forward-backward"
                              >
                                <img
                                  src={assets?.icons?.forward}
                                  width="17px"
                                  alt="forward"
                                />
                              </button>
                              <div className="volume">
                                <div onClick={muteUnmute}>
                                  <img
                                    src={
                                      audioInfo?.volume > 0
                                        ? assets?.icons?.volume
                                        : assets?.icons?.muteVolume
                                    }
                                    width="23px"
                                    className="speaker"
                                    alt={
                                      audioInfo?.volume > 0
                                        ? "volume"
                                        : "muteVolume"
                                    }
                                  />
                                </div>
                                <input
                                  type="range"
                                  min={0.0}
                                  max={1.0}
                                  step={0.1}
                                  value={audioProps?.initVolume}
                                  onChange={(e) => {
                                    changeVolume(e);
                                  }}
                                  className="volume-bar"
                                />
                              </div>
                            </div>
                            <span className="duration">
                              {playTime?.currentPlayTime}&nbsp;/&nbsp;
                              {playTime?.totalTime}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="social-media-icon-group">
              <div className="social-media-icons-wrapper">
                <div className="blank-div"></div>
                <div className="icon-group">
                  <span className="label">Share: </span>

                  <img
                    src={require("../../../../assets/icons/facebook-podcast.svg")}
                    alt="facebook"
                    className="subscribe-image"
                    onClick={() => shareContent(podcastData?.url, "facebook")}
                  />

                  <img
                    src={require("../../../../assets/icons/twitter-podcast.svg")}
                    alt="twitter"
                    className="subscribe-image"
                    onClick={() => shareContent(podcastData?.url, "twitter")}
                  />

                  <img
                    src={require("../../../../assets/icons/linkedin-podcast.svg")}
                    alt="linkedin"
                    className="subscribe-image"
                    onClick={() => shareContent(podcastData?.url, "linkedin")}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ******************* mobile-view********************** */}
          <div className="mobile-view">
            <div className="mobile-podcast-section1-inner-container">
              <span className="mobile-podcast-host-info">
                Hosted by: {podcastData?.host}
              </span>
              <span className="mobile-podcast-host-info">
                {podcastData?.date}
              </span>
              <h3 className="mobile-podcast-title">{podcastData?.title}</h3>
              <p className="mobile-podcast-desc">{podcastData?.synopsis}</p>
              <img
                src={
                  require(`../../../../assets/${podcastData?.image}`).default
                }
                alt="title"
                className="mobile-podcast-title-image"
              />
              <div className="audio-player-warapper">
                {showPlayerStatus === false ? (
                  <div>
                    <audio id="dummy-player" preload="auto">
                      <source
                        src={require(`../podcast_audio/${podcastData?.podcast_audio}`)}
                        type="audio/mpeg"
                      />
                    </audio>
                    <div
                      className="play-btn-main-section"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="play-btn" onClick={showPlayer}>
                        <span className="play-btn-conent">
                          <img
                            src={assets.icons?.play}
                            width="20px"
                            alt="play"
                          />
                          Play
                        </span>
                      </div>
                      <span className="duration">{audioDuration} min</span>
                    </div>
                  </div>
                ) : (
                  <div className="audio-player">
                    <div>
                      <audio id="player" preload="auto">
                        <source
                          src={require(`../podcast_audio/${podcastData?.podcast_audio}`)}
                          type="audio/mpeg"
                        />
                      </audio>
                    </div>
                    <div className="audio-player-section">
                      <div className="section-1">
                        <button
                          id="paly"
                          className="play-pause-btn"
                          onClick={playPause}
                        >
                          <img
                            src={
                              audioInfo?.paused
                                ? assets.icons?.play
                                : assets.icons?.pause
                            }
                            style={
                              audioInfo?.paused
                                ? { marginLeft: "4px" }
                                : { marginLeft: "0px" }
                            }
                            alt={audioInfo?.paused ? "play" : "pause"}
                          />
                        </button>
                      </div>
                      <div className="section-2">
                        <div className="audio-seek-bar">
                          <div className="small-device">
                            <span className="duration">
                              {playTime?.currentPlayTime}&nbsp;/&nbsp;
                              {playTime?.totalTime}
                            </span>
                          </div>
                          <input
                            type="range"
                            min={0}
                            max={String(audioInfo?.duration)}
                            value={audioInfo?.currentTime || 0}
                            step={0.00001}
                            onChange={(e) => {
                              handleAudioSeek(e);
                            }}
                            className="mobile-progress"
                          />
                        </div>
                        <div className="audio-controls-container">
                          <div className="audio-controls-inner-container">
                            <button
                              id="back"
                              onClick={() => rewind(15)}
                              className="forward-backward"
                            >
                              <img
                                src={assets?.icons?.backward}
                                width="17px"
                                alt="backward"
                              />
                            </button>
                            <button
                              id="fast"
                              onClick={() => fastRate(0.5)}
                              className="speed-indicator"
                            >
                              {audioProps?.initSpeed}X
                            </button>
                            <button
                              id="forward"
                              onClick={() => forward(15)}
                              className="forward-backward"
                            >
                              <img
                                src={assets?.icons?.forward}
                                width="17px"
                                alt="forward"
                              />
                            </button>
                            <div className="volume">
                              <div onClick={muteUnmute}>
                                <img
                                  src={
                                    audioInfo?.volume > 0
                                      ? assets?.icons?.volume
                                      : assets?.icons?.muteVolume
                                  }
                                  width="23px"
                                  className="speaker"
                                  alt={
                                    audioInfo?.volume > 0
                                      ? "volume"
                                      : "muteVolume"
                                  }
                                />
                              </div>
                              <input
                                type="range"
                                min={0.0}
                                max={1.0}
                                step={0.1}
                                value={audioProps?.initVolume}
                                onChange={(e) => {
                                  changeVolume(e);
                                }}
                                className="mobile-volume-bar"
                              />
                            </div>
                          </div>
                          <div className="medium-device">
                            <span className="duration">
                              {playTime?.currentPlayTime}&nbsp;/&nbsp;
                              {playTime?.totalTime}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="mobile-subscribe-section">
                <h3>Listen and Subscribe</h3>
                <div className="mobile-subscribe-link-wrapper">
                  <a
                    href={podcastData.google_podcast}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../../../assets/icons/google-podcast.svg")}
                      alt="google-products"
                      className="subscribe-image"
                    />
                  </a>
                  {podcastData?.spotify_podcast !== null && (
                    <a
                      href={podcastData.spotify_podcast}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../assets/icons/spotify.svg")}
                        alt="spotify"
                        className="subscribe-image"
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="mobile-social-media-icon-group">
              <div className="mobile-social-media-icon-wrapper">
                <span className="label">Share: </span>

                <img
                  src={require("../../../../assets/icons/facebook-podcast.svg")}
                  alt="facebook"
                  className="subscribe-image"
                  onClick={() => shareContent(podcastData?.url, "facebook")}
                />

                <img
                  src={require("../../../../assets/icons/twitter-podcast.svg")}
                  alt="twitter"
                  className="subscribe-image"
                  onClick={() => shareContent(podcastData?.url, "twitter")}
                />

                <img
                  src={require("../../../../assets/icons/linkedin-podcast.svg")}
                  alt="linkedin"
                  className="subscribe-image"
                  onClick={() => shareContent(podcastData?.url, "linkedin")}
                />
              </div>
            </div>
          </div>
        </div>

        {/* ///////////////////// DONT DELETE below code////////////////////// */}

        {/* <div className="podcast-section2">
          <div className="podcast-section2-inner-container">
            <div className="transcript-container">
              <div className="transcript-header">
                <span className="podcast-transcript-header">
                  Podcast Transcript
                </span>

                <img
                  src={assets?.icons?.download}
                  onClick={generatePdf}
                  className="pdf-download-icon"
                />
              </div>
              <div className="transcript-body" id="transcript-body">
                <p>{podcastData?.transcript}</p>
              </div>
            </div>
            
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PodcastInfo;
