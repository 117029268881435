import React from "react";
import FormSection from "./FormSection";
import MetaTags from "react-meta-tags";

function FreeTrial({
  mobile,
  setMobile,
  handleTextOnChange,
  handleSendRequest,
  formObj,
  setSelectedOption,
  selectedOption,
  options,
  customStyles,
}) {
  return (
    <>
      <MetaTags>
        <title>Request FreeTrial | Updapt</title>
        <meta
          name="description"
          content="Get a Free Trial of Updapt's ESG Tool"
        />
        <meta property="og:title" content="Request FreeTrial | Updapt" />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <FormSection
        formObj={formObj}
        handleTextOnChange={handleTextOnChange}
        handleSendRequest={handleSendRequest}
        mobile={mobile}
        setMobile={setMobile}
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        customStyles={customStyles}
      />
    </>
  );
}

export default FreeTrial;
