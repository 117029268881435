import React from "react";
function SalesEnquiry() {
  return (
    <div className="main-container">
      <div className="sales-header">
        <p className="heading">Sales Enquiry</p>
        <p className="sub-heading">Interested in any of our products?</p>
      </div>
      <div className="sales-body">
        <div style={{ padding: "25px" }}>
          <div className="content">
            <p className="img-section">
              <img
                src={require("../../../assets/icons/phone.svg")}
                alt="phone"
              />
            </p>
            <p className="img-label">Feel free to call us</p>
          </div>
          <div className="main-content">
            <div className="sub-content-wrapper">
              <div className="main-label">India</div>
              <div className="sub-content">
                <p className="label">West Region</p>
                <p className="description">+91 70455 43542</p>
              </div>
              <div className="sub-content">
                <p className="label">North-East Region</p>
                <p className="description">+91 98107 06896</p>
              </div>
              <div className="sub-content">
                <p className="label">South Region</p>
                <p className="description">+91 97423 55502 </p>
              </div>
            </div>
          </div>
          <div className="content">
            <p className="label">USA</p>
            <p className="description">+1 (802) 376 1111</p>
          </div>
          <div className="content">
            <p className="label">UAE</p>
            <p className="description">+971 52 793 7087</p>
          </div>
          <div className="content">
            <p className="label">UK</p>
            <p className="description">+44 (0) 7399412572</p>
          </div>
        </div>
        <div className="divider"></div>
        <div style={{ padding: "25px" }}>
          <div className="content">
            <p className="img-section">
              <img
                src={require("../../../assets/icons/email.svg")}
                alt="email"
              />
            </p>
            <p className="img-label">Drop a mail at</p>
          </div>
          <div className="content">
            <p className="label">Sales</p>
            <p className="description">sales@updaptcsr.com</p>
          </div>
          <div className="content">
            <p className="label">Support</p>
            <p className="description">support@updaptcsr.com</p>
          </div>
          <div className="content">
            <p className="label">Partner</p>
            <p className="description">partner@updaptcsr.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesEnquiry;
