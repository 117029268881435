import React from "react";
import assets from "../../../../shared/constants/assetVariables";
import SubscriptionForm from "../../../SubscriptionForm";
function Subscribe() {
  return (
    <section className="blogs-subscribe-section">
      <div className="container">
        <div className="row">
          <div className="blogs-subscribe-content-wrapper">
            <div className="blogs-subscribe-content">
              <p className="blogs-subscribe-description">
                Subscribe and get the latest ESG updates sent to your inbox.
              </p>
              <p className="blogs-subscribe-msg">
                We promise not to use your email for spam!
              </p>
           
                <SubscriptionForm formName="blogs-subscribe-form" />
              
            </div>
          </div>
          <div className="blogs-subscribe-image-wrapper">
            <div className="blogs-subscribe-image">
              <img src={assets.images.blogsPageImage} alt="updapt" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Subscribe;
