import React from "react";
import Intro from "./Intro";
import FeatureOne from "./FeatureOne";
import FeatureTwo from "./FeatureTwo";
import FeatureThree from "./FeatureThree";
import FeatureFour from "./FeatureFour";
import FeatureFive from "./FeatureFive";
import MetaTags from "react-meta-tags";


function Advisory(props) {
  return (
   <>
     <MetaTags>
        <title>ESG Management | ESG Data Platform | Data Management Platform</title>
        <meta
          name="description"
          content="Streamline your ESG data collection process, configure metrics, and connect data to all of your ESG reports directly."
        />
        <meta property="og:title" content="ESG Management | ESG Data Platform | Data Management Platform" />
        <meta property="og:image" content="%PUBLIC_URL%/static/media/banner.jpg" />
      </MetaTags>
    <Intro></Intro>
    <FeatureOne></FeatureOne>
    <FeatureTwo/>
    <FeatureThree/>
    <FeatureFour/>
    <FeatureFive/>
    
 </>
   
   
  );
}

export default Advisory;
