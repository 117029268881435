import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";

function Platform({ blogsData }) {
  const history = useHistory();
  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="home-blogs-section">
      <div className="home-blogs-container">
        <div className="home-blogs-content">
          <h3 className="home-blogs-title">Resources</h3>
          <p className="home-blogs-description">
            Get the most important updates on ESG and insights on climate
            change.
          </p>
        </div>
        <div className="home-blogs-slider-wrapper">
          <Slider {...settings} className="mobile-blog">
            {blogsData

              .map((data) => {
                let dataUrl = data.url;
                if (data.type === "blogs")
                  dataUrl = `/resource/blogs/${data.url}`;
                else if (data.type === "website-media")
                  dataUrl = `/resource/media/${data.url}`;
                else if (data.type === "newsletter") dataUrl = `${data.url}`;
                else if (data.type === "newsroom-media")
                  dataUrl = `/resource/newsroom/${data.url}`;
                return (
                  <div className="home-blogs-slider" key={data.url}>
                    {data?.type !== "podcast" ? (
                      <a href={dataUrl} rel="noopener noreferrer">
                        <div className="home-blogs-grid">
                          <div className="home-blogs-grid-img">
                            <img
                              alt="img"
                              src={
                                data.image.split(".")[1] !== "svg"
                                  ? require(`../../../assets/${data.image}`)
                                      .default
                                  : require(`../../../assets/${data.image}`)
                              }
                              height="100%"
                              width="100%"
                            />
                          </div>
                          <div className="home-blogs-grid-content">
                            <h3 className="home-blogs-grid-title">
                              {data.title}
                            </h3>
                            <p
                              className="home-blogs-grid-description"
                              dangerouslySetInnerHTML={{ __html: data.content }}
                            ></p>

                            <div className="home-blogs-grid-badge">
                              {data.type === "blogs" && (
                                <span className="blogs">{data.type}</span>
                              )}
                              {data.type === "newsletter" && (
                                <span className="newsletters">{data.type}</span>
                              )}
                              {data.type === "media" && (
                                <span className="media">{data.type}</span>
                              )}
                              {data.type === "website-media" && (
                                <span className="media">Media</span>
                              )}

                              {data.type === "case study" && (
                                <span className="case-study">case study</span>
                              )}
                              {(data.type === "newsroom" ||
                                data.type === "newsroom-media") && (
                                <span className="newsroom">newsroom</span>
                              )}
                            </div>
                            <div className="home-blogs-grid-meta">
                              <span className="date">{data.date}</span>
                              <span className="status">{data.status}</span>
                            </div>
                          </div>
                        </div>
                      </a>
                    ) : (
                      <div
                        className="home-blogs-grid"
                        onClick={() => {
                          history.push({
                            pathname: `/resource/podcast/${data.url}`,
                            state: data,
                          });
                        }}
                      >
                        <div className="home-blogs-grid-img">
                          <img
                            alt="img"
                            src={
                              data.image.split(".")[1] !== "svg"
                                ? require(`../../../assets/${data.image}`)
                                    .default
                                : require(`../../../assets/${data.image}`)
                            }
                            height="100%"
                            width="100%"
                          />
                        </div>
                        <div className="home-blogs-grid-content">
                          <h3 className="home-blogs-grid-title">
                            {data.title}
                          </h3>
                          <p
                            className="home-blogs-grid-description"
                            dangerouslySetInnerHTML={{ __html: data.synopsis }}
                          ></p>

                          <div className="home-blogs-grid-badge">
                            <span className="podcast">{data.type}</span>
                          </div>
                          <div className="home-blogs-grid-meta">
                            <span className="date">{data.date}</span>
                            <span className="status">{data.status}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
              .reverse()}
          </Slider>
          <div className="desktop-blog">
            {blogsData
              .map((data) => {
                let dataUrl = data.url;
                if (data.type === "blogs")
                  dataUrl = `/resource/blogs/${data.url}`;
                else if (data.type === "website-media")
                  dataUrl = `/resource/media/${data.url}`;
                else if (data.type === "newsletter") dataUrl = `${data.url}`;
                else if (data.type === "newsroom-media")
                  dataUrl = `/resource/newsroom/${data.url}`;
                return (
                  <div className="home-blogs-slider" key={data.url}>
                    {data.type !== "podcast" ? (
                      <a href={dataUrl} rel="noopener noreferrer">
                        <div className="home-blogs-grid">
                          <div className="home-blogs-grid-img">
                            <img
                              alt="img"
                              src={
                                data.image.split(".")[1] !== "svg"
                                  ? require(`../../../assets/${data.image}`)
                                      .default
                                  : require(`../../../assets/${data.image}`)
                              }
                              height="100%"
                              width="100%"
                            />
                          </div>
                          <div className="home-blogs-grid-content">
                            <h3 className="home-blogs-grid-title">
                              {data.title}
                            </h3>
                            <p
                              className="home-blogs-grid-description"
                              dangerouslySetInnerHTML={{ __html: data.content }}
                            ></p>

                            <div className="home-blogs-grid-badge">
                              {data.type === "blogs" && (
                                <span className="blogs">{data.type}</span>
                              )}
                              {data.type === "newsletter" && (
                                <span className="newsletters">{data.type}</span>
                              )}
                              {data.type === "media" && (
                                <span className="media">{data.type}</span>
                              )}
                              {data.type === "website-media" && (
                                <span className="media">Media</span>
                              )}

                              {data.type === "case study" && (
                                <span className="case-study">case study</span>
                              )}
                              {(data.type === "newsroom" ||
                                data.type === "newsroom-media") && (
                                <span className="newsroom">newsroom</span>
                              )}
                            </div>
                            <div className="home-blogs-grid-meta">
                              <span className="date">{data.date}</span>
                              <span className="status">{data.status}</span>
                            </div>
                          </div>
                        </div>
                      </a>
                    ) : (
                      <div
                        className="home-blogs-grid"
                        onClick={() => {
                          history.push({
                            pathname: `/resource/podcast/${data.url}`,
                            state: data,
                          });
                        }}
                      >
                        <div className="home-blogs-grid-img">
                          <img
                            alt="img"
                            src={
                              data.image.split(".")[1] !== "svg"
                                ? require(`../../../assets/${data.image}`)
                                    .default
                                : require(`../../../assets/${data.image}`)
                            }
                            height="100%"
                            width="100%"
                          />
                        </div>
                        <div className="home-blogs-grid-content">
                          <h3 className="home-blogs-grid-title">
                            {data.title}
                          </h3>
                          <p
                            className="home-blogs-grid-description"
                            dangerouslySetInnerHTML={{ __html: data.synopsis }}
                          ></p>

                          <div className="home-blogs-grid-badge">
                            <span className="podcast">podcast</span>
                          </div>
                          <div className="home-blogs-grid-meta">
                            <span className="date">{data.date}</span>
                            <span className="status">{data.status}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
              .reverse()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Platform;
