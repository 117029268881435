import React from "react";
import "../scss/styles.scss";
import MetaTags from "react-meta-tags";

function BlogTwenty() {
  return (
    <>
      <div className="BlogTwenty-container">
        {/* <div className="header">
          <span>By: Kiran</span>
          <span>|</span>
          <span>4 min read</span>
        </div> */}
        <MetaTags>
          <title>Let’s talk about Biofuels</title>
          <meta
            name="description"
            content="Using biofuels for sustainability  has been around since the 1800s.
          "
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">Let’s talk about Biofuels.</div>
        <div className="blog-author-details">
          {/*   <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Deeksha.svg")}
              alt="Deeksha"
            />
          </div>*/}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Meenakshi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Sep 07, 2022</div>
              <div className="blog-read-time">2 min read</div>
            </div>
          </div>
        </div>
        {/* <div className="update-date">Updated: December 23, 2019</div> */}
        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog11.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/b20.jpg").default}
            alt="blog-20"
          />
        </div>
        <div className="sub-header">
          <div className="sub-header-content">
            <p style={{ marginTop: "25px", marginBottom: "20px" }}>
              <b style={{ color: "black", fontSize: "22px" }}>Did you know?</b>
            </p>
          </div>
        </div>

        <p>
          Using biofuels for{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            sustainability
          </a>{" "}
          has been around since the 1800s.
        </p>
        <p>
          <b>Henry Ford</b>, well known as an automotive inventor, said, “The
          fuel of the future is going to come from fruit like a sumach out by
          the road, or from apples, weeds, sawdust – almost anything, there is a
          fuel in every bit of vegetable matter that can be fermented”. However,
          regarding the concerns of{" "}
          <a
            href="/solutions/esg-data-management"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG management
          </a>
          , biofuel today accounts for approximately 9% of global energy.
        </p>

        <p>
          Since the industrial revolution, most energy we consume is from
          burning fossil fuels that are compounds made of hydrogen and carbon;
          when burnt, they release large amounts of carbon dioxide into the
          atmosphere causing global warming. In contrast, biofuels are extracted
          from recently dead or living plant material and animal waste. There
          are various types of biofuels in the form of liquid, solid or gas
          based on the raw materials used; ethanol and biodiesel are considered
          the primary producers.
        </p>
        <p>
          Bioethanol is a liquid and renewable biofuel produced and oxygenated
          through Agricultural Biological Engineering (ABE) fermentation of
          different feedstocks such as corn, soybeans, wheat straw, woodchips
          and, more recently, microalgae. It is said that corn-based bioethanol
          and production reduces greenhouse gas emissions by up to 52% compared
          to gasoline production and use.
        </p>
        <p>
          According to the National Laboratory reports, carbon dioxide emissions
          are reduced by 74% when switched from petroleum to biodiesel, and
          about 1 billion gallons of biodiesel are produced annually. Can use
          almost any raw material from fats and oils to produce biodiesel, from
          vegetable oil to coffee grounds and plants. Although biodiesel is way
          more expensive than other conventional fuels, it is added to
          petroleum-based diesel to reduce emissions and improve engine life as
          it has the highest energy balance.
        </p>

        <p>
          While we can keep talking about the facts and functions of more
          biofuels, have you considered including{" "}
          <a
            href="/solutions/esg-reporting"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG software
          </a>{" "}
          to track the biofuel use in your company?
        </p>
      </div>
    </>
  );
}
export default BlogTwenty;
