import React from "react";
import "../SCSS/styles.scss";

function BlogThree() {
  return (
    <>
      <div className="BlogThree-container">
        {/* <div className="header">
          <span>By: Chinmayi</span>
          <span>|</span>
          <span>3 min read</span>
        </div> */}
        <div className="blog-title">
          Why Diversity is integral to a Sustainable Business?
        </div>
        {/* <div className="update-date">Updated: Jun 27, 2020</div> */}
        <div className="blog-author-details">
          {/*
         <div className="blog-author-pic">
            <img
              className="author-pic"
              src={require("../../../../../assets/Blogs/Chinmayi.svg")}
              alt="Chinmayi"
            />
          </div> */}
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Chinmayi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Jun 27, 2020</div>
              <div className="blog-read-time">3 min read</div>
            </div>
          </div>
        </div>

        {/* <div className="right">
          {" "}
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/Blog3.png")}
          />
        </div> */}
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-3a.jpg").default}
            alt="Blog-3a"
          />
        </div>

        <p>
          The world we live in is divided; diversity exists in the form of
          culture, race and societal structures. Sustainability embraces these
          diverse communities and the various voices they support. With
          increasing challenges related to global warming, poverty and
          overpopulation, it is important for all of us to work together keeping
          our differences apart to create a future that is resilient to all
          these challenges. This makes diversity a facilitator and a driver of
          the environmental, social as well as the economic dimensions of
          sustainable development. Diversity is an integral component of
          sustainability as it is a source of exchange, that fosters creativity
          among various communities. It teaches us the importance of
          co-existence, hence respect and acceptance which in turn prevents
          conflicts.
        </p>

        <p>
          As concerns regarding various environmental and social issues grow
          across the world, investors are now looking at environmental, social
          and governance impacts of their potential investments while making
          business decisions. When deploying funds they consider issues
          regarding response to climate change, conservation of energy and
          natural resources and also health and safety policies at workplaces.
          With globalization, businesses are expanding to various parts of the
          world and are as a result diversifying. Therefore, investors now need
          to integrate diversity into the ESG values and principles in their
          businesses.
        </p>
        <p>
          Embracing diversity is the essence of innovation and growth for
          businesses. In many cases we see a lack of diversity –ethnicity,
          gender and race in various stages across organisations especially in
          top management. Shining the light on diversity and inclusion can go a
          long way in securing the future of a business.
        </p>
        <p>
          It encourages companies to engage with underrepresented populations by
          making changes in top management and recruitment, providing mentorship
          or simply be more accessible to all employees within the organisation
          making it conducive for individual and business growth.
        </p>
        <p>
          Companies must focus on building inclusive work cultures that respect
          employees regardless of their gender, race, disability as well as
          sexual orientation. Inclusivity is not only important for a business
          but also crucial in achieving the Sustainable Development Goals,
          empowering women and the underrepresented population helps us get
          closer to achieving SDG targets for Goals 5 and 10.
        </p>
        <p>
          Presently, businesses want to be more ethical and sustainable across
          all their operations. In order to do this, diversity and inclusion are
          its strong points. Studies have shown that ethnically and gender
          diverse teams in companies usually outperform homogeneous teams. This
          is because boards which consist of a melange of genders, ethnicities,
          career experiences, life experiences and varied thinking result in a
          diverse mindset, hence higher cultural intelligence. This helps in
          better identification of opportunities and strategies that promote
          overall growth. Diversified teams have a better understanding that
          helps overcome bias by creating equal opportunities for everyone
          therefore resulting in higher social impact. Businesses will also see
          an enhanced reputation and brand image and will have a greater ability
          to attract and retain employees. Through this, companies benefit all
          their stakeholders, from shareholders to customers and even the
          communities in which they function. Utilising investment opportunities
          to reduce hurdles for underrepresented communities and populations
          worldwide helps create sustainable markets and also a better society.
        </p>
        <p>
          Businesses simply need to do a better job. The recent ‘Black Lives
          Matter’ protests and demonstrations across the world show that we
          still have a long way to go in making all voices heard. Dismantling of
          systemic racism and other inequalities helps promote development
          worldwide.
        </p>
      </div>
    </>
  );
}
export default BlogThree;
