import React from "react";
import "../SCSS/styles.css";

function SecondaryDescription() {
  return (
    <div className="description-outer-container">
      <p className="description-content">
        Our end-to-end{" "}
        <a
          href="/"
          target="_blank"
          rel="noopener noreferrer"
          className="landing-page-content-href"
        >
          sustainability data management & Reporting software
        </a>{" "}
        streamlines the entire process, allowing your organization to easily
        manage, report, and analyze ESG data all in one platform. From data
        collection and consolidation to automated reporting aligns with global
        sustainability reporting standards and deep analytics, our software
        empowers Organizations to make informed decisions and drive value
        creation.
      </p>
    </div>
  );
}

export default SecondaryDescription;
