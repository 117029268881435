import React from "react";
import assets from "../../../shared/constants/assetVariables";
import { NavLink, withRouter } from "react-router-dom";
import "../SCSS/styles.css";

function GetDemoBanner() {
  return (
    <section
      className="get-demo"
      style={{
        backgroundImage: `-webkit-linear-gradient(#4147db00, #414adbed), url(${assets.images.footerDemoBackground})`,
      }}
      //style={{ backgroundImage: `url(${assets.images.footerDemoBackground})` }}
    >
      <div className="demo-container">
        <div className="demo-section-title-wrapper">
          <h2 className="demo-section-title">
            Book a Demo today to learn more about UpdaptESG and how it can add
            value to your corporate sustainability journey.
          </h2>
        </div>
        <div className="demo-section-button-wrapper">
          <NavLink to="/demo" className="demo-section-button">
            Book a demo
          </NavLink>
        </div>
      </div>
    </section>
  );
}

export default withRouter(GetDemoBanner);
