import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import Subscribe from "../../component/Subscribe";
function NewsletterNine() {
  return (
    <>
      <section
        className="about-banner-section"
        style={{ padding: "150px 0 62px" }}
      >
        <div className="about-circle">
          <div className="about-circle-1"></div>
          <div className="about-circle-2"></div>
          <div className="about-circle-3"></div>
          <div className="about-circle-4"></div>
          <div className="about-circle-5"></div>
        </div>
        <div className="about-banner-container">
          <div className="about-content">
            <h1 className="about-title" style={{ paddingTop: "25px" }}>
              SustainabilityConnect
            </h1>
            {/* <p className="about-description">Newsletter 2022</p> */}
            <p
              className="about-description"
              style={{ fontSize: "25px", marginTop: "18px", marginBottom: "0" }}
            >
              Newsletter June 2023
            </p>
          </div>
        </div>
        <div className="about-elements">
          <div className="about-element-1"></div>
          <div className="about-element-2"></div>
          <div className="about-element-3"></div>
          <div className="about-element-4"></div>
          <div className="about-element-5"></div>
        </div>
      </section>
      <div className="NewsletterNine-container">
        <MetaTags>
          <title>
            The Australian government establishes the Net Zero Authority to
            oversee the smooth transition to a low-carbon economy.
          </title>
          <meta
            name="description"
            content="The Australian government's Net Zero Authority will guide the country's economic transformation towards achieving net-zero emissions, reskilling workers for the energy transition, and facilitating collaboration with industry and investors on transformation opportunities."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>

        <div
          className="Newsletter-title1"
          style={{ paddingTop: "56px", fontSize: "32px" }}
        >
          The Australian government establishes the Net Zero Authority to
          oversee the smooth transition to a low-carbon economy.
        </div>
        <div className="Newsletter-picture">
          <figure style={{ marginTop: "25px" }}>
            <img
              className="picture"
              src={
                require("../../../../../assets/images/june2023-1.jpg").default
              }
              alt="june2023-1"
            />
          </figure>
        </div>
        <p>
          The Australian government's Net Zero Authority will guide the
          country's economic transformation towards achieving net-zero
          emissions, reskilling workers for the energy transition, and
          facilitating collaboration with industry and investors on
          transformation opportunities.
        </p>
        <p>
          The creation of this authority comes on the heels of Australia's
          commitment to its climate goals, including a target to reduce
          greenhouse gas emissions by 43% by 2030 (compared to 2005 levels) and
          achieve net-zero emissions by 2050. As significant economies worldwide
          gear up to capitalize on the opportunities presented by the global
          transition to cleaner energy systems and industries, Australia aims to
          position itself as a critical player in this transformative journey.
        </p>
        <p>
          One of the primary focuses of the Net Zero Authority will be to
          support workers, particularly in emissions-intensive sectors, in
          accessing new skills and employment opportunities. The authority will
          facilitate engagement between investors, companies, and net-zero
          transformation opportunities. It will support regions and communities
          in attracting new clean energy industries by coordinating programs and
          policies across the government.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Australia's plan to establish a Net Zero Authority is a significant
            step towards sustainable economic transformation. The authority's
            focus on reskilling workers, coordinating with industry and
            investors, and supporting clean energy industries aligns with the
            core principles of ESG (Environmental, Social, and Governance). By
            prioritizing a just transition and fostering collaboration,
            Australia aims to achieve its climate goals while leaving no one
            behind.
          </p>
        </div>

        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Asia-Pacific Region Unites for Urgent Climate Action and Sustainable
          Development.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/june2023-2.jpg").default}
            alt="june2023-2"
          />
        </figure>
        <p>
          The 79th session of the Economic and Social Commission for Asia and
          the Pacific (ESCAP) concluded in Bangkok with a commitment from member
          countries to address the pressing challenges of climate change and
          sustainable development. The meeting, attended by over 1,000
          representatives from governments and stakeholder groups, resulted in
          the adoption of several resolutions to strengthen regional action and
          partnerships towards achieving the 2030 Agenda for Sustainable
          Development.
        </p>
        <p>
          A key highlight of the session was the resolution on curbing
          greenhouse gas emissions, which emphasized the region's responsibility
          as a significant contributor to global emissions. The resolution
          outlined immediate actions, reflecting the region's determination to
          accelerate efforts and achieve climate goals.
        </p>
        <p>
          The resolution also called for the promotion of clean energy
          technologies, regional power system connectivity, low-emission
          mobility, early warning systems, and the use of climate change-related
          statistics. These measures are considered vital components of
          mitigation and adaptation strategies to address climate change
          effectively.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The 79th session of ESCAP showcased a firm commitment from
            Asia-Pacific countries to urgently address climate change and
            sustainable development. By adopting these resolutions and forging
            strategic partnerships, member countries demonstrate their resolve
            to tackle these global challenges collaboratively. With the
            spotlight on the transformative role of cities and the unique
            vulnerabilities certain countries face, ESCAP aims to drive
            inclusive and sustainable development throughout the region.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          British government commits funding for Experiments in the Amazon
          Rainforest.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/june2023-3.jpg").default}
            alt="june2023-3"
          />
        </figure>
        <p>
          The British government has announced new funding for a groundbreaking
          scientific experiment set in the heart of Brazil's Amazon rainforest.
          Based in the Amazon rainforest, the project focuses on measuring the
          impact of rising carbon dioxide levels on trees and holds tremendous
          potential for shaping sustainable practices and conservation
          strategies.
        </p>
        <p>
          In line with their commitment to combating climate change and
          promoting sustainability, the British government has pledged £7.3
          million ($9.2 million) towards this research initiative.
        </p>
        <p>
          Led by the National Institute for Amazonian Research, the University
          of Campinas in Brazil, and the British Met Office, the
          experiment—dubbed AmazonFACE (Free-Air CO2 Enrichment)—is set to
          transform our comprehension of tropical forests' response to increased
          atmospheric carbon dioxide.
        </p>
        <p>
          Researchers are erecting multiple towers deep within the Amazon
          rainforest to inject carbon dioxide into the forest canopy. By closely
          monitoring how plants absorb this greenhouse gas, the project aims to
          unravel the intricate relationship between rising CO2 levels, plant
          growth, and the delicate balance of the water cycle.
        </p>
        <p>
          Apart from pushing temperatures higher, carbon dioxide can also act as
          a natural fertilizer for plants. This phenomenon has potential
          implications for the resilience of the rainforest in the face of
          climate change. The findings, expected to be available in 2024, will
          be shared with the global scientific community, fostering
          collaboration and enabling a better understanding of Amazon's capacity
          to sequester carbon and its vulnerability to climate change.
        </p>
        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The knowledge gained through the AmazonFACE project will fuel the
            efforts to develop innovative solutions that promote sustainability,
            protect precious ecosystems, and combat climate change. The
            collaboration between Britain and Brazil, including a climate
            partnership launch, underlines the importance of international
            cooperation in addressing global challenges.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          EU and Republic of Korea Forge Green Partnership for Climate Action
          and Sustainable Transition.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/june2023-4.jpg").default}
            alt="june2023-4"
          />
        </figure>
        <p>
          At the EU-Korea Summit in Seoul, the European Union (EU) and the
          Republic of Korea solidified their commitment to climate action and
          sustainable development by establishing a Green Partnership.
        </p>
        <p>
          The partnership aims to enhance bilateral cooperation and exchange
          best practices in various areas of the green transition, including
          climate action, clean energy transition, environmental protection, and
          more. Commission President Ursula von der Leyen and Korean President
          Yoon Suk Yeol jointly launched the Green Partnership, underscoring
          their shared objective of limiting global temperature rise to below
          1.5°C and achieving climate neutrality by 2050.
        </p>
        <p>
          The EU-Korea Green Partnership will prioritize the following areas:
        </p>
        <p>
          <ul>
            <li>
              <b>Combating climate change:</b> Both parties will intensify
              efforts in climate adaptation, carbon pricing, methane emissions
              reduction, and climate finance.
            </li>
            <li>
              <b>Environmental protection:</b> Cooperation will focus on halting
              biodiversity loss, addressing deforestation and forest
              degradation, promoting the circular economy, tackling plastic
              waste across its lifecycle, and implementing the Kunming-Montreal
              Global Biodiversity Framework.
            </li>
            <li>
              <b>Clean and fair energy transition:</b> The partnership will
              enhance collaboration on renewable energies, energy efficiency,
              renewable and low-carbon hydrogen, phasing out coal-fired power
              generation, advancing batteries and green mobility, and Carbon
              Capture, Utilization and Storage (CCUS).
            </li>
            <li>
              <b>Green transition facilitation:</b> The EU and Korea will
              jointly support third countries in transitioning to sustainable
              practices, including climate change itigation, adaptation,
              resilience, clean energy transition, and circular economy
              measures.
            </li>
            <li>
              The Green Partnership also encompasses cooperation in areas such
              as business collaboration, sustainable finance, research and
              innovation, sustainable food systems, supply chain resilience,
              employment, and the social aspects of the green transition.
            </li>
          </ul>
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            The EU and the Republic of Korea's Green Partnership sets a strong
            example of international collaboration in addressing climate change
            and advancing sustainability. Both parties aim to accelerate the
            global transition to a greener and more resilient future by sharing
            expertise and working together.
          </p>
        </div>
        <div className="Newsletter-title" style={{ paddingTop: " 57px" }}>
          Dubai's Roads and Transport Authority launches a net zero strategy for
          its public transportation.
        </div>

        <figure style={{ marginTop: "25px" }}>
          <img
            className="picture"
            src={require("../../../../../assets/images/june2023-5.jpg").default}
            alt="june2023-5"
          />
        </figure>
        <p>
          Dubai's Roads and Transport Authority (RTA) has unveiled an
          initiative, the Zero-Emissions Public Transportation in Dubai 2050,
          adopting an ESG approach.
        </p>
        <p>
          The RTA's ambitious ESG-driven strategy is expected to reduce 10
          million tonnes of carbon dioxide emissions significantly. Furthermore,
          it aims to generate significant financial savings of AED 3.3 billion
          ($USD 900 million).
        </p>
        <p>
          The authority has set specific interim targets as part of this
          strategy. By 2030, they aim to convert 10% of public transport buses
          to electric and hydrogen vehicles, with a subsequent increase to 40%
          by 2040 and a complete transition to 100% by 2050. Additionally, they
          plan to retrofit buildings and facilities with solar cell systems,
          upgrading 74% of existing buildings by 2030 and achieving 100% by
          2045. All new buildings are expected to be near zero energy by 2025.
        </p>
        <p>
          The RTA also plans to implement initiatives for reusing and recycling
          100% of municipal waste by 2030 and increase the use of recycled water
          in buildings and facilities to 40% by 2050.
        </p>
        <p>
          To achieve these ambitious ESG goals, the RTA has outlined targeted
          actions in each category, including decarbonising all taxis,
          limousines, and public buses, designing buildings with near-zero
          energy consumption, sourcing energy from renewable sources, and
          implementing waste management practices that eliminate the need for
          landfills.
        </p>

        <div className="updapt-views">
          <div className="views" style={{ display: "flex" }}>
            <img
              src={require("../../../../../assets/Newsletter/view1.svg")}
              width="40px"
              height="40px"
              alt="view1"
            />
            <h3>Updapt Views:</h3>
          </div>
          <p style={{ marginLeft: " 22px", color: "white", width: "96%" }}>
            Dubai's RTA is well on its way to achieving its vision of
            sustainable and eco-friendly transportation, contributing to global
            efforts to combat climate change while improving commuters' and
            residents' lives.
          </p>
        </div>
      </div>
      <Subscribe />
    </>
  );
}

export default NewsletterNine;
