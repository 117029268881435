import React from "react";

function Intro() {
  return (
    <section className="page-banner">
      <div className="container">
        <div className="page-content-wrapper">
          <div className="page-badge">ANALYTICS</div>
          <h1 className="page-title">
            {/* Use Analytics to Obtain Valuable Insights */}
            ESG Analytics and Reporting
          </h1>
          <p className="page-description">
            Make strategic decisions, benchmark performance, and communicate
            more effectively with internal and
            <br />
            external stakeholders with robust ESG analysis.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Intro;
