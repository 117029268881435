import React, { useEffect, useState } from "react";
import PodcastInfo from "../component/PodcastInfo";
import { jsPDF } from "jspdf";
import podcastValues from "../../../../shared/constants/podcastValues";
import { useParams } from "react-router-dom";

function PodcastInfoContainer(props) {
  const { info } = useParams();

  let podcastData = podcastValues?.find((ele) => {
    return ele?.url === info;
  });

  const [audioInfo, setAudioInfo] = useState(undefined);
  const [showPlayerStatus, setShowPlayerStatus] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [playTime, setPlayTime] = useState({
    currentPlayTime: "00:00:00",
    totalTime: 0,
  });
  const [audioProps, setAudioProps] = useState({
    initSpeed: 1,
    curTime: 0,
    totalDuration: 0,
    initVolume: 0.5,
    status: false,
  });

  //Function not in use so added false condition to remove sonarqube error
  const generatePdf = (type) => {
    if (type === "true") {
      const doc = new jsPDF();
      // Source HTMLElement or a string containing HTML.
      const elementHTML = document.querySelector(".transcript-body");
      doc.html(elementHTML, {
        callback: function (doc) {
          // Save the PDF
          doc.save("sample-document.pdf");
        },
        x: 15,
        y: 15,
        width: 170, //target width in the PDF document
        windowWidth: 650, //window width in CSS pixels
      });
    }
  };
  generatePdf("false");

  useEffect(() => {
    if (showPlayerStatus) {
      const aud = document.getElementById("player");
      aud.volume = 0.5;
      setAudioInfo(aud);
      document.querySelector(".progress").style.background = "#F0F0F0";
      document.querySelector(".volume-bar").style.background = "#B2B1B1";
      document.querySelector(".mobile-progress").style.background = "#F0F0F0";
      document.querySelector(".mobile-volume-bar").style.background = "#B2B1B1";

      if (!audioInfo?.muted) {
        document.querySelector(
          ".volume-bar"
        ).style.background = `linear-gradient(to right, #3EBB63 0%, #3EBB63 ${
          (0.5 * 100) / 1.0
        }%, #B2B1B1 ${(0.5 * 100) / 1.0}%, #B2B1B1 100%)`;

        document.querySelector(
          ".mobile-volume-bar"
        ).style.background = `linear-gradient(to right, #3EBB63 0%, #3EBB63 ${
          (0.5 * 100) / 1.0
        }%, #B2B1B1 ${(0.5 * 100) / 1.0}%, #B2B1B1 100%)`;
      }
    }
  }, [audioInfo, showPlayerStatus]);

  if (audioInfo) {
    audioInfo.ontimeupdate = () => {
      if (audioInfo?.duration === audioInfo?.currentTime) {
        audioInfo.currentTime = 0;
      }

      document.querySelector(
        ".progress"
      ).style.background = `linear-gradient(to right, #3EBB63 0%, #41ea73 ${
        (audioInfo.currentTime * 100) / audioInfo.duration
      }%, #F0F0F0 ${
        (audioInfo.currentTime * 100) / audioInfo.duration
      }%, #F0F0F0 100%)`;

      document.querySelector(
        ".mobile-progress"
      ).style.background = `linear-gradient(to right, #3EBB63 0%, #41ea73 ${
        (audioInfo.currentTime * 100) / audioInfo.duration
      }%, #F0F0F0 ${
        (audioInfo.currentTime * 100) / audioInfo.duration
      }%, #F0F0F0 100%)`;

      let date = new Date(Math.trunc(audioInfo?.duration));
      date.setSeconds(Math.trunc(audioInfo?.currentTime));
      playTime.currentPlayTime = date.toISOString().slice(11, 19);
      setPlayTime({ ...playTime });
    };
  }
  const handleAudioSeek = async (e) => {
    audioInfo.currentTime = e.target.value;
    setAudioInfo(audioInfo);
  };

  const playPause = () => {
    if (audioInfo.paused) {
      audioInfo.play();
      audioProps.status = true;
    } else {
      audioInfo.pause();
      audioProps.status = false;
    }
    setAudioInfo(audioInfo);
  };

  const slowRate = (n) => {
    audioInfo.playbackRate -= n;
    setAudioInfo(audioInfo);
    audioProps.initSpeed -= n;
    setAudioProps({ ...audioProps });
  };

  const fastRate = (n) => {
    if (audioProps.initSpeed !== 3) {
      audioInfo.playbackRate += n;
      audioProps.initSpeed += n;
    } else {
      audioInfo.playbackRate = 1;
      audioProps.initSpeed = 1;
    }
    setAudioInfo(audioInfo);
    setAudioProps({ ...audioProps });
  };

  const forward = (n) => {
    audioInfo.currentTime += n;
    setAudioInfo(audioInfo);
  };

  const rewind = (n) => {
    audioInfo.currentTime -= n;
    setAudioInfo(audioInfo);
  };

  const muteUnmute = () => {
    if (audioInfo.muted === false && audioInfo.volume > 0) {
      audioInfo.volume = 0;
      audioInfo.muted = true;
      audioProps.initVolume = 0;
      document.querySelector(
        ".volume-bar"
      ).style.background = `linear-gradient(to right, #3EBB63 0%, #3EBB63 ${
        (0 * 100) / 1.0
      }%, #B2B1B1 ${(0 * 100) / 1.0}%, #B2B1B1 100%)`;
      document.querySelector(
        ".mobile-volume-bar"
      ).style.background = `linear-gradient(to right, #3EBB63 0%, #3EBB63 ${
        (0 * 100) / 1.0
      }%, #B2B1B1 ${(0 * 100) / 1.0}%, #B2B1B1 100%)`;
    } else {
      audioInfo.volume = 0.5;
      audioInfo.muted = false;
      audioProps.initVolume = 0.5;
      document.querySelector(
        ".volume-bar"
      ).style.background = `linear-gradient(to right, #3EBB63 0%, #3EBB63 ${
        (0.5 * 100) / 1.0
      }%, #B2B1B1 ${(0.5 * 100) / 1.0}%, #B2B1B1 100%)`;
      document.querySelector(
        ".mobile-volume-bar"
      ).style.background = `linear-gradient(to right, #3EBB63 0%, #3EBB63 ${
        (0.5 * 100) / 1.0
      }%, #B2B1B1 ${(0.5 * 100) / 1.0}%, #B2B1B1 100%)`;
    }
    setAudioProps({ ...audioProps });
    setAudioInfo(audioInfo);
  };

  const changeVolume = async (e) => {
    audioInfo.volume = e.target.value;
    audioProps.initVolume = e.target.value;
    setAudioProps({ ...audioProps });
    setAudioInfo(audioInfo);
    document.querySelector(
      ".volume-bar"
    ).style.background = `linear-gradient(to right, #3EBB63 0%, #3EBB63 ${
      (e.target.value * 100) / 1.0
    }%, #B2B1B1 ${(e.target.value * 100) / 1.0}%, #B2B1B1 100%)`;

    document.querySelector(
      ".mobile-volume-bar"
    ).style.background = `linear-gradient(to right, #3EBB63 0%, #3EBB63 ${
      (e.target.value * 100) / 1.0
    }%, #B2B1B1 ${(e.target.value * 100) / 1.0}%, #B2B1B1 100%)`;
  };

  if (audioInfo) {
    audioInfo.onvolumechange = () => {
      audioProps.initVolume = audioInfo.volume;
      setAudioProps({ ...audioProps });
      document.querySelector(
        ".volume-bar"
      ).style.background = `linear-gradient(to right, #3EBB63 0%, #3EBB63 ${
        (audioInfo.volume * 100) / 1.0
      }%, #B2B1B1 ${(audioInfo.volume * 100) / 1.0}%, #B2B1B1 100%)`;
    };
  }

  const showPlayer = () => {
    setShowPlayerStatus(true);
  };

  useEffect(() => {
    const a = document.getElementById("dummy-player");
    if (a !== null) {
      a.onloadedmetadata = () => {
        setAudioDuration(Math.ceil(a.duration / 60));
        let date = new Date(Math.trunc(a?.duration));
        date.setSeconds(Math.trunc(a?.duration));
        playTime.totalTime = date.toISOString().slice(11, 19);
        setPlayTime({ ...playTime });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shareContent = (content, socialmedia) => {
    const websiteUrl = `https://updaptcsr.com/resource/podcast/${content}`;
    const websiteText = "Updapt CSR: ";

    let shareUrl;
    if (socialmedia === "facebook") {
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        websiteUrl
      )}&quote=${encodeURIComponent(websiteUrl)}`;
    } else if (socialmedia === "twitter") {
      shareUrl = `https://twitter.com/share?url=${encodeURIComponent(
        websiteUrl
      )} 
      &text=${encodeURIComponent(websiteText)}`;
    } else {
      shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        websiteUrl
      )} 
      &title=${encodeURIComponent(websiteText)}`;
    }

    window.open(shareUrl, "LinkedIn Share", "height=500,width=600");
  };

  return (
    <PodcastInfo
      audioDuration={audioDuration}
      showPlayer={showPlayer}
      podcastData={podcastData}
      // generatePdf={generatePdf}
      playPause={playPause}
      audioProps={audioProps}
      handleAudioSeek={handleAudioSeek}
      slowRate={slowRate}
      fastRate={fastRate}
      forward={forward}
      rewind={rewind}
      muteUnmute={muteUnmute}
      changeVolume={changeVolume}
      audioInfo={audioInfo}
      showPlayerStatus={showPlayerStatus}
      playTime={playTime}
      shareContent={shareContent}
    />
  );
}

export default PodcastInfoContainer;
